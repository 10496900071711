import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "18px",
    fontWeight: "500",
    margin: "8px",
    display: "inline-block",
    '@media (max-width: 600px)': {
      fontSize: "15px",
    },
  },
}));

const LbTitle = (props) => {
  const classes = useStyles();
  return (
    <span
      className={classes.root}
      style={{
        fontSize: props.size + "px",
        color: props?.color ? props?.color : "#000",
        fontWeight: props.weight?props.weight:'400'
      }}
    >
      {props?.name}
    </span>
  );
};

LbTitle.propTypes = {
  name: PropTypes.string,
};

export default LbTitle;
