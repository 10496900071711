import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import ItemCard from "../shared/item-card";
import { getRequest } from "../core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LbSearch from "../shared/components/lb-search/lb-search";
import { useLocation } from "react-router-dom";
import {
  BUSINESSCATEGORYLIST,
  defaultPageSize,
  vendorSortingFields,
} from "core/utils/constants";
import { useSelector } from "react-redux";
import { GAPageViewTrigger, searchFilter } from "core/utils/utilities";
import SortingMenu from "shared/components/sorting/sorting-menu";
import { SEO } from "core/common/seo-title";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  alignEnd: {
    justifyContent: "flex-end",
  },
}));

export default function VendorList() {
  const classes = useStyles();
  const search = useLocation().search;
  const settingValueId = new URLSearchParams(search).get("settingValueId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [vendorList, setVendorList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const business = BUSINESSCATEGORYLIST?.find((item) =>
    item?.link?.includes(settingValueId)
  );
  const locationDialog = useSelector((state) => state.appData.locationDialog);

  // Get List of data from Api
  const getVendorsList = (firstLoad, pageNo) => {
    getRequest(
      "LbVendor/GetVendors?settingValueId=" +
        settingValueId +
        "&zipcode=" +
        JSON.parse(localStorage.getItem("LB_USER_LOCATION"))?.zip_code +
        `&pageSize=${defaultPageSize}&pageNo=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          //****** Pagination load Data logic start ****
          let addMoreData = [];
          if (firstLoad) addMoreData.push(...resp.data);
          else addMoreData.push(...vendorList, ...resp.data);
          //****** Pagination load Data logic End ****
          setRecordCount(resp?.data?.length);
          setVendorList(addMoreData);
          setSearchList(addMoreData);
          setFilteredList(addMoreData);
          //  console.log("hii",resp?.data)
          if (resp?.data?.length === 0)
            setNoRecordMsg(t("Vendor.VendorDetails.NO_RECORD_MSG"));
          else setNoRecordMsg("");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.GETVENDORLISTFAILED"), {
          variant: "error",
        });
      });
  };

  //******Serach Result *********/
  const [searchList, setSearchList] = useState([]);
  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setFilteredList(filterdData);
  };
  //******Serach Result end *********/

  // Handle use effect
  useEffect(() => {
    GAPageViewTrigger(
      window.location.pathname + window.location.search,
      "vendor-list"
    );
    //  console.log(window.location.pathname + window.location.search);
    if (!locationDialog) getVendorsList(true, 1);
  }, [locationDialog]);

  const pageSEOTitle = {
    name: "Lagan Booking",
    sub_title: t(business?.title) + " - Vendor Listing",
    descriptio: "Search wedding vendors near your location",
  };

  const loadMore = () => {
    setInitialPageNo(initialPageNo + 1);
    getVendorsList(false, initialPageNo + 1);
  };
  return (
    <div>
      <SEO {...pageSEOTitle} />
      <Grid container className={classes.alignment}>
        <Grid item xs className="mx-2">
          <LbSearch
            sticky={true}
            searchValue={searchValue}
            placeholder={t("Shared.SEARCH_NAME_ADDRESS")}
          ></LbSearch>
        </Grid>
        <Grid item>
          <SortingMenu
            data={vendorList}
            updateData={setFilteredList}
            sortingFields={vendorSortingFields}
          ></SortingMenu>
        </Grid>
      </Grid>
      <Grid container direction="row">
        {filteredList?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={4} xl={3} key={index}>
              <ItemCard
                data={item}
                settingValueId={settingValueId}
                icon={business?.icon}
              />
            </Grid>
          );
        })}
        <div className="m-auto">
          {filteredList.length === 0 || noRecordMsg ? (
            <div>{noRecordMsg}</div>
          ) : recordCount === defaultPageSize ? (
            <Button
              className="my-8"
              type="button"
              size="small"
              variant="contained"
              color="secondary"
              onClick={loadMore}
            >
              {t("Shared.SHOWRESULT")}
            </Button>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </div>
  );
}
