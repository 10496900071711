import React from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core";
import { ThemeColor } from "core/utils/constants";
import { useContext } from "react";
import { ThemeContext } from "App";
import { useEffect } from "react";
import { getLocalStorage } from "core/utils/utilities";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  colorblock: {
    backgroundColor: "red",
    width: "40px",
    display: "inline-block",
  },
}));

export default function ThemeSwitch() {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(ThemeColor.BLUE);
  const { setValue } = useContext(ThemeContext);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setValue(event.target.value);
    localStorage.setItem("CURRENT_THEME", JSON.stringify(event.target.value));
    log_activity("handleChange", "theme-wsitch.jsx", "Success");
  };

  useEffect(() => {
    let currentSelectedTheme = getLocalStorage("CURRENT_THEME");
    setSelectedValue(currentSelectedTheme);
  }, []);

  return (
    <div className={classes.root}>
      <div className="flex space-between align-center">
        <div>
          <Radio
            defaultChecked
            checked={selectedValue === ThemeColor.BLUE}
            onChange={handleChange}
            value={ThemeColor.BLUE}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <div>
          <span
            className={classes.colorblock}
            style={{ backgroundColor: "#3f51b5" }}
          >
            &nbsp;
          </span>
        </div>
      </div>
      <div className="flex space-between align-center">
        <div>
          <Radio
            checked={selectedValue === ThemeColor.PINK}
            onChange={handleChange}
            value={ThemeColor.PINK}
            name="radio-button-demo"
            inputProps={{ "aria-label": "B" }}
          />
        </div>
        <div>
          <span
            className={classes.colorblock}
            style={{ backgroundColor: "#f50057" }}
          >
            &nbsp;
          </span>
        </div>
      </div>
      <div className="flex space-between align-center">
        <div>
          <Radio
            checked={selectedValue === ThemeColor.YELLOW}
            onChange={handleChange}
            value={ThemeColor.YELLOW}
            name="radio-button-demo"
            inputProps={{ "aria-label": "B" }}
          />
        </div>
        <div>
          <span
            className={classes.colorblock}
            style={{ backgroundColor: "#e7ad00" }}
          >
            &nbsp;
          </span>
        </div>
      </div>
    </div>
  );
}
