import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Home, PhoneInTalk, AccessTime } from "@material-ui/icons";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useTranslation } from "react-i18next";
import { GAEventTrack, getBusinesNameBySettingValueId } from "core/utils/utilities";
import VerifyIcon from "./components/verify-icon";
import LbFavorite from "./lb-favorite";
import { Rating } from "@material-ui/lab";
import { log_activity } from "./services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "6px 6px 6px 6px",
  },
  card: {
    textAlign: "center",
    margin: "auto",
    border: "1px solid " + theme.palette.primary.main,
    transition: "transform 0.3s ease",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      transform: "scale(1.01)",
    },
  },
  content: {
    textAlign: "left",
    padding: theme.spacing(1),
    height: "max-content",
  },
  heading: {
    fontWeight: "500",
    textTransform: "capitalize",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "350px !important",
    marginLeft: "10px",
  },
  subTitle: {
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      marginLeft: -theme.spacing(1),
    },
  },
  media: {
    height: "150px",
  },
  spaceBtw: {
    marginTop: 4,
    justifyContent: "space-between",
  },
  img: {
    position: "absolute",
    right: 0,
    top: 6,
    width: "75px",
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  header: {
    height: "50px",
    paddingTop: "7px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffff",
  },
  imageIcon: {
    position: "absolute",
    marginRight: '27px',
    height: '40px',
    right: "0",
    top: "50px",
  },
  prFav: {
    paddingRight: "24px",
  },
  rightAlign: {
    justifyContent: "flex-end",
  },
}));

export default function ItemCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSelect = () => {
    GAEventTrack("Vendor","Vendor-Slection","Button","Details")
    //  console.log(props.settingValueId);
    dispatch(appActions.setInventoryVendor(props.data));
    // if(props.settingValueId === 1)
    history.push("/VendorDetails?settingValueId=" + props.settingValueId);
    log_activity("VendorDetails", "item-card.jsx", "Details view");
  };

  return (
    <div className={classes.root}>
      <Card className={[classes.card].join(" ")}>
        <div className={classes.header}>
          <Grid item container direction="row" className="center">
            <Typography className={classes.heading} variant={"h6"}>
              {props.data?.org_name
                ? props.data?.org_name
                : "Lagan Booking(Partner)"}  {props?.data?.is_verified ? <VerifyIcon /> : ""}
            </Typography>
          </Grid>
        </div>
        <Divider className={classes.divider} light />
        <CardMedia
          className={classes.media}
          component="img"
          src={props?.data?.banner_img?props?.data?.banner_img:"images/bg/" + props.settingValueId + ".jpg"} 
          title="banner images"
        />
        <CardContent className={classes.content + " relative"}>
          <Typography variant="h6" className={classes.subTitle + " left"}>
            {props.data?.first_name + " " + props.data?.last_name + " "}
          </Typography>
          <LbFavorite
            data={props.data}
            settingValueId={props.settingValueId}
          ></LbFavorite>
          <Grid item container direction="row">
            <PhoneInTalk className={classes.iconColor + " mr-5"} />
            <Typography variant="body1" color="textSecondary">
              {props.data?.mobile_no}
            </Typography>
            <img
              className={classes.imageIcon}
              src={`/images/icons/1/${props.icon}.png`}
              alt="category"
            />
          </Grid>
          <Grid item container direction="row">
            <AccessTime className={classes.iconColor + " mr-5"} />
            <Typography variant="body1" color="textSecondary">
              {"Since - " + props.data?.estb_year}
            </Typography>
          </Grid>
          <Grid item container direction="row" className="no-wrap">
            <Home className={classes.iconColor + " mr-5"} />
            <Typography
              variant="body1"
              color="textSecondary"
          //    style={{ width: "68%" }}
            >
              {props.data?.city_local +
                ", " +
                props.data?.taluka +
                ", " +
                props.data?.district}
            </Typography>
          </Grid>
          <Grid item container direction="row" className="mt-16">
            <Typography variant="body1">
              {t("Vendor.VendorDetails.BUSINESS_TYPE")} :
            </Typography>
            <Typography variant="body1">
              {t(
                getBusinesNameBySettingValueId(parseInt(props.settingValueId))
              )}
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            spacing={2}
            className={classes.spaceBtw}
          >
            <Grid item className="pointer">
              <Rating value={props.data?.star_rating} size="small" readOnly />{" "}
              <span>{props.data?.review_count + " reviews"}</span>
            </Grid>
            <Grid item className={classes.rightAlign + " pointer"}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  onSelect();
                }}
              >
                {t("Vendor.VendorDetails.DETAILS")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
