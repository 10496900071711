import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Chip,
  Avatar,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LbDialogContent from "shared/components/overridden-component/lb-dialog-content";
import LbDialogTitle from "shared/components/overridden-component/lb-dialog-title";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  get_booking_contract,
  get_booking_details,
} from "customer/services/customer-helper";
import { useSnackbar } from "notistack";
import { dateFormat, getUserType } from "core/utils/utilities";
import CardArt1 from "shared/components/card-art1";
import { defaultQuantity } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 4,
    marginRight: 4,
  },
  widthList: {
    width: "400px",
    marginLeft: "5px",
  },
  headAlign: {
    fontSize: "1rem",
    marginBottom: "3px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
}));
function BookingDetails({ bookingId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = React.useState({});
  const [serviceList, setServiceList] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const msg = {
    NoDetail: t("HelperMsg.NODETAIL"),
    GetBookingFail: t("HelperMsg.GETBOOKINGFAIL"),
  };
  const handleClickOpen = () => {
    let custVend = getUserType();
    get_booking_details(
      bookingId,
      custVend,
      setOpen,
      setResponse,
      setServiceList,
      enqueueSnackbar,
      msg
    );
    log_activity("get_booking_details", "booking-details.jsx", "Success");
  };

  const handleClose = () => {
    setOpen(false);
    log_activity("handleClose", "booking-details.jsx", "Success");
  };

  return (
    <>
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          {t("Shared.FORM.DETAIL_VIEW")}
        </Button>
      </Box>

      <Dialog onClose={handleClose} open={open}>
        <LbDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("Shared.FORM.BOOKING_DETAILS")}
        </LbDialogTitle>
        <LbDialogContent dividers>
          <CardArt1 leftS="-8px" topS="-8px" heightS="80px" widthS="80px" />
          <CardArt1
            rotate={90}
            rightS="-8px"
            topS="-8px"
            heightS="80px"
            widthS="80px"
          />
          <div className={classes.paper}>
            <List dense={false}>
              <ListItem>
                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.FORM.CUSTOMER_NAME")}
                  secondary={response?.first_name + " " + response?.last_name}
                />

                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.FORM.MOBILE_NO")}
                  secondary={response?.mobile_no}
                />
              </ListItem>

              <div className={classes.headAlign}>
                {t("Shared.FORM.SELECTED_SERVICE_LIST")}
              </div>
              <ListItem className="wrap gap-4">
                {serviceList?.map((value, key) => (
                  <Chip
                    key={key}
                    avatar={
                      <Avatar
                        style={{
                          color: "#fff",
                          background: "#575757",
                        }}
                      >
                        {value?.quantity ?? defaultQuantity}
                      </Avatar>
                    }
                    size="small"
                    label={value?.setting_value}
                  />
                ))}
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.FORM.LAGAN_DATE")}
                  secondary={dateFormat(response?.lagan_date)}
                />
                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.FORM.PINCODE")}
                  secondary={response?.s_zip_code}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.ADDRESS")}
                  secondary={`
                      ${response?.s_address}, 
                      ${response?.s_city_local},
                      ${response?.s_district}, 
                      ${response?.s_region}, 
                      ${response?.s_state},
                      ${response?.s_taluka},
                      ${response?.s_state}
                      `}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.widthList}
                  primary={t("Shared.FORM.NOTES")}
                  secondary={response?.notes}
                />
              </ListItem>
              <ListItem className="center">
                <Link
                  href={`/booking-contract/?bookingId=${bookingId}`}
                  className="mr-16"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <Button variant="outlined" size="small" color="primary">
                    {"View Contract"}
                  </Button>
                </Link>
              </ListItem>
            </List>
          </div>
          <CardArt1
            rotate={180}
            rightS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
          <CardArt1
            rotate={270}
            leftS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
        </LbDialogContent>
      </Dialog>
    </>
  );
}

export default BookingDetails;
