import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { getRequest } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LbSearch from "shared/components/lb-search/lb-search";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { log_activity } from "shared/services/common-service";

export default function VendorSearch() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchedText, setSearchedText] = useState("");
  const [searchedResult, setSearchedResult] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const onSelect = (item) => {
    //  console.log(props.settingValueId);
    dispatch(appActions.setInventoryVendor(item));
    // if(props.settingValueId === 1)
    history.push(
      "/VendorDetails?settingValueId=" + item.setting_value_id?.split(",")[0]
    );
    //  console.log("data", item)
    log_activity("onSelect - View vendor details", "vendor-search.js", "Success");
  };
  const msg = {
    failedmsg: t("HelperMsg.GETSETTINGVALUEFAILED"),
  };
  const searchValue = (value) => {
    setSearchedText(value);
  };

  const getSearchResult = (value) => {
    if (searchedText.length > 0) {
      getRequest(
        "LbVendor/SearchVendors/?settingValueId=" +
          0 +
          "&searchText=" +
          searchedText
      )
        .then((resp) => {
          if (resp?.status === 200) {
            setSearchedResult(resp.data);
            // console.log(resp.data);
            log_activity("getSearchResult", "vendor-search.js", "Success");
          }
        })
        .catch((error) => {
          enqueueSnackbar(msg.failedmsg, {
            variant: "error",
          });
          log_activity("getSearchResult", "vendor-search.js", "Exception: "+JSON.stringify(error));
        });
    }
  };

  return (
    <>
      <Grid container spacing={1} className="m-4">
        <Grid item xs={9}>
          <LbSearch
            sticky={true}
            searchValue={searchValue}
            placeholder={t("Shared.SEARCH_NAME_PHONE")}
          ></LbSearch>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="mt-5"
            onClick={getSearchResult}
          >
            {t("Shared.SEARCH_BTN")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{ width: "100%", maxWidth: 360 }}
            style={{ cursor: "pointer" }}
          >
            {searchedResult?.map((item, index) => {
              return (
                <>
                  <ListItem alignItems="flex-start" key={index}>
                    <ListItemAvatar>
                      <Avatar alt={item.first_name} src={item.banner_img} />
                    </ListItemAvatar>
                    <ListItemText
                      onClick={() => {
                        onSelect(item);
                      }}
                      primary={
                        item.first_name +
                        " " +
                        item.last_name +
                        " - " +
                        item.mobile_no
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.org_name + ", "}
                          </Typography>
                          {item.city_local +
                            ", " +
                            item.district +
                            ", " +
                            item.taluka +
                            ", " +
                            item.state}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
