import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { getRequest } from "../utils/axios-client/axios-client";
import { custRoutes, vendorRoutes } from "../utils/constants";
import { auth } from "../utils/firebase/firebase-config";
import { logOut } from "../utils/utilities";
import { log_activity } from "shared/services/common-service";
import secureLocalStorage from "react-secure-storage";

export const sign_in_func = async (values, enqueueSnackbar, loginMsg) => {
  try {
    await signInWithEmailAndPassword(auth, values.email_id, values.password);
    if (values.is_remember)
      secureLocalStorage.setItem("LbCred", {
        email_id: values.email_id,
        pwd: values.password,
        is_remember:values.is_remember
      });
    else {
      secureLocalStorage.removeItem("LbCred");
    }
  } catch (error) {
    if (
      error.code === "auth/user-not-found" ||
      error.message === "EMAIL_NOT_FOUND"
    ) {
      enqueueSnackbar(loginMsg.LoginError, {
        variant: "error",
      });
    } else if (error.code === "auth/wrong-password") {
      enqueueSnackbar(loginMsg.LoginErrorPass, {
        variant: "error",
      });
    } else {
      enqueueSnackbar(loginMsg.LoginErrorUser, {
        variant: "error",
      });
    }
    //: loginMsg.LoginErrorPass
  }
};

export const getUserDetail = async (
  resp,
  enqueueSnackbar,
  history,
  dispatch,
  appActions,
  msg
) => {
  getCurrentUserToken();
  setTimeout(() => {
    getRequest("LbAuth/GetUser/" + resp.uid)
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem("USER_DATA", JSON.stringify(response.data));
          dispatch(appActions.updateUser(response.data));
          log_activity(
            "Login Button clicked",
            "Sign_in_healper",
            "Login Successful"
          );
          // Get User personalize data
          getUserPersonalization(
            response.data?.account_id,
            enqueueSnackbar,
            history,
            dispatch,
            appActions,
            msg
          );
          if (response?.data?.location_id > 0) {
            if (response?.data?.vendor_id > 0) {
              if (response.data?.setting_value_id?.indexOf(",") > 0) {
                dispatch(
                  appActions.setVenSettingValueId(
                    response.data?.setting_value_id
                  )
                );
                history.push("/vendor/business-select");
                log_activity(
                  "Login Button clicked",
                  "Sign_in_healper",
                  "Redirected to Business selection page"
                );
              } else if (
                response.data?.setting_value_id > 0 ||
                response.data?.setting_value_id?.length > 1
              )
                history.push("/vendor/dashboard");
              else history.push("/ownerRegist");
            } else {
              if (
                history.location.state &&
                (vendorRoutes.indexOf(history.location.state?.prevRoute) > -1 ||
                  custRoutes.indexOf(history.location.state?.prevRoute) > -1 ||
                  ["/vendor-list"].indexOf(history.location?.state.prevRoute) >
                    -1)
              )
                setTimeout(() => {
                  history.goBack();
                }, 2000);
              else history.push("/");
            }
          } else {
            if (response?.data?.customer_id > 0)
              history.push("/customer/profile");
            else if (response?.data?.vendor_id > 0)
              history.push("/vendor/profile");
          }
          enqueueSnackbar(msg.successMsg, {
            variant: "success",
          });
        } else if (response?.status === 204) {
          enqueueSnackbar(msg.userRegister, {
            variant: "error",
          });
          history.push("/register");
          log_activity(
            "Login Button clicked",
            "Sign_in_healper",
            "Redirected to Register page"
          );
        } else {
          logOut(enqueueSnackbar, msg.LOGOUT);
          enqueueSnackbar(msg.failedMsg, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        logOut(enqueueSnackbar, msg.LOGOUT);
        console.log(msg.failedMsg);
        log_activity(
          "Login Button clicked - Error",
          "Sign_in_healper",
          error.message
        );
        // enqueueSnackbar(msg.failedMsg, {
        //   variant: "error",
        // });
      });
  }, 2000);
};
export const getUserPersonalization = async (
  accountId,
  enqueueSnackbar,
  history,
  dispatch,
  appActions,
  msg
) => {
  getRequest("LbProfile/GetUserPersonalization/" + accountId)
    .then((response) => {
      if (response?.status === 200) {
        let perData = {
          account_id: response.data?.account_id,
          data_value: JSON.parse(response.data?.data_value),
        };
        log_activity("GetUserPersonalization", "Sign_in_healper", "success");
        dispatch(appActions.setUserPerData(perData));
        // enqueueSnackbar(msg.successMsg, {
        //   variant: "success",
        // });
      } else if (response?.status === 204) {
        enqueueSnackbar(msg.userRegister, {
          variant: "error",
        });
      }
    })
    .catch((error) => {
      //   logOut(enqueueSnackbar, msg.LOGOUT);
      // enqueueSnackbar(msg.genricError, {
      //   variant: "error",
      // });
      console.log(msg.genricError);
      log_activity(
        "GetUserPersonalization - Error",
        "Sign_in_healper",
        "Error Occured : " + error?.message
      );
    });
};

export const getCurrentUserToken = () => {
  auth.currentUser
    ?.getIdToken(true)
    .then(function (idToken) {
      localStorage.setItem("LB_TOKEN", idToken);
    })
    .catch(function (error) {});
};

export const reset_password_email = async (
  emailId,
  enqueueSnackbar,
  history,
  dispatch,
  msg
) => {
  try {
    await sendPasswordResetEmail(auth, emailId)
      .then(() => {
        enqueueSnackbar(msg.resetpass, {
          variant: "success",
        });
        history.goBack();
      })
      .catch((error) => {
        enqueueSnackbar(error.code + ": " + error.message, {
          variant: "error",
        });
      });
  } catch (error) {
    // console.log(error.message);
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  }
};

export const setUpReCaptcha = (phoneNumber) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    "captcha-container",
    {},
    auth
  );
  recaptchaVerifier.render();
  return signInWithPhoneNumber(auth, "+91" + phoneNumber, recaptchaVerifier);
};

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = async () =>
  signInWithPopup(auth, googleProvider);

const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ display: "popup" });

export const signInWithFacebook = async () =>
  signInWithPopup(auth, facebookProvider);
