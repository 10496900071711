import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { getNoBaseUrlRequest } from "core/utils/axios-client/axios-client";
import { useState } from "react";
import { useEffect } from "react";
import { log_activity } from "shared/services/common-service";
import LbContentSlider from "shared/slider/cms-content-slider";
import { wpFeaturesUrl } from "core/utils/constants";
export default function LandingFeatures(props) {
  const { t } = useTranslation();
  const [featureList, setFeatureList] = useState([]);
  const msg = {
    apiFailed: t("HelperMsg.APIFALIED"),
  };

  const getFeaturesList = () => {
    getNoBaseUrlRequest(wpFeaturesUrl)
      .then((resp) => {
        if (resp.status === 200) {
            setFeatureList(resp.data);
        } else {
          console.log("Features-blog.jsx, else : "+msg.apiFailed);
          log_activity("getFeaturesList", "Features-blog.jsx", "Success");
        }
      })
      .catch((error) => {
        console.log("Features-blog.jsx, catch : "+msg.apiFailed);
        log_activity("getFeaturesList", "Features-blog.jsx", "Exception: "+JSON.stringify(error));
      });
  };

  useEffect(() => {
    getFeaturesList(props);
  }, []);

  return (
    <div className="m-4">
      <Grid container spacing={2}>
        <Suspense fallback={<>...</>}>
          <LbContentSlider
            arrayData={featureList}
            slidesPerView={4}
            navigation={true}
            spaceBetween={10}
            effect={true}
            autoplay={true} />
        </Suspense>
      </Grid>
    </div>
  );
}
