import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomerBookingCard from "./customer-booking-card";
import { get_customer_booking } from "customer/services/customer-helper";
import LbSearch from "shared/components/lb-search/lb-search";
import { searchFilter } from "core/utils/utilities";
import { useSnackbar } from "notistack";
import { getSorting } from "core/utils/utilities";
import { defaultPageSize } from "core/utils/constants";
import NotFound from "shared/components/not-found/not-found";
import LbHeading from "shared/components/lb-heading/lb-heading";
const useStyles = makeStyles((theme) => ({
  title: {
    margin:"0px"
  },
}));
function CustomerBookingList() {
  const classes = useStyles();
  const [displayList, setDisplayList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  //******Serach Result *********/
  const [searchList, setSearchList] = useState([]);
  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setDisplayList(filterdData);
  };
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [filteredList, setFilteredList] = useState(displayList);
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const msg = {
    bookingApiFAiled: t("HelperMsg.CUSTOMER_BOOLKING_FAILED"),
    RECORDNOTFOUND: t("HelperMsg.RECORD_NOT_FOUND")
  };
  //******Serach Result end *********/
  // Handle use effect
  useEffect(() => {
    let pageNo = initialPageNo + 1;
    get_customer_booking({
      setSearchList,
      setDisplayList,
      enqueueSnackbar,
      setNoRecordMsg,
      setFilteredList,
      pageNo,
      displayList,
      msg,
    });
    setInitialPageNo(initialPageNo + 1);
  }, []);

  useEffect(() => {
    doSorting(sortingField, sortingOrder);
  }, [sortingField, sortingOrder]);

  const loadMore = () => {
    let pageNo = initialPageNo + 1;
    get_customer_booking({
      setSearchList,
      setDisplayList,
      enqueueSnackbar,
      setNoRecordMsg,
      setFilteredList,
      pageNo,
      displayList,
      msg,
    });
    setInitialPageNo(initialPageNo + 1);
  };
  const doSorting = (field, sortingOrder) => {
    const sortedData = getSorting(filteredList, field, sortingOrder);
    //  console.log(filteredList.sort(sorter2(field,sortingOrder)));
    setFilteredList(sortedData);
  };
  return (
    <Box m={1}>
      <div className={" flex space-between align-center"}>
        <LbHeading className={classes.title} text={t("Camera.Booking.BOOKING_LIST")} />
        <Button variant="contained" color="primary" href="#">
            {t("Shared.BOOK_NOW")}
          </Button>
      </div>
      <Grid item xs={12} className="text-center">
        <LbSearch
          sticky={true}
          searchValue={searchValue}
          placeholder={t("Shared.SEARCH_NAME")}
        ></LbSearch>
      </Grid>
      <Grid container spacing={1}>
        {displayList?.map((item, index) => (
          <Grid item sm={12} md={4} className="width-100-p">
            <CustomerBookingCard item={item} key={index}></CustomerBookingCard>
          </Grid>
        ))}
      </Grid>
      <div className="m-auto text-center">
        {displayList.length === 0 || noRecordMsg ? (
          <div><NotFound text={noRecordMsg} /></div>
        ) : displayList?.length % defaultPageSize === 0 ? (
          <Button
            className="my-8"
            type="button"
            size="small"
            variant="contained"
            color="secondary"
            onClick={loadMore}
          >
            {t("Shared.SHOWRESULT")}
          </Button>
        ) : (
          ""
        )}
      </div>
    </Box>
  );
}

export default CustomerBookingList;
