import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Grid,
  Button,
  Container,
  Avatar,
  FormControl,
} from "@material-ui/core";
import { Apps } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  get_business_type,
  update_vendor_details,
} from "vendor/services/owner_reg_helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { GAPageViewTrigger, getLastNYears } from "core/utils/utilities";
import { useEffect } from "react";
import { useState } from "react";
import LbCheckboxList from "shared/components/lb-checkbox-list/lb-checkbox-list";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  heading: {
    textAlign: "center",
    marginBottom: "15px",
  },
  label: {
    marginBottom: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    alignItems: "center",
  },
  buttonAlignment: {
    display: "flex",
    justifyContent: "end",
  },
}));

export default function OwnerRegistration() {
  const { t } = useTranslation();
  const msg = {
    updatemsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
  };
  const msgVendor = {
    vendorApi: t("HelperMsg.UPDATEVENDORDETAILAPI"),
  };
  const msgUpdate = {
    failApi: t("HelperMsg.UPDATIONFAILED"),
  };
  const msgFail = {
    failedmsg: t("HelperMsg.GETBUSINESSTYPEAPI"),
  };
  const msgError = {
    failedmsg: t("HelperMsg.GETBUSINESSTYPEAERROR"),
  };
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [businessType, setBusinessType] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  const lastNYears = getLastNYears(50);
  const dispatch = useDispatch();
  const vendorData = {
    vendor_id: JSON.parse(localStorage.getItem("USER_DATA"))?.vendor_id,
    org_name: "",
    estb_year: lastNYears[0].toString(),
    gst_numb: "",
    govt_reg_numb: "",
    setting_value_id: selectedBusiness.toString(),
  };
  const vendorSchema = Yup.object().shape({
    vendor_id: Yup.number(),
    org_name: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required(
      t("Shared.FORM.ORG_REQ_MSG")
    ),
    estb_year: Yup.string(),
    gst_numb: Yup.string(),
    govt_reg_numb: Yup.string(),
    setting_value_id: Yup.string().required(
      t("Shared.FORM.BUSINISS_TYPE_CHECKBOX")
    ),
  });

  const formik = useFormik({
    initialValues: vendorData,
    validationSchema: vendorSchema,
    onSubmit: (values) => {
      update_vendor_details(
        values,
        enqueueSnackbar,
        history,
        dispatch,
        appActions,
        msg,
        msgVendor,
        msgUpdate
      );
      //formik.resetForm();
      //  console.log("Business data :" + JSON.stringify(values));
    },
  });
  useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search,"business-register");
    get_business_type(enqueueSnackbar, setBusinessType, msgFail, msgError);
  }, []);

  useEffect(() => {
    formik.setFieldValue("setting_value_id", selectedBusiness.toString());
  }, [selectedBusiness]);

  return (
    <div className="root">
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Apps />
          </Avatar>
          <Typography className={classes.heading} component="h1" variant="h5">
            {t("Vendor.VendorDetails.BUSINESS_DETLS")}
          </Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {/* <div> {JSON.stringify(formik.errors)}</div> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete=""
                  name="org_name"
                  variant="outlined"
                  label={t("Vendor.VendorDetails.ORG_NAME")}
                  fullWidth
                  id="org_name"
                  onChange={formik.handleChange}
                  value={formik.values.org_name}
                />

                {formik.errors.org_name && formik.touched.org_name ? (
                  <div className="error-color">{formik.errors.org_name}</div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="estb_year">
                    {t("Vendor.VendorDetails.ESTD_YR")}
                  </InputLabel>
                  <Select
                    native
                    name="estb_year"
                    value={formik.values.estb_year}
                    label={t("Vendor.VendorDetails.ESTD_YR")}
                    onChange={formik.handleChange}
                    defaultValue=""
                  >
                    {lastNYears.map((year, index) => (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.estb_year && formik.touched.estb_year ? (
                  <div className="error-color">{formik.errors.estb_year}</div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="oname"
                  name="gst_numb"
                  variant="outlined"
                  label={t("Vendor.VendorDetails.GST_NO")}
                  fullWidth
                  id="gst_numb"
                  onChange={formik.handleChange}
                  value={formik.values.gst_numb}
                />
                {formik.errors.gst_numb && formik.touched.gst_numb ? (
                  <div className="error-color">{formik.errors.gst_numb}</div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="oname"
                  name="govt_reg_numb"
                  variant="outlined"
                  label={t("Vendor.VendorDetails.GOVT_REG_NO")}
                  fullWidth
                  id="govt_reg_numb"
                  onChange={formik.handleChange}
                  value={formik.values.govt_reg_numb}
                />
                {formik.errors.govt_reg_numb && formik.touched.govt_reg_numb ? (
                  <div className="error-color">
                    {formik.errors.govt_reg_numb}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12}>
                <LbCheckboxList
                  title={t("Shared.FORM.BUSINESS_TYPE", +"*")}
                  data={businessType}
                  checked={[]}
                  setSelectedItems={setSelectedBusiness}
                ></LbCheckboxList>
                {formik.errors.setting_value_id &&
                formik.touched.setting_value_id ? (
                  <div className="error-color">
                    {formik.errors.setting_value_id}
                  </div>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                container
                spacing={2}
                className={classes.buttonAlignment}
              >
                <Button
                  type="button"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  {t("Shared.CNCL")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                  className="ml-16"
                >
                  {t("Shared.SUBMIT")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
