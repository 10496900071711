import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { DeleteForever } from "@material-ui/icons";
import ListSubheader from "@material-ui/core/ListSubheader";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    // width: 500,
    // height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 1)",
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function LbImageList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList}>
        <ImageListItem
          key="Subheader"
          cols={props.col}
          style={{ height: "auto" }}
        >
          {props.itemData.length === 0 && (
            <ListSubheader component="div">{props.title}</ListSubheader>
          )}
        </ImageListItem>
        {props.itemData.map((item) => (
          <ImageListItem key={item.image_id}>
            <img src={item.data} alt={item.name} />
            <ImageListItemBar
              title={item.name}
              actionIcon={
                <IconButton
                  aria-label={`info about ${item.name}`}
                  className={classes.icon}
                  onClick={() => {
                    props.removeImage(item);
                  }}
                >
                  <DeleteForever />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
