import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import "core/layout/layout.scss";
import { useEffect } from "react";
import ManageService from "./manage-service/manage-service";

export default function VendorServiceMngt(props) {

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ManageService></ManageService>
      </Grid>
    </Grid>
  );
}
