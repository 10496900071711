import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Divider } from "@material-ui/core";
import LbDialogTitle from "./components/overridden-component/lb-dialog-title";
import { useTranslation } from "react-i18next";
import DownloadApp from "home/new-landing/download-app";
import { downloadPopupTime } from "core/utils/constants";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useSelector } from "react-redux";
import { isMobileDevice } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: "0 0 0 0",
  },
}));

const AppDownloadDialog = (props) => {
  const classes = useStyles();
  let firstTime = localStorage.getItem("LB_FIRST_TIME");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.appData.appDownloadDialog);

  // Below logic will only start working if the device is desktop


  if (!isMobileDevice()) {
    if (!firstTime) {
      // first time loaded!
      localStorage.setItem("LB_FIRST_TIME", new Date().getTime());
      // console.log("First time : " );
    } else {
      let currentTime = new Date().getTime();
      // Convert the time difference in minutes
      let timeDiffInMin = (currentTime - firstTime) / (60 * 1000);
      if (timeDiffInMin > downloadPopupTime) {
        dispatch(appActions?.setAppDownloadDialog(true));
        // console.log("Download : " + timeDiffInMin);
        localStorage.setItem("LB_FIRST_TIME", new Date().getTime());
      }
    }

  }
  const handleClose = () => {
    dispatch(appActions?.setAppDownloadDialog(false));
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="download-app-dialog"
        open={showDialog}
        backdrop
      >
        <LbDialogTitle
          id="download-app-dialog-title"
          onClose={handleClose}
          disableClose={false}
        >
          {t("Shared.App_download.BUTTON")}
        </LbDialogTitle>
        <Divider></Divider>
        <DialogContent className={classes.padding}>
          <DownloadApp></DownloadApp>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AppDownloadDialog;
