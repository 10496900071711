import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import { get_notification_list } from "shared/services/notification-service";
import { getLocalStorage } from "core/utils/utilities";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { useSnackbar } from "notistack";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { postRequest } from "core/utils/axios-client/axios-client";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "8px",
  },
  column: {
    flexDirection: "column",
  },
  lightBg: {
    background: theme.palette.primaryLight.main,
  },
}));

export default function Notification() {
  const classes = useStyles();
  const { t } = useTranslation();
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
  };
  const [expanded, setExpanded] = React.useState(false);
  const [apiResponse, setApiResponse] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  const userNotifications = appData.userNotifications;

  useEffect(() => {
    let user = getLocalStorage("USER_DATA");
    let receiverAccountId = user.account_id;
    // ?isRead=true
    get_notification_list(
      receiverAccountId,
      setApiResponse,
      enqueueSnackbar,
      msgFail
    );
  }, []);

  const handleChange = (panel, data) => (event, isExpanded) => {
    if (!data.is_read) {
      markReadMessage(data.not_id);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const markReadMessage = (notificationId) => {
    postRequest(
      "LbNotification/UpdateNotificationFlag/" + notificationId + "/" + true
    )
      .then((resp) => {
        if (resp.status === 200) {
          let user = getLocalStorage("USER_DATA");
          let receiverAccountId = user.account_id;
          get_notification_list(
            receiverAccountId,
            setApiResponse,
            enqueueSnackbar
          );
          dispatch(
            appActions.setUserNotifications(
              userNotifications.filter(
                (notification) => notification.not_id !== notificationId
              )
            )
          );
        }
        log_activity("markReadMessage", "notification.jsx", "Mark Read, notId :  "+notificationId);
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.MARKREADFAIL"), {
          variant: "error",
        });
        log_activity("markReadMessage", "notification.jsx", "Exception :  "+JSON.stringify(error));
      });
  };

  return (
    <div className={classes.root}>
      <div className="h1-title">{t("Booking_layout.NOTIFICATION")}</div>
      <Divider className="mb-8" />
      {apiResponse?.length > 0 ? (
        apiResponse?.map((data, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`, data)}
            key={index}
            className={!data.is_read ? classes.lightBg : "#dadfff"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="flex-container space-between">
                <strong>{data?.subject}</strong> {data?.msg_type}
                  {!data?.is_read ? (
                    <Badge color="secondary" variant="dot"></Badge>
                  ) : (
                    ""
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.column}>
              <Typography>{data?.body}</Typography>
              <Typography>
                <strong>Sender:</strong>
                {data?.sender_name ? data?.sender_name : " Admin"}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div className="no-notify">
          <NotificationsOffIcon className="no-icon" />
          <div> {t("Booking_layout.NO_NOTIFICATION")} </div>
        </div>
      )}
    </div>
  );
}
