import React from 'react'
import {  useFormContext } from 'react-hook-form';
import TemplateView from 'shared/template/template-view'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({

  title: {
      fontSize: 17,
      textAlign: 'center',
      fontWeight: '600',
      padding:'58px 0'
  }
  
 
});

export default function ConfirmMessage() {
  const classes = useStyles();
  const {  getValues } = useFormContext();

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
      setOpen(false);
  };
  const handleOpen = () => {
      setOpen(false);
  }
  const dateTilak = new Date(getValues()?.tilakSangeetDate);
  const weddingDate = new Date(getValues()?.weddingDate)
  const data = {
    "groomName": getValues()?.groomName,
    "groomMother": getValues()?.groomMother,
    "groomFather": getValues()?.groomFather,
    "groomGrandmother": getValues()?.groomGrandmother,
    "groomGrandfather": getValues()?.groomGrandfather,
    "tilakSangeetDate": dateTilak?.toLocaleDateString('en-GB'),
    "brideName": getValues()?.brideName,
    "brideMother": getValues()?.brideMother,
    "brideFather": getValues()?.brideFather,
    "brideGrandmother": getValues()?.brideGrandmother,
    "brideGrandfather": getValues()?.brideGrandfather,
    "weddingDate": weddingDate?.toLocaleDateString('en-GB'),
    "devotional": getValues()?.devotional,
    "venueAddress": getValues()?.venueAddress,
    "guardianName": getValues()?.guardianName,
    "inviteMessage": getValues()?.inviteMessage,
    "address": getValues()?.address,
    "template": getValues()?.template
}
  return (
    <>
     {/* <TemplateView handleClose={handleClose} open={open} data={data} value={getValues()} handleOpen={handleOpen}></TemplateView>  */}
     <div className={classes.title}>{t("WebInvite.THANKYOU")}</div>
    </>
   
  )
}

