import React, { useState } from 'react';
import BrideGroomDetails from './bride_groom_details';
import FamilyVenueForm from './family_venue_form';
import WebInvtePreview from './web_invite_preview';
import ConfirmMessage from './confirm_message';
import { makeStyles } from "@material-ui/core/styles";
import CustomStepper from './custom-stepper';
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { brideGroomSchema, tempInvite, familyVenue, webPreview } from 'shared/validation-schema/validation-schema';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
import { save_Web_Invites } from 'shared/services/web-invite-service';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));



export default function WebInvite() {
    const { t } = useTranslation();
    const { id } = useParams();
    const msgFail = {
        failedmsg: t("HelperMsg.FAILLED"),
        registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    };
    const formArray = [
        {
            id: 0,
            progress: 0,
            heading: t("WebInvite.BRIDEGROOM"),
            component: <BrideGroomDetails />,
        },
        {
            id: 1,
            progress: 40,
            heading: t("WebInvite.FAMILY_VENUE"),
            component: <FamilyVenueForm />,
        },
        {
            id: 2,
            progress: 75,
            heading: t("WebInvite.TEMPLATE_PREVIEW"),
            component: <WebInvtePreview />,
        },
        {
            id: 3,
            progress: 100,
            heading: t("WebInvite.WEB_COMFIRM"),
            component: <ConfirmMessage />

        }
    ];
    const appData = useSelector((state) => state?.appData);
    const customer_id = appData?.user?.customer_id;
    const WebInviteData = appData.WebInviteUpdate;
    const { enqueueSnackbar } = useSnackbar();
    const [currentForm, setCurrentForm] = React.useState(0);
    const [showButton, setShowButton] = useState(true);
    const classes = useStyles();
    const history = useHistory();
    const validationSchemas = {
        0: brideGroomSchema(t),
        1: tempInvite(t),
        2: familyVenue(t),
        3: webPreview(t),
        // 4:familyVenue(t)
    };

    const methods = useForm({
        resolver: yupResolver(validationSchemas[currentForm]),
    });


    const handleBack = (e) => {
        e.preventDefault();
        if (currentForm > 0) {
            setCurrentForm(currentForm - 1);
        }
    };

    const validateForm = (obj) => {
        const formFields = {
            0: ["brideName", "brideMother", "brideFather", "brideGrandmother", "brideGrandfather", "weddingDate", "groomName", "groomMother", "groomFather", "groomGrandmother", "groomGrandfather", "tilakSangeetDate"],
            1: ["inviteMessage", "guardianName", "address"],
            2: ["venueAddress", "devotional"],
            3: ['template']
        };

        const fieldsToValidate = formFields[currentForm];

        let flag = true;
        for (let i = 0; i < fieldsToValidate?.length; i++) {
            const fieldName = fieldsToValidate[i];
            const val = obj[fieldName];

            if (!val || val.length < 1) {
                flag = false;
                break;
            }
        }

        return flag;
    };

    const handleContinue = () => {
        const validated = validateForm(methods.getValues());

        if (validated && currentForm < formArray?.length - 1) {
            setCurrentForm(currentForm + 1);
        }
    };

    const handleClick = () => {
        history.push("/customer/invite/invite-list");
    };
    const handleConfirm = (e) => {
        e.preventDefault();
        setCurrentForm(currentForm + 1);
        setShowButton(false);
        let temp = {
            "invitation_id": WebInviteData?.invitation_id && id === 'update' ? WebInviteData?.invitation_id : 0,
            "customer_id": customer_id,
            "bride_name": methods?.getValues()?.brideName,
            "groom_name": methods?.getValues()?.groomName,
            "bride_mother_name": methods?.getValues()?.brideMother,
            "bride_father_name": methods?.getValues()?.brideFather,
            "groom_mother_name": methods?.getValues()?.groomMother,
            "groom_father_name": methods?.getValues()?.groomFather,
            "lagan_date": methods?.getValues()?.weddingDate,
            "tilak_sangeet_date": methods?.getValues()?.tilakSangeetDate,
            "template_name": methods?.getValues()?.templateName,
            "custom_invite_msg": methods?.getValues()?.inviteMessage,
            "invited_by": methods?.getValues()?.guardianName,
            "invitee_address": methods?.getValues()?.address,
            "members_on_invite": methods?.getValues()?.familyMemberNames?.toString(),
            "devine_quote": methods?.getValues()?.devotional,
            "event_venue": methods?.getValues()?.venueAddress,
            "is_active": true,
            "bride_grand_mother_name": methods?.getValues()?.brideGrandmother,
            "bride_grand_father_name": methods?.getValues()?.brideGrandfather,
            "groom_grand_mother_name": methods?.getValues()?.groomGrandmother,
            "groom_grand_father_name": methods?.getValues()?.groomGrandfather,
        }
        save_Web_Invites(temp, enqueueSnackbar, msgFail);
        handleClick()
    };

    return (
        <div className={classes.root}>
            <CustomStepper value={formArray[currentForm]?.progress} currentStepLabel={t(formArray[currentForm]?.heading)} />
            <div className="form-component">
                <FormProvider {...methods}>
                    <form method="POST" onSubmit={methods.handleSubmit(handleContinue)}>
                        {formArray[currentForm].component}
                        {showButton ?
                            <div className={'flex flex-end pt-4 pr-4 gap-8'}>
                                {currentForm > 0 && (
                                    <Button
                                        margin="normal"
                                        className="nav-next"
                                        type="button"
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        onClick={handleBack}>
                                        {t("Shared.BACK")}
                                    </Button>
                                )}
                                {currentForm === formArray.length - 2 ? (
                                    <Button
                                        margin="normal"
                                        className="nav-next"
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={handleConfirm}
                                    >
                                        {t("Shared.CONFIRM")}l
                                    </Button>

                                ) : (
                                    <Button
                                        margin="normal"
                                        className="nav-next"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        size="small">
                                        {t("Shared.Next")}
                                    </Button>
                                )}
                            </div>
                            : ""}

                    </form>
                </FormProvider>
            </div>
        </div>
    );
}
