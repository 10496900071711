import { makeStyles } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
    borderCollapse: "collapse",
    textAlign: "center",
    margin: "5px",
    "& h4": {
      margin: "15px",
    },
    "& table": {
      border: theme.palette.primary.main + " solid 1px",
    },
    "& th,td": {
      padding: "8px",
      textAlign: "left",
      border: "1px solid #ddd",
    },
    "& th": {
      border: theme.palette.primary.main + " solid 1px",
    },
  },
}));

const VivahMuhurat = () => {
  const classes = useStyles();
  useState(() => {
    log_activity("VivahMuhurat-page load", "vivah-muhurat.jsx", "Success");
  }, []);
  return (
    <div className={classes.root}>
      <h3>
        <span id="">
          ठाकुर प्रसाद कैलेंडर 2024 – शादी के शुभ मुहूर्त और तिथियां
        </span>
      </h3>
      <h4>जनवरी विवाह शुभ मुहूर्त&nbsp;2024</h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>16 जनवरी 2024, मंगलवार</td>
            <td>20:01 से 17 जनवरी 07:15</td>
            <td>उत्तर भाद्रपद, रेवती</td>
            <td>षष्ठी, सप्तमी</td>
          </tr>
          <tr>
            <td>17 जनवरी 2023, बुधवार</td>
            <td>07:15 से 21:50</td>
            <td>रेवती</td>
            <td>सप्तमी</td>
          </tr>
          <tr>
            <td>20 जनवरी 2024, शनिवार</td>
            <td>03:09 से 21 जनवरी 07:14</td>
            <td>रोहिणी</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>21 जनवरी 2024, रविवार</td>
            <td>07:14 से 07:23</td>
            <td>रोहिणी</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>22 जनवरी 2024, सोमवार</td>
            <td>07:14 से 23 जनवरी 04:58</td>
            <td>मृगशिरा</td>
            <td>द्वादशी, त्रयोदशी</td>
          </tr>
          <tr>
            <td>27 जनवरी 2024, शनिवार</td>
            <td>19:44 से 28 जनवरी 07: 12</td>
            <td>मघा</td>
            <td>द्वितीया, तृतीया</td>
          </tr>
          <tr>
            <td>28 जनवरी 2024, रविवार</td>
            <td>07:12 से 15:53</td>
            <td>मघा</td>
            <td>तृतीया</td>
          </tr>
          <tr>
            <td>30 जनवरी 2024, मंगलवार</td>
            <td>10:43 से 31 जनवरी 07:10</td>
            <td>उत्तराफाल्गुनी, हस्त</td>
            <td>पंचमी</td>
          </tr>
          <tr>
            <td>31 जनवरी 2024, बुधवार</td>
            <td>07:10 से 1 फरवरी 01:08</td>
            <td>हस्त</td>
            <td>पंचमी, षष्ठी</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>फरवरी विवाह शुभ मुहूर्त 2024</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>4 फरवरी 2024, रविवार</td>
            <td>07:21 से 05 फरवरी 05:44</td>
            <td>अनुराधा</td>
            <td>नवमी, दशमी</td>
          </tr>
          <tr>
            <td>6 फरवरी 2024, मंगलवार</td>
            <td>13:18 से 07 फरवरी 06:27</td>
            <td>मूल</td>
            <td>एकादशी, द्वादशी</td>
          </tr>
          <tr>
            <td>7 फरवरी 2024, बुधवार</td>
            <td>04:37 से 08 फरवरी 07:05</td>
            <td>उत्तराषाढ़ा</td>
            <td>त्रयोदशी</td>
          </tr>
          <tr>
            <td>8 फरवरी 2024,&nbsp; गुरुवार</td>
            <td>07:05 से 11:17</td>
            <td>उत्तराषाढ़ा</td>
            <td>त्रयोदशी</td>
          </tr>
          <tr>
            <td>12 फरवरी 2024, सोमवार</td>
            <td>14:56 से 13 फरवरी 07:02</td>
            <td>उत्तर भाद्रपद</td>
            <td>तृतीया, चतुर्थी</td>
          </tr>
          <tr>
            <td>13 फरवरी 2024, मंगलवार</td>
            <td>14:41 से 14 फरवरी 05:11</td>
            <td>रेवती</td>
            <td>पंचमी</td>
          </tr>
          <tr>
            <td>17 फरवरी 2024, शनिवार</td>
            <td>08:46 से 13:44</td>
            <td>रोहिणी</td>
            <td>नवमी</td>
          </tr>
          <tr>
            <td>24 फरवरी 2024, शनिवार</td>
            <td>13:35 से 22:20</td>
            <td>मघा</td>
            <td>पूर्णिमा, प्रतिपदा</td>
          </tr>
          <tr>
            <td>25 फरवरी 2024, रविवार</td>
            <td>01:24 से 26 फरवरी 06:50</td>
            <td>उत्तराफाल्गुनी</td>
            <td>द्वितीया</td>
          </tr>
          <tr>
            <td>26 फरवरी 2024, सोमवार</td>
            <td>06:50 से 15:27</td>
            <td>उत्तराफाल्गुनी</td>
            <td>द्वितीया</td>
          </tr>
          <tr>
            <td>29 फरवरी 2024, गुरुवार</td>
            <td>10:22 से 01 मार्च 06:46</td>
            <td>स्वाती</td>
            <td>पंचमी</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>मार्च&nbsp;विवाह शुभ मुहूर्त</strong>&nbsp;<strong>2024</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>1 मार्च 2024, शुक्रवार</td>
            <td>06:46 से 12:48</td>
            <td>स्वाती</td>
            <td>षष्ठी</td>
          </tr>
          <tr>
            <td>2 मार्च 2024, शनिवार</td>
            <td>20:24 से 03 मार्च 06:44</td>
            <td>अनुराधा</td>
            <td>सप्तमी</td>
          </tr>
          <tr>
            <td>3 मार्च 2024, रविवार</td>
            <td>06:44 से 15:55</td>
            <td>अनुराधा</td>
            <td>सप्तमी, अष्टमी</td>
          </tr>
          <tr>
            <td>4 मार्च 2024, सोमवार</td>
            <td>22:16 से 05 मार्च 06: 42</td>
            <td>मूल</td>
            <td>नवमी</td>
          </tr>
          <tr>
            <td>5 मार्च 2024, मंगलवार</td>
            <td>06:42 से 14:09</td>
            <td>मूल</td>
            <td>नवमी, दशमी</td>
          </tr>
          <tr>
            <td>6 मार्च 2024, बुधवार</td>
            <td>14:52 से 07 मार्च 0640</td>
            <td>उत्तराषाढ़ा</td>
            <td>एकादशी, द्वादशी</td>
          </tr>
          <tr>
            <td>7 मार्च 2024, गुरुवार</td>
            <td>06:40 से 08:24</td>
            <td>उत्तराषाढ़ा</td>
            <td>द्वादशी</td>
          </tr>
          <tr>
            <td>10 मार्च 2024, रविवार</td>
            <td>01:55 से 11 मार्च 06:35</td>
            <td>उत्तर भाद्रपद</td>
            <td>प्रतिपदा</td>
          </tr>
          <tr>
            <td>11 मार्च 2024, सोमवार</td>
            <td>06:35 से 12 मार्च 06:34</td>
            <td>उत्तर भाद्रपद, रेवती</td>
            <td>प्रतिपदा, द्वितीया</td>
          </tr>
          <tr>
            <td>12 मार्च 2024, मंगलवार</td>
            <td>06:34 से 15:08</td>
            <td>रेवती</td>
            <td>तृतीया</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>अप्रैल&nbsp;विवाह शुभ मुहूर्त&nbsp;2024</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>18 अप्रैल 2024, गुरुवार</td>
            <td>00:44 से 19 अप्रैल 05:51</td>
            <td>मघा</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>19 अप्रैल 2024, शुक्रवार</td>
            <td>05:51 से 06:46</td>
            <td>मघा</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>20 अप्रैल 2024, शनिवार</td>
            <td>14:04 से 21 अप्रैल 02:48</td>
            <td>उत्तराफाल्गुनी</td>
            <td>द्वादशी, त्रयोदशी</td>
          </tr>
          <tr>
            <td>21 अप्रैल 2024 रविवार,</td>
            <td>03:45 से 22 अप्रैल 05:48</td>
            <td>हस्त</td>
            <td>चतुर्दशी</td>
          </tr>
          <tr>
            <td>22 अप्रैल 2024, सोमवार</td>
            <td>05:48 से 20:00</td>
            <td>हस्त</td>
            <td>चतुर्दशी</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>मई&nbsp;विवाह शुभ मुहूर्त&nbsp;2024</strong>
      </h4>
      <p>इस माह में विवाह करने के लिए कोई शुभ मुहूर्त उपलब्ध नहीं है।</p>
      <h4>
        <strong>जून&nbsp;विवाह शुभ मुहूर्त&nbsp;2024</strong>
      </h4>
      <p>इस माह में विवाह करने के लिए कोई शुभ मुहूर्त उपलब्ध नहीं है।</p>
      <h4>
        <strong>जुलाई&nbsp;विवाह शुभ मुहूर्त&nbsp;2024&nbsp;</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>9 जुलाई 2024, मंगलवार</td>
            <td>14:28 से 18:56</td>
            <td>मघा</td>
            <td>चतुर्थी</td>
          </tr>
          <tr>
            <td>11 जुलाई 2024, गुरुवार</td>
            <td>13:04 से 12 जुलाई 04:09</td>
            <td>उत्तराफाल्गुनी</td>
            <td>षष्ठी</td>
          </tr>
          <tr>
            <td>12 जुलाई 2024, शुक्रवार</td>
            <td>05:15 से 13 जुलाई 05:32</td>
            <td>हस्त</td>
            <td>सप्तमी</td>
          </tr>
          <tr>
            <td>13 जुलाई 2024, शनिवार</td>
            <td>05:32 से 15:05</td>
            <td>हस्त</td>
            <td>सप्तमी</td>
          </tr>
          <tr>
            <td>14 जुलाई 2024, रविवार</td>
            <td>22:06 से 15 जुलाई 05:33</td>
            <td>स्वाती</td>
            <td>नवमी</td>
          </tr>
          <tr>
            <td>15 जुलाई 2024, सोमवार</td>
            <td>05:33 से 16 जुलाई 00:30</td>
            <td>स्वाती</td>
            <td>नवमी, दशमी</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>अगस्त&nbsp;विवाह शुभ मुहूर्त&nbsp;2024</strong>
      </h4>
      <p>इस माह में विवाह करने के लिए कोई शुभ मुहूर्त उपलब्ध नहीं है।</p>
      <h4>
        <strong>सितंबर&nbsp;विवाह शुभ मुहूर्त&nbsp;2024</strong>
      </h4>
      <p>इस माह में विवाह करने के लिए कोई शुभ मुहूर्त उपलब्ध नहीं है।</p>
      <h4>
        <strong>अक्टूबर&nbsp;विवाह शुभ मुहूर्त&nbsp;2024&nbsp;</strong>
      </h4>
      <p>इस माह में विवाह करने के लिए कोई शुभ मुहूर्त उपलब्ध नहीं है।</p>
      <h4>
        <strong>नवंबर&nbsp;विवाह शुभ मुहूर्त&nbsp;2024&nbsp;</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>12 नवंबर 2024, मंगलवार</td>
            <td>16ः04 से 19ः10</td>
            <td>उत्तर भाद्रपद</td>
            <td>द्वादशी</td>
          </tr>
          <tr>
            <td>13 नवंबर 2024 बुधवार</td>
            <td>15:26 से 21:48</td>
            <td>रेवती</td>
            <td>त्रयोदशी</td>
          </tr>
          <tr>
            <td>16 नवंबर 2024 शनिवार</td>
            <td>23:48 से 17 नवंबर 06:45</td>
            <td>रोहिणी</td>
            <td>द्वितीया</td>
          </tr>
          <tr>
            <td>17 नवंबर 2024, रविवार</td>
            <td>06:45 से 18 नवंबर 06:46</td>
            <td>रोहिणी, मृगशिरा</td>
            <td>द्वितीया, तृतीया</td>
          </tr>
          <tr>
            <td>18 नवंबर 2024, सोमवार</td>
            <td>06:46 से 07:56</td>
            <td>मृगशिरा</td>
            <td>तृतीया</td>
          </tr>
          <tr>
            <td>22 नवंबर 2024, शुक्रवार</td>
            <td>23:44 से 23 नवंबर 06:50</td>
            <td>मघा</td>
            <td>अष्टमी</td>
          </tr>
          <tr>
            <td>23 नवंबर 2024, शनिवार</td>
            <td>06:50 से 11:42</td>
            <td>मघा</td>
            <td>अष्टमी</td>
          </tr>
          <tr>
            <td>25 नवंबर 2024, सोमवार</td>
            <td>01:01 से 26 नवंबर 06:53b</td>
            <td>हस्त</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>26 नवंबर 2024, मंगलवार</td>
            <td>06:53 से 27 नवंबर 04:35</td>
            <td>हस्त</td>
            <td>एकादशी</td>
          </tr>
          <tr>
            <td>28 नवंबर 2024, गुरुवार</td>
            <td>07:36 से 29 नवंबर 06:55</td>
            <td>स्वाती</td>
            <td>त्रयोदशी</td>
          </tr>
          <tr>
            <td>29 नवंबर 2024, शुक्रवार</td>
            <td>06:55 से 08:39</td>
            <td>स्वाती</td>
            <td>त्रयोदशी</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <strong>दिसंबर&nbsp;विवाह शुभ मुहूर्त&nbsp;2024&nbsp;</strong>
      </h4>
      <table width="100%">
        <tbody>
          <tr>
            <th>
              <strong>तारिख और दिन</strong>
            </th>
            <th>
              <strong>मुहूर्त&nbsp;का समय</strong>
            </th>
            <th>
              <strong>नक्षत्र</strong>
            </th>
            <th>
              <strong>तिथि</strong>
            </th>
          </tr>
          <tr>
            <td>4 दिसंबर 2024, बुधवार</td>
            <td>17:15 से 05 दिसंबर 01:02</td>
            <td>उत्तराषाढ़ा</td>
            <td>चतुर्थी</td>
          </tr>
          <tr>
            <td>5 दिसंबर 2024, गुरुवार</td>
            <td>12:49 से 17:26</td>
            <td>उत्तराषाढ़ा</td>
            <td>पंचमी</td>
          </tr>
          <tr>
            <td>9 दिसंबर 2024, सोमवार</td>
            <td>14:56 से 10 दिसंबर 01:06</td>
            <td>उत्तर भाद्रपद</td>
            <td>नवमी</td>
          </tr>
          <tr>
            <td>10 दिसंबर 2024, मंगलवार</td>
            <td>22:03 से 11 दिसंबर 06:13</td>
            <td>रेवती</td>
            <td>दशमी, एकादशी</td>
          </tr>
          <tr>
            <td>14 दिसंबर 2024, शनिवार</td>
            <td>07:06 से 16:58</td>
            <td>मृगशिरा</td>
            <td>पूर्णिमा</td>
          </tr>
          <tr>
            <td>15 दिसंबर 2024, रविवार</td>
            <td>03:42 से 07:06</td>
            <td>मृगशिरा</td>
            <td>पूर्णिमा</td>
          </tr>
        </tbody>
      </table>
      <span>
        Source :{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instapdf.in/thakur-prasad-calendar-2024/"
          aria-label="vivah muhurat"
        >
          Instapdf.in - Vivah muhurat
        </a>
      </span>
    </div>
  );
};

export default VivahMuhurat;
