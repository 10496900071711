import {
  getRequest
} from "core/utils/axios-client/axios-client";
import { log_activity } from "shared/services/common-service";

export const get_vendor_reviews = (
  vendor_id,
  enqueueSnackbar,
  dispatch,
  history,
  appActions,
  setVendorReviews,
  msg
) => {
  getRequest("LbVendor/GetVendorReviews?vendorId=" + vendor_id)
    .then((resp) => {
      if (resp.status === 200) {
        setVendorReviews(resp.data);
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.failedmsg, {
        variant: "error",
      });
      return false;
    });
};

export const get_vendor_bookings = (
  vendorId,
  enqueueSnackbar,
  setDisplayList,
  setSearchList,
  msg
) => {
  getRequest(`LbBooking/GetBookings/${vendorId}?cust_vend=vendor`)
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        setDisplayList(resp?.data);
        setSearchList(resp?.data);
        log_activity("get_vendor_bookings", "vendor_helper.js", "Success");
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.failedmsg, {
        variant: "error",
      });
      log_activity("get_vendor_bookings", "vendor_helper.js", "Exception: "+JSON.stringify(error));
      return false;
    });
};

export const get_deal_list = (vendorId, setDisplayList, enqueueSnackbar, msg) => {
  getRequest("LbBooking/GetDeals/" + vendorId + "?cust_vend=vendor")
    .then((resp) => {
      if (resp.status === 200) {
        setDisplayList(resp?.data);
      } else {
        enqueueSnackbar("Get Deal Api failed", {
          variant: "error",
        });
        //   console.log(JSON.Stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar("Get camera Deal  failed", {
        variant: "error",
      });
    });
};

export const getBiidingList = (
  vendorId,
  settingValueId,
  setDisplayList,
  enqueueSnackbar,
  msg
) => {
  getRequest(
    `LbBidding/GetBiddings/${vendorId}?settingValueId=${settingValueId}`
  )
    .then((resp) => {
      if (resp.status === 200) {
        setDisplayList(resp?.data);
      } else {
        enqueueSnackbar(msg.failedmsg, {
          variant: "error",
        });
        //   console.log(JSON.Stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.failedmsg, {
        variant: "error",
      });
    });
};