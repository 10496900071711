export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const ADD_USER_DETAILS = 'ADD_USER_DETAILS';
export const TYPING = 'TYPING';
export const FILTER_BY_TITLE="FILTER_BY_TITLE";
export const INCREASE_LOADER_COUNT="INCREASE_LOADER_COUNT";
export const STOP_LOADER="STOP_LOADER";
export const DECREASE_LOADER_COUNT="DECREASE_LOADER_COUNT";
export const UPDATE_USER="UPDATE_USER";
export const SET_INVENTORY_VENDOR = "SET_INVENTORY_VENDOR";
export const SELECT_BOOKING = "SELECT_BOOKING";
export const EDIT_INVENTORY = "EDIT_INVENTORY";
export const SET_VEN_SETTING_VAL_ID = "SET_VEN_SETTING_VAL_ID";
export const SET_LOCATION_DIALOG = "SET_LOCATION_DIALOG";
export const PROFILE_DATA="PROFILE_DATA";
export const RESET_STATE="RESET_STATE";
export const SET_ACTIVE_MENU="SET_ACTIVE_MENU";
export const SET_USER_NOTIFICATIONS="SET_USER_NOTIFICATIONS";
export const SET_USER_PER_DATA="SET_USER_PER_DATA";
export const SET_FAV_DRAWER="SET_FAV_DRAWER";
export const SET_APP_DOWNLOAD_DIALOG = "SET_APP_DOWNLOAD_DIALOG";
export const SET_WEB_INVITE_UPDATE="SET_WEB_INVITE_UPDATE";
export const RESET_WEB_INVITE_DATA="RESET_WEB_INVITE_DATA";
