import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
import { getNoBaseUrlRequest } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import "../../cms/cms-content.scss";
import { useTranslation } from "react-i18next";
import { wpContentDetailUrl } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import { GAPageViewTrigger } from "core/utils/utilities";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CmsContentView(props) {
  const params = useParams();
  const { t } = useTranslation();
  const [blogContent, setBlogContent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const msg = {
    apiFailed: t("HelperMsg.APIFALIED"),
  };
  const getSingleBlog = (id) => {
    getNoBaseUrlRequest(wpContentDetailUrl + "/" + id)
      .then((resp) => {
      //  console.log(resp);
        if (resp.status === 200) {
          setBlogContent(resp.data);
          log_activity("getSingleBlog", "content-view.jsx", "Success");
        } else {
          enqueueSnackbar(msg.apiFailed, {
            variant: "error",
          });
          log_activity(
            "getSingleBlog",
            "content-view.jsx",
            "Failed: non 200 response"
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.apiFailed, {
          variant: "error",
        });
        log_activity(
          "getSingleBlog",
          "content-view.jsx",
          "Exception: " + JSON.stringify(error)
        );
      });
  };

  const btnBackClick = () => {
    history.goBack();
  };
  useEffect(() => {
    GAPageViewTrigger(
      window.location.pathname + window.location.search,
      "cms-content-view"
    );
    getSingleBlog(params.id);
  }, []);

  return (
    <>
      <div id="blogview">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className="title">{blogContent?.title?.rendered}</div>
          </Grid>
          <Grid item xs={3}>
            <div className="title text-end">
              <Button color="primary" variant="outlined" onClick={btnBackClick}>
                Back
              </Button>
            </div>
          </Grid>
        </Grid>
        <hr></hr>
        {blogContent ? (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: blogContent?.content?.rendered }}
          ></div>
        ) : (
          ""
        )}
        <hr></hr>
        <Grid container spacing={2}>
          <Grid item xs={9}>
           
          </Grid>
          <Grid item xs={3}>
            <div className="title text-end">
              <Button color="primary" variant="outlined" onClick={btnBackClick}>
                Back
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CmsContentView;
