import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import LbTitle from "shared/components/lb-title/lb-title";
import { Grid, IconButton } from "@material-ui/core";
import BuisnessType from "shared/lb-forms/buisness-type/buisness-type";
import FinancialDetail from "shared/lb-forms/financial-detail/financial-detail";
import { useFieldArray, useForm } from "react-hook-form";
import UserDetails from "../user-details/user-details";
import BookingView from "./booking-view";
import CloseIcon from "@material-ui/icons/Close";
import {
  getZipcode,
  get_vendor_service_list,
} from "shared/services/booking-service";
import { useSnackbar } from "notistack";
import { getLocalStorage } from "core/utils/utilities";
import { getSelectedService, getUserType } from "core/utils/utilities";
import { useTranslation } from "react-i18next";
import LbLocationNew from "shared/components/lb-location/lb-location-new";
import { yupResolver } from "@hookform/resolvers/yup";
import { BookSchema } from "shared/validation-schema/validation-schema";
import LbAvtarName from "shared/components/lb-avtar-name/lb-avtar-name";
//import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  mb20: {
    marginBottom: "20px",
  },
  mb8: {
    marginBottom: "8px",
  },
  imageIcon: {
    height: "3rem",
    width: "3rem",
    border: "1px solid #7058e7",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function Booking({ title, settingValueId, favIndex, vendorId, refreshList }) {
  let responseKeys = {};
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showAmount, setshowAmount] = useState(false);
  const [showAmountErr, setshowAmountErr] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [listValue, setListValue] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [mobileExist, setMobileExtist] = useState(false); // S
  const msgFail = {
    msg: t("HelperMsg.FAILLED"),
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(BookSchema(t)) });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "service_data",
  });

  const onSubmit = (data) => {
    if (!mobileExist) {
      setshowAmount(false)
      setshowAmountErr(false)
      if ((parseInt(data.advance_paid) > parseInt(data.booking_amount))) {
        setshowAmount(true)
        return;

      }

      if (parseInt(data.booking_amount) !== 0 && parseInt(data.booking_amount) > 0 && parseInt(data.advance_paid) >= 0) {
        data.advance_paid = +data.advance_paid;
        data.balance_amount = +data.booking_amount - data.advance_paid;
        data.booking_amount = +data.booking_amount;
        data.business_type = +settingValueId;
        data.cust_vend = getUserType();
        data.vendor_id = vendorId;
        data.cust_name = data.first_name + '' + data.last_name
        data.service_data = getSelectedService(data.service_data);
        let payload = {
          ...data,
          ...responseKeys,
        };
        setBookingData(payload);
        setShowDetail(true);
      } else {
        setshowAmountErr(true)
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setshowAmount(false)
    setshowAmountErr(false)
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
      setshowAmount(false)
      setshowAmountErr(false)
    }
  };

  const handleShowDetails = () => {
    setShowDetail(false);
    setshowAmount(false)
    setshowAmountErr(false)
  };

  const getSettingServices = () => {
    let businessType = settingValueId;
    get_vendor_service_list({
      vendorId,
      businessType,
      append,
      remove,
      enqueueSnackbar,
      msgFail,
    });
  };

  const setUserDeatils = () => {
    const user = getLocalStorage("USER_DATA");
    if (user?.customer_id) {
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("cust_mob", user?.mobile_no);
      setDisableField(true);
    }
  };

  const zipCodeCallD = (zipCode) => {
    //********Set Venodr Id********/
    if (zipCode.length === 6) {
      getZipcode(zipCode, enqueueSnackbar, setListValue, setOpen);
    }
  };
  const handleMobileExist = (newMobileNumber) => {
    setMobileExtist(newMobileNumber);
  };
  React.useEffect(() => {
    setUserDeatils();
    getSettingServices();
  }, [settingValueId, favIndex]);

  return (
    <div>
      <Button
        size="small"
        type="button"
        variant="contained"
        color="secondary"
        className="mr-2"
        onClick={handleClickOpen}
      >
        {title}
      </Button>
      <Dialog open={open} onClose={handleClose} PaperProps={{
        style: {
          margin: "20px",
        },
      }}>
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <LbAvtarName firstName={t("Shared.BOOKING_TITLE")} src={"/images/icons/1/nfc.png"}></LbAvtarName>  
          <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <hr />
        <DialogContent>
          {showDetail ? (
            ""
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                {/* User Details  */}
                <UserDetails
                  register={register}
                  errors={errors}
                  disable={disableField}
                />
                {/* User Location  */}
                <LbTitle
                  name={t("Shared.LOCATION")}
                  size="16"
                  weight="600"
                ></LbTitle>
                {/* Services list  */}
                <LbLocationNew
                  zipCodeCallD={zipCodeCallD}
                  listValue={listValue}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                ></LbLocationNew>

                {/* Services list  */}
                <BuisnessType
                  register={register}
                  fields={fields}
                  watch={watch}
                  errors={errors}
                  control={control}
                  clearErrors={clearErrors}
                />
                {/* Financial Details   */}
                {showAmount ? (<p style={{ color: "red" }}>{t("BIDDING.ADVANCEBOOKING")}</p>) : ("")}
                {showAmountErr ? (<p style={{ color: "red" }}>{t("BIDDING.ADVANCEBOOKING_ERR")}</p>) : ("")}
                <FinancialDetail register={register} watch={watch}
                  errors={errors} />
              </Grid>
              <div align="end" className="mt-8">
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleClose}
                  className="mr-5"
                >
                  {t("Shared.CANCEL")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  {t("Shared.CONFIRM")}
                </Button>
              </div>
            </form>
          )}
          {showDetail ? (
            <BookingView
              bookingData={bookingData}
              handleClose={handleClose}
              handleShowDetails={handleShowDetails}
              refreshList={refreshList}
            ></BookingView>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default Booking;
