import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid, Typography } from "@material-ui/core";
import "shared/components/lb-checkbox-list/lb-checkbox-list.scss";

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

export default function LbCheckboxList(props) {
  const [checked, setChecked] = React.useState(props?.checked?props?.checked:[]);

  // console.log(checked);

  const handleToggle = value => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    props.setSelectedItems(newChecked);
    // console.log(newChecked);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body" component="h4">
          {props?.title}
        </Typography>
      </Grid>
      {props?.data.map(value => {
        const labelId = `checkbox-list-label-${value?.setting_value_id}`;
        return (
          <Grid item xs={6} id={labelId} key={labelId} className="gridBGC">
            <ListItem
              key={value?.setting_value_id}
              role={undefined}
              dense
              button
              onClick={handleToggle(value?.setting_value_id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked?.indexOf(value?.setting_value_id) !== -1?true:false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value?.setting_value} />
            </ListItem>
          </Grid>
        );
      })}
    </Grid>
  );
}
