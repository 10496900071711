import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 240,
  },
});

export default function CmsContentCard({ option }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageExtractor(option?.content?.rendered)}
          title="blog-images"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {option?.title?.rendered}
          </Typography>
          <Typography
            className="clamp-3"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {option ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: option?.content?.rendered.replace(/<img[^>]*>/g, ""),
                }}
              ></div>
            ) : (
              ""
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="flex-end">
        <Button size="small" variant="contained" color="primary">
          Read
        </Button>
      </CardActions>
    </Card>
  );
}

const imageExtractor = (content) => {
  // Create a DOMParser object
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  let srcUrl = "";

  // Access the img element and retrieve the src attribute value
  const imgElement = doc.querySelector("img");
  if (imgElement) {
    const src = imgElement.getAttribute("src");
    srcUrl = src;
   // console.log(src);
    // This will log the extracted src value
  }

  return srcUrl;
};
