import { getRequest, postRequest, deleteRequest } from "core/utils/axios-client/axios-client";

export const save_Web_Invites = (
  payload,
  enqueueSnackbar,
  msgFail
) => {
  console.log(payload)
  postRequest(`LbInvite/SaveInvitation`, payload)
    .then((resp) => {
      if (resp.status === 200) {
        enqueueSnackbar('Success', {
          variant: "Success",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
    });
}


export const get_Web_Invites_List = async (
  setDisplayList, customer_id, defaultPageSize, initialPageNo, enqueueSnackbar, msgFail, setShowButton
) => {
  getRequest(
    `LbInvite/GetInvitations?customer_id=${customer_id}&page_size=${defaultPageSize}&page_no=${initialPageNo}`
  )
    .then((resp) => {
      if (resp.status === 200) {
        if (resp?.data?.length > 0) {
          setShowButton(true)
          setDisplayList(resp.data)
        }

      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
    });
};

export const delete_Web_Invites_Data = async (invitation_id, enqueueSnackbar, msgFail) => {
  getRequest(`LbInvite/DeleteInvitation?invitation_id=${invitation_id}`).then((resp) => {
    if (resp.status === 200) {
      enqueueSnackbar(msgFail.msgS, {
        variant: "Success",
      });
    }
  }).catch((error) => {
    enqueueSnackbar(msgFail.failedmsg, {
      variant: "error",
    });
  })
}