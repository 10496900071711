import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import LbTitle from "shared/components/lb-title/lb-title";
import { Controller } from "react-hook-form";

function BuisnessType({
  register,
  fields,
  watch,
  errors,
  control,
  clearErrors,
}) {
  const { t } = useTranslation();
  const ShowErrorMsg = () => {
    return errors?.service_data?.message ? (
      <div className="warning-text mt-2">{t("BIDDING.SELECT_ONE_SERVICE")}</div>
    ) : '';
  }

  useEffect(() => {
    watch();
  })

  return (
    <div className="width-100-p">
      <LbTitle
        name={t("Shared.SERVICE_LIST") + "*"}
        size="16"
        weight="600"
      ></LbTitle>
      {!fields && (
        <span className="warning-text">
          Services not found, please contact support.
        </span>
      )}
      {fields?.map((item, index) => {
        return (
          <div key={item.id}>
            <label>
              <Controller
                name={`service_data[${index}].checked`}
                control={control}
                defaultValue={item.checked}
                render={({ field }) =>
                  <div>
                    <Checkbox {...field}
                      checked={watch(`service_data.${index}.checked`)} />
                    <LbTitle name={item.setting_value} size="15"></LbTitle>
                  </div>
                }
              />
              {item.description}
            </label>
            {item.service_description ? (
              <LbTitle name={item.service_description} size="15"></LbTitle>
            ) : (
              ""
            )}
            {/* {watch(`service_data.${index}.checked`) && ( */}
            <div className="flex" >
              <TextField
                size="small"
                id="booking_instruction"
                placeholder={t("Shared.FORM.BOOKING_INSTR")}
                {...register(`service_data.${index}.booking_instruction`)}
                value={watch(`service_data.${index}.booking_instruction`)}
                variant="outlined"
                className="width-100-p"
                disabled={!watch(`service_data.${index}.checked`)}
              />
              <TextField
                id="quantity"
                style={{ width: '130px' }}
                size="small"
                type="text"
                placeholder={'Quantity'}
                {...register(`service_data[${index}].quantity`)}
                value={
                  watch(`service_data.${index}.checked`) ? watch(`service_data.${index}.quantity`) ? watch(`service_data.${index}.quantity`) : '' : ''
                }
                variant="outlined"
                className="ml-1"
                disabled={!watch(`service_data.${index}.checked`)}
              />
            </div>
          </div>
        );
      })}
      <ShowErrorMsg />
    </div>
  );
}

export default BuisnessType;