import React from "react";
import { Pie } from "react-chartjs-2";

const Bidingchart = () => {
  const labels = ["Red", "Blue", "Yellow", "Green"];
  const data = [12, 19, 3, 5];
  return (
    <div>
      <Pie
        className="graph"
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              categoryPercentage: 0.5,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};
export default Bidingchart;
