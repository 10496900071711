import React from "react";
import { Helmet } from "react-helmet";


export const SEO = ({ name, sub_title, description }) => {
    return (
      <Helmet>
        <title>{name} - {sub_title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  };