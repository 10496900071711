import { Grid, TextField } from "@material-ui/core";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isPhoneExists } from "../../services/common-service";
import { phoneRegex } from "core/utils/constants";
function UserDetails({
  register,
  disable,
  errors,
  initialValues
}) {
  const { t } = useTranslation();
  const msg = {
    existMobileMsg: t("HelperMsg.MOBILEALREADYEXISTS"),
  };

  const today = new Date();
  const minDate = today.toISOString().split("T")[0];
  const { enqueueSnackbar } = useSnackbar();
  const [existMobile, setExistMobile] = useState(false);
  const handleInput = (event) => {
    const input = event.target.value;
    if (!phoneRegex.test(input)) {
      event.target.setCustomValidity(t("Validation.VALIDMOBILE"));
    } else {
      event.target.setCustomValidity("");
    }
  };

  const handleExistMobileNo = async (event) => {
    let oldMobile = initialValues?.cust_mob;
    const mobileNo = event.target.value;
    if (oldMobile !== mobileNo) {
      if (mobileNo) {
        isPhoneExists(mobileNo, enqueueSnackbar, "customer", msg).then(
          (mobileExists) => {
            setExistMobile(mobileExists);
          }
        );
      }
    } else {
      setExistMobile(false);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={disable}
            size="small"
            id="first_name"
            name="first_name"
            {...register("first_name")}
            variant="outlined"
            className="width-100-p"
            label={t("Shared.Sorting_menu.FIRST_NAME") + "*"}
            error={!!errors?.first_name}
            helperText={errors?.first_name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={disable}
            size="small"
            id="last_name"
            name="last_name"
            {...register("last_name")}
            variant="outlined"
            className="width-100-p"
            label={t("Shared.Sorting_menu.LAST_NAME") + "*"}
            error={!!errors?.last_name}
            helperText={errors?.last_name?.message}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            id="lagan_date"
            label={t("Shared.FORM.LAGAN_DATE") + "*"}
            {...register("lagan_date")}
            type="date"
            defaultValue={minDate}
            className="width-100-p"
            name="lagan_date"
            format="dd/MM/yyyy"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: minDate },
            }}
             onClick={(event) => {
              event?.target?.showPicker();
            }}
            error={!!errors?.lagan_date}
            helperText={errors?.lagan_date?.message} 
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={disable}
            size="small"
            id="cust_mob"
            label={t("Shared.FORM.MOBILE_NO") + "*"}
            className="width-100-p"
            name="cust_mob"
            variant="outlined"
            inputProps={{
              maxLength: 10,
            }}
            onChange={handleInput}
            {...register("cust_mob", {
              validate: (value) =>
                !existMobile || t("HelperMsg.MOBILEALREADYEXISTS"),
            })}
            onBlur={handleExistMobileNo}
            error={!!errors?.cust_mob}
            helperText={errors?.cust_mob?.message}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UserDetails;
