import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { FormControl, InputLabel, Box, Container } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { getLastNYears } from "core/utils/utilities";
import { get_information, update_vendor_profile } from "./profile_helper";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import VendorInformationView from "./vendor-information-view";
import { useTranslation } from "react-i18next";
import { DeleteForever } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "12px",
  },
  marginBottom15: {
    marginBottom: 15,
  },
  width100: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function VendorInformationForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const last50Years = getLastNYears(50);
  const [isEdit, setIsEdit] = useState(false);
  const [information, setInformation] = useState({});
  const [profileReload, setProfileReload] = useState(new Date());
  const initialValues = {
    first_name: information?.first_name,
    last_name: information?.last_name,
    mobile_no: information?.mobile_no,
    alt_mobile_no: information?.alt_mobile_no,
    org_name: information?.org_name,
    estb_year: information?.estb_year,
    gst_numb: information?.gst_numb,
    email_id: information?.email_id,
    govt_reg_numb: information?.govt_reg_numb,
    setting_value_id: information?.setting_value_id,
    vendorId: userData.vendor_id ? userData.vendor_id : "",
    banner_img: information?.banner_img,
    profile_text: information?.profile_text,

  };


  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const AddressSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    mobile_no: Yup.string()
      .required("Required")
      .matches(phoneRegExp, t('Validation.PHONENOTVALID')),
    alt_mobile_no: Yup.string().notRequired().nullable().matches(phoneRegExp, {
      message: t('Validation.PHONENOTVALID'),
      excludeEmptyString: true,
    }),
    email_id: Yup.string().required("Required").email(t("Validation.InvalidEmailId")),
    org_name: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
    estb_year: Yup.string().required("Required"),
    gst_no: Yup.string(),
  });
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AddressSchema,
    onSubmit: (values) => {
      values.estb_year = parseInt(values.estb_year);
      values.setting_value_id = information.setting_value_id;
      update_vendor_profile(
        userData.vendor_id,
        values,
        enqueueSnackbar,
        history,
        setIsEdit,
        setProfileReload,
        msg
      );
    },
  });

  const getInformation = () => {
    if (userData.vendor_id > 0)
      get_information(
        userData.vendor_id,
        "Vendor",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
    else if (userData.customer_id > 0)
      get_information(
        userData.customer_id,
        "Customer",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
  };

  useEffect(() => {
    if (!isEdit) getInformation();
  }, [isEdit, profileReload]);

  const editClick = () => {
    setIsEdit(true);
    formik.setFieldValue("first_name", information?.first_name);
    formik.setFieldValue("last_name", information?.last_name);
    formik.setFieldValue("mobile_no", information?.mobile_no);
    formik.setFieldValue("alt_mobile_no", information?.alt_mobile_no);
    formik.setFieldValue("org_name", information?.org_name);
    formik.setFieldValue("estb_year", information?.estb_year);
    formik.setFieldValue("gst_numb", information?.gst_numb);
    formik.setFieldValue("email_id", information?.email_id);
    formik.setFieldValue("govt_reg_numb", information?.govt_reg_numb);
    formik.setFieldValue("profile_text", information?.profile_text);
    formik.setFieldValue("banner_img", information?.banner_img);
  };
  const cancelClick = () => {
    setIsEdit(false);
  };
  return (
    <>
      <Container component="main" maxWidth="xl">
        {!isEdit && (
          <VendorInformationView
            information={information}
          ></VendorInformationView>
        )}
        {!isEdit && (
          <Box display="flex" justifyContent="end">
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={editClick}
            >
              {t("Profile_Details.EDIT")}
            </Button>
          </Box>
        )}
        {isEdit && (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="first_name"
                      type="text"
                      label={t("Booking_login.F_NAME")}
                      fullWidth
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.first_name && formik.touched.first_name
                      }
                      helpertext={
                        formik.touched.first_name && formik.errors.first_name
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="last_name"
                      variant="outlined"
                      fullWidth
                      id="last_name"
                      label={t("Booking_login.L_NAME")}
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helpertext={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="mobile_no"
                      type="tel"
                      label={t("Vendor.VendorDetails.MOBILE")}
                      fullWidth
                      name="mobile_no"
                      value={formik.values.mobile_no}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.mobile_no &&
                        Boolean(formik.errors.mobile_no)
                      }
                      helpertext={
                        formik.touched.mobile_no && formik.errors.mobile_no
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="alt_mobile_no"
                      type="tel"
                      label={t("Vendor.VendorDetails.ALT_MOB_NO")}
                      fullWidth
                      name="alt_mobile_no"
                      value={formik.values.alt_mobile_no}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.alt_mobile_no &&
                        Boolean(formik.errors.alt_mobile_no)
                      }
                      helpertext={
                        formik.touched.alt_mobile_no &&
                        formik.errors.alt_mobile_no
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="org_name"
                      type="text"
                      label={t("Vendor.VendorDetails.ORG_NAME")}
                      fullWidth
                      name="org_name"
                      value={formik.values.org_name}
                      onChange={formik.handleChange}
                      // onInput={sZipCode}
                      error={
                        formik.touched.org_name &&
                        Boolean(formik.errors.org_name)
                      }
                      helpertext={
                        formik.touched.org_name && formik.errors.org_name
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="estb_year">
                        {t("Vendor.VendorDetails.ESTD_YR")}
                      </InputLabel>
                      <Select
                        native
                        name="estb_year"
                        value={formik.values.estb_year}
                        // label="estb_year"
                        label={t("Vendor.VendorDetails.ESTD_YR")}
                        onChange={formik.handleChange}
                        defaultValue=""
                      >
                        {last50Years.map((year, index) => (
                          <option value={year}>{year}</option>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.errors.estb_year && formik.touched.estb_year ? (
                      <div className="error-color">
                        {formik.errors.estb_year}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="gst_numb"
                      type="text"
                      label={t("Vendor.VendorDetails.GST_NO")}
                      fullWidth
                      name="gst_numb"
                      value={formik.values.gst_numb}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.gst_numb &&
                        Boolean(formik.errors.gst_numb)
                      }
                      helpertext={
                        formik.touched.gst_numb && formik.errors.gst_numb
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="oname"
                      name="govt_reg_numb"
                      variant="outlined"
                      label={t("Vendor.VendorDetails.GOVT_REG_NO")}
                      // label="GOVT_REG_NO"
                      fullWidth
                      id="govt_reg_numb"
                      onChange={formik.handleChange}
                      value={formik.values.govt_reg_numb}
                      error={
                        formik.touched.govt_reg_numb &&
                        Boolean(formik.errors.govt_reg_numb)
                      }
                      helpertext={
                        formik.touched.govt_reg_numb &&
                        formik.errors.govt_reg_numb
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="email_id"
                      type="email"
                      label={t("Booking_login.EMAIL")}
                      fullWidth
                      className={classes.width100}
                      name="email_id"
                      value={formik.values.email_id}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.email_id &&
                        Boolean(formik.errors.email_id)
                      }
                      helpertext={
                        formik.touched.email_id && formik.errors.email_id
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="profile_text"
                      type="text"
                      label={t("Vendor.VendorDetails.PROFILE_TEXT")}
                      fullWidth
                      name="profile_text"
                      value={formik.values.profile_text}
                      onChange={formik.handleChange}
                      // onInput={sZipCode}
                      error={
                        formik.touched.profile_text &&
                        Boolean(formik.errors.profile_text)
                      }
                      helpertext={
                        formik.touched.profile_text &&
                        formik.errors.profile_text
                      }
                      variant="outlined"
                    />
                  </Grid>
                  {formik.values.banner_img === null ||
                    formik.values.banner_img === "" ? (
                    ""
                  ) : (
                    <ImageList rowHeight={180} className={classes.imageList}>
                      <ImageListItem>
                        <img src={formik.values.banner_img} alt="profile banner" />
                        <ImageListItemBar
                          actionIcon={
                            <IconButton
                              //  aria-label={`info about ${item.name}`}
                              className={classes.icon}
                              onClick={() => {
                                formik.setFieldValue("banner_img", "");
                              }}
                            >
                              <DeleteForever color="secondary" />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    </ImageList>
                  )}
                  <Grid item xs={12} sm={12}>
                    <input
                      type="file"
                      hidden
                      id="banner_img"
                      name="banner_img"
                      onChange={(event) => {
                        let files = event.target.files;
                        let reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        reader.onload = (e) => {
                          formik.setFieldValue("banner_img", e.target?.result);
                        };
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {formik.values.banner_img == null ||
                      formik.values.banner_img == "" ? (
                      <Button
                        onClick={() => {
                          document.getElementById("banner_img").click();
                        }}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        {t("Vendor.VendorDetails.UPLOAD_BANNERIMG")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      type="button"
                      size="small"
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      onClick={cancelClick}
                    >
                      {t("Shared.CNCL")}
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {t("Shared.SUBMIT")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </>
  );
}
