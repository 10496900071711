import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSnackbar } from "notistack";
import { confirm_booking } from "shared/services/booking-service";
import { getUserType } from "core/utils/utilities";
import { Divider } from "@material-ui/core";
import { log_activity } from "shared/services/common-service";

export default function ConfirmBooking({ bookingId }) {
  const { t } = useTranslation();
  const msgBookingConfirm = {
    msg: t("HelperMsg.BOOKINGCONFIRMED"),
    ConfirmFail: t("HelperMsg.CONFIRMBOOKINGFAIL"),
  };
  const [open, setOpen] = React.useState(false);
  const [btnStatus, setBtnStatus] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleEvent = (data) => {
    setBtnStatus(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
    log_activity("handleClickOpen", "confirm-booking.jsx", "Success");
  };

  const handleClose = () => {
    setOpen(false);
    log_activity("handleClose", "confirm-booking.jsx", "Success");
  };

  const confirmBooking = () => {
    let values = {
      bokId: bookingId,
      cust_vend: getUserType(),
      is_confirm: true,
    };
    confirm_booking(
      values,
      enqueueSnackbar,
      handleClose,
      handleEvent,
      msgBookingConfirm
    );
  };

  return (
    <div>
      {btnStatus ? (
        ""
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          {t("Shared.CONFIRM_BOOKING")}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-slide-title">
          {t("Shared.CONFIRM_BOOKING")}
        </DialogTitle>
        <Divider variant="" />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("Shared.CONFIRM_BOOKING_DETAILS")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmBooking}
            color="primary"
            size="small"
            variant="contained"
          >
            {t("Shared.YES")}
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            size="small"
            variant="contained"
          >
            {t("Shared.NO")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
