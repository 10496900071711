import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Box, Grid, makeStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { postRequest } from "core/utils/axios-client/axios-client";
import LbDialogTitle from "shared/components/overridden-component/lb-dialog-title";
import LbDialogContent from "shared/components/overridden-component/lb-dialog-content";
import { useTranslation } from "react-i18next";
import LbImageList from "shared/components/lb-image-list/lb-image-list";
import {
  maxFileCount,
  maxFileCountPerAccount,
  maxFileSize,
} from "core/utils/constants";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

export default function FileUpload(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const appData = useSelector((state) => state.appData);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //intialise the form field
  const initialValues = {
    vendServId: props.vendServId,
    vendor_id: +appData?.user?.vendor_id,
    business_type: +appData?.user?.setting_value_id,
    images: [],
    removedImgIds: "",
    imgIds: "",
  };

  //Validate the form field
  const InventorySchema = Yup.object().shape({
    //vend_serv_id: Yup.number().required("Required"),
  });
  const msg = {
    addedSuccessMsg: t("HelperMsg.inventryAddedSuccessMeg"),
    updatedSuccessMSg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    failedMSg: t("HelperMsg.APIFALIED"),
    imageMsg: t("HelperMsg.IMAGE_API_FAILED"),
    onlyimage: t("HelperMsg.ONLY_IMG"),
    imageSize: t("HelperMsg.IMAGE_SIZE", {
      maxFileSizeAllowed: maxFileSize / (1024 * 1024),
    }),
  };
  const fileUploadForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: InventorySchema,
    onSubmit: (values) => {
      //   console.log(values);
      let imageUploadRequest = [];
      values.images.forEach((img) => {
        imageUploadRequest.push(
          uploadFileToServer(img, values?.vendor_id, values?.business_type)
        );
      });
      Promise.all(imageUploadRequest).then((result) => {
        props.uploadedFilePaths(result);
        setOpen(false);
        log_activity("fileUploadForm", "file-upload.jsx", "Submitted");
      });
    },
  });

  const uploadFileToServer = (file, folderName, subFolderName) => {
    var fileData = {
      image: file?.data,
      folderName: folderName.toString(),
      subFolderName: subFolderName.toString(),
      fileName: file.name,
    };

    return postRequest("Common/UploadBase64FileAsync", fileData)
      .then((resp) => {
        if (resp?.status === 200) {
          return {
            image_id: 0,
            name: file.name,
            type: file.type,
            size: file.size,
            img_path: resp.data,
          };
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.File_Upload_failed"), {
          variant: "error",
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    fileUploadForm.resetForm();
    log_activity("handleClickOpen", "file-upload.jsx", "Success");
  };

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      dispatch(appActions.editInventory(-1));
      fileUploadForm.resetForm();
      //  console.log("Close");
    }
  };
  const uploadImages = (myFiles) => {
    let leftCount = maxFileCountPerAccount - props?.uploadedFileCount;
    if (leftCount > 0) {
      if (myFiles?.length > maxFileCount) {
        enqueueSnackbar(
          t("HelperMsg.IMAGE_LIMIT", { maxFileCountAllowed: maxFileCount }),
          {
            variant: "warning",
          }
        );
      } else if (myFiles?.length > leftCount) {
        enqueueSnackbar(
          t("HelperMsg.LEFT_IMAGE_LIMIT", { maxFileCountAllowed: leftCount, maxFileCountPerAccount:maxFileCountPerAccount }),
          {
            variant: "warning",
          }
        );
      } else {
        upload_images(myFiles, enqueueSnackbar, fileUploadForm, msg);
      }
    }
  };

  const upload_images = (myFiles, enqueueSnackbar, inventorySpecForm, msg) => {
    let uploadedImages = [];
    myFiles.forEach((file) => {
      if (file.type.indexOf("image") <= -1) {
        enqueueSnackbar(msg.onlyimage, {
          variant: "error",
        });
        return;
      }
      if (file.size < maxFileSize) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target.readyState === FileReader.DONE) {
            var binary = "";
            var array = new Uint8Array(evt.target.result);
            for (var i = 0; i < array.byteLength; i++) {
              binary += String.fromCharCode(array[i]);
            }
            uploadedImages.push({
              name: file.name,
              type: file.type,
              size: file.size,
              data: "data:" + file.type + ";base64," + window.btoa(binary),
            });

            inventorySpecForm.setFieldValue(
              "images",
              inventorySpecForm.values.images.concat(uploadedImages)
            );
          }
        };
      } else {
        enqueueSnackbar(msg.imageSize, {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Box ml={2} className="text-end">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          <AddCircleOutlineIcon></AddCircleOutlineIcon>
          {t("Shared.Service_management.ADD_PHOTO")}
        </Button>
      </Box>
      <Dialog
        open={open || appData.editInventoryIndex > -1}
        onClose={handleClose}
      >
        <form onSubmit={fileUploadForm.handleSubmit}>
          <LbDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("Shared.FILEUPLOAD")}
          </LbDialogTitle>
          <LbDialogContent>
            <div className={classes.root}>
              <Grid container justify="space-around" spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="space-around" spacing={1}>
                    <Grid item xs={12} className="text-end">
                      <Button
                        type="button"
                        size="small"
                        variant="contained"
                        component="label"
                        color="primary"
                      >
                        {" "}
                        <AddCircleOutlineIcon></AddCircleOutlineIcon>
                        {t("Shared.Service_management.SELECT_PHOTO")}
                        <input
                          type="file"
                          hidden
                          multiple
                          id="images"
                          name="images"
                          accept="image/*"
                          onChange={(event) => {
                            let myFiles = Array.from(event.target.files);
                            uploadImages(myFiles);
                          }}
                        />
                      </Button>
                      {/* )}  */}
                    </Grid>
                    <LbImageList
                      itemData={fileUploadForm.values.images}
                      title={t("Shared.PREVIEWOFIMAGES")}
                      col={2}
                      removeImage={(item) => {
                        // if (item?.image_id) removedImages.push(item?.image_id);
                        fileUploadForm.setFieldValue(
                          "images",
                          fileUploadForm.values.images.filter(
                            (newE) => newE !== item
                          )
                        );
                      }}
                    ></LbImageList>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </LbDialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t("Shared.CANCEL")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="primary"
            >
              {t("Shared.UPLOAD")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
