import WebInvite from "customer/invite/web-invite";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loaderCount: 0,
  search: {},
  user: {},
  selectedVendor: {},
  selectedBooking: {},
  editInventoryIndex: -1,
  ven_setting_value_id: "",
  locationDialog: false,
  profileData: "",
  activeMenuIndex: 0,
  userNotifications: [],
  userPerData: {},
  favDrawer: false,
  WebInviteUpdate: {},
  WebInviteData: false,
  appDownloadDialog: false,
};
const app = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log(action);
  switch (type) {
    case actionTypes.TYPING:
      return {
        ...state,
        search: payload,
      };
    case actionTypes.INCREASE_LOADER_COUNT:
      return {
        ...state,
        loaderCount: state.loaderCount + 1,
      };
    case actionTypes.RESET_WEB_INVITE_DATA:
      return {
        ...state,
        WebInviteData: null, 
      };
    case actionTypes.DECREASE_LOADER_COUNT:
      return {
        ...state,
        loaderCount: state.loaderCount > 0 ? state.loaderCount - 1 : 0,
      };
    case actionTypes.STOP_LOADER:
      return {
        ...state,
        loaderCount: 0,
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case actionTypes.SET_INVENTORY_VENDOR:
      return {
        ...state,
        selectedVendor: payload,
      };
    case actionTypes.SELECT_BOOKING:
      return {
        ...state,
        selectedBooking: payload,
      };
    case actionTypes.EDIT_INVENTORY:
      return {
        ...state,
        editInventoryIndex: payload,
      };
    case actionTypes.SET_VEN_SETTING_VAL_ID:
      return {
        ...state,
        ven_setting_value_id: payload,
      };
    case actionTypes.SET_LOCATION_DIALOG:
      return {
        ...state,
        locationDialog: payload,
      };
    case actionTypes.PROFILE_DATA:
      return {
        ...state,
        profileData: payload,
      };
    case actionTypes.RESET_STATE:
      return initialState;
    case actionTypes.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenuIndex: payload,
      };
    case actionTypes.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: payload,
      };
    case actionTypes.SET_USER_PER_DATA:
      return {
        ...state,
        userPerData: { ...payload },
      };
    case actionTypes.SET_FAV_DRAWER:
      return {
        ...state,
        favDrawer: payload,
      };
    case actionTypes.SET_WEB_INVITE_UPDATE:
      return {
        ...state,
        WebInviteUpdate: payload,
      };
    case actionTypes.SET_APP_DOWNLOAD_DIALOG:
      return {
        ...state,
        appDownloadDialog: payload,
      };
    default:
      return state;
  }
};

export default app;
