import * as actionTypes from "./actionTypes";

export const typing = (payload) => {
  return {
    type: actionTypes.TYPING,
    payload: payload,
  };
};

export const increaseLoaderCount = () => {
  return {
    type: actionTypes.INCREASE_LOADER_COUNT,
  };
};

export const decreaseLoaderCount = () => {
  return {
    type: actionTypes.DECREASE_LOADER_COUNT,
  };
};
export const stopLoader = () => {
  return {
    type: actionTypes.STOP_LOADER,
  };
};

export const updateUser = (payload) => {
  return {
    type: actionTypes.UPDATE_USER,
    payload: payload,
  };
};

export const setInventoryVendor = (payload) => {
  return {
    type: actionTypes.SET_INVENTORY_VENDOR,
    payload: payload,
  };
};

export const selectBooking = (payload) => {
  return {
    type: actionTypes.SELECT_BOOKING,
    payload: payload,
  };
};

export const editInventory = (payload) => {
  return {
    type: actionTypes.EDIT_INVENTORY,
    payload: payload,
  };
};

export const setVenSettingValueId = (payload) => {
  return {
    type: actionTypes.SET_VEN_SETTING_VAL_ID,
    payload: payload,
  };
};

export const setLocationDialog = (payload) => {
  return {
    type: actionTypes.SET_LOCATION_DIALOG,
    payload: payload,
  };
};
export const profileData = (payload) => {
  return {
    type: actionTypes.PROFILE_DATA,
    payload: payload,
  };
};

export const resetState = () => {
  return {
    type: actionTypes.RESET_STATE,
  };
};

export const setActiveMenu = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_MENU,
    payload: payload,
  };
};


export const setUserNotifications = (payload) => {
  return {
    type: actionTypes.SET_USER_NOTIFICATIONS,
    payload: payload,
  };
};
export const WebInviteUpdate = (payload) => {
  return {
    type: actionTypes.SET_WEB_INVITE_UPDATE,
    payload: payload,
  };
};

export const resetWebInviteData = (payload) => ({
  type: actionTypes.RESET_WEB_INVITE_DATA,
  payload: payload,
});
export const setUserPerData = (payload) => {
  return {
    type: actionTypes.SET_USER_PER_DATA,
    payload: payload,
  };
};
export const setFavDrawer = (payload) => {
  return {
    type: actionTypes.SET_FAV_DRAWER,
    payload: payload,
  };
};
export const setAppDownloadDialog = (payload) => {
  return {
    type: actionTypes.SET_APP_DOWNLOAD_DIALOG,
    payload: payload,
  };
};