import { phoneRegex, zipcodeRegex } from "core/utils/constants";
import * as yup from "yup";

const translateObj = {
    required: "Validation.REQUIRED",
};

export const StringNoReqValidate = (t) => {
    return yup
        .string()
        .max(100, "Too Long!");
};

export const StringValidate = (t) => {
    return yup
        .string()
        .required(t(translateObj.required))
        .min(2, "Too Short!")
        .max(100, "Too Long!")
        .matches(/^[A-Za-z. ]+$/, t("Validation.INVALIDNAME"));
};

export const dateValidate = (t) => {
    return yup
        .date()
        .required(t(translateObj.required));
};

export const EmailValidate = (t) => {
    return yup
        .string()
        .required(t("Validation.REQUIRED"))
        .email(t("Validation.InvalidEmailId"));
};
export const EmailNoReqValidate = (t) => {
    return yup
        .string()
        .email(t("Validation.InvalidEmailId"));
};

// Adjusted PhoneValidate function to accept an empty string as valid input
export const PhoneNoReqValidate = (t) => {
    return yup
        .string()
        .nullable()
        .matches(phoneRegex, t("Validation.PHONENOTVALID"));
};

export const NumberValidate = () => {
    return yup
        .number()
        .typeError('Please enter a valid number')
        .positive('Please enter a positive number')
        .integer('Please enter an integer');
}

export const ZipcodeValidate = (t) => {
    return yup.string()
        .required(t(translateObj.required))
        .matches(zipcodeRegex, 'Please enter a valid Zipcode');
}

export const PhoneValidate = (t) => {
    return yup
        .string()
        .required(t(translateObj.required))
        .matches(phoneRegex, t("Validation.PHONENOTVALID"));
}

export const AddressValidate = (t) => {
    return yup.string()
        .required(t(translateObj.required))
        .matches(/^[A-Za-z0-9,.\/ _-]+$/, t("Validation.INVALIDNAME"))
        .min(2, "Too Short!")
        .max(100, "Too Long!");
}
export const inviteMessageValidate = (t) => {
    return yup.string()
        .required(t(translateObj.required))
        .matches(/^[A-Za-z0-9,.;'"\(\)\\_?\s-]+$/, t("Validation.INVALIDNAME"))
        .min(2, t("Too Short!"))
        .max(100, t("Too Long!"));
}

export const PasswordValidate = (t) => {
    return yup.string().min(6, "Too Short!")
}

export const CustVendValidate = (t) => {
    return yup.string().oneOf(
        ["customer", "vendor"],
        t("Shared.PLEASESELECTONE")
    )
}
export const AccTermValidate = (t) => {
    return yup.bool().oneOf([true], t("Validation.ACCEPT_TERMS"))
}
