import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
//import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import { Grid, Icon, Paper } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import { postRequest } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import * as appActions from "redux/actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function UserMessagesDialog() {
  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const appData = useSelector((state) => state.appData);
  const userNotifications = appData.userNotifications;
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const markReadMessage = (notificationId) => {
    postRequest(
      "LbNotification/UpdateNotificationFlag/" + notificationId + "/" + true
    )
      .then((resp) => {
        if (resp.status === 200) {
          // enqueueSnackbar(t("HelperMsg.MARKREAD"), {
          //   variant: "success",
          // });
          dispatch(
            appActions.setUserNotifications(
              userNotifications.filter(
                (notification) => notification.not_id != notificationId
              )
            )
          );
          if (userNotifications.length === 0) {
            setOpen(false);
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar("Mark Read Failed", {
          variant: "error",
        });
      });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"New Messages"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={classes.root}>
            {userNotifications?.map((userNotification) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {userNotification.subject}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{userNotification.body}</Typography>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => markReadMessage(userNotification.not_id)}
                  >
                    Mark Read
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
