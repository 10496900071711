import { Avatar, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
   // color: "#444",
    fontWeight: "500",
    fontSize: "21px",

  },
  root:{
    padding:"5px"
  },
  primary: {
    border: "2px " + theme.palette.primary.main + " solid",
    backgroundColor: "#ffff",
    padding:"4px"
  },
}));

function LbAvtarName({ firstName, lastName, src, variant, txtCss }) {
  const classes = useStyles();
  return (
        <CardHeader
        className={classes.root}
          avatar={
            <Avatar
              alt={firstName?.toUpperCase()}
              src={src}
              className={classes.primary}
              variant={variant}
            />
          }
          title={
            <span className={ classes.title + " "+{txtCss}}>
              {firstName  + " " + ( lastName ?  lastName : "")}
            </span>
          }
        />
  );
}

export default LbAvtarName;
