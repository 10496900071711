import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Icon,
  Hidden,
  Chip,
} from "@material-ui/core";
import LocationDialog from "shared/location-dialog/location-dialog";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import LanguageIcon from "@material-ui/icons/Language";
import i18next from "i18next";
import "./header.scss";
import MenuDrawer from "vendor/side-menu/menu-drawer";
import { languages } from "core/utils/constants";
import { getLocalStorage, updatePersonalData } from "core/utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import UserMessagesDialog from "shared/notification/user-messages-dialog/user-messages-dialog";
import NotificationMenu from "shared/notification/notification-menu";
import { useEffect } from "react";
import * as appActions from "redux/actions/appActions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { EditLocation } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "block",
    marginBottom: "20px",
  },

  badge: {
    margin: theme.spacing(1),
    cursor: "pointer",
  },
  title: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  txt: {
    color: "white",
    padding: "6px 16px",
  },
  imgLogo: {
    width: "150px",
    height: "55px",
    // Mobile
    [theme.breakpoints.up("sm")]: {
      width: "185px",
      height: "60px",
    },
  },

  button: {
    maxHeight: "40px",
    color: "#ffff",
    marginRight: "10px",
    marginLeft: "10px",
    border: "#ffff solid 1px",
  },
}));

export default function Header() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorE2, setAnchorE2] = React.useState(null);
  const appData = useSelector((state) => state.appData);
  const newUnreadMessageCount = appData?.userNotifications?.length;
  const [openUserNotification, setopenUserNotification] = React.useState(false);
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const [isLocationExist, setIsLocationExist] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(
    getLocalStorage("i18nextLng") === "en" ? 0 : 1
  );
  const locationDialog = useSelector((state) => state.appData.locationDialog);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const locationData = getLocalStorage("LB_USER_LOCATION");
   const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
  };

  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function handleClick(event, index, lang) {
    i18next.changeLanguage(lang?.toLowerCase());
    setSelectedIndex(index);
    setAnchorE2(null);
    appData.userPerData.data_value.lang = lang?.toLowerCase();
    dispatch(appActions.setUserPerData(appData.userPerData));
    if (userData?.account_id > 0)
      updatePersonalData(
        userData?.account_id,
        appData?.userPerData?.data_value,
        enqueueSnackbar,
        msg
      );
  }

  const handleClickListItem = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE2(null);
  };
  const handleBadgeClick = () => {
    setopenUserNotification(true);
  };
  const changeLocationClick = () => {
    dispatch(appActions.setLocationDialog(true));
  };

  useEffect(() => {
    //  console.log("Parent useEffect ");
    setIsLocationExist(
      localStorage.getItem("LB_USER_LOCATION")?.length > 10 ? true : false
    );
    setIsLoading(false);
  }, []);

  if (isLoading) return null;
  else
    return (
      <div className={classes.grow}>
        <AppBar position="fixed">
          <Toolbar>
            <Box display="flex" flexGrow={1}>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <MenuDrawer
                    anchor={anchor}
                    state={state}
                    toggleDrawer={toggleDrawer}
                  ></MenuDrawer>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    <img
                      src="/images/lagan_logo_w.png"
                      className={classes.imgLogo}
                      alt=""
                    ></img>
                  </Link>
                  <Box flexGrow={1} className="text-end color-white">
                    <List component="nav" aria-label="Device settings">
                      <Hidden smDown>
                        <Chip
                          icon={<EditLocation style={{ color: "white" }}/>}
                          label={
                            locationData?.city_local +
                            ", " +
                            locationData?.district
                          }
                          onClick={changeLocationClick}
                          className={classes.button}
                          variant="outlined"
                        />
                      </Hidden>
                      {userData?.account_id && newUnreadMessageCount > 0 && (
                        <NotificationMenu
                          newUnreadMsgCount={newUnreadMessageCount}
                        ></NotificationMenu>
                      )}
                      <Button
                        className={classes.txt}
                        startIcon={<LanguageIcon />}
                        onClick={handleClickListItem}
                      >
                        <ListItemText primary={languages[selectedIndex]} />
                      </Button>
                      {appData?.user?.account_id && (
                        <Hidden smDown>
                          <Icon className="menu-btn mr-4">account_circle</Icon>
                        </Hidden>
                      )}

                      <Icon
                        onClick={toggleDrawer(anchor, true)}
                        className="menu-btn"
                      >
                        menu
                      </Icon>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorE2}
                      keepMounted
                      open={Boolean(anchorE2)}
                      onClose={handleClose}
                    >
                      {languages.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleClick(event, index, option)}
                        >
                          <img
                            src={
                              option === "Hi"
                                ? "/images/lang/in.png"
                                : "/images/lang/us.png"
                            }
                            alt="language"
                            className="lang-icon"
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        {isLocationExist ? (
          locationDialog ? (
            <LocationDialog disableClose={false}></LocationDialog>
          ) : (
            ""
          )
        ) : (
          <LocationDialog disableClose={false}></LocationDialog>
        )}
        {openUserNotification && <UserMessagesDialog></UserMessagesDialog>}
      </div>
    );
}
