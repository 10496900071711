import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Avatar,
  Button,
  TextField,
  Divider,
  } from "@material-ui/core";
import { save_guest_list } from "customer/services/guestlist-helper";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { AddGuestListForm } from "shared/validation-schema/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  top1: {
    marginTop: "1rem",
  },
}));

export default function AddGuestListTitle(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { onClose /* other props */ } = props;
  const { openPopup, setOpenPopup, editData } = props;
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(AddGuestListForm(t)),
  });;
  const appData = useSelector((state) => state.appData);
  const handleClose = (event, reason) => {
    setOpenPopup(false);
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    registerfailed: t("HelperMsg.REGISTERFAILED"),
    errorOccuured: t("HelperMsg.ERROROCCURED"),
    existEmailMsg: t("HelperMsg.EmailIdAlreadyExists"),
  };

  const onSubmit = (data) => {
    let payload = {
      customer_id: appData.user.customer_id,
      name: data.name,
      guest_list_id: editData.guest_list_id ? editData.guest_list_id : 0,
     opr: editData.guest_list_id>0 ? 2 : 1,
    };
    save_guest_list(payload, enqueueSnackbar, msgFail);
    setOpenPopup(false);
    onClose();
  };


  useEffect(() => {
    if (editData) {
      setValue("name", editData.name || "");
    }
  }, [editData]);


  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      PaperProps={{
        style: {
          padding: "1rem",
          width: "550px", // Add the minimum width you want here
        },
      }}
    >
      <div className={classes.center}>
        <Avatar
          alt="New guest list"
          className={classes.imageIcon}
          src="/images/icons/1/nfc.png"
        />
      </div>
      <LbHeading text={t("GuestList.NEW_GUEST_LIST")} />
      <Divider></Divider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.top1} spacing={1}>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="outlined-basic"
              name="name"
              {...register("name", {
                required: { value: true, message: t("Validation.REQUIRED") },
              })}
              variant="outlined"
              className="width-100-p"
              label={t("GuestList.NAME") + "*"}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              placeholder={t("GuestList.PLACEHOLDER")}
            />
          </Grid>
        </Grid>
        <div align="end" className="mt-8">
          <Button
            type="button"
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleClose}
            className="mr-5"
          >
            {t("Shared.CANCEL")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
          >
            {t("Shared.SAVE")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
