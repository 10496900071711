import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import Schedule from "@material-ui/icons/Schedule";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  statusColor: {
    backgroundColor: "#4C9A2A",
    color: "#fff",
  },
}));
function LbStatus({ confirmedVendor, confirmedUser }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
    {confirmedVendor && confirmedUser ? <Chip
        className={classes.statusColor} size="small"
        label={t("Shared.CONFIRMED")} icon={ <DoneIcon style={{ color: "#fff" }} /> }
      />:
      <Chip size="small" label={t("Shared.PENDING")} icon={ <Schedule /> }/>}
    </>
  );
}

export default LbStatus;
