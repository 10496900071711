import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "../utils/firebase/firebase-config";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  register_fun,
  setUpReCaptcha,
  emailSignUp,
  signUpWithGoogle,
} from "./register_helper";
import { useTranslation } from "react-i18next";
import { isPhoneExists, isEmailExists } from '../../shared/services/common-service';
import {
  Phone,
  Visibility,
  VisibilityOff,
  EmailOutlined,
} from "@material-ui/icons";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Link,
  SvgIcon,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Avatar,
} from "@material-ui/core";

import { onAuthStateChanged } from "firebase/auth";
import { GAPageViewTrigger } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  phoneAvatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginBottom15: {
    marginBottom: 15,
  },
  textFiled: {
    margin: theme.spacing(1),
  },
  imageIcon: {
    height: "5rem",
    width: "5rem",
    border: "1px solid #7058e7",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
  },
}));

export default function Register() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [errorMsg] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const toggleFormInitial = {
    showEmailRegistrationForm: false,
    showPhoneRegistrationForm: false,
    showUserRegistrationForm: false,
  };

  const userDataInit = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    email_id: "",
    pwd: "",
    cust_vend: "customer",
    firebase_id: "",
    tnc: false,
  };

  const [toggleForm, setToggleForm] = useState({
    ...toggleFormInitial,
    showEmailRegistrationForm: true,
  });

  const [userData, setUserData] = useState(userDataInit);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [isEye, setIsEyeOpen] = useState(false);
  const [isDisableEmail, setDisableEmail] = useState(false);
  const [isDisablePhone, setDisablePhone] = useState(false);
  const [confirmObj, setConfirmObj] = useState({});
  const [existMobile, setExistMobile] = useState(false);
  const [existEmail, setExistEmail] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const phoneRegExp = /^(0)?[6-9][0-9]{9}$/;

  useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search,"register");
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.providerId !== "password") {
        userRegistrationForm.values.firebase_id = user.uid;

        const name = user?.displayName?.split(" ");
        if (name?.length >= 2) {
          userRegistrationForm.values.first_name = name[0];
          userRegistrationForm.values.last_name = name[1];
        } else {
          userRegistrationForm.values.first_name = user?.displayName ?? "";
        }

        if (user?.email) {
          userRegistrationForm.values.email_id = user?.email;
          userRegistrationForm.values.pwd = user?.email;
          setDisableEmail(true);
        }

        if (user?.phoneNumber) {
          userRegistrationForm.values.mobile_no =
            user?.phoneNumber?.substring(3);
          setDisablePhone(true);
        }

        //setUserData({ ...userData, firebase_id: user.id });
        setToggleForm({ ...toggleFormInitial, showUserRegistrationForm: true });

        //    console.log(userRegistrationForm.values);
      }
    });
    return () => unsubscribe();
  }, []);

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required(t("Validation.REQUIRED")),
    last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    email_id: Yup.string()
      .required(t("Validation.REQUIRED"))
      .email(t("Validation.InvalidEmailId")),
    mobile_no: Yup.string()
      .required(t("Validation.REQUIRED"))
      .matches(phoneRegExp, t("Validation.PHONENOTVALID")),
    pwd: Yup.string().min(6, "Too Short!"),
    cust_vend: Yup.string().oneOf(
      ["customer", "vendor"],
      t("Shared.PLEASESELECTONE")
    ),
    tnc: Yup.bool().oneOf([true], t("Validation.ACCEPT_TERMS")),
  });

  const msg = {
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    registerfailed: t("HelperMsg.REGISTERFAILED"),
    errorOccuured: t("HelperMsg.ERROROCCURED"),
    GETOTP: t("HelperMsg.GETOTP"),
    existEmailMsg: t("HelperMsg.EmailIdAlreadyExists"),
    existMobileMsg: t("HelperMsg.MOBILEALREADYEXISTS"),
  };

  const userRegistrationForm = useFormik({
    enableReinitialize: true,
    initialValues: userData,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
    if(!existEmail && !existMobile){
        values.email_id = values.email_id.trim();
        if (
          !toggleForm.showUserRegistrationForm &&
          !toggleForm.showPhoneRegistrationForm
        ) {
          await emailSignUp(auth, values.email_id, values.pwd, enqueueSnackbar)
            .then((fireuser) => {
              values.firebase_id = fireuser.user.uid;
              register_fun(values, enqueueSnackbar, history, msg);
             // fireuser?.user?.sendEmailVerification();
            })
            .catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
              });
            });
        } else {
          register_fun(values, enqueueSnackbar, history, msg);
        }
      }
    },
   
  });

  const verifyOTPInitValues = {
    verifyOtp: "",
    mobileNo: "",
  };

  const otpForm = useFormik({
    initialValues: verifyOTPInitValues,
    onSubmit: (values) => {
      if (values.verifyOtp === " " || values.verifyOtp === null) return;
      try {
        confirmObj
          .confirm(values.verifyOtp)
          .then(async (resp) => {
            console.log(resp);
            setUserData({ ...userData, mobile_no: values.mobileNo });
          })
          .catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
            });
          });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    },
  });

  const getOTP = async () => {
    const phoneNumber = otpForm.values.mobileNo;
    if (phoneNumber === " " || phoneNumber === null) return;
    try {
      const resp = await setUpReCaptcha(auth, phoneNumber);
      setConfirmObj(resp);
      setShowVerifyOtp(true);
    } catch (error) {
      enqueueSnackbar(msg.GETOTP, {
        variant: "error",
      });
    }
  };

  const handleExistEMAIL = async (emailId) => {
    if(emailId){
      const isEmail = await isEmailExists(emailId, enqueueSnackbar, userRegistrationForm?.values?.cust_vend, msg);
      setExistEmail(isEmail);
    }
  };

  const handleExistMobileNo = async (mobileNo) => {
    if(mobileNo){
      const mobileExists = await isPhoneExists(mobileNo, enqueueSnackbar, userRegistrationForm?.values?.cust_vend, msg);
      setExistMobile(mobileExists);
    }
  };
  const handleExist = () => {
    const mobileNo = userRegistrationForm?.values?.mobile_no;
    const emialId = userRegistrationForm?.values?.email_id;
    if (mobileNo) {
      handleExistMobileNo(mobileNo);
    }
    if (emialId) {
      handleExistEMAIL(emialId)
    }
  }
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.center}>
            <Avatar
              alt="registration"
              className={classes.imageIcon}
              src="/images/icons/1/locksmith.png"
            />
          </div>
          <Typography component="h1" variant="h5" className="mb-8">
            {t("Booking_login.REGISTER")}
          </Typography>

          {(toggleForm.showEmailRegistrationForm ||
            toggleForm.showUserRegistrationForm) && (
              <form onSubmit={userRegistrationForm.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="first_name"
                      name="first_name"
                      variant="outlined"
                      fullWidth
                      id="first_name"
                      label={t("Booking_login.F_NAME")}
                      autoFocus
                      onChange={userRegistrationForm.handleChange}
                      value={userRegistrationForm.values.first_name}
                    />
                    {userRegistrationForm.errors.first_name &&
                      userRegistrationForm.touched.first_name ? (
                      <div className="error-color">
                        {userRegistrationForm.errors.first_name}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="last_name"
                      label={t("Booking_login.L_NAME")}
                      name="last_name"
                      autoComplete="last_name"
                      onChange={userRegistrationForm.handleChange}
                      value={userRegistrationForm.values.last_name}
                    />
                    {userRegistrationForm.errors.last_name &&
                      userRegistrationForm.touched.last_name ? (
                      <div className="error-color">
                        {userRegistrationForm.errors.last_name}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={isDisablePhone}
                      id="mobile_no"
                      label={t("Booking_login.MOB_NO")}
                      name="mobile_no"
                      autoComplete="off"
                      onBlur={() => handleExistMobileNo(userRegistrationForm.values.mobile_no)}
                      onChange={userRegistrationForm.handleChange}
                      value={userRegistrationForm.values.mobile_no}
                    />
                    {userRegistrationForm.errors.mobile_no &&
                      userRegistrationForm.touched.mobile_no ? (
                      <div className="error-color">
                        {userRegistrationForm.errors.mobile_no}
                      </div>
                    ) : null}
                    {existMobile && (
                      <div className="error-color">
                        <p>{t("HelperMsg.MOBILEALREADYEXISTS")}</p>
                      </div>
                    )}
                    <TextField
                      type="email"
                      variant="outlined"
                      margin="normal"
                      disabled={isDisableEmail}
                      fullWidth
                      name="email_id"
                      label={t("Booking_login.EMAIL")}
                      id="email_id"
                      autoComplete="off"
                      onBlur={() => handleExistEMAIL(userRegistrationForm.values.email_id)}
                      onChange={userRegistrationForm.handleChange}
                      value={userRegistrationForm.values.email_id}
                    />
                    {userRegistrationForm.errors.email_id &&
                      userRegistrationForm.touched.email_id ? (
                      <div className="error-color">
                        {userRegistrationForm.errors.email_id}
                      </div>
                    ) : null}
                    {existEmail && (
                      <div className="error-color">
                        <p>{t("HelperMsg.EmailIdAlreadyExists")}</p>
                      </div>
                    )}
                    {toggleForm.showEmailRegistrationForm && (
                      <>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="pwd"
                          label={t("Booking_login.PASS")}
                          name="pwd"
                          type={isEye ? "text" : "password"}
                          onChange={userRegistrationForm.handleChange}
                          value={userRegistrationForm.values.pwd}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setIsEyeOpen(!isEye)}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {isEye ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {userRegistrationForm.errors.pwd &&
                          userRegistrationForm.touched.pwd ? (
                          <div className="error-color">
                            {userRegistrationForm.errors.pwd}
                          </div>
                        ) : null}
                      </>
                    )}

                    <FormControl>
                      <FormLabel>{t("Booking_login.WHO")}</FormLabel>
                      <RadioGroup
                        row
                        aria-label="register as"
                        value={userRegistrationForm.values.cust_vend}
                      >
                        <FormControlLabel
                          value="customer"
                          control={<Radio color="primary" />}
                          label={t("Booking_login.CUSTOMER")}
                          name="cust_vend"
                          onChange={userRegistrationForm.handleChange}
                          onClick={handleExist}
                        />
                        <FormControlLabel
                          onClick={handleExist}
                          value="vendor"
                          control={<Radio color="primary" />}
                          label={t("Booking_login.VENDOR")}
                          name="cust_vend"
                          onChange={userRegistrationForm.handleChange}
                        />
                      </RadioGroup>
                      {userRegistrationForm.errors.cust_vend &&
                        userRegistrationForm.touched.cust_vend ? (
                        <div className="error-color">
                          {userRegistrationForm.errors.cust_vend}
                        </div>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox name="tnc" type="checkbox" color="primary" />
                      }
                      label={t("Booking_login.AGREE")}
                      onChange={userRegistrationForm.handleChange}
                      value={userRegistrationForm.values.tnc}
                    />
                    {userRegistrationForm.errors.tnc &&
                      userRegistrationForm.touched.tnc ? (
                      <div className="error-color">
                        {userRegistrationForm.errors.tnc}
                      </div>
                    ) : null}
                  </Grid>
                  {errorMsg.length > 1 ? (
                    <div className="error-color">{errorMsg}</div>
                  ) : null}
                </Grid>
                <div className="text-end">
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {t("Booking_login.REG_NOW")}
                  </Button>
                </div>
              </form>
            )}
          {toggleForm.showPhoneRegistrationForm && (
            <form className={classes.form} onSubmit={otpForm.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="mobileNo"
                label={t("Booking_login.MOB_NO")}
                type="number"
                id="mobileNo"
                autoComplete="mobileNo"
                onBlur={() => handleExistMobileNo(userRegistrationForm.values.mobileNo)}
                onChange={otpForm.handleChange}
                value={otpForm.values.mobileNo}
              />
              {showVerifyOtp && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="verifyOtp"
                    label="Provide OTP"
                    type="number"
                    id="verifyOtp"
                    autoComplete="verify otp"
                    onChange={otpForm.handleChange}
                    value={otpForm.values.verifyOtp}
                  />
                  {otpForm.errors.verifyOtp && otpForm.touched.verifyOtp ? (
                    <div className="error-color">
                      {otpForm.errors.verifyOtp}
                    </div>
                  ) : null}

                  <Button
                    id="btn-otp-verify"
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Verify
                  </Button>
                </>
              )}
              {!showVerifyOtp ? (
                <>
                  <div id="captcha-container"></div>
                  <Button
                    id="btn-otp-verify"
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={getOTP}
                  >
                    Send OTP
                  </Button>
                </>
              ) : null}
            </form>
          )}
          {(toggleForm.showPhoneRegistrationForm ||
            toggleForm.showEmailRegistrationForm) && (
              <>
                <Grid item xs={12}>
                  <Link href="/signin" variant="body2">
                    {t("Validation.IF_REGISTERED")}
                  </Link>
                </Grid>
                <div className="flexrow">
                  <button
                    className="login-btn gog-btn"
                    onClick={() => signUpWithGoogle(auth)}
                  >
                    <SvgIcon>
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        fill="#EA4335"
                      />
                      <path d="M1 1h22v22H1z" fill="none" />
                    </SvgIcon>
                  </button>
                  {toggleForm.showEmailRegistrationForm ? (
                    <button
                      className="login-btn gog-btn"
                      onClick={() => {
                        setToggleForm({
                          ...toggleFormInitial,
                          showPhoneRegistrationForm: true,
                        });
                      }}
                    >
                      <Phone style={{ color: "black" }} />
                    </button>
                  ) : (
                    <button
                      className="login-btn gog-btn"
                      onClick={() => {
                        setToggleForm({
                          ...toggleFormInitial,
                          showEmailRegistrationForm: true,
                        });
                      }}
                    >
                      <EmailOutlined style={{ color: "black" }} />
                    </button>
                  )}
                </div>
              </>
            )}
        </div>
      </Container>
    </>
  );
}
