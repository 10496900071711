import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core';
import { InsertInvitationRounded, SendOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: '300px', // Set minimum width
    width: '500px', // Set desired width
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function InvitationDialog({ open, onClose }) {
  const classes = useStyles();
  const [selectedCard, setSelectedCard] = useState('');
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');

  const handleSendInvite = () => {
    // Logic to send invitation via email or WhatsApp
    console.log('Sending invitation:', selectedCard, 'to', recipient, 'with message:', message);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Invitation</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Select value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)} fullWidth>
          <MenuItem value="card1">Invitation Card 1</MenuItem>
          <MenuItem value="card2">Invitation Card 2</MenuItem>
          {/* Add more invitation card options */}
        </Select>
        <TextField value={recipient} onChange={(e) => setRecipient(e.target.value)} label="Recipient" fullWidth />
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Message"
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSendInvite} color="primary" variant="contained">
          {/* <SendOutlined className={classes.buttonIcon} /> */}
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InvitationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
