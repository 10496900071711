import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f7f7f5',
        height: '100vh',
        alignItems: 'center'
      },
      text:{
        color:'#a5a5a5',
        fontSize: '18px'
      },
      subhead:{
        color:'#999999',
        fontSize: '2.5rem'
      },
      netBtn:{
        width: '185px',
        marginTop: '20px'
      }
  }));
function LbNetCheck(props) {
    const classes = useStyles();
    const refreshPage = () => { 
        window.location.reload(); 
    }
    return (
        <div className={classes.root}>
            <div className='text-center'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m33.65 29.4-1.75-1.75q1.45-1.6 2.225-3.55.775-1.95.775-4.1 0-2.2-.825-4.175-.825-1.975-2.325-3.575l1.75-1.75q1.9 1.9 2.9 4.35 1 2.45 1 5.15 0 2.65-1 5.05t-2.75 4.35Zm-6.3-6.3-6.45-6.45q.6-.6 1.425-.9.825-.3 1.675-.3 1.9 0 3.225 1.325Q28.55 18.1 28.55 20q0 .85-.3 1.65-.3.8-.9 1.45ZM38.25 34l-1.75-1.75q2.35-2.55 3.675-5.675Q41.5 23.45 41.5 20q0-3.5-1.35-6.675t-3.8-5.675L38.1 5.9q2.85 2.85 4.375 6.475Q44 16 44 20q0 3.95-1.525 7.55-1.525 3.6-4.225 6.45Zm2.4 10.95L25.5 29.8V42h-3V26.8l-9.1-9.1q-.2.55-.25 1.125-.05.575-.05 1.175 0 2.2.8 4.2t2.35 3.55L14.5 29.5q-1.9-1.9-2.9-4.35-1-2.45-1-5.15 0-1.15.175-2.225.175-1.075.575-2.125l-3.1-3.1q-.85 1.75-1.3 3.625Q6.5 18.05 6.5 20q0 3.5 1.325 6.675t3.825 5.675L9.9 34.1q-2.85-2.8-4.375-6.45Q4 24 4 20q0-2.45.6-4.8.6-2.35 1.75-4.55l-3.3-3.3L5.2 5.2l37.6 37.6Z"/></svg>
            <h1 className={classes.subhead}>Ooops!</h1>
            <p className={classes.text}>No Internet connection found, Checkyour connection</p>
            <button className={classes.netBtn} onClick={ refreshPage }>Try Again</button>
            </div>
        </div>
    );
}

export default LbNetCheck;