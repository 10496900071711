import React, { Suspense, lazy, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Icon, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./new-landing.scss";
import LbTitle from "shared/components/lb-title/lb-title";
import LandingSlider from "./landing-slider";
import { Link } from "react-router-dom";
import {
  BUSINESSCATEGORYLIST,
  landingImageGalleryList,
} from "core/utils/constants";
// import LandingPopularSearches from "./landing-popular-searches";
// import LandingSocial from "./landing-social";
// import DownloadApp from "./download-app";
// import CardArt1 from "shared/components/card-art1";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as appActions from "redux/actions/appActions";
import { useDispatch } from "react-redux";
import { get_user_notification } from "core/common/profile/profile_helper";
import LbParagraph from "shared/components/helper-component/lb-paragraph";
import { useSelector } from "react-redux";
import LandingFeatures from "./landing-features";
import { SEO } from "core/common/seo-title";
const SwiperSlideLb = lazy(() => import('shared/slider/swiper-slider'));
const LandingCalender = lazy(() => import('./landing-calender'));
const LandingBlog = lazy(() => import('./landing-blog'));
const LandingVideo = lazy(() => import('./landing-video'));
const DownloadApp = lazy(() => import('./download-app'));
const LandingSocial = lazy(() => import('./landing-social'));
const LandingPopularSearches = lazy(() => import('./landing-popular-searches'));
const CardArt1 = lazy(() => import('shared/components/card-art1'));
const renderLoader = () => <p>Loading</p>;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    //   flexDirection: "column",
    alignItems: "center",
    //  padding: "10px",
    backgroundColor: "#fff",
    // padding: "8px",
  },
  categoryRoot: {
    flexGrow: 1,
    width: "100%",
  },
  category: {
    padding: "12px 4px",
    textAlign: "center",
    height: "84px",
    color: theme.palette.text.secondary,
    position: "relative",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
  },
  section3: {
    backgroundColor: theme.palette.primary.main,
    padding: "8px 0px",
    marginTop: "4px",
  },
}));

function NewLanding() {
  const classes = useStyles();
  const { t } = useTranslation();
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
    Notification: t("HelperMsg.USERNOTIFICATION"),
  };
  const theme = useTheme();
  const [userNotifications, setUserNotification] = useState([]);
  let userData = useSelector((state) => state?.appData?.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getUserNotification = () => {
    get_user_notification(
      userData.account_id,
      enqueueSnackbar,
      dispatch,
      history,
      appActions,
      setUserNotification,
      msg
    );
  };
  const pageSEOTitle = {
    name: "Lagan Booking",
    sub_title:"Your Ultimate Wedding Planning Platform",
    descriptio:"Best wedding vendors near your location"
  }
  useEffect(() => {
    if (userData?.account_id) {
      getUserNotification();
    }
  }, []);

  return (
    <>
     <SEO {...pageSEOTitle} />
      <div className={classes.root}>
        <Grid container>
          <Suspense fallback={renderLoader()}>
            <LandingSlider></LandingSlider>
          </Suspense>
          <div className="category mx-4 my-8">
            <Grid container spacing={1}>
              <Grid item xs={4} xl={2} key="register">
                <Link to="/register" className="no-text-style">
                  <Paper className="paper">
                    <Icon className="material-icons category-icon anim">
                      <img
                        className={classes.imageIcon}
                        src={`/images/icons/1/cash-machine.png`}
                        alt="category"
                      />
                    </Icon>
                    <div className="category-btn">
                      {userData?.account_id > 0
                        ? t("Landing_Page.REGISTER_OTHR")
                        : t("Landing_Page.REGISTER_NOW")}
                    </div>
                  </Paper>
                </Link>
              </Grid>
              {BUSINESSCATEGORYLIST?.slice(0, 7)?.map((item, index) => {
                return (
                  <Grid item xs={4} xl={2} key={index}>
                    <Link to={item.link} className="no-text-style">
                      <Paper className="paper">
                        {item.live ? "" : <div className="soon">Soon</div>}
                        <Icon className="material-icons category-icon">
                          <img
                            className={classes.imageIcon}
                            src={`/images/icons/1/${item.icon}.png`}
                            alt="category"
                          />
                        </Icon>
                        <div className="category-btn">{t(item.title)}</div>
                      </Paper>
                    </Link>
                  </Grid>
                );
              })}
              <Grid item xs={4} xl={2}>
                <Link to="/category" className="no-text-style">
                  <Paper className="paper">
                    <i
                      className="material-icons category-icon"
                      style={{ color: "#686868" }}
                    >
                      arrow_circle_right
                    </i>
                    <div className="category-btn">
                      {t("Landing_Page.OTHERS")}
                    </div>
                  </Paper>
                </Link>
              </Grid>
            </Grid>
          </div>
          <div>
            <LbTitle name={t("Landing_Page.WHAT")} />
            <LbParagraph text={t("Landing_Page.LB_INTRO")}></LbParagraph>
          </div>
          <div
            className="width-100-p offer mx-4 my-4"
            style={{ backgroundColor: theme.palette.primaryLight.main }}
          >
            <Suspense fallback={renderLoader()}>
              <CardArt1 leftS="-8px" topS="-8px" />
            </Suspense>
            <div className="offer-text">
              <div className="text-white pr">
                {t("Landing_Page.BOOK_YOUR") + " " + t("Landing_Page.VEND")}
              </div>
              <div className="text-white pl">
                {t("Landing_Page.BOOK_ALL")}
                <div>
                  <Link to="/register" className="no-text-style pointer">
                    <strong>{t("Landing_Page.SIGN_UP")}</strong>
                  </Link>
                </div>
              </div>
            </div>
            <Suspense fallback={renderLoader()}>
              <CardArt1 rotate={180} bottomS="-8px" rightS="-8px" />
            </Suspense>
          </div>
          <div className={classes.section3 + " width-100-p"}>
            <LbTitle
              name={t("Shared.POPULAR_VENDORS")}
              color="#fff"
              weight="700"
            ></LbTitle>
            <Suspense fallback={renderLoader()}>
              <LandingPopularSearches />
            </Suspense>
          </div>
          <div className="width-100-p my-8">
            <Suspense fallback={renderLoader()}>
              <LandingCalender />
            </Suspense>
          </div>
          <div className="width-100-p my-8">
            <Suspense fallback={renderLoader()}>
              <DownloadApp />
            </Suspense>
          </div>
          <div className="width-100-p">
            <LbTitle
              name={t("Shared.WEDDING_GALLERY")}
              weight="700"
            ></LbTitle>
            <Suspense fallback={renderLoader()}>
              <SwiperSlideLb
                renderBlog={true}
                imageProp={landingImageGalleryList}
                navigation={true}
                slidesPerView={4}
                spaceBetween={10}
                effect={true}
                autoplay={true}
              />
            </Suspense>
          </div>
          <div className="width-100-p m-4">
            <Suspense fallback={renderLoader()}>
              <CardArt1 leftS="8px" topS="12px" />
            </Suspense>
            <Suspense fallback={renderLoader()}>
              <LandingSocial />
            </Suspense>
            <Suspense fallback={renderLoader()}>
              <CardArt1 rotate={180} bottomS="12px" rightS="8px" />
            </Suspense>
          </div>
          <div className="width-100-p">
            <LbTitle
              name={t("CmsContent.BLOG_TITLE")}
              weight="700"
            ></LbTitle>
            <Suspense fallback={renderLoader()}>
              <LandingBlog></LandingBlog>
            </Suspense>
          </div>
          <div className="width-100-p">
            <LbTitle
              name={t("Shared.FEATURED_VIDEO")}
              weight="700"
            ></LbTitle>
            <Suspense fallback={renderLoader()}>
              <LandingVideo></LandingVideo>
            </Suspense>
          </div>
          <div className="width-100-p">
            <LbTitle
              name={t("CmsContent.FEATURES_TITLE")}
              weight="700"
            ></LbTitle>
            <Suspense fallback={renderLoader()}>
              <LandingFeatures></LandingFeatures>
            </Suspense>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default NewLanding;
