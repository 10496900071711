import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Icon from "@material-ui/core/Icon";
import { getSorting } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  noMinHight: {
    minHeight: "0px",
    padding: "0px",
  },
  sortBtn: {
    padding: "6px 10px",
    marginTop: 5,
    maxWidth: "75px",
    marginLeft: 4,
    marginRight: 4,
  },
}));

function SortingMenu(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [sortingOrder, setSortingOrder] = useState("AESC");
  const [selectedItem, setSelectedItem] = useState();

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function sortBy(item, sortingOrder) {
    if (sortingOrder === "AESC") {
      setSortingOrder("DESC");
    } else {
      setSortingOrder("AESC");
    }
    setSelectedItem(item);
  }
  const doSorting = (field, sortingOrder) => {
    let sortedData = getSorting(props.data, field, sortingOrder);
    props.updateData([...sortedData]);
  };

  useEffect(() => {
    doSorting(selectedItem, sortingOrder);
  }, [selectedItem, sortingOrder]);

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {props.sortingFields.map((item, index) => {
        return (
          <MenuItem key={index}>
            <ListItemText
              primary={t("Shared.Sorting_menu." + item.value)}
              onClick={() => sortBy(item.key, sortingOrder)}
            />
            {selectedItem === item?.key && sortingOrder === "AESC" && (
              <ArrowUpwardIcon className="icon" />
            )}
            {selectedItem === item?.key && sortingOrder === "DESC" && (
              <ArrowDownwardIcon className="icon" />
            )}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar className={classes.noMinHight}>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleMobileMenuOpen}
            className={classes.sortBtn}
          >
            <Icon>filter_list</Icon>
            {t("Dashboard.SORTBY")}
          </Button>
        </div>
      </Toolbar>
      <Hidden smDown implementation="css">
        {renderMobileMenu}
      </Hidden>
    </div>
  );
}

SortingMenu.propTypes = {
  window: PropTypes.func,
};

export default SortingMenu;
