import React from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { makeStyles } from "@material-ui/styles";
import { Button, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: "46px 0px",
    // fontSize: "1.2rem",
    // fontWeight: "600",
  },
  img: {
    width: "270px"
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500
  },
  subTitle: {
    textAlign: "center",
    fontSize: "1.15rem",
    color: "grey",
    marginTop: "4px",
    marginBottom: "4px",
  },
}));
function NotFound({ text }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <img alt="No record found" className={classes.img} src="/images/no_data.svg" />
      <div className={classes.title}>{text || t("Shared.NF_1TEXT")}</div>
      <div className={classes.subTitle}>{t("Shared.NF_2TEXT")}</div>
      <Link href="/">
        <Button
          type="button"
          size="small"
          variant="contained"
          color="secondary">
          {t("Shared.NF_3TEXT")}
        </Button>
      </Link>
    </div>
  );
}

export default NotFound;
