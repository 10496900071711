import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import VendorServiceMngt from "vendor/vendor-services/vendor-services-mngt";

export default function ManageAccounts() {
  const appData = useSelector((state) => state.appData);
  const setValId = parseInt(appData?.user?.setting_value_id);
  return (
    <Box m={1}>
        <VendorServiceMngt></VendorServiceMngt>
    </Box>
  );
}
