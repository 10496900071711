import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import BussTypeCard from "./buss-type-card";
import { BUSINESSCATEGORYLIST } from "core/utils/constants";
import { getLocalStorage } from "core/utils/utilities";
import * as appActions from "redux/actions/appActions";
import CardArt1 from "shared/components/card-art1";
import { useTranslation } from "react-i18next";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    minHeight: "calc(100vh - 72px)",
  },
  block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    cursor: "pointer",
  },
  adjust: {
    paddingTop: 48,
    paddingBottom: 48,
    position: "relative",
    zIndex: 1,
  },
  alignTitle: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    margin: "10px 0px",
  },
}));

export default function BusinessSelect() {
  const classes = useStyles();
  const { t } = useTranslation();
  const appData = useSelector((state) => state.appData);
  const businessIds = appData?.ven_setting_value_id?.split(",");
  var userData = getLocalStorage("USER_DATA");
  const dispatch = useDispatch();
  const history = useHistory();

  const setBussinessType = (id) => {
    userData.setting_value_id = parseInt(id);
    localStorage.setItem("USER_DATA", JSON.stringify(userData));
    dispatch(appActions.updateUser(userData));
    log_activity("setBussinessType", "business-select.jsx", "Success");
  };

  const onSelect = (id) => {
    setBussinessType(id);
    history.push("/vendor/dashboard");
  };

  useEffect(() => {
    if (typeof userData?.setting_value_id !== "number")
      setBussinessType(businessIds[0]);
  }, [businessIds]);
  return (
    <div className={classes.root}>
      <CardArt1 leftS="8px" topS="8px" heightS="200px" widthS="200px" />
      <div className={classes.adjust}>
        <div className={classes.alignTitle}>
          {t("Shared.PLEASESELECTBUSINESS")}
        </div>
        <div className={classes.block}>
          {businessIds?.map((id, index) => {
            return (
              <div
                key={index}
                className=""
                onClick={() => {
                  onSelect(id);
                }}
              >
                <BussTypeCard
                  check={
                    userData?.setting_value_id === parseInt(id) ? true : false
                  }
                  name={t(BUSINESSCATEGORYLIST.find((x) => x.id === parseInt(id))?.title)}
                  icon={BUSINESSCATEGORYLIST.find((x) => x.id === parseInt(id))?.icon}
                />
              </div>
            );
          })}
        </div>
      </div>
      <CardArt1
        rotate={180}
        bottomS="8px"
        rightS="8px"
        heightS="200px"
        widthS="200px"
      />
    </div>
  );
}
