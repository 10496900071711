import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import DateTime  from '../../../../src/shared/components/helper-component/date-time'
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Grid
} from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { sortAndFilterObject } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function AddressView(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  // Required Format 
  var address;
  let convrtedAddress = sortAndFilterObject(props?.address, props.orderList);
  address = Object.entries(convrtedAddress);

  return (
    <List>
      <Grid container>
        {address?.map((item, i) => {
          return item?.[0] !== 'region' ? (
            <Fragment key={i}>
              <Grid item xs={12} sm={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.bgColor}>
                      <FormatListBulletedIcon style={{ color: '#fff' }} />
                    </Avatar>
                  </ListItemAvatar>
                  {item[0] === "p_lagan_date" ? <ListItemText
                    primary={t("Profile_Details.P_LAGAN_DATE")}
                    secondary={
                      <>
                        <DateTime dtType="short">{item[1]}</DateTime>
                      </>
                    }
                  /> :
                    <ListItemText
                      primary={t(`Profile_Details.${item ? item[0]?.toLocaleUpperCase() : ''}`)}
                      secondary={item ? item[1] : ''}
                    />
                  }
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </Grid>
            </Fragment>
          ) : (
            ''
          );
        })}
      </Grid>
    </List>
  );
}
