
import React from "react";
import { Bar } from "react-chartjs-2";

const Querieschart = () => {
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const totalComp = [50, 45, 30, 25, 20, 65, 45, 40, 54, 60, 30, 27];
  const resolvedComp =  [40, 35, 30, 23, 20, 50, 44, 40, 50, 44, 10, 20];
  return (
    <div>
      <Bar
        className="graph"
        data={{
          labels:labels,
          datasets: [
            {
              label: "Total complains",
              data: totalComp,
              backgroundColor: ["rgba(255, 206, 86, 1)"],
              borderWidth: 1,
            },
            {
              label: "complains resolved",
              data:resolvedComp,
              backgroundColor: ["rgba(75, 192, 192, 1)"],
              categoryPercentage: 1,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          scales: {},
        }}
      />
    </div>
  );
};
export default Querieschart;
