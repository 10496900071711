export const languages = ["En", "Hi"];
export const maxFileSize = 1 * 1024 * 1024; //1 MB
export const maxFileCount = 25;
export const maxFileCountPerAccount = 50;
export const wpBlogUrl = "https://laganbooking.com/lbblog/wp-json/wp/v2/posts?categories=1";
export const wpTestimonialUrl = "https://laganbooking.com/lbblog/wp-json/wp/v2/posts?categories=6";
export const wpFeaturesUrl = "https://laganbooking.com/lbblog/wp-json/wp/v2/posts?categories=9";
export const wpContentDetailUrl = "https://laganbooking.com/lbblog/wp-json/wp/v2/posts";
export const defaultQuantity = 1;
export const downloadPopupTime = 100; // minute

export const openRouts = [
  "/signin",
  "/landing",
  "/register",
  "/reset",
  "/",
  "/vendor-list",
  "/ownerRegist",
  "/VendorDetails",
  "/terms-condition",
  "/privacy-policy",
  "/contact-us",
  "/category",
  "/help",
  "/cms-content",
  "/content-view/:id",
  "/vivah-muhurat",
  "/booking-contract"
];
export const custRoutes = [
  "/VendorDetails",
  "/customer/booking",
  "/customer/booking-list",
  "/customer/booking-view",
  "/customer/vehicle-booking",
  "/customer/profile",
  "/customer/profile/address",
  "/customer/profile/information",
  "/vendor/search",
  "/customer/deal-list",
  "/customer/notification",
  "/customer/invite/invite-list",
  "/customer/invite/web-invite",
  "/customer/invite/webInvite/:id",
  "/customer/guestlist",
  "/guest-list-view/:id"
];
export const vendorRoutes = [
  "/vendor/contact-list/contact-list",
  "/vendor/dashboard",
  "/vendor/manage",
  "/vendor/booking",
  "/vendor/booking-list",
  "/vendor/booking-view",
  "/vendor/bidding-deal-list",
  "/vendor/profile",
  "/vendor/profile/address",
  "/vendor/manage-subaccount",
  "/vendor/profile/information",
  "/vendor/business-select",
  "/vendor/deal-list",
  "/vendor/notification",
];

export const loopClassCount = [
  0, 1, 4, 5, 8, 9, 12, 13, 16, 17, 20, 21, 24, 25, 28, 29, 32, 33, 36, 37, 40,
  41,
];

export const SETTING_VALUE = Object.freeze({
  CAR_RENTAL: 1,
  CATERING: 2,
  JAIMAL_STAGE_DECORATION: 3,
  TENT_HOUSE: 4,
  BAJA_DJ: 5,
  MARRIAGE_HALL: 6,
  ORCHESTRA_DANCE_PROGRAM: 7,
  FIREWORK: 8,
  RATHWALA_ROAD_LIGHT: 9,
  BEAUTIPARLOUR: 10,
  VIDEOGRAPHY_PHOTOGRAPHY: 11,
  MEHNDI_ARTIST: 12,
  SHADI_CARD_PRINTING: 13,
  PANDIT_JI: 208,
  BEVERAGES_PANI: 209,
  JEWELLERY_DRESS_RENTING: 210,
  CAKE_BAKERY: 211,
  PAINTER: 212,
});

export const UserType = Object.freeze({
  CUSTOMER: "customer",
  VENDOR: "vendor",
});

export const ThemeColor = Object.freeze({
  BLUE: "BLUE",
  PINK: "PINK",
  YELLOW: "YELLOW",
});

export const ChartDataType = Object.freeze({
  UPCOMING_MONTHLY_BOOKINGS: "upcoming_monthly_bookings",
  UPCOMING_BOOKINGS_BY_BUSINESS_TYPE: "upcoming_bookings_by_business_type",
  BIDDINGS_BY_BUSINESS_TYPE: "biddings_by_business_type",
  BIDDINGS_BY_STATUS: "biddings_by_status",
  TOTAL_REVIEWS: "total_reviews",
  TOTAL_BOOKINGS: "total_bookings",
  TOTAL_BIDDINGS: "total_biddings",
});

export const defaultPageSize = 12;

export const BUSINESSCATEGORYLIST = [
  {
    id: 11,
    icon: "video-camera",
    title: "Landing_Page.VIDEOGRAPHY",
    iconColor: "#ff235a",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.VIDEOGRAPHY_PHOTOGRAPHY}`,
    live: true,
    service_id: 17,
  },
  {
    id: 6,
    icon: "wedding-arch-green",
    title: "Landing_Page.MARIAGE_HALL",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.MARRIAGE_HALL}`,
    live: true,
    service_id: 21,
  },
  {
    id: 13,
    icon: "invitation",
    title: "Landing_Page.SHADI_CARD",
    iconColor: "#24ede7",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.SHADI_CARD_PRINTING}`,
    live: true,
    service_id: 13,
  },
  {
    id: 3,
    icon: "wedding",
    title: "Landing_Page.STAGE",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.JAIMAL_STAGE_DECORATION}`,
    live: true,
    service_id: 15,
  },
  {
    id: 4,
    icon: "wedding-arch",
    title: "Landing_Page.TENT",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.TENT_HOUSE}`,
    live: true,
    service_id: 16,
  },
  {
    id: 5,
    icon: "drum",
    title: "Landing_Page.BAJA",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.BAJA_DJ}`,
    live: true,
    service_id: 18,
  },
  {
    id: 2,
    icon: "dinner-table",
    title: "Landing_Page.HALWAI",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.CATERING}`,
    live: true,
    service_id: 25,
  },

  {
    id: 10,
    icon: "bride",
    title: "Landing_Page.BEAUTYPARLOUR",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.BEAUTIPARLOUR}`,
    live: true,
    service_id: 20,
  },
  {
    id: 1,
    icon: "car",
    title: "Landing_Page.CAR",
    iconColor: "#3f3cff",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.CAR_RENTAL}`,
    live: true,
    service_id: 31,
  },
  {
    id: 8,
    icon: "confetti",
    title: "Landing_Page.FIREWORK",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.FIREWORK}`,
    live: true,
    service_id: 22,
  },

  {
    id: 209,
    icon: "wine",
    title: "Landing_Page.WATER",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.BEVERAGES_PANI}`,
    live: true,
    service_id: 26,
  },
  {
    id: 208,
    icon: "pandit",
    title: "Landing_Page.PANDIT",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.PANDIT_JI}`,
    live: true,
    service_id: 24,
  },
  {
    id: 7,
    icon: "dance",
    title: "Landing_Page.DANCEPROGRAM",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.ORCHESTRA_DANCE_PROGRAM}`,
    live: true,
    service_id: 19,
  },
  {
    id: 9,
    icon: "balloons",
    title: "Landing_Page.RATHWALA",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.RATHWALA_ROAD_LIGHT}`,
    live: true,
    service_id: 29,
  },
  {
    id: 211,
    icon: "pancake",
    title: "Landing_Page.CAKE",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.CAKE_BAKERY}`,
    live: true,
    service_id: 27,
  },
  {
    id: 12,
    icon: "henna-painted-hand",
    title: "Landing_Page.MEHANDI_ARTIST",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.BEAUTIPARLOUR}`,
    live: true,
    service_id: 20,
  },
  {
    id: 210,
    icon: "jewelry",
    title: "Landing_Page.RENT_JWEL_CLOTH",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.JEWELLERY_DRESS_RENTING}`,
    live: true,
    service_id: 28,
  },
  {
    id: 212,
    icon: "painting",
    title: "Landing_Page.PAINTER",
    iconColor: "#686868",
    link: `/vendor-list?settingValueId=${SETTING_VALUE.PAINTER}`,
    live: true,
    service_id: 30,
  },
];

export const landingImageGalleryList = [
  {
    img: "https://images.pexels.com/photos/1702373/pexels-photo-1702373.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/1444442/pexels-photo-1444442.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/2060239/pexels-photo-2060239.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/2060240/pexels-photo-2060240.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/1444441/pexels-photo-1444441.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/6387627/pexels-photo-6387627.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/2318049/pexels-photo-2318049.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/12200847/pexels-photo-12200847.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/12759518/pexels-photo-12759518.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/12163288/pexels-photo-12163288.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/3455741/pexels-photo-3455741.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/8286046/pexels-photo-8286046.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/2064485/pexels-photo-2064485.jpeg",
  },
  {
    img: "https://images.pexels.com/photos/16364491/pexels-photo-16364491/free-photo-of-women-in-traditional-dresses-dancing-on-ceremony.jpeg",
  },
];

export const dealStatus = Object.freeze({
  OPEN: "Open",
  BIDDING: "Bidding",
  SENT_TO_CUSTOMER: "Sent to Customer",
  NEGOTIATION: "Negotiation",
  CLOSE: "Close",
});

export const dealStatusCode = Object.freeze({
  ACCEPT: 1,
  REJECT: 0,
});
export const vendorSortingFields = [
  { key: "first_name", value: "FIRST_NAME" },
  { key: "last_name", value: "LAST_NAME" },
  { key: "address", value: "ADDRESS" },
  { key: "mobile_no", value: "MOBILE_NUMBER" },
];
export const contactSortingFields = [
  { key: "p_first_name", value: "FIRST_NAME" },
  { key: "p_last_name", value: "LAST_NAME" },
  { key: "p_address", value: "ADDRESS" },
  { key: "p_mobile_no", value: "MOBILE_NUMBER" },
];
export const dashboardData = [
  { month: "Nov", value: "12" },
  { month: "Dec", value: "15" },
  { month: "Jan", value: "5" },
  { month: "Feb", value: "10" },
];
export const defaultAddress = {
  zip_code: 221010,
  region: null,
  taluka: "Varanasi",
  district: "VARANASI",
  city_local: "Bhelupura",
  state: "UTTAR PRADESH",
  address: "",
  cust_vend: "Customer",
  Id: 0,
};

export const orderList = [
  'first_name',
  'last_name',
  'email_id',
  'mobile_no',
  'alt_mobile_no'
];

  export  const addresViesList = [
    'address',
    'city_local',
    'taluka',
    'zip_code',
    'district',
     'state',
   ];
    export  const contactList = [
      "p_first_name",
      "p_last_name",
      "p_email_id",
      "p_mobile_no",
      "p_lagan_date",
      "p_city_local",
      "p_taluka",
      "p_zip_code",
      "p_district",
      "p_state"
    ];

    export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    export const phoneRegex = /^(0)?[6-9][0-9]{9}$/;
    export const zipcodeRegex = /^(0)?[0-9]{6}$/;
    export const daysBeforeBookingCancel =10;
