import { dashboardData } from "core/utils/constants";
import React from "react";
import { Bar } from "react-chartjs-2";

const Bookingchart = () => {
  let month = [];
   let value = [];

   dashboardData.forEach(element => {
        month.push(element.month);
        value.push(element.value);
       });
  // const labels = ["Sept", "Oct", "Nov", "Dec"];
  // const values = [6, 19, 12, 15];
  const data = {
    labels: month,
    datasets: [
      {
        label: "Previous 4 months bookings",
        data: value,
        backgroundColor: ["rgba(245, 151, 148, 1)"],
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    // scales: {
    //   yAxes: [
    //     {
    //       display:true,
    //       ticks: {
    //         beginAtZero: false,
    //       },
    //     },
    //   ],
    // },
  };

  return (
    <div>
      <Bar className="graph" data={data} options={options} />
    </div>
  );
};
export default Bookingchart;
