import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Avatar, Box, Divider, Icon } from "@material-ui/core";
import "../side-menu/side-menu.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { logOut } from "core/utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { customerSideMenu, vendorSideMenu } from "core/utils/route-path";
import { PersonOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 9999,
  },
  menuIcon: {
    color: "#fff",
    paddingTop:"3px",
    paddingBottom:"3px"
  },
  bgColor: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
  },
  bottomMenu: {
    position: "absolute",
    bottom: 4,
  },
  large: {
    backgroundColor: "#ffff",
    marginLeft: "100px",
    marginTop: "20px",
  },
}));

export default function SideMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.appData.user);

  const venSettingValueId = useSelector(
    (state) => state.appData.ven_setting_value_id
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const msg = {
    LOGOUT: t("HelperMsg.LOGOUT"),
  };
  const activeMenuIndex = useSelector((state) => state.appData.activeMenuIndex);

  const baseMenu = [
    {
      name: "HOME",
      icon: "home",
      path: "/",
    },
    {
      name: "REGISTER",
      icon: "chrome_reader_mode",
      path: "/register",
    },
  ];
  let [menuObj, setMenu] = useState(baseMenu);

  const logOutClick = async () => {
    dispatch(appActions?.updateUser({}));
    dispatch(appActions.setVenSettingValueId(""));
    dispatch(appActions.resetState());
    logOut(enqueueSnackbar, msg.LOGOUT);
    dispatch(appActions?.setActiveMenu(102));
    // setTimeout(() => {
    history.push("/");
    //  }, 1000);
  };
  const menuClick = (index) => {
    dispatch(appActions?.setActiveMenu(index));
  };

  useEffect(() => {
    if (user?.customer_id || user?.vendor_id) {
      if (user?.customer_id > 0) {
        setMenu(customerSideMenu?.route_paths);
      } else {
        setMenu(vendorSideMenu?.route_paths);
      }
    } else {
      setMenu(baseMenu);
    }
  }, []);

  return (
    <Box
      role="presentation"
      onClick={props.toggleDrawer(props.anchor, false)}
      onKeyDown={props.toggleDrawer(props.anchor, false)}
      className={classes.bgColor}
    >
      <List style={{ height: "100%" }}>
        {user?.account_id && (
          <>
            <div className="text-white text-center">
              <Avatar className={classes.large}>
                <PersonOutline color="primary" />
              </Avatar>
              {user?.first_name}
            </div>
            <Divider className="mt-4" style={{ background: "white" }}></Divider>
          </>
        )}
        {menuObj?.map((item, index) => (
          <Link
            to={item?.path}
            className="link"
            variant="body2"
            key={index}
            onClick={() => menuClick(index)}
          >
            <ListItem
              button
              key={index}
              selected={index === activeMenuIndex}
              className={classes.menuIcon}
            >
              <Icon>{item?.icon}</Icon>
              <ListItemText
                primary={t("Shared.Side_menu." + item?.name)}
                className="ml-16"
              />
            </ListItem>
          </Link>
        ))}
        {venSettingValueId?.split(",")?.length > 1 ? (
          <Link
            to="/vendor/business-select"
            className="link"
            variant="body2"
            key={100}
            onClick={() => menuClick(100)}
          >
            <ListItem
              button
              key="switchaccount"
              className={classes.menuIcon}
              selected={100 === activeMenuIndex}
            >
              <Icon>exit_to_app</Icon>
              <ListItemText
                primary={t("Shared.Side_menu.SWITCH_ACC")}
                className="ml-16"
              />
            </ListItem>
          </Link>
        ) : (
          ""
        )}
        {user?.account_id > 0 ? (
          <>
            <Link
              to="#"
              className="link"
              variant="body2"
              key={102}
              onClick={() => menuClick(102)}
            >
              <ListItem
                button
                key="logout"
                className={classes.menuIcon}
                onClick={logOutClick}
                selected={102 === activeMenuIndex}
              >
                <Icon>exit_to_app</Icon>
                <ListItemText
                  primary={t("Shared.Side_menu.LOGOUT")}
                  className="ml-16"
                />
              </ListItem>
            </Link>
          </>
        ) : (
          <Link
            to="/signin"
            className="link"
            variant="body2"
            key={103}
            onClick={() => menuClick(103)}
          >
            <ListItem
              button
              key="logout"
              className={classes.menuIcon}
              selected={103 === activeMenuIndex}
            >
              <Icon>exit_to_app</Icon>
              <ListItemText
                primary={t("Shared.Side_menu.LOGIN")}
                className="ml-16"
              />
            </ListItem>
          </Link>
        )}
        <Link
          to="/help"
          className={classes.bottomMenu + " link"}
          variant="body2"
          key={104}
          onClick={() => menuClick(104)}
        >
          <ListItem
            button
            key="switchaccount"
            className={classes.menuIcon}
            selected={104 === activeMenuIndex}
          >
            <Icon>help</Icon>
            <ListItemText
              primary={t("Shared.Side_menu.HELP")}
              className="ml-16"
            />
          </ListItem>
        </Link>
      </List>
    </Box>
  );
}
