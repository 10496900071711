import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Rating } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { write_review_helper } from "./write_review_helper";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {},
  fieldWidth: {
    width: "100% !important",
  },
  formWidth: {
    display: "flex",
    flexDirection: "column",
  },
  mr24: {
    marginRight: "24px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const ReviewWriting = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(-1);
  const { t } = useTranslation();
  const msg={
    ThanksReview:t("HelperMsg.THANKSREVIEW"),
   ReviewFailed :t("HelperMsg.SAVEVENDORFAIL")
  }
  const { enqueueSnackbar } = useSnackbar();
  const appData = useSelector((state) => state.appData);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth, setMaxWidth] = useState("md");

  //intialise the form field
  const initialValues = {
    message: "",
    customer_id: appData?.user?.customer_id,
    vendor_id: appData?.selectedVendor?.vendor_id,
    star_rating: 0.0,
  };

  //Validate the form field
  const ReviewSchema = Yup.object().shape({
    star_rating: Yup.number().required("Required"),
    message: Yup.string().required("Required"),
  });

  const reviewForm = useFormik({
    initialValues: initialValues,
    validationSchema: ReviewSchema,
    onSubmit: (values) => {
      // console.log("hii",JSON.stringify(values));
      values.star_rating = parseFloat(values?.star_rating);
      write_review_helper(values, enqueueSnackbar,msg);
      log_activity("reviewForm-onSubmit", "review-writing.jsx", "Success ");
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
    log_activity("handleClickOpen", "review-writing.jsx", "Review Form Opened ");
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={handleClickOpen}
        className="ml-4"
      >
        {t("Shared.Review_writing.WRITE_RE")}
      </Button>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
      >
        <form onSubmit={reviewForm.handleSubmit} className={classes.formWidth}>
          <DialogTitle id="alert-dialog-slide-title">
            {t("Shared.Review_writing.PLZ_WRITE")}
          </DialogTitle>
          <DialogContent>
            <div style={{ width: fullScreen ? "" : "65vw" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Box>{t("Shared.Review_writing.YOUR_RATING")}</Box>
                  <Rating
                    name="star_rating"
                    id="star_rating"
                    precision={0.5}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    value={reviewForm.values?.star_rating}
                    onChange={reviewForm.handleChange}
                  />
                  {reviewForm.errors.star_rating &&
                  reviewForm.touched.star_rating ? (
                    <div className="error-color">
                      {reviewForm.errors.star_rating}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  {reviewForm.values?.star_rating !== null && (
                    <Box ml={2}>
                      {
                        labels[
                          hover !== -1 ? hover : reviewForm.values?.star_rating
                        ]
                      }
                    </Box>
                  )}
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                rows={8}
                name="message"
                label={t("Shared.Review_writing.MSG")}
                type="text"
                id="message"
                onChange={reviewForm.handleChange}
                value={reviewForm.values.message}
                className={classes.fieldWidth}
              />
              {reviewForm.errors.message && reviewForm.touched.message ? (
                <div className="error-color">{reviewForm.errors.message}</div>
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              size="small"
            >
              {t("Shared.CNCL")}
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              type="submit"
              variant="contained"
              size="small"
              className={classes.mr24}
            >
              {t("Shared.SAVE")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
export default ReviewWriting;
