import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  Paper,
  SvgIcon,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  Edit,
  Phone,
  Email,
  LockOpenSharp,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  PhoneAuthProvider,
  reauthenticateWithCredential,
  EmailAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  linkWithCredential,
  linkWithPopup,
  GoogleAuthProvider,
  unlink,
  ProviderId,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../utils/firebase/firebase-config";
import { update_user_account } from "./profile_helper";
import ThemeSwitch from "theme/theme-switch";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  toggleArea: {
    alignItems: "center",
    display: "flex",
    marginTop: "16px",
    marginBottom: "16px",
    justifyContent: "flex-end",
  },
  enabled: {
    color: "green",
  },
  disabled: {
    color: "grey",
  },
}));

export default function ProfileSetting(props) {
  const { t } = useTranslation();
  const msg = {
    Account: t("HelperMsg.ACCOUNTLINK"),
    WRONG: t("HelperMsg.SOMETHINGWRONG"),
  };
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const [showLinkPhoneNo, setShowLinkPhoneNo] = useState(false);
  const [showEnableEmail, setShowEnableEmail] = useState(false);
  const [confirmObj, setConfirmObj] = useState({});
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [isPhoneAuth, setIsPhoneAuth] = useState(false);
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const [isEmailAuth, setIsEmailAuth] = useState(false);
  const [isEye, setIsEyeOpen] = useState(false);
  const [isEyeconfirmPass, setIsEyeconfirmPass]=useState(false);
  const [isEyeUpass,setIsEyeOpenUpass]=useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [checkVenodr] = useState(userData.vendor_id > 0);
  let user = auth.currentUser;

  useEffect(() => {
    if (user != null) {
      setIsGoogleAuth(
        user.providerData.findIndex(
          (x) => x.providerId === ProviderId.GOOGLE
        ) !== -1
      );
      setIsPhoneAuth(
        user.providerData.findIndex(
          (x) => x.providerId === ProviderId.PHONE
        ) !== -1
      );
      setIsEmailAuth(
        user.providerData.findIndex(
          (x) => x.providerId === ProviderId.PASSWORD
        ) !== -1
      );

      linkEmailForm.values.email = user?.email;
    }
  }, [user]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const verifyOTPInitValues = {
    verify_otp: "",
  };

  const updatePasswordValues = {
    updateCurrentPassword: "",
    updatePassword: "",
    confirmPassword: "",
  };

  const linkEmailValues = {
    email: "",
    password: "",
  };

  const linkEmailForm = useFormik({
    initialValues: linkEmailValues,
    onSubmit: (values) => {
      linkEmailAccount(values.email, values.password);
    },
  });
  const updatePwdSchema = Yup.object().shape({
    updateCurrentPassword: Yup.string().required(t("Shared.FORM.Provide_current_password")),
    updatePassword: Yup.string().required(t("Shared.FORM.Provide_new_Password")).optional().notOneOf([Yup.ref("updateCurrentPassword")], (t("Shared.FORM.DEFFERENT_NEW_PASSWORD_CURRENT_PASSWORD"))),
    confirmPassword: Yup.string()
      .required(t("Shared.FORM.Provide_Confirm_Password"))
      .oneOf([Yup.ref("updatePassword")], t("Shared.FORM.match_Password")),
  });
  const updatePasswordForm = useFormik({
    initialValues: updatePasswordValues,
    validationSchema: updatePwdSchema,
    onSubmit: (values) => {
      updatePasswordManul(values.updateCurrentPassword, values.updatePassword);
    }
  });

  const VerifyOTPSchema = Yup.object().shape({
    verify_otp: Yup.string()
      .required("Please provide verify OTP")
      .matches(/^[0-9]+$/, "OTP must be only digits")
      .min(6, "OTP must be exactly 5 digits")
      .max(6, "OTP must be exactly 5 digits"),
  });

  const otpForm = useFormik({
    initialValues: verifyOTPInitValues,
    validationSchema: VerifyOTPSchema,
    onSubmit: (values) => {
      if (
        isPhoneAuth ||
        values.verify_otp === " " ||
        values.verify_otp === null
      )
        return;
      dispatch(appActions.increaseLoaderCount());
      try {
        var credential = PhoneAuthProvider.credential(
          confirmObj.verificationId,
          values.verify_otp
        );
        linkWithCredential(auth.currentUser, credential)
          .then(async (result) => {
            setIsPhoneAuth(true);
            update_user_account(
              true,
              setIsPhoneAuth,
              setShowLinkPhoneNo,
              setShowVerifyOtp,
              enqueueSnackbar,
              msg
            );
          })
          .catch((error) => {
            setShowLinkPhoneNo(false);
            setShowVerifyOtp(false);
            enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
              variant: "error",
            });
          });
      } catch (error) {
        setShowLinkPhoneNo(false);
        setShowVerifyOtp(false);
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      } finally {
        dispatch(appActions.decreaseLoaderCount());
      }
      
    },
  });

  const sendOtp = () => {
    const verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
    verify.render();
    signInWithPhoneNumber(auth, "+91" + userData.mobile_no, verify)
      .then((result) => {
        setConfirmObj(result);
        setShowLinkPhoneNo(true);
        setShowVerifyOtp(true);
      })
      .catch((err) => {
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      });
  };

  const getMaskedNumber = (number) => {
    const endDigits = number.slice(-4);
    return endDigits.padStart(number.length, "*");
  };

  const linkGoogle = (provider) => {
    dispatch(appActions.increaseLoaderCount());

    linkWithPopup(auth.currentUser, provider)
      .then(() => {
        setIsGoogleAuth(true);
        enqueueSnackbar("Account linking success", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      });
  };

  const linkEmailAccount = (email, password) => {
    dispatch(appActions.increaseLoaderCount());

    const credential = EmailAuthProvider.credential(email, password);
    linkWithCredential(auth.currentUser, credential)
      .then(() => {
        setIsEmailAuth(true);
        showEnableEmail(false);
        //    console.log("Account linking success", user);
        enqueueSnackbar("Account linking success", {
          variant: "success",
        });
      })
      .catch((error) => {
        //    console.log("Account linking error", error);
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      });
  };

  const unLinkAccount = (providerId) => {
    if (providerId === ProviderId.GOOGLE && !isGoogleAuth) return;
    if (providerId === ProviderId.PHONE && !isPhoneAuth) return;
    if (providerId === ProviderId.PASSWORD && !isEmailAuth) return;

    dispatch(appActions.increaseLoaderCount());

    unlink(auth.currentUser, providerId)
      .then(() => {
        if (providerId === ProviderId.GOOGLE) setIsGoogleAuth(false);
        if (providerId === ProviderId.PHONE) setIsPhoneAuth(false);
        if (providerId === ProviderId.PASSWORD) setIsEmailAuth(false);

        enqueueSnackbar("Account unlinking success", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      });
  };

  const updatePasswordManul = (currentPassword, newPassword) => {
    dispatch(appActions.increaseLoaderCount());

    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            setShowEnableEmail(false);
            enqueueSnackbar(t("Shared.FORM.PASSWORD_UPDATED"), {
              variant: "success",
               });

               updatePasswordForm.resetForm();
               setExpanded(false);
          })
          .catch((error) => {
            //console.log("Update password error: ", error);
            enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
              variant: "error",
            });
        

          });
      })
      .catch((error) => {
      //console.log("reauth error: ", error);
        enqueueSnackbar(t("HelperMsg.SOMETHING_WRONG"), {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      
      });
  };
  return (
    <>
      <Container component="main" maxWidth="xl">

        <div className={classes.root}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<Edit />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={classes.heading}>
                <Phone />
                {t("Profile_Details.MOBILE_LOGIN")}
              </div>
              {isPhoneAuth ? (
                <div
                  className={`${classes.secondaryHeading} ${classes.enabled}`}
                >
                  <CheckCircleIcon /> {"  Enabled "}
                </div>
              ) : (
                <div
                  className={`${classes.secondaryHeading} ${classes.disabled}`}
                >
                  <CancelIcon /> {t("Profile_Details.DISABLED")}
                </div>
              )}
            </AccordionSummary>
           
            <AccordionDetails>
              <Paper elevation={3} className={classes.paper}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    onChange={(_, checked) => {
                      setShowLinkPhoneNo(checked);
                      !checked && unLinkAccount(ProviderId.PHONE);
                    }}
                    control={<Switch color="primary" checked={isPhoneAuth} />}
                    label={t("Profile_Details.MOBILE_LOGIN")}
                    labelPlacement="start"
                  />
                </FormControl>
                {!showLinkPhoneNo && (
                  <>
                    <br />
                    {t("Profile_Details.PHONE_NUMBER")}
                    <br />
                  </>
                )}
                {showLinkPhoneNo && (
                  <form
                    className={classes.form}
                    onSubmit={otpForm.handleSubmit}
                  >
                    <>
                      <span className="text-align mt-1">
                        {t("Profile_Details.PRIMARY_MOBILE")}{" "}
                        {getMaskedNumber(userData.mobile_no)}
                      </span>

                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="verify_otp"
                        label="Provide OTP"
                        type="number"
                        id="verify_otp"
                        autoComplete="verify otp"
                        onChange={otpForm.handleChange}
                        value={otpForm.values.verify_otp}
                      />
                      {otpForm.errors.verify_otp &&
                      otpForm.touched.verify_otp ? (
                        <div className="error-color">
                          {otpForm.errors.verify_otp}
                        </div>
                      ) : null}
                    </>
                    {showVerifyOtp && (
                      <Button
                        id="btn-otp-verify"
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.submit}
                      >
                        {t("Profile_Details.Verify")}
                      </Button>
                    )}
                    {showLinkPhoneNo && !showVerifyOtp ? (
                      <>
                        <div id="recaptcha-container"></div>
                        <Button
                          id="btn-otp-verify"
                          type="button"
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={sendOtp}
                        >
                          {t("Profile_Details.SEND_OTP")}
                        </Button>
                      </>
                    ) : null}
                  </form>
                )}
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<Edit />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <div className={classes.heading}>
                <SvgIcon>
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                  <path d="M1 1h22v22H1z" fill="none" />
                </SvgIcon>
                {t("Profile_Details.GOOGLE")}
              </div>
              {isGoogleAuth ? (
                <div
                  className={`${classes.secondaryHeading} ${classes.enabled}`}
                >
                  <CheckCircleIcon /> {"  Enabled "}
                </div>
              ) : (
                <div
                  className={`${classes.secondaryHeading} ${classes.disabled}`}
                >
                  <CancelIcon /> {t("Profile_Details.DISABLED")}
                </div>
              )}
            </AccordionSummary>
           
            <AccordionDetails>
              <Paper elevation={3} className={classes.paper}>
                <div className="align-item">
                  <Email /> {t("Profile_Details.GOOGLE")}
                  <FormControl component="fieldset">
                    <FormControlLabel
                      onChange={(_, checked) => {
                        if (checked) {
                          linkGoogle(new GoogleAuthProvider());
                        } else {
                          unLinkAccount(ProviderId.GOOGLE);
                        }
                      }}
                      control={
                        <Switch color="primary" checked={isGoogleAuth} />
                      }
                    />
                  </FormControl>
                </div>
                <br />
                {t("Profile_Details.GOOGLE_SIGI-IN")}
                <br />
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "enable_email"}
            onChange={handleChange("enable_email")}
          >
            <AccordionSummary expandIcon={<Edit />}>
              <div className={classes.heading}>
                <Email /> {t("Shared.EMAIL")}
              </div>
              {isEmailAuth ? (
                <div
                  className={`${classes.secondaryHeading} ${classes.enabled}`}
                >
                  <CheckCircleIcon /> {"  Enabled "}
                </div>
              ) : (
                <div
                  className={`${classes.secondaryHeading} ${classes.disabled}`}
                >
                  <CancelIcon /> {"  Disabled "}
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3} className={classes.paper}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    onChange={(_, checked) => {
                      setShowEnableEmail(checked);
                      !checked && unLinkAccount(ProviderId.PASSWORD);
                    }}
                    control={<Switch color="primary" checked={isEmailAuth} />}
                    label="Email Login"
                    labelPlacement="start"
                  />
                </FormControl>

                {showEnableEmail && (
                  <form
                    className={classes.form}
                    onSubmit={linkEmailForm.handleSubmit}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="email"
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={linkEmailForm.handleChange}
                      value={linkEmailForm.values.email}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={isEye ? "text" : "password"}
                      id="password"
                      label="Password"
                      name="password"
                      onChange={linkEmailForm.handleChange}
                      value={linkEmailForm.values.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsEyeOpen(!isEye)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {isEye ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {t("Shared.SUBMIT")}
                    </Button>
                  </form>
                )}
              </Paper>
            </AccordionDetails>
          </Accordion>

          {isEmailAuth && (
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<Edit />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <div className={classes.heading}>
                  <LockOpenSharp /> {t("Shared.UPDATE_PWD")}
                </div>
              </AccordionSummary>
              <AccordionSummary>
                <Paper elevation={3} className={classes.paper}>
                  <form
                    className={classes.form}
                    onSubmit={updatePasswordForm.handleSubmit}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={isEye ? "text" : "password"}
                      id="updateCurrentPassword"
                      label={t("Shared.FORM.CURRENT_PASSWORD")}
                      name="updateCurrentPassword"
                      onChange={updatePasswordForm.handleChange}
                      value={updatePasswordForm.values.updateCurrentPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsEyeOpen(!isEye)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {isEye ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {updatePasswordForm.errors.updateCurrentPassword &&
                    updatePasswordForm.touched.updateCurrentPassword ? (
                      <div className="error-color">
                        {updatePasswordForm.errors.updateCurrentPassword}
                      </div>
                    ) : null}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={isEyeUpass ? "text" : "password"}
                      id="updatePassword"
                      label={t("Shared.FORM.NEW_PASSWORD")}
                      name="updatePassword"
                      onChange={updatePasswordForm.handleChange}
                      value={updatePasswordForm.values.updatePassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsEyeOpenUpass(!isEyeUpass)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {isEyeUpass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                        {updatePasswordForm.errors.updatePassword &&
                    updatePasswordForm.touched.updatePassword ? (
                      <div className="error-color">
                        {updatePasswordForm.errors.updatePassword}
                      </div>
                    ) : null}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={isEyeconfirmPass ? "text" : "password"}
                      id="confirmPassword"
                      label={t("Shared.FORM.CONFIRM_PASSWORD")}
                      name="confirmPassword"
                      onChange={updatePasswordForm.handleChange}
                      value={updatePasswordForm.values.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsEyeconfirmPass(!isEyeconfirmPass)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {isEyeconfirmPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {updatePasswordForm.errors.confirmPassword &&
                    updatePasswordForm.touched.confirmPassword ? (
                      <div className="error-color">
                        {updatePasswordForm.errors.confirmPassword}
                      </div>
                    ) : null}
                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={isEye ? "text" : "password"}
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      onChange={updatePasswordForm.handleChange}
                      value={updatePasswordForm.values.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsEyeOpen(!isEye)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {isEye ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    {/* {updatePasswordForm.errors.confirmPassword &&
                    updatePasswordForm.touched.confirmPassword ? (
                      <div className="error-color">
                        {updatePasswordForm.errors.confirmPassword}
                      </div>
                    ) : null} */}
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {t("Shared.UPDATE_PWD")}
                    </Button>
                  </form>
                </Paper>
              </AccordionSummary>
            </Accordion>
          )}
          {checkVenodr && (
            <Paper elevation={1}>
              <FormControl component="fieldset">
                <FormControlLabel
                  label={t("Shared.SWITCH_COLOR")}
                  control={<ThemeSwitch></ThemeSwitch>}
                  labelPlacement="start"
                ></FormControlLabel>
              </FormControl>
            </Paper>
          )}
        </div>
      </Container>
    </>
  );
}
