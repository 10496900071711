import { postRequest, getRequest } from "core/utils/axios-client/axios-client";
let userData = JSON.parse(localStorage.getItem("USER_DATA"));

export const log_activity = async (activityName, pageName, notes) => {
  if (process.env.REACT_APP_ENV === "Production" || process.env.REACT_APP_ENV === "Test")
    postRequest("Common/LogActivity", {
      account_id: userData?.account_id,
      customer_id: userData?.customer_id,
      vendor_id: userData?.vendor_id,
      activity_name: activityName,
      page_name: pageName,
      notes: notes,
    })
      .then((resp) => {
        if (resp?.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
};

export const isPhoneExists = async (mobileNo, enqueueSnackbar, userType, MSG) => {
  try {
    const resp = await getRequest('LbAuth/IsPhoneExists/' + mobileNo + '/' + userType);
    if (resp?.status === 200) {
      if (resp?.data) {
        enqueueSnackbar(MSG.existMobileMsg, {
          variant: "error",
        });
      }
      return resp?.data;
    }
  } catch (error) {
    enqueueSnackbar(MSG.errorOccuured, {
      variant: "error",
    });
    console.error(error);
    return false;
  }
};

export const isEmailExists = async (emailId, enqueueSnackbar, userType, MSG) => {
  try {
    const resp = await getRequest('LbAuth/IsEmailExists/' + emailId + '/' + userType);
    if (resp?.status === 200) {
      if (resp?.data) {
        enqueueSnackbar(MSG.existEmailMsg, {
          variant: "error",
        });
      }
      return resp?.data;
    }
  } catch (error) {
    enqueueSnackbar(MSG.errorOccuured, {
      variant: "error",
    });
    console.error(error);
    return false;
  }
};

