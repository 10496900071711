import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  centerText: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const ErrorPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <h1 className={classes.centerText}>{t("HelperMsg.INCONVIENCE_MSG")}</h1>
      <h5 className={classes.centerText}>{props.message}</h5>
    </>
  );
};

export default ErrorPage;