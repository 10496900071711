import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#535455"
  },
}));

function LbParagraph({ text, styleClass }) {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      component="p"
      className={classes.root +  " p-8 text-just " + styleClass}
    >
      {text}
    </Typography>
  );
}

export default LbParagraph;
