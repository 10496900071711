import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 300 : 700],
    },
    bar: {
        borderRadius: 5,
        // backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

export default function LbProgress({ value }) {
    return (
        <>
            <BorderLinearProgress variant="determinate" value={value} />
        </>
    );
}

