import { Grid, TextField } from '@material-ui/core';

export default function CommonRenderTextField(label, name, errors, register, rows) {
    return (
        <Grid item xs={12}>
            <TextField
                {...register(name)}
                label={label}
                fullWidth
                margin="dense"
                size='small'
                multiline
                rows={rows ? rows : 0}
                variant="outlined"
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
            />
        </Grid>
    )
};
