import { getRequest } from "core/utils/axios-client/axios-client";
import { defaultPageSize } from "core/utils/constants";
import { getLocalStorage } from "core/utils/utilities";

export const get_customer_booking = async ({
  setSearchList,
  setDisplayList,
  enqueueSnackbar,
  setNoRecordMsg,
  setFilteredList,
  pageNo,
  displayList,
  msg,
}) => {
  let user = getLocalStorage("USER_DATA");
  getRequest(
    `LbBooking/GetBookings/${user.customer_id}?cust_vend=customer&pageSize=${defaultPageSize}&pageNo=${pageNo}`
  )
    .then((resp) => {
      // console.log(resp);
      //  console.log(resp);
      if (resp?.status === 200) {
        setSearchList(resp?.data);
        setDisplayList(resp?.data);
      }
      //****** Pagination load Data logic start ****
      let addMoreData = [];
      addMoreData.push(...displayList, ...resp.data);
      //****** Pagination load Data logic End ****
      setDisplayList(addMoreData);
      setSearchList(addMoreData);
      setFilteredList(addMoreData);
      //  console.log("hii",resp?.data)
      if (resp?.data?.length === 0) setNoRecordMsg(msg.RECORDNOTFOUND);
      else setNoRecordMsg("");
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar(msg.bookingApiFAiled, {
        variant: "error",
      });
    });
};

export const get_booking_details = (
  bokId,
  custVend,
  setOpen,
  setResponse,
  setServiceList,
  enqueueSnackbar,
  msg
) => {
  let user = getLocalStorage("USER_DATA");
  getRequest(`LbBooking/GetBookingDetails/${bokId}/${custVend}`)
    .then((resp) => {
      // console.log(resp);
      if (resp?.status === 200) {
        setResponse(resp?.data);
        setOpen(true);
        setServiceList(JSON.parse(resp?.data?.service_data));
      } else {
        enqueueSnackbar(msg.NoDetail, {
          variant: "error",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.GetBookingFail, {
        variant: "error",
      });
    });
};

export const get_customer_bidding = (
  setSearchList,
  setDisplayList,
  enqueueSnackbar,
  msg
) => {
  let user = getLocalStorage("USER_DATA");
  getRequest("LbCustomer/GetCustomerDeal?custId=" + user.customer_id)
    .then((resp) => {
      // console.log(resp);
      if (resp.status === 200) {
        setSearchList(resp?.data);
        setDisplayList(resp?.data);
      } else {
        enqueueSnackbar(msg.failedmsg, {
          variant: "error",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.failedmsg, {
        variant: "error",
      });
    });
};

export const get_booking_contract = (
  bookingId,
  setContract,
  enqueueSnackbar,
  msg
) => {
  getRequest("LbBooking/GetBookingContract/" + bookingId)
    .then((resp) => {
      // console.log(resp);
      if (resp.status === 200) {
        setContract(resp.data);
      } else {
        enqueueSnackbar(msg.failedmsg, {
          variant: "error",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.failedmsg, {
        variant: "error",
      });
    });
};
