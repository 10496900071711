import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateTime from 'shared/components/helper-component/date-time';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './invite.scss';
import ViewDetail from './view-detials';
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { delete_Web_Invites_Data } from "shared/services/web-invite-service";
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function InviteCard({ item,deleteFilter }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const bull = <span className={classes.bullet}>•</span>;
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();
    const appData = useSelector((state) => state?.appData);
    const invitation_id = appData?.WebInviteUpdate?.invitation_id;
    const { t } = useTranslation();
    const msgFail = {
        msgS: t("HelperMsg.DELETED_SUCCESSFULLY"),
        failedmsg: t("HelperMsg.FAILLED")

    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpenDelete(false);
        setOpen(false);
        setAnchorEl(null);
    };
    const handleOpen = (option) => {
        setOpen(false);
        if (option === "View") {
            handleClickOpen();
        }
        if (option === "Update") {
            handleClickUpdate(option);
        }
        if (option === "Delete") {
            setOpenDelete(true);
        }
        if (option === "Share") {
            console.log(appData)
            console.log(item?.invitation_id)
            const redirectUrl = process.env.REACT_APP_VENDOR_WEB_DOMAIN + '/' + item?.invitation_id;
            window.location.href = redirectUrl;
        }
    }
    const handleClickUpdate = (option) => {
        if (option === "Update") {
            dispatch(
                appActions.WebInviteUpdate(item)
            );
            const update=true
            history.push(`/customer/invite/webInvite/update`);
            dispatch(appActions.resetWebInviteData(true))


        }

    }

    const handleDelete = () => {
        deleteFilter(item?.invitation_id)
        setOpenDelete(false);
        setOpen(false);
        setAnchorEl(null);
        delete_Web_Invites_Data(item?.invitation_id, enqueueSnackbar, msgFail)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const options = [
        'View',
        'Update',
        'Share',
        'Delete',
    ];
    const ITEM_HEIGHT = 48;
    return (
        <>
            <Card className={classes.root + ' flex flex-row space-between'} variant="outlined">
                <div>


                    <CardContent>

                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            WEB INVITE

                        </Typography>
                        <Typography variant="h5" component="h2">
                            <span className="fonts-1 font-weight-700">{item?.groom_name}</span>
                            &nbsp;
                            <span className="fonts-5">  Weds  </span>
                            &nbsp;
                            <span className="fonts-1 font-weight-700">{item?.bride_name}</span>
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <DateTime dtType='short'>
                                {item?.lagan_date}
                            </DateTime>
                        </Typography>
                        <Typography variant="body2" component="p">
                            {item?.invitee_address}
                            <br />
                            {item?.devine_quote}
                        </Typography>
                    </CardContent>
                </div>
                <img src={'/images/template/temp3.png'} className="temp-view" />
                <div style={{ textAlign: "right" }}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open1}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '10ch',
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleOpen(option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </Card>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('HelperMsg.DELETE_CONFIRMATION')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="primary">
                    {t("Shared.YES")}
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                    {t("Shared.NO")}
                    </Button>
                </DialogActions>
            </Dialog>
            <ViewDetail handleClose={handleClose} open={open} item={item}></ViewDetail>
        </>
    );
}
