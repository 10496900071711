import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useSnackbar } from "notistack";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import { Menu, Typography } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import "./notification.scss";
import { postRequest } from "core/utils/axios-client/axios-client";
import * as appActions from "redux/actions/appActions";
import { Link } from "react-router-dom";
import { getUserType } from "core/utils/utilities";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles({
  root: {
    width: "430px",
    "& .MuiMenu-list": {
      padding: "5px",
    },
  },
  lastNote: {
    fontSize: "12px",
    fontWeight: 500,
    padding: 8,
    textAlign: "center",
    color: "#747474",
    cursor: "pointer",
  },
});
export default function NotificationMenu({ newUnreadMsgCount }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  const userNotifications = appData.userNotifications;
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const notiURL =
    getUserType().toString() === "customer"
      ? "/customer/notification"
      : "/vendor/notification";
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const markReadMessage = (notificationId) => {
    postRequest(
      "LbNotification/UpdateNotificationFlag/" + notificationId + "/" + true
    )
      .then((resp) => {
        if (resp.status === 200) {
          // enqueueSnackbar(t("HelperMsg.MARKREAD"), {
          //   variant: "success",
          // });
          dispatch(
            appActions.setUserNotifications(
              userNotifications.filter(
                (notification) => notification.not_id !== notificationId
              )
            )
          );
          log_activity("markReadMessage", "notification-menu.jsx", "Mark Read, notId :  "+notificationId);
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.MARKREADFAIL"), {
          variant: "error",
         });
         log_activity("markReadMessage", "notification-menu.jsx", "Exception :  "+JSON.stringify(error));
      });
  };

  return (
    <>
      <Badge
        className="mr-4"
        badgeContent={newUnreadMsgCount}
        color="secondary"
        onClick={handleClick}
      >
        <MailIcon />
      </Badge>
      <Menu
        className={classes.root}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseAnchor}
      >
        {userNotifications.slice(0, 10).map((userNotification, index) => (
          <MenuItem
            onClick={() => markReadMessage(userNotification.not_id)}
            key={index}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <div className="cust-notification">
              <strong>{userNotification.subject}</strong>
              <div>{userNotification.body}</div>
            </div>
            <Badge color="secondary" variant="dot" className="mr-4"></Badge>
          </MenuItem>
        ))}
        <Divider variant="fullWidth" />
        <div className="text-center no-text-style">
          <Link to={notiURL}>
            <Typography variant="subtitle1" className="font-black">
              {t("Shared.NOTIFICATION.VIEW_ALL_NOTIFICATION")}
            </Typography>
          </Link>
        </div>
      </Menu>
    </>
  );
}
