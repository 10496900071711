import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { postRequest } from "core/utils/axios-client/axios-client";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  mb20: {
    marginBottom: "20px",
  },
  mb8: {
    marginBottom: "8px",
  },
}));

//Validate the form field
const bidSchema = Yup.object().shape({
  bid_price: Yup.string().required("Required"),
});
//intialise the form field
const initialValues = {
  deal_id: 0,
  vendor_id: "",
  setting_value_id: "",
  note: "",
  bid_price: "",
};

function BidSubmitDialog({ deal_id, setRefreshBids, setdOpen }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hideBtn, setHideBtn] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: bidSchema,
    onSubmit: (values) => {
      values.deal_id = Number(deal_id);
      values.bid_price = Number(values.bid_price);
      values.setting_value_id = Number(
        JSON.parse(localStorage.getItem("USER_DATA")).setting_value_id
      );
      values.vendor_id = Number(
        JSON.parse(localStorage.getItem("USER_DATA")).vendor_id
      );
      postRequest("LbBidding/SaveBidding", values)
        .then((resp) => {
          if (resp.status === 200) {
            enqueueSnackbar(t("HelperMsg.BIDADDEDSUCCESS"), {
              variant: "success",
            });
            setRefreshBids(true);
            setHideBtn(true);
            setdOpen(false);
            setOpen(false);
            log_activity("SaveBidding", "bid-submit-dialog.jsx", "Success");
          } else {
            enqueueSnackbar(t("HelperMsg.BIDADDEDSUCCESS"), {
              variant: "error",
            });
            setOpen(false);
            log_activity("SaveBidding", "bid-submit-dialog.jsx", "Non 200 : "+JSON.stringify(resp));
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("HelperMsg.BIDDAPIFAILED"), {
            variant: "error",
          });
          setOpen(false);
          log_activity("SaveBidding", "bid-submit-dialog.jsx", "Exception: "+JSON.stringify(error));
        });
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
    log_activity("handleClickOpen", "bid-submit-dialog.jsx", "Bid form open");
  };

  const handleClose = () => {
    setOpen(false);
    // setHideBtn(true);
    log_activity("handleClose", "bid-submit-dialog.jsx", "Bid form closed");
  };

  return (
    <div>
      {hideBtn ? (
        ""
      ) : (
        <Button
          size="small"
          type="button"
          variant="contained"
          color="secondary"
          className="mr-2"
          onClick={handleClickOpen}
        >
          {t("Bidding_Details.BID")}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Bidding_Details.BIDDING_DETAILS")} </DialogTitle>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogContent>
            <TextField
              id="bid_price"
              size="small"
              label={t("Bidding_Details.BIDDING_AMT")}
              type="text"
              fullWidth
              required
              variant="outlined"
              name="bid_price"
              value={formik.values.bid_price}
              onChange={formik.handleChange}
              error={
                formik.touched.bid_price && Boolean(formik.errors.bid_price)
              }
              helperText={formik.touched.bid_price && formik.errors.bid_price}
              className={classes.mb8}
            />
            <TextField
              id="note"
              size="small"
              label={t("Vendor.VendorDetails.NOTE")}
              type="text"
              fullWidth
              variant="outlined"
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              size="small"
            >
              {t("Shared.CNCL")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="small"
            >
              {t("Shared.SAVE")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default BidSubmitDialog;
