// **************Only 3 keys are allowed for search*****************
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";

LbSearch.propTypes = {
  arrayList: PropTypes.array,
  searchKey1: PropTypes.string,
  searchKey2: PropTypes.string,
};
const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    backgroundColor: "#fff",
  },
  stickyHead: {
    position: "sticky",
    top: "64px",
    backgroundColor: "#fff",
    width: "100%",
    zIndex: 5,
    marginLeft: "0px",
  },
}));

function LbSearch(props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  let placeholder = props.placeholder || "";

  const filter = (e) => {
    const keyword = e.target.value;
    setSearchText(keyword);

    props.searchValue(keyword);
  };
  return (
    <div className={props.sticky ? classes.stickyHead : ""}>
      <div className="my-2">
        <TextField
          className={classes.searchBar}
          variant="outlined"
          value={searchText}
          size="small"
          placeholder={placeholder}
          type="search"
          onChange={filter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default LbSearch;
