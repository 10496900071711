import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { reset_password_email } from "./sign_in_helper";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@material-ui/icons";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  linkText: {
    textDecoration: "none",
  },
}));

export default function ResetPassword() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  // Snackbar show hide
  const { enqueueSnackbar } = useSnackbar();
  //intialise the form field
  const initialValues = {
    email_id: "",
  };
  const msg = {
    resetpass: t("HelperMsg.RESETPASSWORD"),
  };
  //Validate the form field
  const resetSchema = Yup.object().shape({
    email_id: Yup.string().required("Required").email("Invalid email id"),
  });

  const resetForm = useFormik({
    initialValues: initialValues,
    validationSchema: resetSchema,
    onSubmit: (values) => {
      reset_password_email(
        values.email_id,
        enqueueSnackbar,
        history,
        dispatch,
        msg
      );
      console.log("Reset form submit");
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Booking_login.RESET_PASS")}
        </Typography>
        <form className={classes.form} onSubmit={resetForm.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email_id"
            label={t("Booking_login.LOGIN_EMAIL")}
            name="email_id"
            autoComplete="email_id"
            autoFocus
            onChange={resetForm.handleChange}
            value={resetForm.values.email_id}
          />
          {resetForm.errors.email_id && resetForm.touched.email_id ? (
            <div className="error-color">{resetForm.errors.email_id}</div>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Booking_login.RESETLINK")}
          </Button>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link to="./signin" variant="body2" className={classes.linkText}>
                {t("Booking_login.REMEMBER")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
