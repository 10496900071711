import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { searchFilter } from "core/utils/utilities";
import { useSelector } from "react-redux";
import LbSearch from "shared/components/lb-search/lb-search";
import { useTranslation } from "react-i18next";
import Booking from "shared/lb-forms/booking/booking";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import VendorBookingCard from "./vendor-booking-card";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { getSorting } from "core/utils/utilities";
import { getRequest } from "core/utils/axios-client/axios-client";
import { defaultPageSize } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import ShowMoreBtn from "core/common/show-more";

const useStyles = makeStyles(() => ({
  title: {
    color: "#fff",
    padding: "9px",
    margin: "-8px -8px 0px",
    fontWeight: "500",
    fontSize: "17px",
  },
  w100: {
    width: "100%",
  },
}));

function VendorBookingList() {
  const [displayList, setDisplayList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const appData = useSelector((state) => state.appData);
  const { t } = useTranslation();
  const classes = useStyles();
  //******Serach Result *********/
  const [searchList, setSearchList] = useState([]);
  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setDisplayList(filterdData);
  };
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [filteredList, setFilteredList] = useState(displayList);
  const [initialPageNo, setInitialPageNo] = useState(1);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  //******Serach Result end *********/

  const getAllBookings = (firstLoad, pageNo) => {
    getRequest(
      `LbBooking/GetBookings/${appData.user.vendor_id}?cust_vend=vendor&pageSize=${defaultPageSize}&pageNo=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          //****** Pagination load Data logic start ****
          let addMoreData = [];
          if (firstLoad) addMoreData.push(...resp.data);
          else addMoreData.push(...displayList, ...resp.data);
          //****** Pagination load Data logic End ****
          setRecordCount(resp?.data?.length);
          setDisplayList(addMoreData);
          setSearchList(addMoreData);
          setFilteredList(addMoreData);
          //  console.log("hii",resp?.data)
          if (resp?.data?.length === 0)
            setNoRecordMsg(t("Vendor.VendorDetails.NO_RECORD_MSG"));
          else setNoRecordMsg("");
          log_activity("getAllBookings", "vendor-booking-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.GETVENDORLISTFAILED"), {
          variant: "error",
        });
        log_activity("getAllBookings", "vendor-booking-list.jsx", "Exception : "+JSON.stringify(error));
      });
  };

  useEffect(() => {
    doSorting(sortingField, sortingOrder);
  }, [sortingField, sortingOrder]);

  const loadMore = () => {
    setInitialPageNo(initialPageNo + 1);
    getAllBookings(false, initialPageNo + 1);
    log_activity("loadMore", "vendor-booking-list.jsx", "Success");
  };
  const doSorting = (field, sortingOrder) => {
    const sortedData = getSorting(filteredList, field, sortingOrder);

    //  console.log(filteredList.sort(sorter2(field,sortingOrder)));
    setFilteredList(sortedData);
    log_activity("doSorting", "vendor-booking-list.jsx", "Success");
  };
  useEffect(() => {
    getAllBookings(true, 1);
  }, []);

  const refreshList = () => {
    getAllBookings(true, 1);
  };
  return (
    <>
      <Box m={1} className="">
        <div className="flex space-between align-center">
          <LbHeading text={t("Vendor.VendorDetails.BOOKING_LIST")} />
          <Booking
            title={t("Shared.BOOK_NOW")}
            settingValueId={appData?.user?.setting_value_id}
            vendorId={appData?.user?.vendor_id}
            refreshList={refreshList}
          ></Booking>
        </div>
        <LbSearch
          sticky={true}
          searchValue={searchValue}
          placeholder={t("Shared.SEARCH_NAME_SOURCE_DESTINATION")}
        ></LbSearch>
        <Grid container spacing={1}>
          {displayList?.map((item, index) => (
            <Grid item sm={12} md={4} className={classes.w100} key={index}>
              <VendorBookingCard item={item} key={index}></VendorBookingCard>
            </Grid>
          ))}
        </Grid>
        <div className="m-auto">
          <ShowMoreBtn dataList={displayList} loadMoreCbFn={loadMore}></ShowMoreBtn>
        </div>
      </Box>
    </>
  );
}

export default VendorBookingList;
