import { postRequest } from "core/utils/axios-client/axios-client";

  export const save_contact = (
    payload,
    enqueueSnackbar,
    msgFail
  ) => { 
    postRequest(`LbContact/SaveContact`,payload)
      .then((resp) => {
        if (resp.status === 200) {
          enqueueSnackbar('Success', {
            variant: "Success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(msgFail.failedmsg, {
          variant: "error",
        });
      });
  }
  