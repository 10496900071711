import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Avatar,
  Button,
  TextField,
  Divider,
} from "@material-ui/core";
import { save_contact } from "shared/services/contact-list-service";
import React, { useState, useEffect } from "react";
import LbTitle from "shared/components/lb-title/lb-title";
import { useForm } from "react-hook-form";
import UserDetails from "shared/lb-forms/user-details/user-details";
import LbLocationNew from "shared/components/lb-location/lb-location-new";
import { getZipcode } from "shared/services/booking-service";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { isEmailExists } from "../../shared/services/common-service";
import { emailRegex } from "core/utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactFormSchema } from "shared/validation-schema/validation-schema";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  top1: {
    marginTop: "1rem",
  },
}));

export default function ContactForm(props) {
  const [existEmail, setExistEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [disableField, setDisableField] = useState(false);
  const { onClose /* other props */ } = props;
  const [open, setOpen] = useState(false);
  const { openPopup, setOpenPopup, editData } = props;
  const [listValue, setListValue] = useState([]);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors
  } = useForm({ resolver: yupResolver(ContactFormSchema(t)) });
  const appData = useSelector((state) => state.appData);
  const setting_value_id = appData.user.setting_value_id;
  const vendor_id = appData.user.vendor_id;
  const handleClose = (event, reason) => {
    setOpenPopup(false);
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    registerfailed: t("HelperMsg.REGISTERFAILED"),
    errorOccuured: t("HelperMsg.ERROROCCURED"),
    existEmailMsg: t("HelperMsg.EmailIdAlreadyExists"),
  };
  const onSubmit = (data) => {
    let payload = {
      p_location_id: editData.p_location_id ? editData.p_location_id : 0,
      p_zip_code: data.s_zip_code,
      p_address: data.s_address,
      p_city_local: data.s_city_local,
      p_taluka: data.s_taluka,
      p_district: data.s_district,
      p_state: data.s_state,
      p_region: data.s_region,
      p_customer_id: editData.p_customer_id ? editData.p_customer_id : 0,
      p_first_name: data.first_name,
      p_last_name: data.last_name,
      p_email_id: data.mail,
      p_mobile_no: data.cust_mob,
      p_lagan_date: data.lagan_date,
      p_setting_value_id: editData.p_setting_value_id
        ? editData.p_setting_value_id
        : parseInt(setting_value_id),
      p_vendor_id: parseInt(vendor_id),
      p_contact_id: editData.p_contact_id ? editData.p_contact_id : 0,
      p_status: "",
      p_notes: "",
      p_is_deleted: 0,
    };
    save_contact(payload, enqueueSnackbar, msgFail);
    setOpenPopup(false);
    onClose();
  };

  const zipCodeCallD = (zipCode) => {
    //********Set Venodr Id********/
    // setValue('s_city_local', ''); //
    if (zipCode?.length === 6) {
      getZipcode(zipCode, enqueueSnackbar, setListValue, setOpen);
    }
  };

  const handleExistEMAIL = async (emailId) => {
    if (editData.p_email_id !== emailId) {
      if (emailId) {
        const isEmail = await isEmailExists(
          emailId,
          enqueueSnackbar,
          "customer",
          msgFail
        );
        setExistEmail(isEmail);
      }
    } else {
      setExistEmail(false);
    }
  };

  const user = {
    cust_mob: editData.p_mobile_no || "",
  };
  useEffect(() => {
    setExistEmail(false);
    // This useEffect will be triggered whenever the editData prop changes
    // Use the editData to pre-fill your form fields
    if (editData) {
      // Set values for form fields based on editData
      zipCodeCallD(editData?.p_zip_code);
      const date = editData?.p_lagan_date?.split("T");
      setValue("mail", editData.p_email_id || "");
      setValue("first_name", editData.p_first_name || "");
      setValue("last_name", editData.p_last_name || "");
      setValue("cust_mob", editData.p_mobile_no || "");
      setValue("lagan_date", date ? date[0] : "" || "");
      setValue("s_zip_code", editData.p_zip_code || "");
      setValue("s_address", editData.p_address || "");
      setValue("s_city_local", editData.p_city_local || "");
      setValue("s_taluka", editData.p_taluka || "");
      setValue("s_district", editData.p_district || "");
      setValue("s_state", editData.p_state || "");
      setValue("s_region", editData.p_region || "");
      // Add more fields as needed
    }
  }, [editData]);
  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      PaperProps={{
        style: {
          padding: "1rem",
          width: "550px", // Add the minimum width you want here
        },
      }}
    >
      <div className={classes.center}>
        <Avatar
          alt="best_deal"
          className={classes.imageIcon}
          src="/images/icons/1/nfc.png"
        />
      </div>
      <LbHeading text={t("Shared.ADD_CONTACT")} />
      <Divider></Divider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.top1} spacing={1}>
          <Grid xs={12} className="mb-4">
            <TextField
              size="small"
              id="outlined-basic"
              name="mail"
              {...register("mail", {
                required: { value: true, message: t("Validation.REQUIRED") },
                pattern: {
                  value: emailRegex,
                  message: t("Validation.InvalidEmailId"),
                },
                validate: (value) =>
                  !existEmail || t("HelperMsg.EmailIdAlreadyExists"),
              })}
              variant="outlined"
              className="width-100-p"
              onBlur={() => handleExistEMAIL(getValues("mail"))}
              label={t("Booking_login.EMAIL")}
            />
            {errors.mail && (
              <span className="error-color">{errors.mail.message}</span>
            )}
          </Grid>
          {/* User Details  */}
          <UserDetails
            register={register}
            errors={errors}
            disable={disableField}
            initialValues={user}
          />
          {/* User Location  */}
          <LbTitle name={t("Shared.LOCATION")} size="16" weight="600"></LbTitle>
          {/* Services list  */}
          <LbLocationNew
            zipCodeCallD={zipCodeCallD}
            listValue={listValue}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
          ></LbLocationNew>
        </Grid>
        <div align="end" className="mt-8">
          <Button
            type="button"
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleClose}
            className="mr-5"
          >
            {t("Shared.CANCEL")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
          >
            {t("Shared.SAVE")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
