import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

function DateTime({ children, dtType, ...props }) {
    const [dateFormat, setDateFormat] = useState('');
    const [timeFormat, setTimeFormat] = useState('');
    useEffect(() => {
        let dateObj = new Date(children);
        let sDate = dateObj.toString().split(" ");

        setDateFormat(`${sDate[2]} ${sDate[1]} ${sDate[3]} ${sDate[0]}` || '');
        setTimeFormat(`${sDate[4]}` || '');
    }, [])
    return (
        <span {...props}>
            {dtType === 'short' ? dateFormat : dateFormat + " " + timeFormat}
        </span>
    );
}

export default DateTime;