import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GAPageViewTrigger, getLocalStorage } from "core/utils/utilities";
import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LbTitle from "shared/components/lb-title/lb-title";
import { get_vendor_service } from "vendor/services/service_manage_helper";
import ManageServiceDialog from "./manage-service-dialog";

import VendorServicesList from "home/vendor-services-list/vendor-services-list";

const LINES_TO_SHOW = 2;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: 6,
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    minHeight: "calc(100vh - 236px)",
  },
  card: {
    margin: "12px",
    minWidth: 200,
    minHeight: 300,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  subTitle: {
    fontWeight: "500",
    marginBottom: "6px",
    color: "#000",
  },
  photo: {
    margin: "12px 0px",
    color: "#a1a1a1",
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
    fontSize: 12,
  },
}));
function ManageService(props) {
  const classes = useStyles();
  const [serviceList, setServiceList] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = getLocalStorage("USER_DATA");
  const { t } = useTranslation();
  const msgFail={
    failedmsg: t("HelperMsg.FAILLED"),
  }
  const returnManageService = (event) => {
    if (event) {
      get_vendor_service(
        setServiceList,
        setApiResponse,
        user?.vendor_id,
        user?.setting_value_id,
        enqueueSnackbar,
        msgFail
      );
    }
  };

  React.useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search,"manage-vendor-service");
    returnManageService(true);
  }, []);
  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Grid item xs={8}>
          <LbTitle
            size="18"
            name={t("Shared.Service_management.MNG_SERVICES")}
            weight="600"
          ></LbTitle>
        </Grid>
        <Grid item xs={4} className="text-right">
          <ManageServiceDialog
            returnManageService={returnManageService}
            vendorServicesList={serviceList}
            apiResponse={apiResponse}
          ></ManageServiceDialog>
        </Grid>
      </Grid>
      <Divider className="mb-8" />
      <VendorServicesList
        serviceList={serviceList}
        apiResponse={apiResponse}
        viewOnly={false}
      ></VendorServicesList>
    </div>
  );
}

export default ManageService;
