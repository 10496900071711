import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Fab from "@material-ui/core/Fab";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloseIcon from "@material-ui/icons/Close";
import "./call-us.scss";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  subTitle: {
    fontSize: "0.9rem",
    color: "#808080",
    marginBottom: "16px",
  },
  green: {
    backgroundColor: "#28D146",
    color: "#ffffff",
  },
  imgSize: {
    width: "250px",
    height:"120px"
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "4px",
  },
  wrapper: {
    position: "relative",
  },
  border1:{
    border: theme.palette.primary.main +" solid",
    borderWidth:"2px 2px 0px 2px"
  },
  border2:{
    border: theme.palette.primary.main+" solid",
    borderWidth:"0px 2px 2px 2px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CallUs = ({ openCall, isMobile }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth, setMaxWidth] = useState("md");
  const favDrawer = useSelector((state) => state.appData?.favDrawer);
  const dispatch = useDispatch();
  const actions = [
    { icon: <CallIcon />, name: "Call" },
    { icon: <FavoriteIcon />, name: "Favorite" },
  ];

  const handleCallOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const favClick = () => {
    dispatch(appActions?.setFavDrawer(!favDrawer));
  };
  const handleClose = (actionName) => {
    if (actionName === "Call") handleCallOpen();
    if (actionName === "Favorite") favClick();

    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (openCall) {
      setOpen(true);
    }
  }, [openCall]);
 
  return (
    <div>
      {isMobile ? (
        <CallIcon onClick={handleCallOpen} />
      ) : (
        <div className={classes.wrapper}>
          <SpeedDial
            ariaLabel="Speed Dial button"
            className={"fab-call"}
            icon={<CallIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={"up"}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => {
                  handleClose(action.name);
                }}
              />
            ))}
          </SpeedDial>
        </div>
      )}
      <Dialog
        open={dialogOpen}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.border1}>
          <img
            src="/images/lb_logo.png"
            alt=""
            className={classes.imgSize}
          />
          <div>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.border2}>
          <div>
            <div className={classes.title}>Are you facing any problem?</div>
            <div className={classes.subTitle}>
              If you need instant support then use below option to reach us
              quickly. Our support will reply as soon as possible after you send
              us a message or call.
            </div>
            <div className="mb-5 mt-10">
              <a href="tel:9319012098">
                <Fab
                  size="large"
                  variant="extended"
                  aria-label="Call Us"
                  color="secondary"
                  style={{ textTransform: "none" }}
                >
                  <CallIcon color="#ffffff" />
                  {t("Shared.CALLUS")} 931-901-2098
                </Fab>
              </a>
            </div>
            <strong className="my-2">Or</strong>
            <div className="my-5">
              <a href="https://wa.me/9319012098?text=Hello%2C%20I%20have%20a%20question%20about%20your%20service.%20Can%20you%20please%20help%20me%3F">
                <Fab
                  size="large"
                  variant="extended"
                  aria-label="Whatsapp"
                  style={{ textTransform: "none" }}
                  className={classes.green}
                >
                  <WhatsAppIcon color="#ffffff" />
                  Conatct on Whatsapp
                </Fab>
              </a>
            </div>
            <strong className="my-2">Or</strong>
            <div className="my-5">
              <a href="mailto:lagan.booking@gmail.com">
                <Fab
                  size="large"
                  variant="extended"
                  autoCapitalize="none"
                  aria-label="E-mail"
                  color="primary"
                  style={{ textTransform: "none" }}
                >
                  <MailOutlineIcon color="#ffffff" />
                  Email: lagan.booking@gmail.com
                </Fab>
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CallUs;
