import React from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DateTime from "../helper-component/date-time";

function LbDateTime({ date }) {
  return (
    <div className="flex mtb">
      <div className="mr-8 flex-nogrow ">
        <CalendarTodayIcon color="secondary" className="cust-icon" />
        <span className="sub-title rel">
          {" "}
          {date ? <DateTime dtType="short">{date}</DateTime> : ""}
        </span>
      </div>
    </div>
  );
}

export default LbDateTime;
