import React from "react";
import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border:"1px "+theme.palette.primary.main + " solid",
  },
}));

const LbCard = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Card {...props} className={classes.root}>
      {children}
    </Card>
  );
};

export default LbCard;
