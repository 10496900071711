import {
  Container,
  Button,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { useTranslation } from "react-i18next";
import LbSearch from "shared/components/lb-search/lb-search";
import { searchFilter } from "core/utils/utilities";
import AddGuest from "./add-guest";
import { defaultPageSize } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import {
  getRequest,
  deleteModuleRequest,
} from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import GuestListTable from "./guest-list-table";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "100%",
  },
  headM: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    textAlign: "right",
    zIndex: "1",
    position: "relative",
    top: "0rem;",
  },
  buttonRight: {
    textAlign: "right",
  },
  actionbtn: {
    margin: "8px",
  },
}));

const GuestListDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayList, setDisplayList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState([]);
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setFilteredList(filterdData);
  };
  const history = useHistory();
  const guest_list_id =useParams();
  const guestListId = guest_list_id.id.split('=')[1];

  const handleChange = (panel, data) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const editClick = (data) => {
    setEditData(data);
    setOpenPopup(true);
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
    setRefresh((prevRefresh) => !prevRefresh);
  };
  const orderList = [
    "first_name",
    "last_name",
    "email_id",
    "mobile_no",
    "place",
    "relation",
    "mobile_no",
    "e_mail",
    "invitation_type",
  ];

  const handlePageChange = (e) => {
    getGuestData();
    log_activity("handlePageChange", "guest-list-details.jsx", "Success");
  };

  const getGuestData = () => {
    let pageNo = initialPageNo ? initialPageNo : 1;
    getRequest(
      `LbGuest/GetGuests?guest_list_id=${guestListId}&page_size=${defaultPageSize}&page_no=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          setDataLoaded(true);
          if (resp?.data?.length) {
            //****** Pagination load Data logic start ****
            let addMoreData = [];
            addMoreData.push(...displayList, ...resp.data);
            setInitialPageNo(pageNo + 1);
            //****** Pagination load Data logic End ****
            setDisplayList(addMoreData);
            setSearchList(addMoreData);
            setFilteredList(addMoreData);
          } else {
            setShowButton(true);
          }
          log_activity("getContactData", "guest-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.CONTACTLISTFAILED"), {
          variant: "error",
        });
        log_activity(
          "getContactData",
          "guest-list.jsx",
          "Exception : " + JSON.stringify(error)
        );
      });
  };

  const deleteGuestData = (id) => {
    deleteModuleRequest(`LbGuest/DeleteGuest?guest_id=${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          setDataLoaded(true);
          // log_activity("deleteData", "contact-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.CONTACTLISTFAILED"), {
          variant: "error",
        });
        // log_activity(
        //   "getGuestData",
        //   "contact-list.jsx",
        //   "Exception : " + JSON.stringify(error)
        // );
      });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getGuestData();
  }, []);

  return (
    <Container component="main" maxWidth="xl" style={{ height: "100%" }}>
      <div className="flex space-between align-center mt-4 pt-4">
        <LbHeading text={t("GuestList.GUESTS")} />
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ float: "right" }}
          onClick={editClick}
        >
          {t("GuestList.ADD_GUEST")}
        </Button>
      </div>
      <Grid container>
        <Grid item xs className="mx-2">
          <LbSearch
            sticky={true}
            searchValue={searchValue}
            placeholder={t("Shared.SEARCH_NAME_PHONE")}
          ></LbSearch>
        </Grid>
      </Grid>
      <div className="pb-8">
        <Grid container className="mt-6">
          <AddGuest
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            editData={editData}
            refresh={refresh}
            onClose={handlePopupClose}
          />
        </Grid>
        <GuestListTable  data={filteredList} onEdit={editClick} onDelete={deleteGuestData}/>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className="my-8 right mr-8"
          onClick={goBack}
        >
          {t("Shared.BACK")}
        </Button>
      </div>
    </Container>
  );
};

export default GuestListDetails;
