import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LbProgress from 'shared/components/lb-progress/lb-progress';

const CustomStepper = ({ value, currentStepLabel }) => {
  return (
    <>
      <Box className='width-100-p'>
        <Box textAlign="center">
          <div className='text-title my-4'>{currentStepLabel}</div>
        </Box>
        <div className='flex align-center width-100-p px-2'>
          <LbProgress value={Math.round(
            value,
          )} />
          <Typography variant="caption" component="div" color="textSecondary" className='pl-6'>{`${Math.round(
            value,
          )}%`}
          </Typography>
        </div>
      </Box>
    </>
  );
}

CustomStepper.propTypes = {
  value: PropTypes.number.isRequired,
  currentStepLabel: PropTypes.string.isRequired,
};

export default CustomStepper;
