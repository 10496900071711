import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import StarBorder from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  star: {
    paddingTop: "4px",
    marginLeft: "4px",
  },
  goldColor: {
    color: "#ffd819",
    stroke: "#b39500",
    border: "#e0e0e0 solid 1px",
  },
}));

export default function RatingStar(props) {
  const classes = useStyles();

  return (
    <div className={classes.star}>
      <Rating
        name="rating"
        precision={0.5}
        value={props.value}
        emptyIcon={<StarBorder fontSize="inherit" />}
      />
    </div>
  );
}
