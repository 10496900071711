import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PhotoGallery from "vendor/vendor-services/photo-gallery";
import { useTranslation } from "react-i18next";
import LbTitle from "shared/components/lb-title/lb-title";
import "home/vendor-services-list/vendor-services.scss";
import NotFound from "shared/components/not-found/not-found";
import { GAPageViewTrigger } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding:"4px 0px"
  },

  media: {
    height: 50,
    width: 50,
  },
  card: {
    margin: "5px",
    minWidth: 200,
    minHeight: 220,
  },

  uiTab: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px 10px 0px 0px",
      "& .MuiTab-wrapper > span": {
        color: "#fff !important",
        fontWeight: "600 !important",
        
      },
    },
  },
}));

function a11yProps(index) {
  return {
    id: `service-tab-${index}`,
    "aria-controls": `service-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lagan-tabpanel-${index}`}
      aria-labelledby={`lagan-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const VendorServicesList = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const [selected, setSetSelected] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const selectBtnClick = (event) => {
    setSetSelected(selected ? false : true);
  };
  useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search,"vendor-service-list");
}, []);
 
return (
    <Grid className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="vendor-services-list"
        variant="fullWidth"
        className={"customeTab " + classes.uiTab}
      >
        <Tab
          label={<LbTitle name={t("Shared.SERVICES")} color={""} size="14" />}
          {...a11yProps(0)}
        />
        <Tab
          label={<LbTitle name={t("Shared.PHOTOS")} color={""} size="14" />}
          {...a11yProps(1)}
        />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        {props?.serviceList.length ? (
          <div className="mx8y16">
            <div className="ser-title my-4">{t("Shared.OURBESTSERVICES")}</div>
            <div className="flex-w">
              <Grid container>
                {props?.serviceList?.map((service, index) => (
                  <Grid item xs={12} md={6}>
                    <div className="head-value item">
                      <img alt="tick-icon" src="./images/icons/tick.svg" />
                      {service.setting_value}
                    </div>
                    <div className="head-detail">
                      <Typography variant="body2">
                        {service.service_description}
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              {/* This Empty Div Added To Fill the gap for different screen sizes  */}
              <div className="head-value item filling-empty-space-childs">
                &nbsp;
              </div>
              <div className="head-value item filling-empty-space-childs">
                &nbsp;
              </div>
              <div className="head-value item filling-empty-space-childs">
                &nbsp;
              </div>
              <div className="head-value item filling-empty-space-childs">
                &nbsp;
              </div>
            </div>
          </div>
        ) : (
          <NotFound text={t("Shared.Review_rating.NOSERVICESADD")} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props?.apiResponse?.success ? (
          <PhotoGallery
            vendServId={props?.apiResponse?.data?.vend_serv_id}
            viewOnly={props.viewOnly}
          ></PhotoGallery>
        ) : (
          <NotFound text={t("Shared.NoImagesAdd")} />
        )}
      </TabPanel>
    </Grid>
  );
};
export default VendorServicesList;
