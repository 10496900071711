import { List, ListItem, ListItemText, Button, Chip, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultQuantity } from "core/utils/constants";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { add_bestdeal } from "shared/services/booking-service";
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  widthList: {
    width: "130px",
  },
  btnAlign: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  headAlign: {
    paddingLeft: "16px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
}));
function BestDealView({ bestDealData, handleClose, handleShowDetails }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const msgBooking = {
    msg: t("HelperMsg.BOOKINGSAVED"),
  };
  const msgBestDeal = {
    msg: t("HelperMsg.BESTDEALSAVED"),
  };
  const msgAddFail = {
    msg: t("HelperMsg.ADDFAILED"),
  };
  const [serviceList, setServiceList] = useState([]);
  const handleSave = () => {
    add_bestdeal(
      bestDealData,
      enqueueSnackbar,
      handleClose,
      msgBestDeal,
      msgAddFail
    );
    
  };

  useEffect(() => {
    setServiceList(JSON.parse(bestDealData.service_data));
  }, [bestDealData]);
  return (
    <div className={classes.paper}>
      <List dense={false}>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.CUSTOMER_NAME")}
            secondary={bestDealData?.cust_name}
          />
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.MOBILE_NO")}
            secondary={bestDealData?.cust_mob}
          />
        </ListItem>

        <div className={classes.headAlign}>
          {t("Shared.FORM.SELECTED_SERVICE_LIST")}
        </div>
        <ListItem className="wrap gap-4">
          {serviceList?.map((value, index) => (
             <Chip key={index} avatar={<Avatar style={{
              color: '#fff',
              background: '#575757'
            }}>{value?.quantity ?? defaultQuantity}</Avatar>} size="small" label={value?.setting_value} />
          ))}
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.LAGAN_DATE")}
            secondary={bestDealData?.lagan_date}
          />
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.PINCODE")}
            secondary={bestDealData?.s_zip_code}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Shared.ADDRESS")}
            secondary={`
                      ${bestDealData?.s_address}, 
                      ${bestDealData?.s_city_local},
                      ${bestDealData?.s_district}, 
                      ${bestDealData?.s_region}, 
                      ${bestDealData?.s_state},
                      ${bestDealData?.s_taluka},
                      ${bestDealData?.s_state}
                      `}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Shared.FORM.NOTES")}
            secondary={bestDealData?.notes}
          />
        </ListItem>
      </List>
      <div className={classes.btnAlign}>
        <Button
          onClick={handleShowDetails}
          type="submit"
          color="secondary"
          size="small"
          variant="contained"
        >
          {t("Shared.BACK")}
        </Button>
        <Button
          onClick={handleClose}
          type="submit"
          color="secondary"
          variant="contained"
          size="small"
        >
          {t("Shared.CANCEL")}
        </Button>
        <Button
          onClick={handleSave}
          type="submit"
          color="primary"
          variant="contained"
          size="small"
        >
          {t("Shared.SAVE")}
        </Button>
      </div>
    </div>
  );
}

export default BestDealView;
