import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import RatingAccordian from "../rating-accordian";
import Ratingfilter from "./rating-filter";
import { useState } from "react";
import { getSorting } from "core/utils/utilities";
import ReviewCard from "./review-card";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "8px 0px",
    paddingBottom:"10px !important",
  },
  boxStyle:{
    border:  theme.palette.primary.main + " solid 1px !important",
    borderRadius: "10px 10px 0px 0px"
  }
}));

export default function RatingCard(props) {
  const classes = useStyles();
  const [order, setOrder] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  function doSorting(field, order) {
    setOrder(order);
    setFilteredList(getSorting(filteredList, field, order));
  }
  useEffect(() => {
    setFilteredList(props.data);
    //  console.log(JSON.stringify(props.data));
  }, []);
  return (
    <>
      <Grid item container className={classes.root}>
        <Grid item xs={12} className={classes.boxStyle}>
          <Ratingfilter doSorting={doSorting} order={order} />
          <Divider />
          <ReviewCard></ReviewCard>
          <Divider />
          <RatingAccordian data={filteredList} />
        </Grid>
      </Grid>
    </>
  );
}
