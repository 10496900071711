import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

function LbLinearProgressWithLabel(props) {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" margin={"4px 0px 8px 0px"}>
      <Box width={"calc(100% - 134px)"} mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={134} textAlign={"end"}>
        <Typography variant="body2" color="textSecondary">
          {t("Profile_Details.PROFILE_COMPLETED")}:
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default LbLinearProgressWithLabel;
