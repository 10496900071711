import React from "react";

function LbHeading({ text, className }) {
  return (
    <>
      <h2 className={className}>{text}</h2>
    </>
  );
}

export default LbHeading;
