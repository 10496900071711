import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CallUs from "core/common/help/call-us/call-us";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { AccountCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: "0px",
    paddingLeft: "15px",
    paddingRight: "15px",
    left: "0px",
    right: "0px",
    zIndex: "11",
    backgroundColor: theme.palette.primary.main,
    "& .MuiBottomNavigationAction-root": {
      color: "#fff",
      minWidth: "70px",
    },
    "& .Mui-selected": {
      color: theme.palette.text.primary,
    },
  },
}));

export default function BottomMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("recent");
  const [openCall, setOpenCall] = React.useState(false);
  const userData = useSelector((state) => state.appData?.user);
  const favDrawer = useSelector((state) => state.appData?.favDrawer);
  const dispatch = useDispatch();

  const changeFavorite = () => {
    dispatch(appActions?.setFavDrawer(!favDrawer));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if (newValue === "call") setCallValue(!callValue);
    if (newValue === "back") history.goBack();
    if (newValue === "call") {
      setOpenCall(true);
    }
    if (newValue === "location") {
      dispatch(appActions?.setLocationDialog(true));
      dispatch(appActions?.setActiveMenu(101));
    }
    if (newValue === "account") {
      userData?.customer_id > 0
        ? history.push("/customer/profile")
        : history.push("/vendor/profile");
    }
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.root}
        showLabels
      >
        {userData?.account_id && (
          <BottomNavigationAction
            label={t("Booking_layout.PROFILE")}
            value="account"
            className={classes.lableTxt}
            icon={<AccountCircle />}
          />
        )}
        <BottomNavigationAction
          label={t("Booking_layout.FAV")}
          value="favorites"
          icon={<FavoriteIcon />}
          onClick={changeFavorite}
        />
        <BottomNavigationAction
          label={t("Shared.Side_menu.CHNG_LOCATION")}
          value="location"
          icon={<LocationOnIcon />}
        />
        <BottomNavigationAction
          label={t("Booking_layout.CALLUS")}
          value="call"
          icon={<CallUs isMobile={true} openCall={openCall} />}
        />
        <BottomNavigationAction
          label={t("Booking_layout.BACK")}
          value="back"
          icon={<RestoreIcon />}
        />
      </BottomNavigation>
    </>
  );
}
