import React from 'react';

function FacebookPlugin(props) {
    return (
        <div>
            <div className="fb-page"
                data-href="https://www.facebook.com/laganbooking"
                data-width="380"
                data-hide-cover="false"
                data-show-facepile="false"></div>
        </div>
    );
}

export default FacebookPlugin;