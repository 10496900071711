import axios from "axios";

const axiosClient = axios;

  axiosClient.defaults.baseURL = process.env.REACT_APP_API;
 // axiosClient.defaults.baseURL = "https://localhost:44322/api/";

//All request will wait 20 seconds before timeout
axiosClient.defaults.timeout = 20000;
axiosClient.defaults.withCredentials = true;
export default axiosClient;

export function getRequest(params) {
  axiosClient.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("LB_TOKEN"),
  };
  return axiosClient.get(axiosClient.defaults.baseURL + params);
}

export function postRequest(mehtod, payload) {
  axiosClient.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("LB_TOKEN"),
  };
  return axiosClient.post(axiosClient.defaults.baseURL + mehtod, payload);
}

export function patchRequest(payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteModuleRequest(params) {
  axiosClient.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("LB_TOKEN"),
  };
  return axiosClient
    .get(axiosClient.defaults.baseURL + params)
    .then((response) => response);
}

export function deleteRequest(params) {
  axiosClient.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("LB_TOKEN"),
  };
  return axiosClient
    .delete(axiosClient.defaults.baseURL + params)
    .then((response) => response);
}

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("LB_TOKEN"),
};

export function getZipcode(zipCode) {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  return axios
    .get(axiosClient.defaults.baseURL + "Common/Pincode/" + zipCode)
    .then((response) => response);
}

export function getNoBaseUrlRequest(params) {
  axiosClient.defaults.headers = {
    "Content-Type": "application/json"
  };
  return axiosClient.get(params);
}