import React from "react";
import { Bar } from "react-chartjs-2";

const Financechart = () => {
  //  defaults.font.size = 20;
  const labels = ["2005", "2006", "2007", "2008", "2009"];
  const IncomeValues = [12, 19, 23, 30, 15];
  const ExpenseValues = [22, 18, 20, 35, 25];
  return (
    <div>
      <Bar
        className="graph"
        // font
        data={{
          labels: labels,
          datasets: [
            {
              label: "Income",
              data: IncomeValues,
              backgroundColor: ["rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
            {
              label: "Expenses",
              data: ExpenseValues,
              backgroundColor: ["rgba(255, 99, 132, 1)"],
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          // scales: {
          //   yAxes: [
          //     {
          //       ticks: {
          //         beginAtZero: true,
          //       },
          //     },
          //   ],
          // },
        }}
      />
    </div>
  );
};
export default Financechart;
