import {
  createUserWithEmailAndPassword,
  deleteUser,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

import { postRequest, getRequest } from "../utils/axios-client/axios-client";
import { logOut } from "../utils/utilities";
export function register_fun(values, enqueueSnackbar, history, MSG) {
  postRequest("LbAuth/Register", values)
    .then(async (resp) => {
      if (resp?.status === 200) {
        enqueueSnackbar(MSG.registersuccessfully, {
          variant: "success",
        });
        history.push("/signin");
      } else {
        await logOut(enqueueSnackbar, MSG.registerfailed + ":" + resp?.message);
        alert(resp?.message);
        window.location.reload();
      }
    })
    .catch(async (error) => {
      console.log(error);
      await logOut(enqueueSnackbar, MSG.registerfailed + ":" + error.message);
      alert(error?.message);
      window.location.reload();
    });
}

export const emailSignUp = (auth, emailId, password) => {
  return createUserWithEmailAndPassword(auth, emailId, password);
};

export const setUpReCaptcha = (auth, phoneNumber) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    "captcha-container",
    {},
    auth
  );
  recaptchaVerifier.render();
  return signInWithPhoneNumber(auth, "+91" + phoneNumber, recaptchaVerifier);
};

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

export const signUpWithGoogle = async (auth) =>
  signInWithPopup(auth, googleProvider);

export const deleteFirebaseUser = (auth) => {
  deleteUser(auth.currentUser)
    .then(() => {
      // User deleted.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
};



