import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/free-mode';
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';

import './slider.scss';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper';

export default function ViewSlider(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <div id='slider-main-box'>
                <div className='slider-overlay'></div>
                <div className='slider-box'>
                    <div className='slider-close' onClick={() => { props.clickEvent(false) }}>x</div>
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        initialSlide={props.initialImg}
                        className="mySwiper2">
                        {props.imageList?.map((item, index) => {
                            return (
                                <SwiperSlide >
                                    <img src={item?.img +'?auto=compress&cs=tinysrgb&w=1260&h=750&lazy=load'} alt={'Slider-laganbooking images'} key={index} className="slide-card" />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="viewSlider"
                    >
                        {props.imageList?.map((item, index) => {
                            return (
                                <SwiperSlide >
                                    <img src={item?.img+'?auto=compress&cs=tinysrgb&w=320&h=360&lazy=load'} alt={'Slider-laganbooking images'} key={index} className="bottom-slide-card" />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </>
    );
}
