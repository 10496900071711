import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { defaultPageSize } from "core/utils/constants";
import { log_activity } from "./common-service";

export const add_booking = async (
  values,
  enqueueSnackbar,
  handleClose,
  refreshList,
  msgBooking,
  msgAddBooking
) => {
  postRequest("LbBooking/SaveBooking", values)
    .then((resp) => {
      if (resp?.status === 200) {
        enqueueSnackbar(msgBooking.msg, {
          variant: "success",
        });
        handleClose();
        refreshList();
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgAddBooking.msgAdd, {
        variant: "error",
      });
      // console.log(error);
    });
};
export const add_bestdeal = async (
  values,
  enqueueSnackbar,
  handleClose,
  msgAddBooking,
  msgAddFail
) => {
  postRequest("LbBooking/SaveDeal", values)
    .then((resp) => {
      if (resp?.status === 200) {
        enqueueSnackbar(msgAddBooking.msg, {
          variant: "success",
        });
        handleClose();
        log_activity("SaveDeal", "booking-service.js", "Success");
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgAddFail.msg, {
        variant: "error",
      });
      // console.log(error);
      log_activity("SaveDeal", "booking-service.js", "Exception: "+JSON.stringify(error));
    });
};

export const get_vendor_service_list = async ({
  vendorId,
  businessType,
  append,
  remove,
  enqueueSnackbar,
  msgFail,
}) => {
  getRequest(
    `LbVendorService/GetVendorService?vendorId=${vendorId}&businessType=${businessType}`
  )
    .then((resp) => {
      if (resp.status === 200) {
        let service_data = JSON.parse(resp.data.service_data);
        let data = service_data.map((r) => {
          let settingValues = {
            setting_value_id: r.setting_value_id,
            setting_value: r.setting_value,
            checked: false,
            service_description: r.service_description,
            booking_instruction: "",
          };
          return settingValues;
        });
        remove();
        append(data);
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.msg, {
        variant: "error",
      });
    });
};

export const confirm_booking = async (
  values,
  enqueueSnackbar,
  handleClose,
  handleEvent,
  msgBookingConfirm
) => {
  postRequest("LbBooking/ConfirmBooking", values)
    .then((resp) => {
      if (resp?.status === 200) {
        handleEvent(true);
        enqueueSnackbar(msgBookingConfirm.msg, {
          variant: "success",
        });
        handleClose();
        log_activity("ConfirmBooking", "booking-service.js", "Success");
      } else {
        handleEvent(false);
        log_activity("ConfirmBooking", "booking-service.js", "Not 200: "+ JSON.stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgBookingConfirm.ConfirmFail, {
        variant: "error",
      });
    });
};

export const get_deals = (
  custVendId,
  userType,
  enqueueSnackbar,
  setDisplayList,
  pageNo,
  msgDealFail
) => {
  getRequest(
    `LbBooking/GetDeals/${custVendId}?cust_vend=${userType}&pageSize=${defaultPageSize}&pageNo=${pageNo}`
  )
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        setDisplayList(resp?.data);
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgDealFail.msg, {
        variant: "error",
      });
      return false;
    });
};

export const get_deal_details = (
  userType,
  bokId,
  enqueueSnackbar,
  setDealDetails,
  setServiceList,
  msgDealFail
) => {
  getRequest(`LbBooking/GetDealDetails/${bokId}/${userType}`)
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        setDealDetails(resp?.data);
        try {
          setServiceList(JSON.parse(resp?.data?.service_data));
          log_activity("GetDealDetails", "booking-service.js", "Success ");
        } catch (error) {
          setServiceList([]);
          log_activity("GetDealDetails", "booking-service.js", "Error: "+JSON.stringify(error));
        }
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgDealFail.msg, {
        variant: "error",
      });
      log_activity("GetDealDetails", "booking-service.js", "Exception: "+JSON.stringify(error));
      return false;
    });
};

export const get_best_deals = (
  bokId,
  enqueueSnackbar,
  setBestDeal,
  msgDealFail
) => {
  getRequest(`LbCustomer/GetBestDeal/?dealId=${bokId}`)
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        setBestDeal(resp?.data);
      }
      log_activity("get_best_deals", "booking-service.jsx", "Success");
    })
    .catch((error) => {
      enqueueSnackbar(msgDealFail.msg, {
        variant: "error",
      });
      log_activity("get_best_deals", "booking-service.js", "Exception: "+JSON.stringify(error));
      return false;
    });
};

export const getZipcode = (
  zipCode,
  enqueueSnackbar,
  setListValue,
  setOpen
) => {
  if(zipCode)
  getRequest("Common/Pincode/" + zipCode)
    .then((res) => {
      if (res.status === 200 && res?.data.postOffice.length > 0) {
        // Populate city list and open the dropdown
        setListValue(res?.data?.postOffice);
        setOpen(true);
      } else {
        enqueueSnackbar("Zipcode Not found", {
          variant: "error",
        });
      }
      log_activity("getZipcode", "booking-service.js", "Success");
    })
    .catch((error) => {
      enqueueSnackbar("Zipcode Not found", {
        variant: "error",
      });
      log_activity("get_best_deals", "booking-service.js", "Exception: "+JSON.stringify(error));
    });
};

export const change_deal_status = (
  biddingId,
  customerId,
  dealStatusCode,
  enqueueSnackbar,
  msgDealFail
) => {
  return getRequest(
    `LbCustomer/ChangeDealStatus/${biddingId}/${customerId}/${dealStatusCode}`
  )
    .then((resp) => {
      if (resp.status === 200) {
        log_activity("ChangeDealStatus", "booking-service.js", "Success");
        return true;
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgDealFail.msg, {
        variant: "error",
      });
      log_activity("ChangeDealStatus", "booking-service.js", "Exception: "+JSON.stringify (error));
      return false;
    });
};
