import { makeStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import ReviewWriting from "./review-writing";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "8px 0px 8px",
  },
  ratingsize: {
    fontSize: "2rem",
  },
  subtitle: {
    fontWeight: "500",
    color: "#5e5e5e",
    fontSize: 14,
  },
}));
function ReviewCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const appData = useSelector((state) => state.appData);
  return (
    <div className={classes.root}>
      <h2>{t("Shared.Review_rating.REVIEW")}  <img width="35rem" alt="review" src="./images/icons/1/cheque.png" /></h2>
      <h2>{appData.selectedVendor?.star_rating.toFixed(1)}</h2>
      <div>
        <Rating
          className={classes.ratingsize}
          value={appData.selectedVendor?.star_rating}
          readOnly
        />
        <div className={classes.subtitle}>
          {t("Shared.Review_rating.TOTAL")} {appData.selectedVendor?.review_count} {t("Shared.Review_rating.REVIEW")}
        </div>
      </div>
      {appData?.user?.customer_id > 0 ? (
      <ReviewWriting></ReviewWriting>
      ) : (
        ""
      )}
    </div>
  );
}

export default ReviewCard;
