import { Grid, makeStyles } from "@material-ui/core";
import { SETTING_VALUE } from "core/utils/constants";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BestDeal from "shared/lb-forms/best-deal/best-deal";
import Booking from "shared/lb-forms/booking/booking";
import VendorDetailsCard from "./vendor-detail-card";
import { getLocalStorage } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  bgImageProp: {
    backgroundRepeat: "repeat",
    // backgroundSize: "cover",
    minHeight: "250px",
  },
}));

export default function CardTopPart(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [setValId, setSetValId] = useState(props.settingValueId);
  const userData = getLocalStorage("USER_DATA");
  var divStyle = {
    backgroundImage: props?.vendorDetails?.banner_img?'url("'+props?.vendorDetails?.banner_img+'")':'url("images/bg/' + setValId + '.jpg")',
  };
  return (
    <div className={classes.root}>
      <div className={[classes.bgImageProp].join(" ")} style={divStyle}>
        <Grid container>
          <Grid item xs={12} sm={8} md={4}>
            <VendorDetailsCard
              vendorDetails={props.vendorDetails}
              className="glass-effect"
            ></VendorDetailsCard>
          </Grid>
          {userData?.customer_id > 0 ? (
            <Grid item xs={12} className="flex flex-end">
              <BestDeal
                title={t("Shared.BEST_DEAL")}
                settingValueId={props.settingValueId}
                favIndex={props.favIndex}
                vendorId={props.vendorDetails?.vendor_id}
                color={"primary"}
              ></BestDeal>
              <Booking
                title={t("Shared.BOOK_NOW")}
                settingValueId={props.settingValueId}
                favIndex={props.favIndex}
                vendorId={props.vendorDetails?.vendor_id}
                refreshList={props.refreshList}
              ></Booking>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </div>
  );
}
