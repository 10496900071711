import React from 'react';
import InformationForm from '../core/common/profile/information-form';
import AddressForm from '../core/common/profile/address-form';
import ProfileSetting from 'core/common/profile/profile-setting';
import VendorInformationForm from 'core/common/profile/vendor-information-form';

const Components = {
  info: InformationForm,
  address: AddressForm,
  setting: ProfileSetting,
  vendorInfo: VendorInformationForm
};

export default (block) => {
  if (typeof Components[block.component] !== 'undefined') {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
