import { signOut } from "firebase/auth";
import { UserType, BUSINESSCATEGORYLIST, defaultQuantity } from "./constants";
import { auth } from "./firebase/firebase-config";
import { add_update_personalization } from "core/common/profile/profile_helper";

export const getLastMonths = (n) => {
  var m = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Aprl",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var months = [];
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth() + 1;

  var i = 0;
  do {
    months.push(m[parseInt((month > 9 ? "" : "0") + month)] + "-" + year);
    if (month === 1) {
      month = 12;
      year--;
    } else {
      month--;
    }
    i++;
  } while (i < n);
  return months;
};

export const getLocalStorage = (key) => {
  let data = {};
  try {
    data = JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return localStorage.getItem(key);
  }
  return data;
};

export const logOut = async (enqueueSnackbar, msg) => {
  await signOut(auth);
  localStorage.removeItem("LB_TOKEN");
  localStorage.removeItem("USER_DATA");
  localStorage.removeItem("REDUX_STATE");
  enqueueSnackbar(msg, {
    variant: "info",
  });
};

export const getMaskedPhoneNumber = (phoneNumber) => {
  const endDigits = phoneNumber.slice(6);
  return endDigits.padStart(phoneNumber.length, "*");
};

export const unAuthorizeAccess = (
  enqueueSnackbar,
  history,
  dispatch,
  appActions,
  msg
) => {
  enqueueSnackbar(msg.AUTHENTICATIONLOGINSIGNUP, {
    variant: "error",
  });
  let location = {
    pathname: "/signin",
    state: { prevRoute: history.location.pathname },
  };
  history.push(location);
  dispatch(appActions.stopLoader());
  // console.log("current path " + history.location.pathname);
};

export const getLastNYears = (n) => {
  let currentYear = new Date().getFullYear();
  let lastnYears = [currentYear];
  for (let i = 1; i <= n; i++) {
    lastnYears.push(currentYear - i);
  }
  return lastnYears;
};

export const getTodaysDate = () => {
  let month =
    new Date().getMonth() < 9
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1;

  let date =
    new Date().getDate() < 9
      ? "0" + new Date().getDate()
      : new Date().getDate();
  return new Date().getFullYear() + "-" + month + "-" + date;
};

export const getCurrentTime = () => {
  return new Date().getHours() + ":" + new Date().getMinutes();
};

export const searchFilter = (value, arraylist) => {
  let listReturn = [];
  const lowercasedValue = value.toLowerCase().trim();

  if (lowercasedValue === "") {
    listReturn = arraylist;
  } else {
    const filteredData = arraylist.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key]?.toString().toLowerCase().includes(lowercasedValue)
      );
    });
    listReturn = filteredData;
  }
  return listReturn;
};

// Sorting of data
const sorter2 = (field, orderBy) => (a, b) => {
  // console.log("a:"+JSON.stringify(a) + "b:"+JSON.stringify(b));
  // console.log("Field:"+field + "Order:"+orderBy);
  return orderBy === "AESC"
    ? a[field]?.toString()?.toLowerCase() > b[field]?.toString()?.toLowerCase()
      ? 1
      : -1
    : a[field]?.toString()?.toLowerCase() > b[field]?.toString()?.toLowerCase()
      ? -1
      : 1;
};

export const getSorting = (datalist, field, sortingOrder) => {
  return datalist.sort(sorter2(field, sortingOrder));
};

export const arrayRemoveValue = (arr, value) => {
  return arr.filter(function (ele) {
    return ele != value;
  });
};

export const getBusinessServicesSettingId = (settingValueId) => {
  let business = BUSINESSCATEGORYLIST.filter(
    (v) => v?.id === parseInt(settingValueId)
  );
  return business[0]?.service_id;
};

export const getSelectedService = (serviceList) => {
  let serviceArrayList = serviceList.filter((v) => v.checked === true);
  return JSON.stringify(serviceArrayList);
};

export const getBusinesNameBySettingValueId = (settingValueId) => {
  let business = BUSINESSCATEGORYLIST.filter(
    (v) => v?.id === parseInt(settingValueId)
  );
  return business[0]?.title;
};
export const getBusinesIconBySettingValueId = (settingValueId) => {
  let business = BUSINESSCATEGORYLIST.filter(
    (v) => v?.id === parseInt(settingValueId)
  );
  return business[0]?.icon;
};
export const getUserType = () => {
  let type = "";
  let user = getLocalStorage("USER_DATA");
  if (user.customer_id) {
    type = UserType.CUSTOMER;
  } else {
    type = UserType.VENDOR;
  }
  return type;
};

// Date format DDMMYYYY
export const dateFormat = (date) => {
  let dateReturn = null;
  if (date) {
    let setDate = new Date(date);
    dateReturn =
      setDate.getDate() +
      "-" +
      parseInt(setDate.getMonth() + 1) +
      "-" +
      setDate.getFullYear();
  }
  return dateReturn;
};

export const updatePersonalData = (
  accountId,
  dataValue,
  enqueueSnackbar,
  msg
) => {
  add_update_personalization(
    {
      account_id: accountId,
      data_value: JSON.stringify(dataValue),
    },
    enqueueSnackbar,
    msg
  );
  return "";
};

export const sortAndFilterObject = (inputObject, orderList) => {
  let objData = {};
  if (inputObject && orderList) {
    const sortedObject = {};
    orderList.forEach((key) => {
      if (inputObject.hasOwnProperty(key)) {
        if (key.charAt(0).toLowerCase() === "p") {
          sortedObject[key] = inputObject[key];
        } else {
          sortedObject["p_" + key] = inputObject[key];
        }
      }
    });
    objData = sortedObject;
  } else {
    objData = {};
  }

  return objData;
};

export const isMobileDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const GAPageViewTrigger = (location, title) => {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: location,
      title: title,
    },
  });
};

export const GAEventTrack = (category, action, label, value) => {
  window.dataLayer.push({
    event: "event",
    eventProps: {
      category: category,
      action: action,
      label: label,
      value: value,
    },
  });
};

export const getServieListHtml = (serviceList) => {

  let tempData = `<ul>${serviceList.map(value =>
    "<li><b>Service: " +
    value?.setting_value +
    ", Details: " +
    value?.service_description +
    ", Instructions: " +
    value?.booking_instruction + "</b></li>").join('')}
          </ul>`;
  return tempData;
};

export const getUrlWithToken = (websiteId) => {
  let token = getLocalStorage("LB_TOKEN");
  const redirectUrl = process.env.REACT_APP_VENDOR_WEB_DOMAIN + '/' + websiteId + '?token=' + token;
  return redirectUrl;
}