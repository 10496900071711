import { postRequest } from "core/utils/axios-client/axios-client";
import { log_activity } from "shared/services/common-service";

export const write_review_helper = async (values, enqueueSnackbar, msg) => {
  postRequest("LbVendor/SaveVendorReview", values)
    .then(resp => {
      if (resp.status === 200) {
        enqueueSnackbar(msg.ThanksReview,
          {
            variant: "success",
          }
        );
        //   history.push("/vendor/dashboard");
        //  console.log(resp);
        log_activity("SaveVendorReview", "write_review_helper.js", "Success");
      }
    })
    .catch(error => {
      enqueueSnackbar(msg.ReviewFailed, {
        variant: "error",
      });
      //   console.log(error);
      log_activity("SaveVendorReview", "write_review_helper.js", "Exception: "+JSON.stringify(error));
    });
};
