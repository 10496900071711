import { Grid,Ty } from "@material-ui/core";
import React from "react";

const Chartdata = props => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <p>{props.column[0]}</p>
        <ul>
          {props.data.map(function (val) {
            return <li key={val.data}>{val.data}</li>;
          })}
        </ul>
      </Grid>
      <Grid item xs={3}>
        <p>{props.column[1]}</p>
        <ul>
          {props.data.map(function (val) {
            return <li key={val.count}>{val.count}</li>;
          })}
        </ul>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography>Total Booking:</Typography>
        <Typography>Total Review:</Typography>
      </Grid> */}

    </Grid>
  );
};
export default Chartdata;
