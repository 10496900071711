import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  Button,
  Divider,
  Grid,
  Avatar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BUSINESSCATEGORYLIST, dealStatusCode } from "core/utils/constants";
import { useTranslation } from "react-i18next";
import {
  change_deal_status,
  get_best_deals,
} from "shared/services/booking-service";
import { useSnackbar } from "notistack";
import CardArt1 from "shared/components/card-art1";
import LbDialogTitle from "shared/components/overridden-component/lb-dialog-title";
import LbDialogContent from "shared/components/overridden-component/lb-dialog-content";
import LbDateTime from "shared/components/lb-date-time/lb-date-time";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  widthList: {
    width: "130px",
  },
  headAlign: {
    paddingLeft: "16px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
  bckColor: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  dialogContent:{
    minHeight:"30vh"
  }
}));
function BestDealList({ bookingId }) {
  const classes = useStyles();
  const [bestDeals, setBestDeals] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const msgDealFail = {
    msg: t("HelperMsg.GETDEALDETAILS"),
  };
  const [dOpen, setdOpen] = useState(false);

  const btnActionMethod = () => {
    get_best_deals(bookingId, enqueueSnackbar, setBestDeals, msgDealFail);
    setdOpen(true);
    log_activity("btnActionMethod", "best-deal-list.jsx", "View all the best deals");
  };
  const handleClose = () => {
    setdOpen(false);
  };
  const btnRejectDeal = (biddingId) => {
    change_deal_status(
      biddingId,
      userData.customer_id,
      dealStatusCode.REJECT,
      enqueueSnackbar,
      msgDealFail
    ).then((resp) => {
      //   console.log(JSON.stringify(resp));
      if (resp)
        get_best_deals(bookingId, enqueueSnackbar, setBestDeals, msgDealFail);
    });
    log_activity("btnRejectDeal", "best-deal-list.jsx", "Deal rejected");
  };
  const btnAcceptDeal = (biddingId) => {
    change_deal_status(
      biddingId,
      userData.customer_id,
      dealStatusCode.ACCEPT,
      enqueueSnackbar,
      msgDealFail
    ).then((resp) => {
      // console.log(JSON.stringify(resp));
      if (resp)
        get_best_deals(bookingId, enqueueSnackbar, setBestDeals, msgDealFail);
    });
    log_activity("btnAcceptDeal", "best-deal-list.jsx", "Deal accepted");
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => btnActionMethod()}
      >
        {t("Shared.BEST_DEAL")}
      </Button>
      <Dialog onClose={handleClose} open={dOpen} >
        <LbDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("Shared.BEST_DEAL_TITL")}
        </LbDialogTitle>
        <LbDialogContent dividers>
          <CardArt1 leftS="-8px" topS="-8px" heightS="80px" widthS="80px" />
          <CardArt1
            rotate={90}
            rightS="-8px"
            topS="-8px"
            heightS="80px"
            widthS="80px"
          />
          {/* {JSON.stringify(bestDeals)} */}
          <div className={classes.dialogContent+" p-16"}>
            {bestDeals?.length === 0 ? (
              <div>{t("BIDDING.No_DEAL_MSG")}</div>
            ) : (
              bestDeals?.map((deal, index) => (
                <List dense={false}>
                  <Avatar className={classes.bckColor} variant="square">
                    {index + 1}
                  </Avatar>
                  <ListItem>
                    <ListItemText
                      className={classes.widthList}
                      primary={t("Shared.FORM.BUSINESS_TYPE")}
                      secondary={t(
                        BUSINESSCATEGORYLIST.find(
                          (item) => item?.id === deal?.setting_value_id
                        )?.title
                      )}
                    />
                    <ListItemText
                      className={classes.widthList}
                      primary={t("Shared.FORM.DEAL_STATUS")}
                      secondary={deal?.status}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      className={classes.widthList}
                      primary={t("BIDDING.VEND_NAME")}
                      secondary={deal?.vendor_name}
                    />
                    <ListItemText
                      className={classes.widthList}
                      primary={t("Vendor.VendorDetails.MOBILE_NO")}
                      secondary={deal?.mobile_no}
                    />
                  </ListItem>
                  <div>{t("Shared.FORM.SELECTED_SERVICE_LIST")}</div>
                  <ListItem className="wrap gap-4">
                    {JSON.parse(deal?.service_data)?.map((value) => (
                      <Chip size="small" label={value?.setting_value} />
                    ))}
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      className={classes.widthList}
                      primary={t("Shared.FORM.LAGAN_DATE")}
                      secondary={<LbDateTime date={deal?.lagan_date} />}
                    />
                    <ListItemText
                      className={classes.widthList}
                      primary={t("Bidding_Details.BIDDING_AMT")}
                      secondary={"Rs. " + deal?.bid_price}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t("Shared.FORM.NOTES")}
                      secondary={deal?.note}
                    />
                  </ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Button
                        style={{zIndex:1}}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => btnRejectDeal(deal?.bidding_id)}
                      >
                        {t("BIDDING.REJ_DEAL")}
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="end">
                      <Button
                        style={{zIndex:1}}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => btnAcceptDeal(deal?.bidding_id)}
                      >
                        {t("BIDDING.ACC_DEAL")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider className="mt-5"></Divider>
                </List>
              ))
            )}
          </div>
          <CardArt1
            rotate={180}
            rightS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
          <CardArt1
            rotate={270}
            leftS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
        </LbDialogContent>
      </Dialog>
    </div>
  );
}

export default BestDealList;
