import { makeStyles } from "@material-ui/styles";
import { List, ListItem, ListItemText, Chip, Avatar } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DateTime from "shared/components/helper-component/date-time";
import { defaultQuantity } from "core/utils/constants";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  widthList: {
    width: "130px",
  },
  headAlign: {
    fontSize: "1rem",
    marginBottom: "3px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
}));
function BookingDetailView({ bookingData, serviceList, selectedVendor }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <List dense={false}>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.FIRST_NAME")}
            secondary={bookingData?.first_name}
          />
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.LAST_NAME")}
            secondary={bookingData?.last_name}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.MOBILE_NO")}
            secondary={bookingData?.cust_mob}
          />
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.BOOKING_AMT")}
            secondary={`₹ ${bookingData?.booking_amount}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.ADVANCE_AMT")}
            secondary={`₹ ${bookingData?.advance_paid}`}
          />
          <ListItemText
            className={classes.widthList}
            secondary={`₹ ${bookingData?.balance_amount}`}
            primary={t("Shared.FORM.BALANCE_AMT")}
          />
        </ListItem>

        <div className={classes.headAlign}>
          {t("Shared.FORM.SELECTED_SERVICE_LIST")}
        </div>
        <ListItem className="wrap gap-4">
          {serviceList?.map((value, index) => (
            <Chip
              key={index}
              avatar={
                <Avatar
                  style={{
                    color: "#fff",
                    background: "#575757",
                  }}
                >
                  {value?.quantity ?? defaultQuantity}
                </Avatar>
              }
              size="small"
              label={value?.setting_value}
            />
          ))}
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.LAGAN_DATE")}
            secondary={
              <DateTime dtType="short">{bookingData?.lagan_date}</DateTime>
            }
          />
          <ListItemText
            className={classes.widthList}
            primary={t("Shared.FORM.PINCODE")}
            secondary={bookingData?.s_zip_code}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Shared.ADDRESS")}
            secondary={`
                    ${bookingData?.s_address}, 
                    ${bookingData?.s_city_local},
                    ${bookingData?.s_district}, 
                    ${bookingData?.s_taluka},
                    ${bookingData?.s_state}
                    `}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Shared.FORM.NOTES")}
            secondary={bookingData?.notes}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Shared.FORM.VENDOR_DETAILS")}
            secondary={
              selectedVendor?.org_name +
              " - " +
              selectedVendor?.first_name +
              " " +
              selectedVendor?.last_name +
              " Mob - " +
              selectedVendor?.mobile_no
            }
          />
        </ListItem>
      </List>
    </div>
  );
}

export default BookingDetailView;
