import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import { sortAndFilterObject } from "core/utils/utilities";
const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffff",
  },
}));

export default function InformationView(props) {
  //orderList
  const { t } = useTranslation();

  let convrtedInformation = sortAndFilterObject(props?.information, props.orderList);
var info = Object.entries(convrtedInformation);
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        {info?.map((item, i) => {
          return (
            <>
              <ListItem className="pr-0" key={i}>
                <ListItemAvatar>
                  <Avatar className={classes.bgColor}>
                    <PersonOutline style={{ color: "#fff" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={t(
                    `Profile_Details.${
                      item ? item[0]?.toLocaleUpperCase() : ""
                    }`
                  )}
                  secondary={item ? item[1] : ""}
                />
              </ListItem>
              <Divider variant="fullWidth" component="li" />
            </>
          );
        })}
      </List>
    </React.Fragment>
  );
}
