import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { Grid, Menu } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.primary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    "& .MuiListItemText-root": {
      display: "flex",
      justifyContent: "right",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    margin: "20px",
  },
  filterIcon: {
    display: "flex",
    justifyContent: "right",
    paddingRight: "10px",
    fontWeight: "500",
  },
  sortMenu: {
    paddingRight: "16px !important",
  },
}));

const Ratingfilter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const sortingItems = [
    { key: "star_rating", value: t("Shared.Rating_filter.RATINGS") },
    { key: "created_at", value: t("Shared.Rating_filter.REVIEW_DATE") },
    { key: "cust_name", value: t("Shared.Rating_filter.REVIEWER_NAME") },
    { key: "number_of_likes", value: t("Shared.Rating_filter.LIKES") },
    { key: "shares", value: t("Shared.Rating_filter.SHARES") },
  ];
  const [sortingOrder, setSortingOrder] = useState("AESC");
  function sortBy(sortingOrder) {
    if (sortingOrder === "AESC") {
      setSortingOrder("DESC");
    } else {
      setSortingOrder("AESC");
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    log_activity("handleClick", "rating-filter.jsx", "Succes :  "+event.currentTarget);
  };

  const handleClose = (field, order) => {
    setAnchorEl(null);
    sortBy(order);
    props.doSorting(field, order);
  };

  return (
    <div className="mt-6">
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} className={classes.filterIcon} onClick={handleClick}>
          <div>{t("Shared.Review_rating.SORTBY")}</div> <TuneRoundedIcon />
        </Grid>
      </Grid>
      <Menu
        id="sorting-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sortingItems.map((item, index) => {
          return (
            <MenuItem
              key={item.key}
              onClick={() => handleClose(item.key, sortingOrder)}
              className={classes.sortMenu}
            >
              {item.value}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
export default Ratingfilter;
