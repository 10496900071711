import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Business,
  BusinessCenter,
  Email,
  History,
  MobileScreenShare,
  PersonOutline,
  PhoneAndroid,
  Security,
} from "@material-ui/icons";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import BusinessChip from "shared/components/business-chip";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  large: {
    backgroundColor: theme.palette.primaryLight.main,
  },
}));

export default function VendorInformationView(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const handelClick = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <List>
        <ListItem className="pr-0">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <PersonOutline color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.P_FIRST_NAME")}
            secondary={props?.information?.first_name}
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <PersonOutline color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.P_LAST_NAME")}
            secondary={props?.information?.last_name}
          />
        </ListItem>
        <ListItem className="pr-0">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <PhoneAndroid color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.P_MOBILE_NO")}
            secondary={props?.information?.mobile_no}
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <MobileScreenShare color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.ALT_MOBILE_NO")}
            secondary={props?.information?.alt_mobile_no}
          />
        </ListItem>
        <ListItem className="pr-0">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <Business color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.ORG_NAME")}
            secondary={props?.information?.org_name}
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <History color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.ESTD")}
            secondary={props?.information?.estb_year}
          />
        </ListItem>
        <ListItem className="pr-0">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <Security color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.GST_NO")}
            secondary={props?.information?.gst_numb}
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <Security color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.GOVT_REG_NO")}
            secondary={props?.information?.govt_reg_numb}
          />
        </ListItem>
        <ListItem className="pr-0">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <BusinessCenter color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Shared.FORM.BUSINESS_TYPE")}
            secondary={
              <BusinessChip
                settingValueId={props?.information?.setting_value_id}
              />
            }
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <Email color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Profile_Details.P_EMAIL_ID")}
            secondary={props?.information?.email_id}
          />
        </ListItem>
        <ListItem className="pr-0 gridBGC">
          <ListItemAvatar>
            <Avatar className={classes.large}>
              <PersonOutline color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Vendor.VendorDetails.PROFILE_TEXT")}
            secondary={props?.information?.profile_text}
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
