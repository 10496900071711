import {
  Container,
  List,
  ListItemText,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import React from "react";
import LbTitle from "shared/components/lb-title/lb-title";

const useStyles = makeStyles((theme) => ({
  heading1: {
    //   lineHeight:"12px"
  },
  imageIcon: {
    height: "100%",
    width: "100%",
  },
}));
const ContactUs = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <LbTitle size="22" name="Contact Us"></LbTitle>
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Pgone nunber"
                src={`/images/icons/1/phone-call.png`}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Phone Numbers"
              secondary="+91 93-190-120-98  and  +91 63-632-151-71"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                src={`/images/icons/1/address.png`}
                alt="Address"
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Address"
              secondary="PG-II, Greater Noida(West), UP - 201306"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={`/images/icons/1/send.png`} alt="category"></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="E-mail"
              secondary="lagan.booking@gmail.com"
            />
          </ListItem>
        </List>
      </Container>
    </>
  );
};
export default ContactUs;
