// configureStore.js

import { createStore } from "redux";
import rootReducer from "../reducers";

const persistedState = localStorage.getItem("REDUX_STATE")
  ? JSON.parse(localStorage.getItem("REDUX_STATE"))
  : {};
  if(persistedState.appData)
  persistedState.appData.loaderCount = 0;
const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => {
  let storeData = store.getState()
  // storeData.appData.loaderCount = 0;
  localStorage.setItem("REDUX_STATE", JSON.stringify(storeData));
});

export default store;
