import { Button, Checkbox, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { postRequest } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LbTitle from "shared/components/lb-title/lb-title";
import { get_vendor_images } from "vendor/services/service_manage_helper";
import FileUpload from "./file-upload/file-upload";
import Tooltip from "@material-ui/core/Tooltip";
import ImageModal from "shared/image-modal";
import { log_activity } from "shared/services/common-service";
import ViewSlider from "shared/slider/view-slider";

const useStyles = makeStyles((theme) => ({
  img: {
    height: "200px",
    width: "100%",
    cursor: "pointer"
  },
  iconAbs: {
    position: "absolute",
    top: "8px",
    right: "8px",
    color: "red",
  },
  noImage: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 500,
    color: "#9b9b9b",
    margin: "50px 0 80px 0",
  },
}));
export default function PhotoGallery(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [apiResponse, setApiResponse] = React.useState([]);
  const [listImgIds, setListImgIds] = React.useState("");
  const { t } = useTranslation();
  const [removedImages, setRemovedImages] = React.useState([]);
  const [orgImageList, setOrgImageList] = React.useState([]);
  const [initialImg, setInitialImg] = React.useState(0);
  const [closeOpen, setCloseOpen] = React.useState(false);
  const [viewImageList, setViewImageList] = React.useState([]);

  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
  }
  const msgFile = {
    msgFile: t("HelperMsg.FILEUPLOAD"),
  }
  const uploadedFilePaths = (uploadedFiles) => {
    var vendServImages = {
      vendServId: props.vendServId,
      images: uploadedFiles,
      removedImgIds: "",
      imgIds: listImgIds,
    };

    postRequest("LbVendorService/SaveVendorServiceImages", vendServImages)
      .then((resp) => {
        if (resp?.status === 200) {
          get_vendor_images(setApiResponse, props.vendServId, enqueueSnackbar,msgFail);
          log_activity("SaveVendorServiceImages", "photo-gallery.jsx", "Success: Imaged uploaded");
        }
      })
      .catch((error) => {
        enqueueSnackbar(msgFile.msgFile, {
          variant: "error",
        });
        log_activity("SaveVendorServiceImages", "photo-gallery.jsx", "Error: "+JSON.stringify(error));
      });
  };

  // Delete Images
  const deleteImages = (index) => () => {
    const cloneOrgImageList = [...orgImageList];
    cloneOrgImageList[index].checked = !cloneOrgImageList[index].checked;
    setOrgImageList(cloneOrgImageList);
    const indexValue = cloneOrgImageList[index].image_id;
    if (!cloneOrgImageList[index].checked) {
      removedImages.splice(removedImages.indexOf(indexValue), 1);
    } else {
      removedImages.push(cloneOrgImageList[index].image_id);
    }
  };

  const saveDeletedPhoto = () => {
    var vendServImages = {
      vendServId: props.vendServId,
      images: [],
      removedImgIds: removedImages.toString(),
      imgIds: listImgIds,
    };
    postRequest("LbVendorService/SaveVendorServiceImages", vendServImages)
      .then((resp) => {
        if (resp?.status === 200) {
          get_vendor_images(setApiResponse, props.vendServId, enqueueSnackbar,msgFail);
          log_activity("SaveVendorServiceImages", "photo-gallery.jsx", "Success: updated deleted images");
        }
      })
      .catch((error) => {
        enqueueSnackbar(msgFile.msgFile, {
          variant: "error",
        });
        get_vendor_images(setApiResponse, props.vendServId, enqueueSnackbar,msgFail);
        log_activity("SaveVendorServiceImages", "photo-gallery.jsx", "Exception: "+JSON.stringify(error));
      });
  };

  useEffect(() => {
    get_vendor_images(setApiResponse, props.vendServId, enqueueSnackbar,msgFail);
  }, []);

  useEffect(() => {
    //****logic used to segrigate the Uploaded img ids in string****
    let arrayList = [];
    apiResponse.forEach((item) => {
      arrayList.push(item.image_id);
    });
    setListImgIds(arrayList.join());
    setOrgImageList(apiResponse);
    setRemovedImages([]);
    //**** End here****
    //**** Prepare image list to display on click 
    let newImgArray = apiResponse.map((item) => {
      return { img: process.env.REACT_APP_SERVER_PATH + item.img_path }
    });
    setViewImageList(newImgArray);
    //**** End Prepare image list
  }, [apiResponse]);

  const clickEvent = (event) => {
    setCloseOpen(false);
  };

  const getImage = (event) => {
    setCloseOpen(true);// open slider 
    setInitialImg(event);// Set clicked image Slider
  };
  return (
    <div>
      <div className="flex space-between">
        {props.viewOnly ? (
          ""
        ) : (
          <>
            <LbTitle size="18" name={t("Shared.PHOTOS")} weight="600"></LbTitle>
            <div className="flex">
              {removedImages?.length > 0 && (
                <div className="mr-2">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={saveDeletedPhoto}
                  >
                    {t("Shared.DELETE")}
                  </Button>
                </div>
              )}
              <FileUpload uploadedFilePaths={uploadedFilePaths} uploadedFileCount={orgImageList.length}></FileUpload>
            </div>
          </>
        )}
      </div>
      <div>
        <Grid container spacing={1}>
          {orgImageList.length > 0 ? (
            orgImageList.map((item, index) => (
              <Grid key={index} item xs={6} md={3}>
                <div className="relative">
                  <img
                    onClick={() => getImage(index)}
                    src={process.env.REACT_APP_SERVER_PATH + item.img_path}
                    alt={item?.name}
                    className={classes.img}
                  />
                  {props.viewOnly ? (
                    ""
                  ) : (
                    <Tooltip title="Delete">
                      <Checkbox
                        checked={item.checked}
                        onClick={deleteImages(index)}
                        className={classes.iconAbs}
                      />
                    </Tooltip>
                  )}
                </div>
              </Grid>
            ))
          ) : (
            <div className={classes.noImage}>
              <h3>{t("Shared.ADDIMAGESHERE")}</h3>
            </div>
          )}
        </Grid>
        {
          closeOpen ? <ViewSlider clickEvent={clickEvent} initialImg={initialImg} imageList={viewImageList}></ViewSlider> : ''
        }
      </div>
    </div>
  );
}
