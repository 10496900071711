import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { getLocalStorage } from "core/utils/utilities";
import { Box, CardHeader, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getBiidingList } from "vendor/services/vendor_helper";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { getSorting } from "core/utils/utilities";
import { getRequest } from "core/utils/axios-client/axios-client";
import { defaultPageSize } from "core/utils/constants";
import LbCard from "shared/components/lb-card/lb-card";
import DateTime from "shared/components/helper-component/date-time";
import NotFound from "shared/components/not-found/not-found";
import LbAvtarName from "shared/components/lb-avtar-name/lb-avtar-name";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
  subTitle: {
    fontWeight: 600,
    marginRight: 8,
    color: "gray",
    minWidth: "125px",
    display: "inline-block",
  },
  iconColor: {
    color: "#7058e7",
    marginRight: 8,
  },
  header: {
    height: "50px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffff",
  },
}));

function VendorBiddingDealList(props) {
  const classes = useStyles();
  const [displayList, setDisplayList] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let user = getLocalStorage("USER_DATA");
  const msg = {
    failedmsg: t("HelperMsg.GETCAMERADEAL"),
  };

  useEffect(() => {
    getBiidingList(
      user.vendor_id,
      user.setting_value_id,
      setDisplayList,
      enqueueSnackbar,
      msg
    );
  }, []);
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [filteredList, setFilteredList] = useState(displayList);
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [searchList, setSearchList] = useState([]);

  const getbiddingdeal = () => {
    let pageNo = initialPageNo ? initialPageNo : 1;
    getRequest(
      `LbBidding/GetBiddings/${user.vendor_id}?settingValueId=${user.setting_value_id}&pageSize=${defaultPageSize}&pageNo=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          //****** Pagination load Data logic start ****
          let addMoreData = [];
          addMoreData.push(...displayList, ...resp.data);
          setInitialPageNo(pageNo + 1);
          //****** Pagination load Data logic End ****
          setDisplayList(addMoreData);
          setSearchList(addMoreData);
          setFilteredList(addMoreData);
          //  console.log("hii",resp?.data)
          if (resp?.data?.length === 0)
            setNoRecordMsg(t("Vendor.VendorDetails.NO_RECORD_MSG"));
          else setNoRecordMsg("");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.GETVENDORLISTFAILED"), {
          variant: "error",
        });
      });
  };
  useEffect(() => {
    getbiddingdeal();
  }, []);

  const loadMore = () => {
    getbiddingdeal();
    log_activity("loadMore", "vendor-bidding-deal-list.jsx", "Success");
  };
  return (
    <>
      <Box m={1} className={classes.root}>
        <LbHeading text={t("Shared.BIDDING")} />
        <Grid container spacing={1}>
          {displayList.map((data, index) => (
            <Grid item xs={12} md={4} lg={3} xl={3} key={index}>
              <LbCard style={{minHeight:'22vh'}}>
              <div className={classes.header} >
                <LbAvtarName
                  firstName={""}
                  lastName={""}
                  index={index}
                  src={`/images/icons/1/balloons.png`}
                  variant="round"
                  txtCSS={"text-white"}
                />
              </div>
              <Divider className={classes.divider} light />
                <div className="m-4">
                  <span className={classes.subTitle}>
                    {t("Bidding_Details.BIDDING_AMT")}
                  </span>
                  :<span className="ml-4"> ₹ {data.bid_price}</span>
                </div>
                <div className="m-4">
                  <span className={classes.subTitle}>
                    {t("Bidding_Details.BIDDING_STATUS")}
                  </span>
                  :<span className="ml-4">{data.status}</span>
                </div>
                <div className="m-4">
                  <span className={classes.subTitle}>
                    {t("Camera.Booking.LAGAN_DATE")}
                  </span>
                  :
                  <span className="ml-4">
                    <DateTime dtType="short">{data.lagan_date}</DateTime>
                  </span>
                </div>
                <div className="m-4 flex">
                  <span className={classes.subTitle}>
                    {t("Shared.Sorting_menu.ADDRESS")}
                  </span>
                  :
                  <span className="ml-4">
                    {data.address}, {data.city_local}, {data.taluka},{" "}
                    {data.district}, {data.state}, Pin:
                    {data.zip_code}
                  </span>
                </div>
              </LbCard>
            </Grid>
          ))}
          <Grid container>
            <div className="m-auto">
              {displayList.length === 0 ? <NotFound /> : ""}
              {displayList.length === 12 ? (
                <Button
                  className="my-8"
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={loadMore}
                >
                  {t("Shared.SHOWRESULT")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default VendorBiddingDealList;
