import React from 'react';
import { Card, CardContent, CardMedia, Radio } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './template.scss';
import TemplateView from 'shared/template/template-view';
import { useFormContext } from 'react-hook-form';


function Template({ data, value, isDefault, setSelectedItem }) {
    const [open, setOpen] = React.useState(false);
    const { register, setValue, formState: { errors } } = useFormContext();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

        setSelectedItem(1)
    };
    const handleOpen = () => {
        setOpen(false);
        setSelectedItem(data.id);
        setValue("templateName",data.name)
    }
    const handleChange = () => {

    }

    return (
        <>
            <div className='relative'>
                <div
                    className='radio-sel'>
                    <Radio
                        checked={isDefault}
                        onChange={handleChange}
                        value="a"
                        name="radio-button-demo"
                        color="primary"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </div>
                <Card className={''}>
                    <div className='img-temp' style={{ backgroundImage: `url(/images/template/${data?.img})` }}>

                    </div>
                </Card>
                <div className='icons'>
                    <VisibilityIcon className='icon-color' onClick={handleClickOpen} />
                </div>
                <div className='layer'></div>
            </div>
            <TemplateView handleClose={handleClose} open={open} data={data} value={value} handleOpen={handleOpen}></TemplateView>

        </>
    );
}

export default Template