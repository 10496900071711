import { Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LbTitle from "shared/components/lb-title/lb-title";

function FinancialDetail({ register, errors }) {
  const { t } = useTranslation();
  return (
    <div>
      <LbTitle
        name={t("Shared.FORM.FINANCIAL_DETAILS")}
        size="16"
        weight="600"
      ></LbTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            className="width-100-p"
            id="booking_amount"
            name="booking_amount"
            variant="outlined"
            {...register("booking_amount")}
            label={t("Shared.FORM.TOTAL_BOOKING_AMT")+' *'}
            error={!!errors?.booking_amount}
            helperText={errors?.booking_amount?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            id="advance_paid"
            className="width-100-p"
            name="advance_paid"
            variant="outlined"
            {...register("advance_paid")}
            label={t("Shared.FORM.ADVANCED_PAID")+' *'}
            error={!!errors?.advance_paid}
            helperText={errors?.advance_paid?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            id="notes"
            multiline={true}
            minRows={3}
            label={t("Shared.FORM.NOTES")}
            {...register("notes")}
            type="text"
            className="width-100-p"
            name="notes"
            variant="outlined"
            error={!!errors?.notes}
            helperText={errors?.advance_paid?.notes}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default FinancialDetail;
