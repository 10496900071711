import React from "react";
import "./new-landing.scss";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

function LandingSlider(props) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ width: "100%" }}>
        <Carousel
          className="slidecontainer"
          infiniteLoop={true}
          autoPlay={true}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
        >
          <div
            className="slide-img"
            style={{ backgroundImage: "url('/images/kalash.jpg')" }}
          >
            <div className="book-container overlay " style={{ width: "310px" }}>
              <div className="book-title">{t("Landing_Page.SLIDE_TITLE1")}</div>
              <div className="book-sub-title">
                {t("Landing_Page.SLIDE_SUB_TITLE1")}
              </div>
              <Button
                className="book-btn"
                style={{ backgroundColor: "#ea4c62", color: "#fff" }}
                href="/vendor-list?settingValueId=11"
              >
                {t("Landing_Page.BOOK_NOW")}
              </Button>
            </div>
          </div>
          <div
            className="slide-img"
            style={{ backgroundImage: "url('/images/invitations.jpg')" }}
          >
            <div className="book-container overlay " style={{ width: "310px" }}>
              <div className="book-title">
                {" "}
                {t("Landing_Page.SLIDE_TITLE2")}
              </div>
              <div className="book-sub-title">
                {t("Landing_Page.SLIDE_SUB_TITLE2")}
              </div>
              <Button
                className="book-btn"
                style={{ backgroundColor: "#ea4c62", color: "#fff" }}
                href="/vendor-list?settingValueId=13"
              >
                {t("Landing_Page.BOOK_NOW")}
              </Button>
            </div>
          </div>
          <div
            className="slide-img"
            style={{ backgroundImage: "url('/images/fireworks.png')" }}
          >
            <div className="book-container overlay " style={{ width: "310px" }}>
              <div className="book-title">
                {" "}
                {t("Landing_Page.SLIDE_TITLE3")}
              </div>
              <div className="book-sub-title">
                {t("Landing_Page.SLIDE_SUB_TITLE3")}
              </div>
              <Button
                className="book-btn"
                style={{ backgroundColor: "#ea4c62", color: "#fff" }}
                href="/vendor-list?settingValueId=5"
              >
                {t("Landing_Page.BOOK_NOW")}
              </Button>
            </div>
          </div>
          <div
            className="slide-img"
            style={{ backgroundImage: "url('/images/bg/tent-house.jpg')" }}
          >
            <div className="book-container overlay " style={{ width: "310px" }}>
              <div className="book-title">{t("Landing_Page.SLIDE_TITLE4")}</div>
              <div className="book-sub-title">
                {t("Landing_Page.SLIDE_SUB_TITLE4")}
              </div>
              <Button
                className="book-btn"
                style={{ backgroundColor: "#ea4c62", color: "#fff" }}
                href="/vendor-list?settingValueId=4"
              >
                {t("Landing_Page.BOOK_NOW")}
              </Button>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default LandingSlider;
