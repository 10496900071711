import { getRequest } from "core/utils/axios-client/axios-client";

export const chart_helper = (
  vendorId,
  dataType,
  enqueueSnackbar,
  setData,
  msg
) => {
  getRequest(
    "LbVendor/GetVendorStats?vendorId=" + vendorId + "&dataType=" + dataType
  )
    .then((resp) => {
      if (resp?.status === 200) {
        setData(resp.data);
        //  {console.log("hiii",ChartDataType)}
      } else {
        enqueueSnackbar(msg.Success, {
          variant: "error",
        });
        //   console.log(JSON.Stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.Fail, {
        variant: "error",
      });
    });
};
