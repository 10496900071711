import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    placeItems: "center",
    justifyContent: "center",
    height: "100vh",
    "@media (max-width: 600px)": {
        height: "70vh",
    }
  },
  img: {
    width: "250px",
    margin: "auto",
    "@media (max-width: 600px)": {
        width: "150px",
    }
  },
  circle: {
    border: "2px solid #7058e7",
    borderRadius: "50%",
    height: "360px",
    width: "360px",
    display: "flex",
    // Mobile
    "@media (max-width: 600px)": {
      height: "260px",
      width: "260px",
    },
  },
});
function LbLogo() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src="/images/LB_Squre_logo.png"
        alt="Lagan Booking logo"
        className={classes.img}
      />
    </div>
  );
}

export default LbLogo;
