import React, { useEffect } from "react";
import { Avatar, Box } from "@material-ui/core";
import LbProfileBody from "shared/components/lb-profile-body/lb-profile-body";
import { makeStyles } from "@material-ui/styles";
import LocationDialog from "shared/location-dialog/location-dialog";
import PublicIcon from '@material-ui/icons/Public';
import { GAPageViewTrigger, getUrlWithToken, getUserType } from "core/utils/utilities";
import { useSelector } from "react-redux";
import { UserType } from "core/utils/constants";

const useStyles = makeStyles((theme) => ({
  editBox: {
    margin: "8px 0px 20px 0px",
  },
  avtar: {
    position: "relative",
    top: "-110px",
    backgroundColor: "#00000052",
    paddingTop: "5px",
  },
  img: {
    height: "25vh",
    width: "100%",
    backgroundColor: theme.palette.primaryLight.main,
  },
  profileBody: {
    marginTop: "-90px",
  },
  propdetail: {
    alignItems: "start",
    color: "#fff",
    paddingLeft: "14px",
    display: "inline-flex",
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: 700,
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: 500,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#ffff",
    marginRight: "10px",
    marginBottom: "5px",
  },
  banner: {
    height: "40vh",
    "@media (max-width: 600px)": {
      height: "30vh",
    },
  },
  webColor: {
    color: '#362399'
  }
}));

function VendorProfile() {
  const classes = useStyles();
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const appData = useSelector((state) => state.appData);
  const redirectUrl = getUrlWithToken(appData?.profileData?.website_id);
  const vendorMenu = [
    {
      icon: "info",
      menuName: "Profile_Details.INFORMATION",
      isDisabled: false,
      components: {
        _uid: "X1JAfdsZxs",
        component: "vendorInfo",
      },
    },
    {
      icon: "receipt_long",
      menuName: "Profile_Details.ADDRS_DETAILS",
      isDisabled: false,
      components: {
        _uid: "X1JAfdsZxe",
        component: "address",
      },
    },
    {
      icon: "settings",
      menuName: "Profile_Details.STNG",
      isDisabled: false,
      components: {
        _uid: "X1JAfdsZxt",
        component: "setting",
      },
    },
  ];
  useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search, "vendor-profile");
  }, []);
  return (
    <div className="bg-w">
      <Box className={classes.banner}>
        <div className={classes.img}></div>
        <Box className={classes.avtar + ' flex space-between flex-align-center'}>
          <div className={classes.propdetail}>
            <div>
              <Avatar
                alt={userData?.first_name + " " + userData?.last_name}
                src="#"
                className={classes.large}
              />
            </div>
            <div>
              <div className={classes.title}>
                {userData?.first_name} {userData?.last_name}
              </div>
              <div className={classes.subTitle}>{userData?.mobile_no}</div>
            </div>
          </div>
          <div>
            {getUserType() === UserType.VENDOR &&
              <a className={classes.webColor + " flex space-between flex-align-center"} href={redirectUrl} target="_blank">
                <PublicIcon fontSize="medium" />&nbsp;
                <div className={classes.title + " pr-8"}>{userData?.website_id}</div>
              </a>
            }
          </div>
        </Box>
      </Box>
      <Box className={classes.profileBody}>
        <LbProfileBody menuList={vendorMenu}></LbProfileBody>
      </Box>
      {userData?.location_id === 0 && <LocationDialog></LocationDialog>}
    </div>
  );
}

export default VendorProfile;
