import React from "react";
import LbCard from "./lb-card/lb-card";
import { Grid, Icon, makeStyles } from "@material-ui/core";
import BestDeal from "shared/lb-forms/best-deal/best-deal";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "core/utils/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "200px",
    color: theme.palette.background.default,
  },
}));
function LbBestDealAd(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const userData = getLocalStorage("USER_DATA");

  return (
    <LbCard>
      <Grid container className={classes.root} spacing={2}>
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          xl={10}
          className="align-item-center center"
        >
          <Icon style={{ fontSize: props.fontSize }}>{props.iconName}</Icon>
          {props.text}
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          lg={2}
          xl={2}
          className="align-item-center center"
        >
          {userData?.customer_id > 0 ? (
            <BestDeal
              title={t("Shared.BEST_DEAL")}
              settingValueId={props?.settingValueId}
              vendorId={props?.vendorId}
              color={"secondary"}
            ></BestDeal>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </LbCard>
  );
}

export default LbBestDealAd;
