import { makeStyles } from "@material-ui/styles";
import React from "react";
import LbTitle from "shared/components/lb-title/lb-title";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { log_activity } from "shared/services/common-service";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primaryLight.main,
    padding: "32px 12px",
  },
  block: {
    display: "flex",
    justifyContent: "center",
  },
  ul: {
    paddingLeft: "8px",
    marginTop: "16px",
    "& li": {
      color: theme.palette.primary.main,
      fontWeight: "500",
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
      margin: "4px 0px",
    },
  },
  white: {
    color: "#fff",
    marginTop: "38px",
    marginLeft: "10px",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    top: "0px",
    left: "3px",
    backgroundColor: theme.palette.primary.main,
    width: "calc(100% - 6px)",
    height: "calc(100% - 6px)",
    zIndex: 1,
    borderRadius: "12px",
  },
  img: {
    zIndex: 10,
    position: "inherit",
    height: "188px",
  },
}));
function DownloadApp(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const downloadClick = () => {
    log_activity("downloadClick", "download-app.jsx", "App download clicked");
  };

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div>
          <LbTitle
            // size="20"
            name={t("Shared.App_download.TITLE")}
            weight="700"
          ></LbTitle>
          <ul className={classes.ul}>
            <li>
              <FavoriteIcon /> {t("Shared.App_download.POINT1")}
            </li>
            <li>
              <FavoriteIcon /> {t("Shared.App_download.POINT2")}
            </li>
            <li>
              <FavoriteIcon /> {t("Shared.App_download.POINT3")}
            </li>
          </ul>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.white}
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.laganbooking.mobileweb"
            onClick={downloadClick}
          >
            {t("Shared.App_download.BUTTON")}
          </Button>
        </div>
        <div className="text-center">
          <div className={classes.relative}>
            <img
              src="./images/mobile.png"
              className={classes.img}
              alt="app download"
            />
            <div className={classes.absolute}>&nbsp;</div>
          </div>
          <img
            src="./images/social/android.png"
            style={{ height: "35px" }}
            alt="android download"
          />
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
