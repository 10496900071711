import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import SideMenu from "./side-menu";

const drawerWidth = 250;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

export default function MenuDrawer(props) {
  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor={props.anchor}
        open={props.state[props.anchor]}
        onClose={props.toggleDrawer(props.anchor, false)}
        className={classes.drawer}
      >
        <SideMenu
          anchor={props.anchor}
          toggleDrawer={props.toggleDrawer}
        ></SideMenu>
      </Drawer>
    </>
  );
}
