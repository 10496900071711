import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider } from "@material-ui/core";
import LbAvtarName from "shared/components/lb-avtar-name/lb-avtar-name";
import LbCard from "shared/components/lb-card/lb-card";
import LbDateTime from "shared/components/lb-date-time/lb-date-time";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReceiptIcon from "@material-ui/icons/Receipt";
import NoteIcon from "@material-ui/icons/Note";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import BookingDetails from "./booking-details";
import ConfirmBooking from "shared/components/confirm-booking";
import LbStatus from "shared/components/lb-status/lb-status";
import TodayIcon from "@material-ui/icons/Today";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
    padding: "9px",
    margin: "-8px -8px 0px",
    fontWeight: "500",
    fontSize: "17px",
    backgroundColor: theme.palette.primary.main,
    minHeight:"40px"
  },
}));

export default function CustomerBookingCard({ item, index }) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const currentDate = new Date().toLocaleDateString();
  const handleSelect = (item) => {
    history.push("/customer/booking-view");
  };
  return (
    <React.Fragment>
      <LbCard key={index}>
        <div className={"main-sd"}>
          <div className={classes.title}>{item?.value?item?.value:"Lagan Booking"}</div>
          <div className="flex space-between align-baseline my-4">
            <LbAvtarName
              firstName={item?.first_name}
              lastName={item?.last_name}
              index={index}
              src={`/images/icons/1/bouquet.png`}
              variant="round"
            />
            <LbStatus
              confirmedVendor={item?.confm_by_vendor}
              confirmedUser={item?.confm_by_customer}
            ></LbStatus>
          </div>
          <Divider variant="" />
          <div className="flex space-between dashed-hr">
            <div className="align-item-center">
              <NoteIcon className="mr-8" />{" "}
              {t("Vendor.VendorDetails.BOOKING_AMT")}
            </div>
            <div>
              <span>₹ {item?.booking_amount}</span>
            </div>
          </div>
          <div className="flex space-between dashed-hr">
            <div className="align-item-center">
              <AccountBalanceWalletIcon className="mr-8" />{" "}
              {t("Vendor.VendorDetails.BALANCE_AMT")}
            </div>
            <div>
              <span>₹ {item?.balance_amount}</span>
            </div>
          </div>
          <div className="flex space-between dashed-hr">
            <div className="align-item-center">
              <ReceiptIcon className="mr-8" />{" "}
              {t("Vendor.VendorDetails.ADVANCED_PAID")}
            </div>
            <div>
              <span>₹ {item?.advance_paid}</span>
            </div>
          </div>
          {/* <LbDateTime date={item?.lagan_date} /> */}
          <div className="flex space-between dashed-hr">
            <div className="align-item-center">
              <TodayIcon className="mr-8" />{" "}
              {t("Vendor.VendorDetails.BOOKING_DATE")}
            </div>
            <div>
              <span>{currentDate}</span>
            </div>
          </div>
          <div className="flex space-between dashed-hr">
            <div className="align-item-center">
              <TodayIcon className="mr-8" /> {t("Shared.FORM.LAGAN_DATE")}
            </div>
            <div>
              <LbDateTime date={item?.lagan_date} />
            </div>
          </div>
          <div className="flex flex-end gap-4 mt-4">
            {item.confm_by_customer ? (
              ""
            ) : (
              <ConfirmBooking bookingId={item?.booking_id} />
            )}
            <BookingDetails bookingId={item?.booking_id} />
          </div>
        </div>
      </LbCard>
    </React.Fragment>
  );
}
