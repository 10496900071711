import { makeStyles } from "@material-ui/core";
import { Favorite, FavoriteBorderTwoTone } from "@material-ui/icons";
import { updatePersonalData } from "core/utils/utilities";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";

const useStyles = makeStyles((theme) => ({
  prFav: {
    paddingRight: "24px",
  },
}));
export default function LbFavorite (props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userPerData = useSelector((state) => state?.appData?.userPerData);
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const { enqueueSnackbar } = useSnackbar();
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
  };

  const changeFavorite = (favStatus) => {
    if (favStatus) {
      userPerData?.data_value?.userFavorite.push({
        vendor_id: props.data?.vendor_id,
        mobile_no: props.data?.mobile_no,
        setting_value_id: props.data?.setting_value_id,
        first_name: props.data?.first_name,
        last_name: props.data?.last_name,
        estb_year: props.data?.estb_year,
        is_verified: props.data?.is_verified,
        location_id: props.data?.location_id,
        org_name: props.data?.org_name,
        profile_text: props.data?.profile_text,
        review_count: props.data?.review_count,
        star_rating: props.data?.star_rating,
        zip_code: props.data?.zip_code,
        email_id: props.data?.email_id,
        business_type_id: props.settingValueId,
      });
      userPerData.account_id = userData?.account_id;
    } else {
      userPerData?.data_value?.userFavorite.splice(
        0,
        userPerData?.data_value?.userFavorite.length,
        ...userPerData?.data_value?.userFavorite.filter(
          (item) => item.business_type_id !== props?.settingValueId && item.vendor_id !== props?.data?.vendor_id
        )
      );
    }
    dispatch(appActions.setUserPerData(userPerData));
    //  console.log(userFavorites);
    if (userData?.account_id > 0)
      updatePersonalData(
        userData?.account_id,
        userPerData?.data_value,
        enqueueSnackbar,
        msg
      );
  };
  return (
    <div className={"right " + classes.prFav}>
      {userPerData?.data_value?.userFavorite.filter(
        (item) => item.vendor_id === props.data.vendor_id && item.business_type_id === props.settingValueId
      ).length > 0 ? (
        <Favorite
          color={"secondary"}
          style={{fontSize: 30}}
          onClick={() => {
            changeFavorite(false);
          }}
          className="pointer"
        />
      ) : (
        <FavoriteBorderTwoTone
          color={""}
          style={{fontSize: 30}}
          onClick={() => {
            changeFavorite(true);
          }}
          className="pointer"
        />
      )}
    </div>
  );
}
