import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { Avatar, Divider } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px",
  },
  title: {
    fontWeight: "500",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  rating: {
    fontSize: "1.25rem",
  },
  date: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#7c7c7c",
  },
  imageIcon: {
    backgroundColor:theme.palette.primary.main,
  },
}));
export default function RatingAccordian(props) {
  const classes = useStyles();
  return (
    <>
      {props?.data?.map((item, index) => {
        return (
          <>
            <div className={classes.root} key={index}>
              <div className="flex space-between my-4">
                <div className="flex algin-center gap-8 ">
                  <Avatar alt="Customer Name" className={classes.imageIcon} src="/images/icons/2/couple_new.png"/>
                 <div>
                    <div className={classes.title}>{item?.cust_name}</div>
                    <Rating
                      className={classes.rating}
                      name="read-only"
                      value={item?.star_rating}
                      readOnly
                     // icon={<img alt="rating_icons" src="/images/icons/1/heart.png" />}
                    />
                  </div>
                </div>
                <div className={classes.date}>
                  {dayjs(item?.created_at).format("DD/MM/YY")}
                </div>
              </div>
              <div>{item?.message}</div>
            </div>
            <Divider variant="middle" />
          </>
        );
      })}
    </>
  );
}
