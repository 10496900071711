import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CardContent, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { useFormContext } from 'react-hook-form';
import renderTextField from '../../core/common/common_render_text_field';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
const useStyles = makeStyles({
    root: {
        minWidth: "100%",
    },
    title: {
        fontSize: '1rem',
        fontWeight: "600",
        marginTop: '8px',
        '@media (max-width: 600px)': {
            fontSize: "0.75rem",
        },
    },
    pos: {
        margin: "10px",
        width: '96%'
    }, 
    chip: {
        margin: '5px'
    },
});

export default function FamilyVenueForm() {
    const { t } = useTranslation();
    const { id } = useParams();
    const appData = useSelector((state) => state.appData);
    const WebInviteData = appData.WebInviteUpdate;  
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const { register, setValue, formState: { errors } } = useFormContext();
    const [familyMemberNames, setFamilyMemberNames] = useState([]);

    useEffect(() => {
        if(WebInviteData&&id==='update'){
            if (WebInviteData && WebInviteData.members_on_invite) {
                setFamilyMemberNames(WebInviteData.members_on_invite.split(","));
            }
            setValue('guardianName', WebInviteData?.invited_by);
            setValue('devotional', WebInviteData?.devine_quote);
            setValue('address', WebInviteData?.invitee_address);
            setValue('venueAddress', WebInviteData?.event_venue);
            setValue('inviteMessage',WebInviteData?.custom_invite_msg)
        }

    }, [WebInviteData]);

    const handleNameChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleAddName = () => {
        if (inputValue.trim() !== '') {
            const updatedNames = [...familyMemberNames, inputValue.trim()];
            setFamilyMemberNames(updatedNames);
            setValue('familyMemberNames', updatedNames);
            setInputValue('');
        }
    };

    const handleDeleteName = (index) => {
        const updatedNames = [...familyMemberNames];
        updatedNames.splice(index, 1);
        setFamilyMemberNames(updatedNames);
        setValue('familyMemberNames', updatedNames);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAddName();
        }
    };

  

    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}> {t("WebInvite.FAMILYMEMBER")}:</Typography>
                                <Grid className='flex align-baseline wrap'>
                                    {familyMemberNames.map((name, index) => (
                                        <Chip
                                            key={index}
                                            label={name}
                                            onDelete={() => handleDeleteName(index)}
                                            variant="outlined"
                                            className={classes.chip}
                                        />
                                    ))}
                                    <TextField
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                        margin="dense"
                                        size='small'
                                        value={inputValue}
                                        onChange={handleNameChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {renderTextField(t("WebInvite.DEVOTIONAL"), 'devotional', errors, register, 2)}
                            </Grid>
                            <Grid item xs={12}>
                                {renderTextField(t("WebInvite.WEDDINGADDRESS"), 'venueAddress', errors, register, 2)}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}> {t("WebInvite.INVITEDETAILS")}: </Typography>
                                {renderTextField(t("WebInvite.GuardianName"), 'guardianName', errors, register)}
                            </Grid>
                            <Grid item xs={12}>
                                {renderTextField(t("WebInvite.INVITEMESSAGE"), 'inviteMessage', errors, register, 2)}
                            </Grid>
                            <Grid item xs={12}>
                                {renderTextField(t("WebInvite.Address"), 'address', errors, register, 2)}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
