import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Container, Grid, Divider, Icon } from "@material-ui/core";
import { searchFilter } from "core/utils/utilities";
import ContactForm from "./contact-form";
import LbSearch from "shared/components/lb-search/lb-search";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import AddressView from "core/common/profile/address-view";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import NotFound from "shared/components/not-found/not-found";
import { getRequest } from "core/utils/axios-client/axios-client";
import { contactSortingFields, defaultPageSize } from "core/utils/constants";
import LbHeading from "shared/components/lb-heading/lb-heading";
import EditIcon from "@material-ui/icons/Edit";
import { log_activity } from "shared/services/common-service";
import SortingMenu from "shared/components/sorting/sorting-menu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "100%",
  },
  headM: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    textAlign: "right",
    zIndex: "1",
    position: "relative",
    top: "0rem;",
  },
  buttonRight: {
    textAlign: "right",
  },
}));

export default function ContactList() {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [editData, setEditData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const appData = useSelector((state) => state.appData);
  const vendor_id = appData.user.vendor_id;
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [contactList, setContactList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  //******Serach Result *********/
  const [searchList, setSearchList] = useState([]);
  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setFilteredList(filterdData);
  };
  const handleChange = (panel, data) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { t } = useTranslation();
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
  };
  const orderList = [
    "p_first_name",
    "p_last_name",
    "p_email_id",
    "p_mobile_no",
    "p_lagan_date",
    "p_city_local",
    "p_taluka",
    "p_zip_code",
    "p_district",
    "p_state",
  ];
  const editClick = (data) => {
    setEditData(data);
    setOpenPopup(true);
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
    setRefresh((prevRefresh) =>  getContactData());
  };
  const handlePageChange = (e) => {
    getContactData();
    log_activity("handlePageChange", "contact-list.jsx", "Success");
  };

  const getContactData = () => {
    let pageNo = initialPageNo ? initialPageNo : 1;
    getRequest(
      `LbContact/GetMyContacts?p_vendor_id=${vendor_id}&p_page_size=${defaultPageSize}&p_page_no=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          setDataLoaded(true);
          if (resp?.data?.length) {
            //****** Pagination load Data logic start ****
            let addMoreData = [];
            addMoreData.push(...contactList, ...resp.data);
            setInitialPageNo(pageNo + 1);
            //****** Pagination load Data logic End ****
            setContactList(addMoreData);
            setSearchList(addMoreData);
            setFilteredList(addMoreData);
          }else{
            setShowButton(true)
          }
          log_activity("getContactData", "contact-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.CONTACTLISTFAILED"), {
          variant: "error",
        });
        log_activity(
          "getContactData",
          "contact-list.jsx",
          "Exception : " + JSON.stringify(error)
        );
      });
  };

  useEffect(() => {
    getContactData();
  }, []);

  return (
    <Container  maxWidth="xl">
      <div className="flex space-between align-center mt-6">
        <LbHeading text={t("Shared.Side_menu.CONTACTLIST")} />
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ float: "right" }}
          onClick={editClick}
        >
          {t("Shared.ADD_CONTACT")}
        </Button>
      </div>
      <Grid container className={classes.alignment}>
        <Grid item xs className="mx-2">
          <LbSearch
            sticky={true}
            searchValue={searchValue}
            placeholder={t("Shared.SEARCH_NAME_PHONE")}
          ></LbSearch>
        </Grid>
        <Grid item>
          <SortingMenu
            data={contactList}
            updateData={setFilteredList}
            sortingFields={contactSortingFields}
          ></SortingMenu>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Grid container className="mt-6">
          <ContactForm
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            editData={editData}
            refresh={refresh}
            onClose={handlePopupClose}
          />
        </Grid>
        {filteredList?.map((data, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container>
                <Grid xs={12} sm="6" alignItems="center">
                  <Typography className={"title-case " + classes.heading}>
                    <Icon className="menu-btn mr-4">account_circle</Icon>
                    {data.p_first_name} {data?.p_last_name}
                  </Typography>
                </Grid>
                <Grid xs={12} sm="6" alignItems="center">
                  {expanded === `panel${index}` && data.p_contact_id > 0 && (
                    <Grid xs={12}>
                      <div className={classes.secondaryHeading}>
                        <EditIcon onClick={() => editClick(data)} />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid item xs="12">
                  <AddressView
                    address={data}
                    orderList={orderList}
                  ></AddressView>
                </Grid>
              </div>
            </AccordionDetails>
            <Divider variant="fullWidth" component="li" />
          </Accordion>
        ))}
        <div className="m-auto">
          {dataLoaded ? filteredList.length === 0 ? <NotFound /> : "" : ""}
          {!showButton && filteredList.length > 0 ? (
            <div className="buttonRight">
              <Button
                className="my-8 "
                dir="rtl"
                type="button"
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handlePageChange()}
              >
                {t("Shared.SHOWRESULT")}
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Container>
  );
}
