import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  Button,
  Avatar,
} from "@material-ui/core";
import React, { useState } from "react";
import { BUSINESSCATEGORYLIST, defaultQuantity } from "core/utils/constants";
import { useTranslation } from "react-i18next";
import { getUserType } from "core/utils/utilities";
import { get_deal_details } from "shared/services/booking-service";
import { useSnackbar } from "notistack";
import CardArt1 from "shared/components/card-art1";
import LbDialogTitle from "shared/components/overridden-component/lb-dialog-title";
import LbDialogContent from "shared/components/overridden-component/lb-dialog-content";
import BidSubmitDialog from "shared/bid-submit-dialog/bid-submit-dialog";
import LbDateTime from "shared/components/lb-date-time/lb-date-time";
import { log_activity } from "shared/services/common-service";
const useStyles = makeStyles((theme) => ({
  widthList: {
    width: "130px",
  },
  headAlign: {
    paddingLeft: "16px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
}));
function DealDetails({ bookingId, bidBtnShow, setRefreshBids }) {
  const classes = useStyles();
  const [dealDetails, setDealDetails] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const msgDealFail = {
    msg: t("HelperMsg.GETDEALDETAILS"),
  };
  let userTypeData = getUserType();
  const [dOpen, setdOpen] = useState(false);

  const btnActionMethod = () => {
    get_deal_details(
      userTypeData,
      bookingId,
      enqueueSnackbar,
      setDealDetails,
      setServiceList,
      msgDealFail
    );
    setdOpen(true);
    log_activity("get_deal_details", "deal_details.jsx", "View deal details");
  };
  const handleClose = () => {
    setdOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => btnActionMethod()}
      >
        {t("Shared.FORM.DETAIL_VIEW")}
      </Button>
      <Dialog onClose={handleClose} open={dOpen}>
        <LbDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("Shared.FORM.DEAL_DETAILS")}
        </LbDialogTitle>
        <LbDialogContent dividers>
          <CardArt1 leftS="-8px" topS="-8px" heightS="80px" widthS="80px" />
          <CardArt1
            rotate={90}
            rightS="-8px"
            topS="-8px"
            heightS="80px"
            widthS="80px"
          />
          <List dense={false} className="p-8">
            <ListItem>
              <ListItemText
                className={classes.widthList}
                primary={t("Shared.FORM.BUSINESS_TYPE")}
                secondary={t(
                  BUSINESSCATEGORYLIST.find(
                    (item) => item?.id === dealDetails?.setting_value_id
                  )?.title
                )}
              />
              <ListItemText
                className={classes.widthList}
                primary={t("Shared.FORM.DEAL_STATUS")}
                secondary={dealDetails?.deal_status}
              />
            </ListItem>
            <div>{t("Shared.FORM.SELECTED_SERVICE_LIST")}</div>
            <ListItem className="wrap gap-4">
              {serviceList?.map((value, index) => (
                <Chip key={index} avatar={<Avatar style={{
                  color: '#fff',
                  background: '#575757'
                }}>{value?.quantity ?? defaultQuantity}</Avatar>} size="small" label={value?.setting_value} />
              ))}
            </ListItem>
            <ListItem>
              <ListItemText
                className={classes.widthList}
                primary={t("Shared.FORM.LAGAN_DATE")}
                secondary={<LbDateTime date={dealDetails?.lagan_date} />}
              />

              <ListItemText
                className={classes.widthList}
                primary={t("Shared.FORM.PINCODE")}
                secondary={dealDetails?.s_zip_code}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("Shared.ADDRESS")}
                secondary={`
                    ${dealDetails?.s_address},
                    ${dealDetails?.s_city_local},
                    ${dealDetails?.s_district},
                    ${dealDetails?.s_taluka},
                    ${dealDetails?.s_state}
                    `}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("Shared.FORM.NOTES")}
                secondary={dealDetails?.notes}
              />
            </ListItem>
            <ListItem className="flex center">
              {bidBtnShow && userTypeData === "vendor" ? (
                <>
                  {dealDetails.deal_status === "Open" ? (
                    <BidSubmitDialog
                      deal_id={dealDetails.booking_id}
                      setRefreshBids={setRefreshBids}
                      setdOpen={setdOpen}
                    ></BidSubmitDialog>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </ListItem>
          </List>
          <CardArt1
            rotate={180}
            rightS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
          <CardArt1
            rotate={270}
            leftS="-8px"
            bottomS="-8px"
            heightS="80px"
            widthS="80px"
          />
        </LbDialogContent>
      </Dialog>
    </div>
  );
}

export default DealDetails;
