import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Carsoul from "core/common/carousel-slider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    height: "100vh",
    border: "solid",
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "black",
    flexDirection: "column",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.1),
    top: theme.spacing(-1),
    color: theme.palette.grey[500],
  },
}));

export default function ImageModal(props) {
  const classes = useStyles();
  const close = props.onClose;
  const body = (
    <div className={classes.paper}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={close}
      >
        <CloseIcon />
      </IconButton>
      <div>
        <Carsoul images={props.data}></Carsoul>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={close}
        aria-labelledby="lb-image-model-title"
        aria-describedby="lb-image-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
