import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { add_booking } from "shared/services/booking-service";
import BookingDetailView from "./booking-detail-view";
import { getNoBaseUrlRequest } from "core/utils/axios-client/axios-client";
import { daysBeforeBookingCancel, wpContentDetailUrl } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import { getServieListHtml, getTodaysDate } from "core/utils/utilities";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  widthList: {
    width: "130px",
  },
  btnAlign: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  headAlign: {
    paddingLeft: "16px",
    fontFamily: "Roboto,Raleway,Open Sans",
  },
  content: {
    textAlign: "justify",
  },
}));
function BookingView({
  bookingData,
  handleClose,
  handleShowDetails,
  refreshList,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [contract, setContract] = useState(null);
  const [contractTitle, setContractTitle] = useState("");
  const reportTemplateRef = useRef(null);
  const msgBooking = {
    msg: t("HelperMsg.BOOKINGSAVED"),
    apiFailed: t("HelperMsg.APIFALIED"),
  };
  const msgAddBooking = {
    msgAdd: t("HelperMsg.ADDBOOKING"),
  };
  const [serviceList, setServiceList] = useState([]);
  const selectedVendor = useSelector((state) => state?.appData?.selectedVendor);

  const handleSave = () => {
    bookingData.booking_contract = reportTemplateRef?.current?.innerHTML;
    add_booking(
      bookingData,
      enqueueSnackbar,
      handleClose,
      refreshList,
      msgBooking,
      msgAddBooking
    );
   // console.log(bookingData);
  };
  const getSingleBlog = (id) => {
    getNoBaseUrlRequest(wpContentDetailUrl + "/" + id)
      .then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          let contractText = t(resp.data?.content?.rendered, {
            CustomerName:
              bookingData?.first_name + " " + bookingData?.last_name,
            TodayDate: getTodaysDate(),
            VendorName:
              selectedVendor?.org_name +
              " - " +
              selectedVendor?.first_name +
              " " +
              selectedVendor?.last_name,
            LaganDate: bookingData?.lagan_date,
            VenueAndLocation:
              bookingData?.s_address +
              " " +
              bookingData?.s_city_local +
              " " +
              bookingData?.s_taluka +
              " " +
              bookingData?.s_district +
              " " +
              bookingData?.s_state +
              " " +
              bookingData?.s_zip_code,
            TotalAmount: bookingData?.booking_amount,
            AdvanceAmount: bookingData?.advance_paid,
            DaysBeforeCancel: daysBeforeBookingCancel,
            ServicesList: getServieListHtml(JSON.parse(bookingData?.service_data)),
          });
          //  console.log(contractText);
          setContract(contractText);
          setContractTitle(resp.data?.title?.rendered);
          log_activity("getSingleBlog", "content-view.jsx", "Success");
        } else {
          enqueueSnackbar(msgBooking.apiFailed, {
            variant: "error",
          });
          log_activity(
            "getSingleBlog",
            "content-view.jsx",
            "Failed: non 200 response"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(msgBooking.apiFailed, {
          variant: "error",
        });
        log_activity(
          "getSingleBlog",
          "content-view.jsx",
          "Exception: " + JSON.stringify(error)
        );
      });
  };
  

  useEffect(() => {
    setServiceList(JSON.parse(bookingData.service_data));
    getSingleBlog(115);
  }, [bookingData]);

  return (
    <div className={classes.paper}>
      <BookingDetailView
        bookingData={bookingData}
        serviceList={serviceList}
        selectedVendor={selectedVendor}
      ></BookingDetailView>
      <hr />
      <div ref={reportTemplateRef}>
        <div className="title">{contractTitle}</div>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: contract }}
        ></div>
      </div>
      <div className={classes.btnAlign}>
        <Button
          onClick={handleShowDetails}
          type="submit"
          color="secondary"
          variant="contained"
          size="small"
        >
          {t("Shared.BACK")}
        </Button>
        <Button
          onClick={handleClose}
          type="submit"
          color="secondary"
          variant="contained"
          size="small"
        >
          {t("Shared.CANCEL")}
        </Button>
        <Button
          onClick={handleSave}
          type="submit"
          color="primary"
          variant="contained"
          size="small"
        >
          {t("Shared.SAVE")}
        </Button>
      </div>
    </div>
  );
}

export default BookingView;
