import React, { useEffect,useState }  from 'react';
import LbProfileHead from 'shared/components/lb-profile-head/lb-profile-head';
import LbProfileBody from 'shared/components/lb-profile-body/lb-profile-body';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LocationDialog from 'shared/location-dialog/location-dialog';
import { useTranslation } from 'react-i18next';
import * as appActions from "redux/actions/appActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { get_information } from 'core/common/profile/profile_helper';

const useStyles = makeStyles((theme) => ({
  editBox: {
     margin: '8px 0px 20px 0px',
  },
}));

function CustomerProfile() {
  const { t } = useTranslation();
  const [information, setInformation] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const customerMenu = [
    {
      icon: 'info',
      menuName: 'Profile_Details.INFORMATION',
      isDisabled: false,
      components: {
        _uid: 'X1JAfdsZxd',
        component: 'info',
      },
    },
    {
      icon: 'receipt_long',
      menuName: 'Profile_Details.ADDRS_DETAILS',
      isDisabled: false,
      components: {
        _uid: 'X1JAfdsZxc',
        component: 'address',
      },
    },
    {
      icon: 'settings',
      menuName: 'Profile_Details.STNG',
      isDisabled: false,
      components: {
        _uid: 'X1JAfdsZxa',
        component: 'setting',
      },
    }
  ];
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED")
  }
  const getInformation = () => {
    if (userData.vendor_id > 0)
      get_information(
        userData.vendor_id,
        "Vendor",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
    else if (userData.customer_id > 0)
      get_information(
        userData.customer_id,
        "Customer",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
  };
  useEffect(() => {
   getInformation();
  }, []);
  
  const classes = useStyles();
  let userData = JSON.parse(localStorage.getItem('USER_DATA'));
  return (
    <div className='bg-w'>
      <LbProfileHead userData={information}></LbProfileHead>
      <div className={`${classes.editBox} text-center`}>
      </div>
      <Box className={classes.box}>
      <LbProfileBody menuList={customerMenu}></LbProfileBody>
      </Box>
      {userData?.location_id === 0 && <LocationDialog></LocationDialog>}
    </div>
  );
}

export default CustomerProfile;
