import React from "react";
import {
  Divider,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import * as Yup from "yup";
import { getZipcode } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import "./location-dialog.scss";
import { useTranslation } from "react-i18next";
import { add_address } from "core/common/profile/profile_helper";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage } from "core/utils/utilities";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import * as appActions from "redux/actions/appActions";
import LbDialogTitle from "shared/components/overridden-component/lb-dialog-title";
import { defaultAddress } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles({
  img: {
    width: "50px",
  },
  imgContainer: {
    textAlign: "center",
    marginBottom: "15px",
  },
  tField: {
    width: "100%",
    marginBottom: "12px",
  },
  btmTitle: {
    fontWeight: "700",
    fontSize: "15px",
    textAlign: "center",
  },
  listPadding: {
    paddingLeft: "15px !important",
    minHeight: "30px",
  },
});

export default function LocationDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  let pincodesList = {};
  const msg = {
    AddAddress: t("HelperMsg.ADDRESSADDSUCCESS"),
    Failedaddaddress: t("HelperMsg.FAILEDADDADDRESS"),
    FailedaddaddressContact: t("HelperMsg.FAILEDADDADDRESSCONTACT"),
    validation: t("Validation.ZipcodeNotFound"),
  };
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [sOpen, sSetOpen] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useSelector((state) => state.appData.user);
  const [listValue, setListValue] = React.useState([]);
  const history = useHistory();

  //Validate the form field
  const userLocationSchema = Yup.object().shape({
    zip_code: Yup.string()
      .required(t("Validation.ZIP_CODE_REQUIRED"))
      .min(6, t("Validation.MUST_BE_EXACTLY"))
      .max(6, t("Validation.MUST_BE_EXACTLY")),
    city_local: Yup.string().required(t("Validation.cityLocal")),
    state: Yup.string().required(t("Validation.STATENAME")),
    district: Yup.string().required(t("Validation.DESTRICTREQUIRED")),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(userLocationSchema) });

  const onSubmit = (values) => {
    try {
      localStorage.setItem("LB_USER_LOCATION", JSON.stringify(values));
      if (user?.account_id > 0 && user?.location_id <= 0) {
        values.zip_code = values?.zip_code.toString();
        values.address = user?.first_name + " " + user?.last_name + "-" + user?.mobile_no;
        add_address(values, enqueueSnackbar, history, msg);
      }
      dispatch(appActions?.setLocationDialog(false));
      setOpen(false);
      log_activity("onSubmit", "location-dialog.jsx", "Success");
    } catch (error) {
      enqueueSnackbar(msg.Failedaddaddress, {
        variant: "error",
      });
      log_activity("onSubmit", "location-dialog.jsx", "Exception: " + JSON.stringify(error));
    }
  }
  const callZipCodeApi = (zipcode, msg) => {
    getZipcode(zipcode)
      .then((res) => {
        if (res?.data) {
          // Populate city list and open the dropdown
          pincodesList = res?.data?.postOffice;
          // Populate city list and open the dropdown
          setListValue(res?.data?.postOffice);
          sSetOpen(true);
          setValue('city_local', '')
          //    }
        } else {
          enqueueSnackbar(msg?.validation, {
            variant: "error",
          });
          log_activity("callZipCodeApi", "location-dialog.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg?.validation, {
          variant: "error",
        });
        log_activity("callZipCodeApi", "location-dialog.jsx", "Exception: " + JSON.stringify(error));
      });
  };

  const zipCodeCallD = (zipcode) => {
    if (zipcode?.length === 6) {
      callZipCodeApi(zipcode, msg);
    }
  };

  // Location Zipcode call
  const sZipCode = (event) => {
    const zipcode = event.target.value;
    zipCodeCallD(zipcode);
  };

  const handleClose = (event, reason) => {
    let addr = getLocalStorage("LB_USER_LOCATION");
    if (addr == null || addr?.zip_code.length === 0)
      localStorage.setItem("LB_USER_LOCATION", JSON.stringify(defaultAddress));

    if (reason && reason === "backdropClick") return;
    setOpen(false);
    dispatch(appActions?.setLocationDialog(false));
  };
  const sHandleCloseDrop = () => {
    sSetOpen(false);
  };

  const sHandleOpen = () => {
    sSetOpen(true);
  };

  const cityhandleChange = (event, values) => {
    if (values) {
      //Bind To Address
      setValue("zip_code", values?.pincode);
      setValue("region", values?.region);
      setValue("district", values?.district);
      setValue("state", values?.state);
      setValue("taluka", values?.division);
      setValue("city_local", values?.name);

      if (localStorage.getItem("LB_TOKEN")?.length > 5) {
        setValue("cust_vend", user?.customer_id > 0 ? "Customer" : "Vendor");
        setValue("Id", user?.customer_id > 0 ? user?.customer_id : user.vendor_id);
      }
      log_activity("cityhandleChange", "location-dialog.jsx", "Success: " + values?.name);
    }
  };
  useEffect(() => {
    //  console.log("useEffect fired : " + props.disableClose);
    let locations = getLocalStorage("LB_USER_LOCATION");
    if (locations) {
      setValue("region", locations?.region);
      setValue("district", locations?.district);
      setValue("state", locations?.state);
      setValue("taluka", locations?.taluka);
      setValue("city_local", locations?.city_local);
      setValue("zip_code", locations?.zip_code);
    }
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="location-dialog-box"
      open={open}
      backdrop>
      <LbDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        disableClose={props.disableClose}>
        {t("Shared.Review_rating.LOCATION")}
      </LbDialogTitle>
      <Divider></Divider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="">
          <div className={classes.imgContainer}>
            <img
              src="/images/icons/1/address1.png"
              alt="location"
              className={classes.img}
            />
            <div>{t("Shared.Location_dialog.LOC_TITLE_MSG")}</div>
          </div>
          <TextField
            id="zip_code"
            size="small"
            type="number"
            label={t("Shared.Location_dialog.ENTER_PINCODE")}
            {...register(`zip_code`)}
            onChange={sZipCode}
            className={classes.tField}
            variant="outlined"
            error={!!errors.zip_code}
            helperText={errors.zip_code?.message}
          />
          <Autocomplete
            id="city_local"
            size="small"
            options={listValue}
            getOptionLabel={(option) => option?.name}
            onChange={cityhandleChange}
            className={classes.tField}
            open={sOpen}
            onClose={sHandleCloseDrop}
            onOpen={sHandleOpen}
            inputValue={watch(`city_local`)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Shared.Location_dialog.CTY")}
                variant="outlined"
                error={!!errors.city_local}
                helperText={errors.city_local?.message}
                {...register(`city_local`)}
              />
            )}
          />
          <TextField
            id="district"
            size="small"
            label={t("Shared.Location_dialog.DST")}
            {...register(`district`)}
            className={classes.tField}
            InputLabelProps={{ shrink: watch(`district`) ? true : false }}
            error={!!errors.district}
            helperText={errors.district?.message}
            variant="outlined"
          />
          <TextField
            id="state"
            size="small"
            label={t("Shared.Location_dialog.STATE")}
            {...register(`state`)}
            className={classes.tField}
            InputLabelProps={{ shrink: watch(`state`) ? true : false }}
            error={!!errors.state}
            helperText={errors.state?.message}
            variant="outlined"
          />
          <div className={classes.btmTitle}>
            {t("Shared.Location_dialog.HELP_US")}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            size="small"
            color="primary"
            autoFocus
          >
            {t("Shared.SAVE")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
