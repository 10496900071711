import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 145,
  },
  width100: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function LbLocationNew({
  listValue,
  zipCodeCallD,
  setValue,
  register,
  errors,
  clearErrors,
  watch
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const handleOnChange = (event, values) => {
    if (values && values.district && values.name && values.state && values.division) {
      setValue("s_district", values?.district);
      setValue("s_city_local", values?.name);
      setValue('s_state', values?.state);
      setValue("s_taluka", values?.division);
      if(typeof clearErrors === 'function'){
        clearErrors();
      }
    }
  };

  const handleCloseDrop = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //unsubscribe.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Condition added to check pin is 6 digit or not 
      if (name === 's_zip_code' && value?.s_zip_code.length === 6) {
        zipCodeCallD(value?.s_zip_code);
        setValue('s_city_local', '');
      }
    }
    )
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="s_address"
            size="small"
            label={t("Shared.HOUSE_NO")}
            {...register(`s_address`)}
            className={classes.width100}
            error={!!errors.s_address}
            helperText={errors.s_address?.message}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="s_zip_code"
            size="small"
            label={t("Camera.Booking.ZIP_CODE")}
            {...register(`s_zip_code`)}
            className={classes.width100}
            InputLabelProps={{ shrink: watch(`s_zip_code`) ? true : false }}
            error={!!errors.s_zip_code}
            helperText={errors.s_zip_code?.message}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="s_city_local"
            size="small"
            options={listValue}
            getOptionLabel={(option) => option?.name}
            onChange={handleOnChange}
            onClose={handleCloseDrop}
            onOpen={handleOpen}
            open={open}
            inputValue={watch(`s_city_local`)}
            getOptionSelected={(option, value) => option?.name === value.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Camera.Booking.CITY")}
                variant="outlined"
                error={!!errors.s_city_local}
                helperText={errors.s_city_local?.message}
                {...register(`s_city_local`)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="s_taluka"
            size="small"
            label={t("Camera.Booking.TEHSIL")}
            {...register(`s_taluka`)}
            className={classes.width100}
            InputLabelProps={{ shrink: watch(`s_taluka`) ? true : false }}
            error={!!errors.s_taluka}
            helperText={errors.s_taluka?.message}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="s_district"
            size="small"
            label={t("Camera.Booking.DISTRICT")}
            {...register(`s_district`)}
            className={classes.width100}
            InputLabelProps={{ shrink: watch(`s_district`) ? true : false }}
            error={!!errors.s_district}
            helperText={errors.s_district?.message}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="s_state"
            size="small"
            label={t("Camera.Booking.STATE")}
            {...register(`s_state`)}
            className={classes.width100}
            InputLabelProps={{ shrink: watch(`s_state`) ? true : false }}
            error={!!errors.s_state}
            helperText={errors.s_state?.message}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LbLocationNew;
