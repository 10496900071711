import {
  Container,
  Button,
  Grid,
  AccordionSummary,
  Accordion,
  Divider,
  Typography,
  Icon,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { useTranslation } from "react-i18next";
import LbSearch from "shared/components/lb-search/lb-search";
import { searchFilter } from "core/utils/utilities";
import NotFound from "shared/components/not-found/not-found";
import { useSelector } from "react-redux";
import { contactSortingFields, defaultPageSize } from "core/utils/constants";
import { log_activity } from "shared/services/common-service";
import {
  getRequest,
} from "core/utils/axios-client/axios-client";
import SortingMenu from "shared/components/sorting/sorting-menu";
import { useSnackbar } from "notistack";
import AddGuestListTitle from "./add_guest_list_title";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { save_guest_list } from "customer/services/guestlist-helper";
import { ListAltOutlined } from "@material-ui/icons";
import ConfirmationDialog from "./confirmation_dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "100%",
  },
  headM: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    textAlign: "right",
    zIndex: "1",
    position: "relative",
    top: "0rem;",
  },
  buttonRight: {
    textAlign: "right",
  },
  actionbtn: {
    margin: "8px",
  },
}));

const GuestListTitle = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayList, setDisplayList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState([]);
  const [initialPageNo, setInitialPageNo] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const appData = useSelector((state) => state.appData);
  const [showButton, setShowButton] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [guestIdToDelete, setGuestIdToDelete] = useState(null);
  const [title,setTitle]=useState('')
  const searchValue = (value) => {
    let filterdData = searchFilter(value, searchList);
    setFilteredList(filterdData);
  };
  const history = useHistory();

  const handleChange = (panel, data) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const editClick = (data) => {
    setEditData(data);
    setOpenPopup(true);
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    registerfailed: t("HelperMsg.REGISTERFAILED"),
    errorOccuured: t("HelperMsg.ERROROCCURED"),
    existEmailMsg: t("HelperMsg.EmailIdAlreadyExists"),
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const handlePageChange = (e) => {
    getGuestData();
    log_activity("handlePageChange", "guest-list.jsx", "Success");
  };

  const getGuestData = () => {
    let pageNo = initialPageNo ? initialPageNo : 1;
    getRequest(
      `LbGuest/GetGuestList?customer_id=${appData.user.customer_id}&page_size=${defaultPageSize}&page_no=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          setDataLoaded(true);
          if (resp?.data?.length) {
            //****** Pagination load Data logic start ****
            let addMoreData = [];
            addMoreData.push(...displayList, ...resp.data);
            setInitialPageNo(pageNo + 1);
            //****** Pagination load Data logic End ****
            setDisplayList(addMoreData);
            setSearchList(addMoreData);
            setFilteredList(addMoreData);
          } else {
            setShowButton(true);
          }
          log_activity("getContactData", "guest-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.CONTACTLISTFAILED"), {
          variant: "error",
        });
        log_activity(
          "getContactData",
          "guest-list.jsx",
          "Exception : " + JSON.stringify(error)
        );
      });
  };

  const handleDelete = (data) => {
    let payload = {
      customer_id: appData.user.customer_id,
      name: data.name,
      guest_list_id: data.guest_list_id,
      opr: 3,
    };
    save_guest_list(payload, enqueueSnackbar, msgFail);
  };

  const guestListView = (id,name) => {
    history.push("/guest-list-view/id=" + id);
    setTitle(name)
  };

  useEffect(() => {
    getGuestData();
  }, [refresh]);

  const handleDeleteConfirmation = (data) => {
    handleDelete(data);
    setConfirmationOpen(false);
  };


  const handleDeleteButtonClick = (guestId) => {
    setConfirmationOpen(true);
    setGuestIdToDelete(guestId);
  };


  return (
    <Container component="main" maxWidth="xl">
      <div className="flex space-between align-center mt-6">
        <LbHeading text={t("GuestList.GUEST_LIST")} />
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ float: "right" }}
          onClick={editClick}
        >
          {t("GuestList.NEW_GUEST_LIST")}
        </Button>
      </div>
      <Grid container>
        <Grid item xs className="mx-2">
          <LbSearch
            sticky={true}
            searchValue={searchValue}
            placeholder={t("Shared.SEARCH_NAME_PHONE")}
          ></LbSearch>
        </Grid>
        <Grid item>
          <SortingMenu
            data={displayList}
            updateData={setFilteredList}
            sortingFields={contactSortingFields}
          ></SortingMenu>
        </Grid>
      </Grid>
      <div>
        <Grid container className="mt-6">
          <AddGuestListTitle
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            editData={editData}
            refresh={refresh}
            onClose={handlePopupClose}
          />
        </Grid>

        {filteredList?.map((data, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            // onClick={() => {
            //   guestListView(data?.guest_list_id);
            // }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={8} lg={9} alignItems="center">
                  <Typography className={"title-case " + classes.heading} onClick={() => {guestListView(data?.guest_list_id,data?.name); }}>
                    <Icon className="menu-btn mr-4"><ListAltOutlined /></Icon>
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={() => editClick(data)}
                    className={classes.actionbtn}
                  >
                    {t("Shared.EDIT")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>handleDeleteButtonClick(data)}
                    className={classes.actionbtn}
                  >
                     {t("Shared.DELETE")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {guestListView(data?.guest_list_id,data?.name)}}
                    className={classes.actionbtn}
                  >
                   {t('GuestList.ADD_GUEST')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionSummary>
            <Divider variant="fullWidth" component="li" />
          </Accordion>
        ))}
        <div className="m-auto">
          {dataLoaded ? filteredList.length === 0 ? <NotFound /> : "" : ""}
          {!showButton && filteredList.length > 0 ? (
            <div className="buttonRight">
              <Button
                className="my-8 "
                dir="rtl"
                type="button"
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handlePageChange()}
              >
                {t("Shared.SHOWRESULT")}
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={isConfirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => handleDeleteConfirmation(guestIdToDelete)}
      />

    </Container>
  );
};

export default GuestListTitle;
