import React, { useState, useEffect, useCallback } from "react";
import Bookingchart from "./booking-chart";
import Bidingchart from "./biding-chart";
import Financechart from "./finance-chart";
import Querieschart from "./queries-chart";
import Chartdata from "./chart-data";
import {
  Grid,
  Box,
  Typography,
  Button,
  Hidden,
  IconButton,
} from "@material-ui/core";
import "./charts.css";
import { useTranslation } from "react-i18next";
import Booking from "shared/lb-forms/booking/booking";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { ChartDataType } from "core/utils/constants";
import { chart_helper } from "./chart_helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as appActions from "redux/actions/appActions";
import { getLocalStorage } from "core/utils/utilities";

const Charts = () => {
  const { t } = useTranslation();
  const graph = [
    { id: "1", dataof: t("Shared.CHARTS.BOOKING"), grap: <Bookingchart /> },
    { id: "2", dataof: t("Shared.CHARTS.BIDDING"), grap: <Bidingchart /> },
    { id: "3", dataof: t("Shared.CHARTS.FINANCE"), grap: <Querieschart /> },
    { id: "4", dataof: t("Shared.CHARTS.QUERIES"), grap: <Financechart /> },
  ];
  const columns = [t("Vendor.VendorDetails.DATE"), t("Shared.CHARTS.BOOKING")];
  const [modal, setModal] = useState(false);
  const [graphs, setGraph] = useState(<Bookingchart />);
  const [dataOf, statDataof] = useState(t("Shared.CHARTS.BOOKING"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  let [statdata, setData] = useState([]);
  const msg = {
    getVehicledeals: t("HelperMsg.GET_VEHICLE_DEALS"),
    Fail: t("HelperMsg.FAILLED"),
    Success: t("HelperMsg.SUCCESS"),
  };

  const businessStat = useCallback((dataType) => {
    chart_helper(
      getLocalStorage("USER_DATA").vendor_id,
      dataType,
      enqueueSnackbar,
      setData,
      // setSearchList,
      msg
    );
  }, []);

  const getGraph = (graph, dataof) => {
    setGraph(graph);
    setModal(true);
    statDataof(dataof);
    if (dataOf === t("Shared.CHARTS.BOOKING")) {
      businessStat(ChartDataType.UPCOMING_MONTHLY_BOOKINGS);
    } else if (dataOf === t("Shared.CHARTS.BIDDING")) {
      businessStat(ChartDataType.BIDDINGS_BY_BUSINESS_TYPE);
    }
    setData([]);
  };

  useEffect(() => {
    if (dataOf === t("Shared.CHARTS.BOOKING")) {
      businessStat(ChartDataType.UPCOMING_MONTHLY_BOOKINGS);
    }
  }, []);

  return (
    <Grid container>
      <Hidden smDown>
        <Grid item xs={12} sm={12} spacing={1}>
          <Box className="box3">
            <h3>{dataOf}</h3>
            <div className="box4">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Chartdata column={columns} data={statdata} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {graphs}
                </Grid>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="lb-image-model-title"
          aria-describedby="lb-image-modal-description"
        >
          <Grid item xs={12} sm={12}>
            <Box className="box3">
              <h3>{t("Shared.CHARTS.BOOKING")}</h3>
              <IconButton aria-label="close" onClick={() => setModal(false)}>
                <CloseIcon />
              </IconButton>
              <div className="box1">
                <Grid container spacing={1.5}>
                  <Grid item xs={12} sm={6}>
                    <Chartdata column={columns} data={statdata} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {graphs == "" ? <Bookingchart /> : graphs}
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Modal>
      </Hidden>
      {graph.map((item, index) => {
        return (
          <Grid item xs={12} sm={3} key={index}>
            <Box className="box2">
              <h3 key={item.id}>{item.dataof}</h3>
              <div className="box1">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    {item.grap}
                  </Grid>
                </Grid>
              </div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => getGraph(item.grap, item.dataof)}
              >
                {t("Shared.CHARTS.VIEW_DETLS")}
              </Button>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Charts;
