import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Template from 'shared/template/template';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
const useStyles = makeStyles({
    root: {
        minWidth: '90%',
    },
    title: {
        fontSize: 17,
        textAlign: 'center',
        fontWeight: '600',
        '@media (max-width: 600px)': {
            fontSize: '12px',
        },
    }, wrapper: {
        padding: '20px',
        backgroundColor: 'beige',
        width: '30%',
        height: "248px"
    }
});

export default function WebInvtePreview() {
    const { id } = useParams();
    const dataArray = [
        {
            id: 1,
            name: "Template 1",
            img: "temp1.png"
        },
        {
            id: 2,
            name: "Template 2",
            img: "temp2.png"
        },
        {
            id: 3,
            name: "Template 3",
            img: "temp3.png"
        },
        {
            id: 4,
            name: "Template 4",
            img: "temp4.png"
        },
        {
            id: 5,
            name: "Template 5",
            img: "temp5.png"
        },
        {
            id: 6,
            name: "Template 6",
            img: "temp6.png"
        },
        {
            id: 7,
            name: "Template 7",
            img: "temp7.png"
        },
    ];
    const appData = useSelector((state) => state.appData);
    const WebInviteData = appData.WebInviteUpdate;
    const classes = useStyles();
    const { setValue, getValues } = useFormContext();
    const [selectedItem, setSelectedItem] = React.useState(1);
    useEffect(() => {
        if (WebInviteData &&id==='update') {
            const filteredItem = dataArray?.find(item => item.name === WebInviteData.template_name);
            if (filteredItem) {
                setSelectedItem(filteredItem?.id);
            } 
        }
    }, [WebInviteData]);

    return (
        <>
            <div className="reviewBox">
                <Grid container spacing={2} className='p-8'>
                    {dataArray?.map((data, index) => (
                        <Grid item xs={12} md={3} key={index} alignItems="center" direction="row" spacing={2}>
                            <Template data={data} value={getValues()} isDefault={data?.id === selectedItem} setSelectedItem={setSelectedItem} />

                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    );
}
