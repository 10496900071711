import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { UserType } from "core/utils/constants";
import { auth } from "core/utils/firebase/firebase-config";
import { getLocalStorage } from "core/utils/utilities";
import { log_activity } from "shared/services/common-service";

export const add_address = async (values, enqueueSnackbar, history, msg) => {
  try {
    postRequest("LbProfile/AddAddress", values)
      .then((resp) => {
        if (resp.status === 200) {
          let user_data = getLocalStorage("USER_DATA");
          user_data.location_id = resp?.data;
          localStorage.setItem("USER_DATA", JSON.stringify(user_data));
          if (user_data.vendor_id > 0) {
            if (!user_data.setting_value_id > 0) history.push("/ownerRegist");
          }
          enqueueSnackbar(msg.AddAddress, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(msg.Failedaddaddress, {
            variant: "error",
          });
          // console.log(resp);
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.FailedaddaddressContact, {
          variant: "error",
        });
        // console.log(error);
      });
  } catch (error) {
    console.log(JSON.stringify(error));
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  }
};

export const get_address = (
  Id,
  custVend,
  enqueueSnackbar,
  dispatch,
  history,
  appActions,
  setAddress,
  msg
) => {
  getRequest("LbProfile/GetAddress?Id=" + Id + "&custVend=" + custVend)
    .then((resp) => {
      if (resp.status === 200) {
        setAddress(resp.data);
        log_activity(
          "GetAddress",
          "profile_helper.js",
          "Exception : " + JSON.stringify(resp)
        );
      } else {
        enqueueSnackbar(msg.apiFailed, {
          variant: "error",
        });
        //   console.log(JSON.stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.apiFailed, {
        variant: "error",
      });
    });
};

export const update_address = async (
  values,
  enqueueSnackbar,
  history,
  setIsEdit,
  msg
) => {
  try {
    postRequest("LbProfile/UpdateAddress", values)
      .then((resp) => {
        if (resp.status === 200) {
          if (setIsEdit) setIsEdit(false);
          enqueueSnackbar(msg.Update, {
            variant: "success",
          });
          log_activity("UpdateAddress", "profile_helper.js", "Successfull");
        } else {
          enqueueSnackbar(msg.AddressFail, {
            variant: "error",
          });
          // console.log(JSON.stringify(resp));
          log_activity(
            "UpdateAddress",
            "profile_helper.js",
            "Failed: " + JSON.stringify(resp)
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.AddressFail, {
          variant: "error",
        });
        //  console.log(error);
        log_activity(
          "UpdateAddress",
          "profile_helper.js",
          "Exception: " + JSON.stringify(error)
        );
      });
  } catch (error) {
    console.log(JSON.stringify(error));
    enqueueSnackbar(error.message, {
      variant: "error",
    });
    log_activity(
      "UpdateAddress",
      "profile_helper.js",
      "Exception1: " + JSON.stringify(error)
    );
  }
};

export const get_information = (
  Id,
  custVend,
  enqueueSnackbar,
  dispatch,
  history,
  appActions,
  setInformation,
  msg
) => {
  getRequest("LbProfile/GetUserProfile/" + Id + "/" + custVend)
    .then((resp) => {
      if (resp.status === 200) {
        if (custVend.toLowerCase() === UserType.VENDOR.toLowerCase()) {
          let user_data = getLocalStorage("USER_DATA");
          user_data.website_id = resp?.data?.website_id;
          localStorage.setItem("USER_DATA", JSON.stringify(user_data));
        }
        setInformation(resp.data);
        dispatch(appActions.profileData(resp.data));
        log_activity("GetUserProfile", "profile_helper.js", "Successfull");
      } else {
        enqueueSnackbar(msg.addressApiFailed, {
          variant: "error",
        });
        //   console.log(JSON.stringify(resp));
        log_activity(
          "GetUserProfile",
          "profile_helper.js",
          "Failed: " + JSON.stringify(resp)
        );
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.addressApiFailed, {
        variant: "error",
      });
      log_activity(
        "GetUserProfile",
        "profile_helper.js",
        "Exception: " + JSON.stringify(error)
      );
    });
};

export const update_vendor_profile = async (
  id,
  values,
  enqueueSnackbar,
  history,
  setIsEdit,
  setVendorProfile,
  msg
) => {
  try {
    postRequest("LbProfile/UpdateVendorProfile/" + id, values)
      .then((resp) => {
        if (resp.status === 200) {
          if (setIsEdit) setIsEdit(false);
          enqueueSnackbar(msg.successMsg, {
            variant: "success",
          });
          setVendorProfile(new Date());
          log_activity(
            "UpdateVendorProfile",
            "profile_helper.js",
            "Successfull"
          );
        } else {
          enqueueSnackbar(msg.apiFailed, {
            variant: "error",
          });
          //    console.log(JSON.stringify(resp));
          log_activity(
            "UpdateVendorProfile",
            "profile_helper.js",
            "Failed: " + JSON.stringify(resp)
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.apiFailed, {
          variant: "error",
        });
        //  console.log(error);
        log_activity(
          "UpdateVendorProfile",
          "profile_helper.js",
          "Exception: " + JSON.stringify(error)
        );
      });
  } catch (error) {
    //  console.log(JSON.stringify(error));
    enqueueSnackbar(error.message, {
      variant: "error",
    });
    log_activity(
      "UpdateVendorProfile",
      "profile_helper.js",
      "Exception1 : " + JSON.stringify(error)
    );
  }
};

export const update_customer_profile = async (
  id,
  values,
  enqueueSnackbar,
  history,
  setIsEdit,
  msg
) => {
  try {
    postRequest("LbProfile/UpdateCustomerProfile/" + id, values)
      .then((resp) => {
        if (resp.status === 200) {
          if (setIsEdit) setIsEdit(false);
          enqueueSnackbar(msg.successMsg, {
            variant: "success",
          });
          log_activity("UpdateCustomerProfile", "profile_helper.js", "Success");
        } else {
          enqueueSnackbar(msg.apiFailed, {
            variant: "error",
          });
          //   console.log(JSON.stringify(resp));
          log_activity(
            "UpdateCustomerProfile",
            "profile_helper.js",
            "Failed: " + JSON.stringify(resp)
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.apiFailed, {
          variant: "error",
        });
        //  console.log(error);
        log_activity(
          "UpdateCustomerProfile",
          "profile_helper.js",
          "Exception: " + JSON.stringify(error)
        );
      });
  } catch (error) {
    // console.log(JSON.stringify(error));
    enqueueSnackbar(error.message, {
      variant: "error",
    });
    log_activity(
      "UpdateCustomerProfile",
      "profile_helper.js",
      "Exception1: " + JSON.stringify(error)
    );
  }
};

export const update_user_account = async (
  isPhoneAuth,
  setIsPhoneAuth,
  setFlag,
  setShowMe,
  enqueueSnackbar,
  msg
) => {
  try {
    postRequest(`LbAuth/UpdateUserAccount?isPhoneAuth=${isPhoneAuth}`, {})
      .then((res) => {
        if (res.data) {
          let userInfo = JSON.parse(localStorage.getItem("USER_DATA"));
          userInfo.is_phone_auth = true;
          localStorage.setItem("USER_DATA", JSON.stringify(userInfo));
          setIsPhoneAuth(true);
          setFlag(false);
          setShowMe(false);
          enqueueSnackbar(msg.Account, {
            variant: "success",
          });
          log_activity("UpdateUserAccount", "profile_helper.js", "Success");
        } else {
          setFlag(false);
          setShowMe(false);
          enqueueSnackbar(msg.WRONG, {
            variant: "error",
          });
          log_activity(
            "UpdateUserAccount",
            "profile_helper.js",
            "Failed : " + JSON.stringify(res)
          );
        }
      })
      .catch((error) => {
        setIsPhoneAuth(false);
        log_activity(
          "UpdateUserAccount",
          "profile_helper.js",
          "Exception : " + JSON.stringify(error)
        );
      });
  } catch (error) {
    return false;
  }
};

export const get_user_notification = (
  accountID,
  enqueueSnackbar,
  dispatch,
  history,
  appActions,
  setInformation,
  msg
) => {
  getRequest("LbNotification/GetNotification/" + accountID + "?isRead=" + false)
    .then((resp) => {
      if (resp.status === 200) {
        setInformation(resp.data);
        dispatch(appActions.setUserNotifications(resp.data));
        log_activity("GetNotification", "profile_helper.js", "success");
      } else {
        // enqueueSnackbar(msg.Notification, {
        //   variant: "error",
        // });
        console.log(JSON.stringify(resp));
        log_activity(
          "GetNotification",
          "profile_helper.js",
          "Failed : " + JSON.stringify(resp)
        );
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.Notification, {
        variant: "error",
      });
      log_activity(
        "GetNotification",
        "profile_helper",
        "Exception : " + JSON.stringify(error)
      );
    });
};

export const get_profile_percent = (
  Id,
  custVend,
  enqueueSnackbar,
  setProfilePercent,
  msg
) => {
  getRequest("LbProfile/GetProfilePercent/" + Id + "/" + custVend)
    .then((resp) => {
      if (resp.status === 200) {
        setProfilePercent(resp?.data);
        log_activity(
          "GetProfilePercent",
          "profile_helper.js",
          "Success : " + JSON.stringify(resp)
        );
      } else {
        enqueueSnackbar(msg?.apiFailed, {
          variant: "error",
        });
        //   console.log(JSON.stringify(resp));
        log_activity(
          "GetProfilePercent",
          "profile_helper.js",
          "Error : " + JSON.stringify(resp)
        );
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg?.apiFailed, {
        variant: "error",
      });
      log_activity(
        "GetProfilePercent",
        "profile_helper.js",
        "Exception : " + JSON.stringify(error)
      );
    });
};

export const add_update_personalization = async (
  values,
  enqueueSnackbar,
  msg
) => {
  try {
    //  console.log(values);
    postRequest("LbProfile/AddUpdatePersonalization", values)
      .then((resp) => {
        if (resp.status === 200) {
          // enqueueSnackbar(msg.successMsg, {
          //   variant: "success",
          // });
          log_activity(
            "AddUpdatePersonalization",
            "profile_helper.js",
            "Success"
          );
        } else {
          // enqueueSnackbar(msg.apiFailed, {
          //   variant: "error",
          // });
          //   console.log(JSON.stringify(resp));
          log_activity(
            "AddUpdatePersonalization",
            "profile_helper.js",
            "Failed : " + JSON.stringify(resp)
          );
        }
      })
      .catch((error) => {
        // enqueueSnackbar(msg.apiFailed, {
        //   variant: "error",
        // });
        console.log(error);
        log_activity(
          "AddUpdatePersonalization",
          "profile_helper.js",
          "Exception : " + JSON.stringify(error)
        );
      });
  } catch (error) {
    // console.log(JSON.stringify(error));
    enqueueSnackbar(error.message, {
      variant: "error",
    });
    log_activity(
      "AddUpdatePersonalization",
      "profile_helper.js",
      "Exception1 : " + JSON.stringify(error)
    );
  }
};

export const delete_my_account = (accountId, enqueueSnackbar, msg) => {
  getRequest("LbAuth/DeleteAccount/" + accountId)
    .then((resp) => {
      if (resp.status === 200) {
        enqueueSnackbar(msg.DELETED, {
          variant: "warning",
        });
        auth?.currentUser
          ?.delete()
          .then(function () {
            // User deleted.
            log_activity(
              "delete_my_account",
              "profile_helper.js",
              "Firebase account deletion Successfull"
            );
          })
          .catch(function (error) {
            // An error happened.
            log_activity(
              "delete_my_account",
              "profile_helper.js",
              "Exception: " + JSON.stringify(error)
            );
          });
        log_activity(
          "delete_my_account",
          "profile_helper.js",
          "DB account deletion Successfull"
        );
      } else {
        enqueueSnackbar(msg.FAILED, {
          variant: "error",
        });
        //   console.log(JSON.stringify(resp));
        log_activity(
          "delete_my_account",
          "profile_helper.js",
          "Failed: " + JSON.stringify(resp)
        );
      }
    })
    .catch((error) => {
      enqueueSnackbar(msg.FAILED, {
        variant: "error",
      });
      log_activity(
        "delete_my_account",
        "profile_helper.js",
        "Exception: " + JSON.stringify(error)
      );
    });
};
