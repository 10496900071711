import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Chip,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { getUserType } from "core/utils/utilities";
import LbCard from "shared/components/lb-card/lb-card";
import LbAvtarName from "shared/components/lb-avtar-name/lb-avtar-name";
import LbDateTime from "shared/components/lb-date-time/lb-date-time";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import DealDetails from "./deal_details";
import { useTranslation } from "react-i18next";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { getSorting } from "core/utils/utilities";
import { getRequest } from "core/utils/axios-client/axios-client";
import { defaultPageSize } from "core/utils/constants";
import BestDealList from "./best-deal-list";
import { PhoneAndroid } from "@material-ui/icons";
import { log_activity } from "shared/services/common-service";
import ShowMoreBtn from "core/common/show-more";

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    position: "absolute",
    marginLeft: "15rem",
    marginTop:"1rem",
    width: "70px",
  },
  header: {
    height: "50px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffff",
  },
  avtTxtCss: {
    color: "#ffff",
  },

}));
export default function DealList() {
  const classes = useStyles();
  const [displayList, setDisplayList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const appData = useSelector((state) => state.appData);
  let userTypeData = getUserType();
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [filteredList, setFilteredList] = useState(displayList);
  const [initialPageNo, setInitialPageNo] = useState(1);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [refreshBids, setRefreshBids] = useState(false);

  const getbiddingdeal = (firstLoad, pageNo) => {
    getRequest(
      `LbBooking/GetDeals/${
        userTypeData === "customer"
          ? appData.user.customer_id
          : appData.user.vendor_id
      }?cust_vend=${userTypeData}&pageSize=${defaultPageSize}&pageNo=${pageNo}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          //****** Pagination load Data logic start ****
          let addMoreData = [];
          if (firstLoad) addMoreData.push(...resp.data);
          else addMoreData.push(...displayList, ...resp.data);
          //****** Pagination load Data logic End ****
          setDisplayList(addMoreData);
          setSearchList(addMoreData);
          setFilteredList(addMoreData);
          //  console.log("hii",resp)
          // console.log("hii addMoreData",addMoreData.length)

          if (resp?.data?.length === 0)
            setNoRecordMsg(t("Vendor.VendorDetails.NO_RECORD_MSG"));
          else setNoRecordMsg("");
          log_activity("GetDeals", "deal-list.jsx", "Success");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("HelperMsg.GETVENDORLISTFAILED"), {
          variant: "error",
        });
        log_activity("GetDeals", "deal-list.jsx", "Exception: "+JSON.stringify(error));
      });
  };
  useEffect(() => {
    getbiddingdeal(true, 1);
  }, [refreshBids]);
  useEffect(() => {
    doSorting(sortingField, sortingOrder);
  }, [sortingField, sortingOrder]);

  const loadMore = () => {
    setInitialPageNo(initialPageNo + 1);
    getbiddingdeal(false, initialPageNo + 1);
  };
  const doSorting = (field, sortingOrder) => {
    const sortedData = getSorting(filteredList, field, sortingOrder);
    //  console.log(filteredList.sort(sorter2(field,sortingOrder)));
    setFilteredList(sortedData);
    log_activity("doSorting", "deal-list.jsx", "Field: "+field + " , order: "+sortingOrder);
  };

  return (
    <Box m={1}>
      <LbHeading text={t("Shared.DEALLIST")} />
      <Grid container spacing={2}>
        {displayList?.map((item, index) => (
          <Grid item sm={12} md={4} className="width-100-p" key={index}>
            <LbCard style={{padding:'0px'}}>
              <div className={classes.header} >
                <LbAvtarName
                  firstName={item?.first_name}
                  lastName={item?.last_name}
                  index={index}
                  src={`/images/icons/1/balloons.png`}
                  variant="round"
                  txtCSS={"text-white"}
                />
              </div>
              <Divider className={classes.divider} light />
              <div className="flex-end">
                <img
                  className={classes.imageIcon}
                  src={`/images/icons/1/nfc.png`}
                  alt="category"
                />
              </div>
              <Grid container spacing={3} className="p-8">
                <Grid item xs={12}>
                  <Chip
                  className="mt-8"
                    icon={<PhoneAndroid />}
                    label={"Mob - " + item.mobile_no}
                    clickable
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LbDateTime date={item?.lagan_date} />
                </Grid>
                <Grid item xs={6}>
                  {userTypeData === "customer" ? (
                    <BestDealList bookingId={item.booking_id}></BestDealList>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6} align="end">
                  <DealDetails
                    bookingId={item.booking_id}
                    bidBtnShow={true}
                    setRefreshBids={setRefreshBids}
                  ></DealDetails>
                </Grid>
              </Grid>
            </LbCard>
          </Grid>
        ))}
        <div className="m-auto">
          <ShowMoreBtn dataList={displayList} displayMsg={noRecordMsg} loadMoreCbFn={loadMore}></ShowMoreBtn>
        </div>
      </Grid>
    </Box>
  );
}
