import React, { useEffect, useState } from "react";
import "./lb-profile-head.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function LbProfileHead(props) {
  const appData = useSelector((state) => state.appData);
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const { t } = useTranslation();
  return (
    <div className="profile-head">
      <div className="profile-img">
        {userData.vendor_id > 0 ? (
          <img src="/images/dummy/dummy.svg" alt="vendor default" />
        ) : (
          <img
            src={
              appData?.profileData?.profile_img === ""
                ? "/images/dummy/dummy.svg"
                : appData?.profileData?.profile_img
            }
            alt={t("HelperMsg.Customerdefault")}
          />
        )}
      </div>
      <div className="name-title">
        {userData?.first_name} {userData?.last_name}
      </div>
      <div className="email-title">{userData?.mobile_no}</div>
    </div>
  );
}

export default LbProfileHead;
