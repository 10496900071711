import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Avatar,
  Button,
  TextField,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { save_guest } from "customer/services/guestlist-helper";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { emailRegex } from "core/utils/constants";
import { AddGuestForm } from "shared/validation-schema/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneRegex } from "core/utils/constants";
import { useParams } from 'react-router-dom';






const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  top1: {
    marginTop: "1rem",
  },
}));

export default function AddGuest(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { onClose /* other props */ } = props;
  const { openPopup, setOpenPopup, editData } = props;
  const { t } = useTranslation();
  // const search = useLocation().search;
  const guest_list_id = useParams();
  const guestListId = guest_list_id.id.split('=')[1];



  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(AddGuestForm(t)),
  });;
  const handleClose = (event, reason) => {
    setOpenPopup(false);
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
    registerfailed: t("HelperMsg.REGISTERFAILED"),
    errorOccuured: t("HelperMsg.ERROROCCURED"),
    existEmailMsg: t("HelperMsg.EmailIdAlreadyExists"),
  };

  const onSubmit = (data) => {
    let payload = {
      guest_list_ids: editData.guestListId ? editData.guestListId + "," + guestListId : guestListId,
      first_name: data.first_name,
      last_name: data.last_name,
      place: data.place,
      relation: data.relation,
      mobile_no: data.mobile_no,
      e_mail: data.mail,
      guest_id: editData.guest_id ? editData.guest_id : 0,
      invitation_type: data.invitation_type,
      is_active: true,
    };
    save_guest(payload, enqueueSnackbar, msgFail);
    
    setOpenPopup(false);
    onClose();
  };


  const handleInput = (event) => {
    const input = event.target.value;
    if (!phoneRegex.test(input)) {
      event.target.setCustomValidity(t("Validation.VALIDMOBILE"));
    } else {
      event.target.setCustomValidity("");
    }
  };



  useEffect(() => {
    if (editData) {
      setValue("mail", editData.e_mail || "");
      setValue("first_name", editData.first_name || "");
      setValue("last_name", editData.last_name || "");
      setValue("mobile_no", editData.mobile_no || "");
      setValue("place", editData.place || "");
      setValue("relation", editData.relation || "");
      setValue("invitation_type", editData.invitation_type || "");
      setValue("place", editData.place || "");
    }
  }, [editData]);


  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      PaperProps={{
        style: {
          padding: "1rem",
          width: "550px", // Add the minimum width you want here
        },
      }}
    >
      <div className={classes.center}>
        <Avatar
          alt="best_deal"
          className={classes.imageIcon}
          src="/images/icons/1/nfc.png"
        />
      </div>
      <LbHeading text="ADD Guest" />
      <Divider></Divider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.top1} spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="outlined-basic"
              name="first_name"
              {...register("first_name", {
                required: { value: true, message: t("Validation.REQUIRED") },
              })}
              variant="outlined"
              className="width-100-p"
              label={t("Shared.Sorting_menu.FIRST_NAME") + "*"}
              error={!!errors?.first_name}
              helperText={errors?.first_name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="outlined-basic"
              name="last_name"
              {...register("last_name",
                {
                  required: { value: true, message: t("Validation.REQUIRED") },
                }
              )}
              variant="outlined"
              className="width-100-p"
              label={t("Shared.Sorting_menu.LAST_NAME") + "*"}
              error={!!errors?.last_name}
              helperText={errors?.last_name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="place"
              name="place"
              {...register("place")}
              variant="outlined"
              className="width-100-p"
              label={t("GuestList.PLACE")+"*"}
              error={!!errors?.place}
              helperText={errors?.place?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="relation"
              name="relation"
              select
              {...register("relation")}
              variant="outlined"
              className="width-100-p"
              label={t("GuestList.RELATION") +"*"}
              value={editData ? editData.relation : ""}
              error={!!errors.relation}
              helperText={errors.relation?.message}
            >
              <MenuItem value="relative">{t("Relation.RELATIVE")}</MenuItem>
              <MenuItem value="family">{t("Relation.FAMILY")}</MenuItem>
              <MenuItem value="friend">{t("Relation.FRIEND")}</MenuItem>
              <MenuItem value="colleague">{t("Relation.COLLEAUGE")}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="mail"
              name="mail"
              // onChange={handleChange}
              {...register("mail", {
                pattern: {
                  value: emailRegex,
                  message: t("Validation.InvalidEmailId"),
                },

              })
              }
              variant="outlined"
              className="width-100-p"
              label={t("GuestList.EMAIL")}
            />
            {errors.mail && (
              <span className="error-color">{errors.mail.message}</span>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              type="tel"
              id="mobile_no"
              label={t("Shared.FORM.MOBILE_NO")}
              className="width-100-p"
              name="mobile_no"
              variant="outlined"
              inputProps={{
                maxLength: 10,
              }}
              onChange={handleInput}
              {...register("mobile_no")}
              error={!!errors?.mobile_no}
              helperText={errors?.mobile_no?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="invitation_type"
              name="invitation_type"
              select
              {...register("invitation_type")}
              variant="outlined"
              className="width-100-p"
              label={t("GuestList.INVITATION") +"*"}
              value={editData ? editData.invitation_type : ""}
              error={!!errors.invitation_type}
              helperText={errors.relation?.invitation_type}
            >
               <MenuItem value="with family">{t("InvitationType.WITHFAMILY")}</MenuItem>
              <MenuItem value="single">{t("InvitationType.SINGLE")}</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <div align="end" className="mt-8">
          <Button
            type="button"
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleClose}
            className="mr-5"
          >
            {t("Shared.CANCEL")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
          >
            {t("Shared.CONFIRM")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
