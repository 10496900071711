import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmationDialog from './confirmation_dialog';
import { ArrowDropDown, ArrowDropUp, ArrowUpward, DeleteForeverOutlined, Edit, InsertInvitationRounded, MoreVert, Send } from '@material-ui/icons';
import InvitationDialog from './send-invite-form';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  sortIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    fontWeight: 'bold',
    boxShadow:"0px 0px 4px gray"
  },
  boldHeader: {
    fontWeight: 'bold',
    
  },
  zebraRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover, // Change this to the desired color
    },
  },
  // menu: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: theme.shape.borderRadius,
  //   boxShadow: theme.shadows[3],
  // },
  menuItem: {
    marginTop:"12px",
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function GuestListTable({ data, onEdit, onDelete }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [guestIdToDelete, setGuestIdToDelete] = useState(null);
  const [isInvitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);


  // Function to open the invitation dialog
  const handleOpenInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const toggleRow = (index) => {
    const currentIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];
    if (currentIndex === -1) {
      newExpandedRows.push(index);
    } else {
      newExpandedRows.splice(currentIndex, 1);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((row) => row.guest_id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };


  const handleClick = (event, guestId) => {
    const selectedIndex = selected.indexOf(guestId);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(guestId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteConfirmation = (guestId) => {
    onDelete(guestId);
    setConfirmationOpen(false);
  };


  const handleDeleteButtonClick = (guestId) => {
    setConfirmationOpen(true);
    setGuestIdToDelete(guestId);
  };


  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig && sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' ? (
        <span className={classes.sortIcon}><ArrowDropUp /></span>
      ) : (
        <span className={classes.sortIcon}><ArrowDropDown /></span>
      );
    }
    return null;
  };

  const handleMenuOpen = (event, rowId) => {
    event.stopPropagation(); // Prevent event propagation
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const handleEdit = (data) => {
    handleMenuClose();
    onEdit(data);
  };

  const handleItemClick = (event, action, data) => {
    event.stopPropagation(); // Stop event propagation

    switch (action) {
      case 'edit':
        handleEdit(data);
        break;
      case 'delete':
        handleDeleteButtonClick(data);
        break;
      case 'invite':
        handleOpenInvitationDialog();
        break;
      default:
        break;
    }
    // Close the menu
    handleMenuClose();
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-label="guest list table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < data.length}
                    checked={selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all guests' }}
                  />
                </TableCell>
                <TableCell className={classes.boldHeader} onClick={() => handleSort('first_name')}>
                  {t("Shared.Sorting_menu.FIRST_NAME")} {renderSortIcon('first_name')}
                </TableCell>
                <TableCell className={classes.boldHeader} onClick={() => handleSort('last_name')}>
                  {t("Shared.Sorting_menu.LAST_NAME")} {renderSortIcon('last_name')}
                </TableCell>
                <TableCell className={classes.boldHeader} onClick={() => handleSort('place')}>
                  {t("GuestList.PLACE")} {renderSortIcon('place')}
                </TableCell>
                <TableCell className={classes.boldHeader} onClick={() => handleSort('relation')}>
                  {t("GuestList.RELATION")} {renderSortIcon('relation')}
                </TableCell>
                <TableCell className={classes.boldHeader} onClick={() => handleSort('invitation_type')}>
                  {t("GuestList.INVITATION")} {renderSortIcon('invitation_type')}
                </TableCell>
                <TableCell className={classes.boldHeader}>Actions</TableCell>
                <TableCell className={classes.boldHeader}>More Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const isItemSelected = isSelected(row.guest_id);
                const isRowExpanded = expandedRows.includes(index);
                return (
                  <>
                    <TableRow
                      key={row.id}
                      hover
                      className={classes.zebraRow}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': `guest-checkbox-${row.guest_id}` }}
                          onClick={(event) => handleClick(event, row.guest_id)}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                        />
                      </TableCell>
                      <TableCell>{row.first_name}</TableCell>
                      <TableCell>{row.last_name}</TableCell>
                      <TableCell>{row.place}</TableCell>
                      <TableCell>{row.relation}</TableCell>
                      <TableCell>{row.invitation_type}</TableCell>
                      {/* <TableCell style={{width:"326px"}}>
                        <Button color='primary' onClick={() => onEdit(row)}><Edit/></Button>
                        <Button color='secondary' onClick={() => handleDeleteButtonClick(row.guest_id)}><DeleteForeverOutlined/></Button>
                        <Button color="default" onClick={handleOpenInvitationDialog}>
                        <Send />
                       </Button>
                        <Button color='default' onClick={() => toggleRow(index)}  >{isRowExpanded ? <ArrowDropUp /> : <ArrowDropDown />}</Button>
                      </TableCell> */}
                      <TableCell>
                        <MoreVert onClick={(event) => handleMenuOpen(event, row.guest_id)} />
                        <Menu
                          id="row-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl) && selectedRowId === row.guest_id}
                          onClose={handleMenuClose}
                          className={classes.menu}
                        >
                          <MenuItem
                            onClick={(event) => handleItemClick(event, 'edit', row)}
                            className={classes.menuItem}
                          >
                            <Edit /> Edit
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => handleItemClick(event, 'delete', row.guest_id)}
                            className={classes.menuItem}
                          >
                            <DeleteForeverOutlined /> Delete
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => handleItemClick(event, 'invite')}
                            className={classes.menuItem}
                          >
                            <Send /> Invite
                          </MenuItem>
                        </Menu>

                      </TableCell>
                      <TableCell>
                        <Button color='default' onClick={() => toggleRow(index)}  >{isRowExpanded ? <ArrowDropUp /> : <ArrowDropDown />}</Button>
                      </TableCell>
                    </TableRow>
                    {isRowExpanded && (
                      <>
                        <TableRow>
                          <TableCell colSpan={8}>Email:-{row.e_mail}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8}>Mobile no:-{row.mobile_no}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        open={isConfirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => handleDeleteConfirmation(guestIdToDelete)}
      />
      <InvitationDialog open={isInvitationDialogOpen} onClose={() => setInvitationDialogOpen(false)} />
    </div>
  );
}

GuestListTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
