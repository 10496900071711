import * as yup from "yup";
import { AccTermValidate,inviteMessageValidate, AddressValidate, dateValidate, CustVendValidate, EmailValidate, NumberValidate, PasswordValidate, PhoneValidate, StringNoReqValidate, StringValidate, ZipcodeValidate, EmailNoReqValidate, PhoneNoReqValidate } from "./validation-names";

const addressControls = (t) => {
    return {
        s_address: AddressValidate(t),
        s_zip_code: ZipcodeValidate(t),
        s_city_local: StringValidate(t),
        s_taluka: StringValidate(t),
        s_state: StringValidate(t),
        s_district: StringValidate(t)
    }
};

const bookingAmtControls = (t) => {
    return {
        booking_amount: NumberValidate(t),
        advance_paid: NumberValidate(t),
    }
}

const userDetailsControls = (t) => {
    return {
        first_name: StringValidate(t),
        last_name: StringValidate(t),
        lagan_date: dateValidate(t),
        cust_mob: PhoneValidate(t),
    }
}

export const AddressFormSchema = (t) => {
    return yup.object().shape({
        ...addressControls(t)
    });
}

export const BookSchema = (t) => {
    return yup.object().shape({
        ...userDetailsControls(t),
        ...addressControls(t),
        ...bookingAmtControls(t),
        service_data: yup.array()
            .test("at-least-one-checked", "Select at least one service", (value) =>
                value.some((service) => service.checked)
            )
            .of(
                yup.object().shape({
                    checked: yup.boolean(),
                    quantity: yup.number().positive("Quantity must be positive")
                })
            )

    });
}

export const DealSchema = (t) => {
    return yup.object().shape({
        ...userDetailsControls(t),
        ...addressControls(t),
        service_data: yup.array()
            .test("at-least-one-checked", "Select at least one service", (value) =>
                value.some((service) => service.checked)
            )
            .of(
                yup.object().shape({
                    checked: yup.boolean(),
                    quantity: yup.number().positive("Quantity must be positive")
                })
            )
    });
}

export const brideGroomSchema = (t) => {
    return yup.object().shape({
        brideName: StringValidate(t),
        brideMother: StringValidate(t),
        brideFather: StringValidate(t),
        brideGrandmother: StringValidate(t),
        brideGrandfather: StringValidate(t),
        weddingDate: dateValidate(t),
        groomName: StringValidate(t),
        groomMother: StringValidate(t),
        groomFather: StringValidate(t),
        groomGrandmother: StringValidate(t),
        groomGrandfather: StringValidate(t),
        tilakSangeetDate: dateValidate(t),
    })
}
export const tempInvite = (t) => {
    return yup.object().shape({
        inviteMessage: inviteMessageValidate(t),
        guardianName: StringValidate(t),
        address: AddressValidate(t),
        // venueAddress: StringValidate(t),
        // devotional: StringValidate(t)
    })
}
export const familyVenue = (t) => {
    return yup.object().shape({
        venueAddress: AddressValidate(t),
        devotional: StringValidate(t),
        inviteMessage: inviteMessageValidate(t),
        guardianName: StringValidate(t),
        address: AddressValidate(t),
    })
}

export const webPreview = (t) => {
    return yup.object().shape({
        template: StringValidate(t)
    });
}
export const ContactFormSchema = (t) => {
    return yup.object().shape({
        ...userDetailsControls,
        ...addressControls
    });
}

export const RegisterSchema = (t) => {
    return yup.object().shape({
        first_name: StringValidate(t),
        last_name: StringNoReqValidate(t),
        email_id: EmailValidate(t),
        mobile_no: PhoneValidate(t),
        pwd: PasswordValidate(t),
        cust_vend: CustVendValidate(t),
        tnc: AccTermValidate(t),
    });
}


export const AddGuestForm = (t) => {
    return yup.object().shape({
        first_name: StringValidate(t),
        last_name: StringValidate(t),
        place:  StringValidate(t),
        relation:  StringNoReqValidate(t),
        mail: EmailNoReqValidate(t),
        // mobile_no:PhoneNoReqValidate(t),
        invitation_type: StringNoReqValidate(t), 
    });
}

export const AddGuestListForm = (t) => {
    return yup.object().shape({
        name: StringValidate(t),
    });
}

