import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import './template.scss';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    imgbg: {
        // marginTop: '6rem',
        height: '100%',
        width: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
        // backgroundPositionY: 'center'
    },
    alignDetail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%',
        width: '100%',
        margin: 'auto',
        textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px #fafafa'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemplateView({ handleClose, open, data, value, handleOpen }) {
    const { t } = useTranslation();
    const dateTilak = new Date(value.tilakSangeetDate);
    const weddingDate = new Date(value.weddingDate)
    const classes = useStyles();
    const dataS = {
        "groomName": value.groomName,
        "groomMother": value.groomMother,
        "groomFather": value.groomFather,
        "groomGrandmother": value.groomGrandmother,
        "groomGrandfather": value.groomGrandfather,
        "tilakSangeetDate": dateTilak.toLocaleDateString('en-GB'),
        "brideName": value.brideName,
        "brideMother": value.brideMother,
        "brideFather": value.brideFather,
        "brideGrandmother": value.brideGrandmother,
        "brideGrandfather": value.brideGrandfather,
        "weddingDate": weddingDate.toLocaleDateString('en-GB'),
        "devotional": value.devotional,
        "venueAddress": value.venueAddress,
        "guardianName": value.guardianName,
        "inviteMessage": value.inviteMessage,
        "address": value.address,
        "template": value.template
    }

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>

                        </Typography>
                       { handleOpen !==undefined?<Button autoFocus color="inherit" onClick={handleOpen}>
                            {t("WebInvite.SELECT")}
                        </Button>:''}
                    </Toolbar>
                </AppBar>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ backgroundImage: `url('/images/template/${data?.img}')` }} className={classes.imgbg + ' relative'}>
                        <div className={classes.alignDetail}>
                            <img className="sticker" src={`/images/template/sticker/g1.png`} />
                            <div className='fonts-2 invite' style={{ fontSize: '1.8rem' }}>{dataS.inviteMessage}</div>
                            <div>
                                <div className='sub'>
                                    <div>{t("WebInvite.GROOMFATHER")}: {dataS.groomFather}</div>
                                    <div>{t("WebInvite.GROOMMOTHER")}: {dataS.groomMother}</div>
                                    <div>{t("WebInvite.GROOMGRANDFATHER")}: {dataS.groomGrandfather}</div>
                                    <div>{t("WebInvite.GROOMGRANDMOTHER")}: {dataS.groomGrandmother}</div>
                                </div>
                                <div className='main fonts-2'>{dataS.groomName}</div>
                            </div>
                            <div className='font-size-2rem fonts-4'>Weds</div>
                            <div>
                                <div className='main fonts-2'>{dataS.brideName}</div>
                                <div className='sub'>
                                    <div>{t("WebInvite.BRIDEFATHER")}: {dataS.brideFather}</div>
                                    <div>{t("WebInvite.BRIDEMOTHER")}: {dataS.brideMother}</div>
                                    <div>{t("WebInvite.BRIDEGANDFATHER")}: {dataS.brideGrandfather}</div>
                                    <div>{t("WebInvite.BRIDEGRANDMOTHER")}: {dataS.brideGrandmother}</div>
                                </div>
                            </div>
                            <div className='fonts-2 on'>on</div>
                            <div className='date'>
                                <div>{t("WebInvite.TILALDATE")}: {dataS.tilakSangeetDate}</div>
                                <div>{t("WebInvite.WEDDINGDATE")} :{dataS.weddingDate}</div>
                            </div>
                            <div className='sub'>
                                <div>{dataS.venueAddress}</div>
                                <div>{dataS.address}</div>
                            </div>
                            <div className='fonts-4 devotional'>{dataS.devotional}</div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
