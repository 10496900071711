import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import ErrorPage from "./core/common/error/error-page";
import { custRoutes, openRouts, vendorRoutes } from "./core/utils/constants";
import { useTranslation } from "react-i18next";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const user = useSelector(state => state.appData.user);
  const { t } = useTranslation();
  // console.log(JSON.stringify(user));
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          {openRouts.indexOf(rest.path) > -1 ? (
            <Component {...props} />
          ) : user ? (
            user?.vendor_id > 0 && vendorRoutes.indexOf(rest.path) > -1 ? (
              <Component {...props} />
            ) : user?.customer_id > 0 && custRoutes.indexOf(rest.path) > -1 ? (
              <Component {...props} />
            ) : (
              <ErrorPage message={t("HelperMsg.ERRORUNATHORIZE")}></ErrorPage>
            )
          ) : (
            <>
              <ErrorPage message={t("HelperMsg.ERRORLOGIN")}></ErrorPage>
            </>
          )}
        </Layout>
      )}
    />
  );
};

export default AppRoute;
