import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { log_activity } from "./common-service";

export const get_notification_list = (
  receiverAccountId,
  setApiResponse,
  enqueueSnackbar,
  msgFail
) => {
  // console.log(receiverAccountId);
  getRequest(`LbNotification/GetAllNotification/${receiverAccountId}`)
    .then((resp) => {
      //  console.log(resp);
      if (resp.status === 200) {
        setApiResponse(resp.data);
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
    });
};

export const sendNotification = async (msgType, subject, body, receiverId) => {
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  let payload = {
    sender_acc_id: userData.account_id,
    msg_type: msgType,
    subject: subject,
    body: body,
    receiver_acc_id: receiverId,
  };
  postRequest("LbNotification/SendNotification", payload)
    .then((resp) => {
      //   console.info(resp);
      log_activity("sendNotification", "notification-service.js", "Success");
    })
    .catch((error) => {
      //console.error(error);
      log_activity("sendNotification", "booking-service.js", "Exception: "+JSON.stringify (error));
    });
};
