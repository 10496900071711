import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close, FavoriteTwoTone } from "@material-ui/icons";
import {
  getBusinesNameBySettingValueId,
  updatePersonalData,
} from "core/utils/utilities";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as appActions from "redux/actions/appActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: "none !important",
    width: "15rem !important",
    //    backgroundColor:theme.palette.primary.main
  },
  bgColor: {
    backgroundColor: "#FFFF",
    height: "100%",
    color: theme.palette.primary.main,
    padding: "6px",
  },
  divClass: {
    border: theme.palette.primary.main + " solid 1px",
  },
  crossClass: {
    right: "0",
    bottom: "73px",
  },
  
}));

function LbDrawer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const userPerData = useSelector((state) => state?.appData?.userPerData);
  const favDrawer = useSelector((state) => state.appData.favDrawer);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  let userFavorite = userPerData?.data_value?.userFavorite;
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const { enqueueSnackbar } = useSnackbar();
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
  };

  const setOpen = () => {
    dispatch(appActions?.setFavDrawer(false));
  };
  const handleClick = (data, index) => {
    dispatch(appActions.setInventoryVendor(data));
    setSelectedIndex(index);
    history.push(
      "/VendorDetails?settingValueId=" +
        data?.business_type_id +
        "&favInx=" +
        index
    );
  };
  const removeClick = (data) => {
    userPerData?.data_value?.userFavorite.splice(
      0,
      userFavorite.length,
      ...userFavorite.filter(
        (item) =>
          item?.vendor_id + item.business_type_id !==
          data?.vendor_id + data?.business_type_id
      )
    );
    // let userChangedData ={ ...userPerData }
    dispatch(appActions.setUserPerData(userPerData));
    if (userData?.account_id > 0)
      updatePersonalData(
        userData?.account_id,
        userPerData?.data_value,
        enqueueSnackbar,
        msg
      );
  };

  return (
    <SwipeableDrawer
      open={favDrawer}
      anchor={"left"}
      onClose={() => setOpen(false)}
      direction="left"
      className={classes.paper}
    >
      <Box className={classes.bgColor + " primary-border"}>
        <div className="text-center">
          <h3>{"Your Favorites"}</h3>
        </div>
        <Divider className={classes.divClass + " mt-4"}></Divider>
        <List component="nav" aria-labelledby="nested-list-subheader" dense={true}>
          {userFavorite?.map((data, index) => {
            return (
              <Fragment key={index}>
                <ListItem
                dense={true}
                  button
                  onClick={() => {
                    handleClick(data, index);
                  }}
                  selected={selectedIndex === index}
                >
                  <ListItemIcon>
                    <FavoriteTwoTone color={"secondary"} size="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary={data?.first_name + " " + data?.last_name}
                    secondary={data?.mobile_no}
                    className="title-case"
                  />
                </ListItem>
                <Typography variant="caption" display="block">
                  {t(getBusinesNameBySettingValueId(data?.business_type_id))}
                </Typography>
                <ListItemIcon
                  className={classes.crossClass + " right relative pointer"}
                  onClick={() => {
                    removeClick(data);
                  }}
                >
                  <Close color={"primary"} size="large" />
                </ListItemIcon>
                <Divider variant="fullWidth" />
              </Fragment>
            );
          })}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}

export default LbDrawer;
