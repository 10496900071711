export const vendorSideMenu = {
  route_paths: [
    {
      name: "DASHBOARD",
      icon: "speed",
      path: "/vendor/dashboard",
    },
    {
      name: "MANAGE_ACCOUNT",
      icon: "engineering",
      path: "/vendor/manage",
    },
    {
      name: "DEALS",
      icon: "local_offer",
      path: "/vendor/deal-list",
    },
    {
      name: "BIDDING",
      icon: "highlight_alt",
      path: "/vendor/bidding-deal-list",
    },
    {
      name: "BOOKING",
      icon: "book_online",
      path: "/vendor/booking-list",
    },
    {
      name: "CONTACTLIST",
      icon: "folder-user",
      path: "/vendor/contact-list/contact-list",
    },
    {
      name: "PROFILE",
      icon: "person",
      path: "/vendor/profile",
    },
    {
      name: "NOTIFICATION",
      icon: "notifications_active",
      path: "/vendor/notification",
    },
  ],
};

export const customerSideMenu = {
  route_paths: [
    {
      name: "BOOKING",
      icon: "book_online",
      path: "/customer/booking-list",
    },
    {
      name: "DEALS",
      icon: "local_offer",
      path: "/customer/deal-list",
    },
    {
      name: "PROFILE",
      icon: "person",
      path: "/customer/profile",
    },
    {
      name: "VENDOR_SEARCH",
      icon: "person_pin_circle",
      path: "/vendor/search",
    },
    {
      name: "NOTIFICATION",
      icon: "notifications_active",
      path: "/customer/notification",
    },
    {
      name: "WEBINVITE",
      icon: "web_Invites ",
      path: "/customer/invite/invite-list"
    },{
      name: "CUSTOMER_GUEST_LIST",
      icon: "person",
      path: "/customer/guestlist"
    },
  ],
};

export const baseMenu = {
  route_paths: [
    {
      name: "HOME",
      icon: "home",
      path: "/",
    },
    {
      name: "REGISTER",
      icon: "chrome_reader_mode",
      path: "/register",
    },
  ],
};
