import React from 'react';
import PropTypes from "prop-types";
import { Button } from '@material-ui/core';
import { defaultPageSize } from 'core/utils/constants';
import NotFound from 'shared/components/not-found/not-found';
import { useTranslation } from 'react-i18next';

ShowMoreBtn.propTypes = {
    dataList: PropTypes.array.isRequired,
    loadMoreCbFn: PropTypes.func.isRequired,
    displayMsg: PropTypes.string
};

function ShowMoreBtn({ dataList, displayMsg, loadMoreCbFn }) {
    const { t } = useTranslation();

    return (
        <div className="text-center width-100-p">
            {
                dataList.length === 0 ? (
                    <div>{<NotFound text={displayMsg} />}</div>
                ) : dataList.length % defaultPageSize === 0 ? (
                    <Button
                        className="my-8"
                        type="button"
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={loadMoreCbFn}>
                        {t("Shared.SHOWRESULT")}
                    </Button>
                ) : (
                    ""
                )}
        </div>
    );
}

export default ShowMoreBtn;