import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LbSearch from "shared/components/lb-search/lb-search";
import { searchFilter } from "core/utils/utilities";
import { defaultPageSize } from "core/utils/constants";
import { useSelector } from "react-redux";
import NotFound from "shared/components/not-found/not-found";
import LbHeading from "shared/components/lb-heading/lb-heading";
import { get_Web_Invites_List } from "shared/services/web-invite-service";
import { useSnackbar } from "notistack";
import InviteCard from "./invite-card";
const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0px"
  },
}));

export default function InviteList() {
  const classes = useStyles();
  const [displayList, setDisplayList] = useState([]);
  const { t } = useTranslation();
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
    registersuccessfully: t("HelperMsg.REGISTERSUCCESSFULLY"),
  };
  const [showButton, setShowButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const appData = useSelector((state) => state?.appData);
  const customer_id = appData?.user?.customer_id;
  const [initialPageNo, setInitialPageNo] = useState(1);
  const history = useHistory();
  //******Serach Result ********* /
  const [searchList, setSearchList] = useState([]);
  const [noRecordMsg, setNoRecordMsg] = useState("");
  const [filteredList, setFilteredList] = useState(displayList);
  const searchValue = (value) => {
    if(value){
      let filterdData = searchFilter(value, displayList);
      setDisplayList(filterdData);
    }else{
      getWebList();
    }
   
  };
  

  const handleClick = () => {
    history.push("/customer/invite/web-invite");
  };
  const loadMore = () => {

  };
  const deleteFilter = (data) => {
   let data1= displayList.filter((item)=>item.invitation_id !==data)
   setDisplayList(data1)
  }
 const getWebList=()=>{
    get_Web_Invites_List(setDisplayList, customer_id, defaultPageSize, initialPageNo, enqueueSnackbar, msgFail, setShowButton);
  }
  useEffect(() => {
    getWebList()
  }, []);

  return (
    <Box m={1}>
      <div className={" flex space-between align-center"}>
        <LbHeading className={classes.title} text={t("WebInvite.WEBINVITELIST")} />
        <Button variant="contained" color="primary" onClick={handleClick}>
          {t("WebInvite.ADDWEBINVITE")}
        </Button>
      </div>
      <Grid item xs={12} className="text-center">
        <LbSearch
          sticky={true}
          searchValue={searchValue}
          placeholder={t("Shared.SEARCH_NAME")}
        ></LbSearch>
      </Grid>
      <Grid container spacing={1}>
        {displayList?.map((item, index) => (
          <Grid item sm={12} md={4} className="width-100-p">
            <InviteCard item={item} key={index} deleteFilter={deleteFilter}></InviteCard>
          </Grid>
        ))}
      </Grid>
      <div className="m-auto text-center">
        {showButton && displayList?.length === 0 || noRecordMsg ? (
          <div><NotFound text={noRecordMsg} /></div>
        ) : showButton && displayList?.length % defaultPageSize === 0 ? (
          <Button
            className="my-8"
            type="button"
            size="small"
            variant="contained"
            color="secondary"
            onClick={loadMore}
          >
            {t("Shared.SHOWRESULT")}
          </Button>
        ) : (
          ""
        )}
      </div>
    </Box>
  );
}
