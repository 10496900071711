import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const myStyles = makeStyles(() => ({
  imgSize: {
    maxHeight: "500px",
    maxWidth: "500px",
  },
}));

const Carsoul = (props) => {
  const classes = myStyles();
  const { t } = useTranslation();
  return (
    <div className="carouselslide">
      <Carousel
        showThumbs={false}
        className={classes.imgSize + " slidecontainer"}
      >
        {props.images.map((image, index) => {
          return (
            <div key={index}>
              <img
                src={
                  image?.data ||
                  image?.img_data ||
                  process.env.REACT_APP_SERVER_PATH + image.img_path
                }
                alt={t("Shared.PHOTOS")}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default Carsoul;
