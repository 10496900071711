import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { Box, Container } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getZipcode } from "core/utils/axios-client/axios-client";
import { get_address, update_address } from "./profile_helper";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import AddressView from "./address-view";
import { useTranslation } from "react-i18next";
import LbLocationNew from "shared/components/lb-location/lb-location-new";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressFormSchema } from "shared/validation-schema/validation-schema";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "12px",
  },
  marginBottom15: {
    marginBottom: 15,
  },
  width100: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddressForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const [isEdit, setIsEdit] = useState(false);
  const [address, setAddress] = useState({});
  const [listValue, setListValue] = useState([]);
  const { t } = useTranslation();

  const msg = {
    apiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
    Update: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    AddressFail: t("HelperMsg.UPDATEADDRESSFAILED"),
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({ resolver: yupResolver(AddressFormSchema(t)) });

  const zipCodeCallD = (zipcode) => {
    //********Set Venodr Id********/
    if (zipcode.length === 6) {
      reset({ s_city_local: "" }); // Reset City value each time when new Zipcode searched
      getZipcode(zipcode)
        .then((res) => {
          if (res?.data) {
            // Populate city list and open the dropdown
            setListValue(res?.data?.postOffice);
          } else {
            enqueueSnackbar(t("HelperMsg.ZIP_CODE_MSG"), {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("HelperMsg.ZIP_CODE_MSG"), {
            variant: "error",
          });
        });
    }
  };

  const onSubmit = (values) => {
    let payload = {
      address: values.s_address,
      city_local: values.s_city_local,
      taluka: values.s_taluka,
      zip_code: values.s_zip_code,
      district: values.s_district,
      location_id: address.location_id,
      state: values.s_state,
    };
    update_address(payload, enqueueSnackbar, history, setIsEdit, msg);
  };

  const getAddress = () => {
    if (userData.vendor_id > 0)
      get_address(
        userData.vendor_id,
        "Vendor",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setAddress,
        msg
      );
    else if (userData.customer_id > 0)
      get_address(
        userData.customer_id,
        "Customer",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setAddress,
        msg
      );
  };

  useEffect(() => {
    if (!isEdit) getAddress();
  }, [isEdit]);

  const editClick = () => {
    setIsEdit(true);
    setValue("s_address", address?.address);
    setValue("s_district", address?.district);
    setValue("s_state", address?.state);
    setValue("s_city_local", address?.city_local);
    setValue("s_taluka", address?.taluka);
    setValue("s_zip_code", address?.zip_code);
    setValue("location_id", address?.location_id);
  };

  const cancelClick = () => {
    setIsEdit(false);
  };
  // Defiend to show in sequence
  const orderList = [
    "address",
    "city_local",
    "taluka",
    "zip_code",
    "district",
    "state",
  ];

  return (
    <>
      <Container component="main" maxWidth="xl">
        {isEdit && (
          <Typography component="h5" variant="body2" className="m-8">
            {t("Profile_Details.CHNG_ZIP_CODE")}
          </Typography>
        )}
        {!isEdit && (
          <AddressView address={address} orderList={orderList}></AddressView>
        )}
        {!isEdit && (
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={editClick}
            >
              {t("Profile_Details.EDIT")}
            </Button>
          </Box>
        )}
        {isEdit && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <LbLocationNew
              zipCodeCallD={zipCodeCallD}
              listValue={listValue}
              register={register}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              watch={watch}
            ></LbLocationNew>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.submit}
                onClick={cancelClick}
              >
                {t("Shared.CNCL")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t("Shared.SUBMIT")}
              </Button>
            </Box>
          </form>
        )}
      </Container>
    </>
  );
}
