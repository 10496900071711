import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  Container,
  InputAdornment,
  SvgIcon,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  IconButton,
  Avatar,
} from "@material-ui/core";

import { Phone, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  getUserDetail,
  setUpReCaptcha,
  sign_in_func,
  signInWithGoogle,
} from "./sign_in_helper";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useTranslation } from "react-i18next";
import "./login.scss";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { auth } from "../utils/firebase/firebase-config";
import secureLocalStorage from "react-secure-storage";
import { GAPageViewTrigger, logOut } from "core/utils/utilities";
import { use } from "i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    minHeight: "40rem",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  imageIcon: {
    height: "5rem",
    width: "5rem",
    border: "1px solid #7058e7",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
}));
const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default function SignIn() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const [confirmObj, setConfirmObj] = useState({});
  const [isEye, setIsEyeOpen] = useState(false);

  // Snackbar show hide
  const { enqueueSnackbar } = useSnackbar();
  //intialise the form field
  const initialValues = {
    isEmail: "1",
    email_id: "",
    password: "",
    is_remember: false,
  };
  const msg = {
    successMsg: t("HelperMsg.LOGINSUCCESSFULLY"),
    failedMsg: t("HelperMsg.LOGINFAILED"),
    userRegister: t("HelperMsg.USERREGISTRATION"),
    LOGOUT: t("HelperMsg.LOGOUT"),
    genericError: t("HelperMsg.GENRIC_EXCEPTION"),
  };
  const loginMsg = {
    LoginError: t("HelperMsg.Error (auth/user-not-found)."),
    LoginErrorPass: t("HelperMsg.Error (auth/wrong-password)."),
    LoginErrorUser: t("HelperMsg.LOINUSERError"),
    GETOTP: t("HelperMsg.GETOTP"),
  };
  //Validate the form field
  const SigninSchema = Yup.object().shape({
    email_id: Yup.string().when("isEmail", {
      is: "1",
      then: Yup.string()
        .email(t("Validation.ENTER_VALID_EMAIL"))
        .required(t("Validation.EMAIL_CANNOT_EMPTY")),
      otherwise: Yup.string()
        .required(t("Validation.email_Ph"))
        .min(10, t("Validation.PHONE_NO_VALIDITY")),
    }),

    password: Yup.string().when("isEmail", {
      is: "1",
      then: Yup.string().required(t("Validation.PASSWORD_REQUIRED")),
      otherwise: Yup.string(),
    }),
  });

  const loginForm = useFormik({
    initialValues: initialValues,
    validationSchema: SigninSchema,
    onSubmit: (values) => {
      values.email_id = values.email_id.trim();
      if (values.isEmail === "0" || values.isEmail === 0) {
        getOTP(values.email_id);
      } else {
        sign_in_func(values, enqueueSnackbar, loginMsg);
      }
    },
  });

  const getOTP = async (phoneNumber) => {
    if (phoneNumber === " " || phoneNumber === null) return;
    try {
      const resp = await setUpReCaptcha(phoneNumber);
      setConfirmObj(resp);
      setFlag(true);
    } catch (error) {
      enqueueSnackbar(loginMsg.GETOTP, {
        variant: "error",
      });
    }
  };
  const verifyOTPInitValues = {
    verify_otp: "",
  };
  const VerifyOTPSchema = Yup.object().shape({
    verify_otp: Yup.string()
      .required(t("HelperMsg.VERIFYOTP"))
      .min(6, t("HelperMsg.6DIGIT")),
  });

  const otpForm = useFormik({
    initialValues: verifyOTPInitValues,
    validationSchema: VerifyOTPSchema,
    onSubmit: (values) => {
      if (values.verify_otp === " " || values.verify_otp === null) return;
      try {
        confirmObj
          .confirm(values.verify_otp)
          .then(async (resp) => {})
          .catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
            });
          });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    },
  });

  const btnSignInWithGoogle = async () => {
    dispatch(appActions.increaseLoaderCount());
    await signInWithGoogle()
      .catch((err) => {})
      .finally(() => {
        dispatch(appActions.decreaseLoaderCount());
      });
  };

  const onInputChange = (event) => {
    //   console.log('change triggered');
    loginForm.handleChange("email_id")(event);
    if (Number(loginForm.values.email_id)) {
      loginForm.handleChange("isEmail")("0");
    } else {
      loginForm.handleChange("isEmail")("1");
    }
    //  console.log(loginForm.values.isEmail);
    //   console.log(loginForm.values.email_id);
  };
  const rememberChange = (event) => {
    loginForm.setFieldValue("is_remember", event.target.checked);
  };

  useEffect(() => {
    GAPageViewTrigger(
      window.location.pathname + window.location.search,
      "sign-in"
    );
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user?.emailVerified) {
        await getUserDetail(
          user,
          enqueueSnackbar,
          history,
          dispatch,
          appActions,
          msg
        );
      } else {
        if (user) {
          sendEmailVerification(user).then(() => {
            enqueueSnackbar(
              "Email not verified, please login to your email account and verify it and then login.",
              {
                variant: "error",
              }
            );
            logOut(enqueueSnackbar, "LOGOUT");
          });
        }
      }
      let value = secureLocalStorage.getItem("LbCred");
      if (value) {
        loginForm.setFieldValue("email_id", value?.email_id);
        loginForm.setFieldValue("password", value?.pwd);
        loginForm.setFieldValue("is_remember", value?.is_remember);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <div className={classes.center}>
            <Avatar
              alt="registration"
              className={classes.imageIcon}
              src="/images/icons/1/login.png"
            />
          </div>
          <Typography component="h1" variant="h5">
            {t("Booking_login.SIGN_IN")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={loginForm.handleSubmit}
            style={{ display: !flag ? "block" : "none" }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email_id"
              label={t("Booking_login.EMAIL_ADD")}
              name="email_id"
              autoComplete="email_id"
              autoFocus
              onChange={onInputChange}
              value={loginForm.values.email_id}
            />
            {loginForm.errors.email_id && loginForm.touched.email_id ? (
              <div className="error-color">{loginForm.errors.email_id}</div>
            ) : null}
            {loginForm.values.isEmail > 0 && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label={t("Booking_login.PASS")}
                  type={isEye ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={loginForm.handleChange}
                  value={loginForm.values.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsEyeOpen(!isEye)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {isEye ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {loginForm.errors.password && loginForm.touched.password ? (
                  <div className="error-color">{loginForm.errors.password}</div>
                ) : null}
              </>
            )}
            <div id="captcha-container"></div>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_remember"
                  color="primary"
                  onChange={rememberChange}
                  checked={loginForm.values.is_remember}
                />
              }
              label={t("Booking_login.REMEMBER_ME")}
            />
            <Button
              id="sign-in-button"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("Booking_login.SIGN_IN")}
            </Button>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link href="/reset" variant="body2">
                  {t("Booking_login.RESE_PASS")}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="/register" variant="body2">
                  {t("Booking_login.DONT_HAVE_ACC")}
                </Link>
              </Grid>
            </Grid>
          </form>
          <form
            className={classes.form}
            onSubmit={otpForm.handleSubmit}
            style={{ display: +flag ? "block" : "none" }}
          >
            <>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="verify_otp"
                label="Submit OTP"
                type="number"
                id="verify_otp"
                autoComplete="verify otp"
                onChange={otpForm.handleChange}
                value={otpForm.values.verify_otp}
              />
              {otpForm.errors.verify_otp && otpForm.touched.verify_otp ? (
                <div className="error-color">{otpForm.errors.verify_otp}</div>
              ) : null}
            </>
            <Button
              id="btn-otp-verify"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Verify OTP
            </Button>
          </form>
          <div className="flexrow">
            <button className="login-btn gog-btn" onClick={btnSignInWithGoogle}>
              <SvgIcon>
                <path
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  fill="#4285F4"
                />
                <path
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  fill="#34A853"
                />
                <path
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  fill="#EA4335"
                />
                <path d="M1 1h22v22H1z" fill="none" />
              </SvgIcon>
            </button>
            <button className="login-btn gog-btn">
              <Phone style={{ color: "black" }} />
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}
