import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { get_booking_contract } from "customer/services/customer-helper";
import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "600px",
    position: "relative",
    margin: "0 auto",
    textAlign:"justify"
  },
}));

function BookingContract() {
  const [contract, setContract] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const search = useLocation().search;
  const bookingId = new URLSearchParams(search).get("bookingId");
  const reportTemplateRef = useRef(null);
  const classes = useStyles();

  const msg = {
    NoDetail: t("HelperMsg.NODETAIL"),
    GetBookingFail: t("HelperMsg.GETBOOKINGFAIL"),
  };

  const getBookingContract = () => {
    get_booking_contract(bookingId, setContract, enqueueSnackbar, msg);
    log_activity("get_booking_contract", "booking-details.jsx", "Success");
  };

  const downloadPdf = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.setFont("helvetica");
    doc.setFontSize(10);
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
      // top,right,bottom,left
      margin: [40, 20, 40, 20],
      width: width,
      height: height,
    });
  };

  useEffect(() => {
    getBookingContract();
  }, []);

  return (
    <div className={classes.root}>
      <Grid contrainer className="p-16">
        <Grid item xs={12} className="flex flex-end">
          <Button
            onClick={downloadPdf}
            type="submit"
            color="primary"
            variant="outlined"
            size="small"
          >
            {t("Shared.DOWNLOAD")}
          </Button>
        </Grid>
        <Grid items xs={12} ref={reportTemplateRef}>
          <div dangerouslySetInnerHTML={{ __html: contract }}></div>
        </Grid>
      </Grid>
    </div>
  );
}

export default BookingContract;
