import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import FacebookPlugin from "shared/social/facebook-plugin";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  img: {
    position: "absolute",
    right: 0,
    top: 60,
    width: "165px",
  },
  [theme.breakpoints.up("sm")]: {
    img: {
      right: 0,
      top: 6,
      width: "auto",
      height: "200px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.root + " mt-16"}>
      <img src="/images/design/art1.png" alt="" className={classes.img} />
      <Box py={{ xs: 2, sm: 2 }} color="#fff" bgcolor="#323232">
        <Container maxWidth="lg" style={{ backgroundColor: "#323232" }}>
          <Grid container spacing={3} style={{ backgroundColor: "#323232" }}>
            <Grid item xs={12} sm={3}>
              <Typography color="primary" component={"span"}>
                <Box fontWeight={700} borderBottom={1} className="mb-8">
                  {t("Shared.Footer.ABOUTUS")}
                </Box>
              </Typography>
              <Typography variant="caption">
                <Box fontSize={14}>
                  <a
                    className="link"
                    rel="noreferrer"
                    color="inherit"
                    href="https://kiatree.com"
                    target="_blank"
                  >
                    {t("Shared.Footer.ABOUT_US_KTIT")}
                  </a>
                </Box>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/">
                    {t("Shared.Footer.CAREER")}
                  </Link>
                </Box>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/contact-us">
                    {t("Shared.Footer.CONTACTUS")}
                  </Link>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography color="primary" component={"span"}>
                <Box fontWeight={700} borderBottom={1} className="mb-8">
                  {t("Shared.Footer.SERVICES")}
                </Box>
              </Typography>
              <Typography variant="caption" fontSize={13}>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/">
                    {t("Shared.Footer.SERVICE1")}
                  </Link>
                </Box>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/">
                    {t("Shared.Footer.SERVICE2")}
                  </Link>
                </Box>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/">
                    {t("Shared.Footer.SERVICE3")}
                  </Link>
                </Box>
                <Box fontSize={14}>
                  <Link className="link" color="inherit" to="/">
                    {t("Shared.Footer.SERVICE4")}
                  </Link>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography color="primary" component={"span"}>
                <Box fontWeight={700} borderBottom={1} className="mb-8">
                  {t("Shared.Footer.FOLLOWUS")}
                </Box>
              </Typography>
              <Typography variant="caption">
                <Box fontSize={14}>
                  <Link color="inherit" className="link" to="/terms-condition">
                    {t("Shared.Footer.TERMS")}{" "}
                  </Link>
                </Box>
                <Box fontSize={14}>
                  <Link color="inherit" className="link" to="/privacy-policy">
                    {t("Shared.Footer.PRIVACY")}{" "}
                  </Link>
                </Box>
              </Typography>
              <Grid container>
                <Grid item xs={1}>
                  <Box>
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/laganbooking"
                    >
                      <FacebookIcon />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box>
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/laganbooking/"
                    >
                      <InstagramIcon />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box>
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCKKGVSlX4wdv1a941gzFiPg"
                    >
                      <YouTubeIcon />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box>
                    <Link className="link" to="/">
                      <TwitterIcon />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FacebookPlugin></FacebookPlugin>
            </Grid>
          </Grid>

          <Box textAlign="center" pt={{ xs: 2, sm: 2 }} pb={{ xs: 2, sm: 2 }}>
            <Typography variant="body2" className="link" align="center">
              {"Copyright © "}-{" "}
              <a
                href="https://kiatree.com"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                {t("Booking_layout.KIATREE")}
              </a>
              - {new Date().getFullYear()}
            </Typography>
            <Typography variant="caption" className="link">
              <Link
                to="#"
                target="_blank"
                underline="hover"
                rel="noreferrer"
                className="link"
              >
                {t("Booking_layout.LAGANBOOKING")} - {process.env.REACT_APP_ENV}
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
