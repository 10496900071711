import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography, TextField, Hidden } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import renderTextField from '../../core/common/common_render_text_field'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
const useStyles = makeStyles({
    root: {
        minWidth: '100%',
    },
    title: {
        fontSize: 17,
        textAlign: 'center',
        fontWeight: '600',
        '@media (max-width: 600px)': {
            fontSize: '12px',
        },
    }, widthCust: {
        padding: "3%"
    },
    imgSize: {
        width: '210px'
    }
});


export default function BrideGroomDetails() {

    const { id } = useParams();
    const appData = useSelector((state) => state.appData);
    const WebInviteData = appData.WebInviteUpdate;
    const today = new Date();
    const minDate = today.toISOString().split("T")[0];
    const classes = useStyles();
    const { t } = useTranslation();
    const { register, formState: { errors }, setValue } = useFormContext();

    const formatDate = (date) => {
        return date ? new Date(date).toISOString().split("T")[0] : ''; // Format the date as needed
    };

    React.useEffect(() => {

        if (WebInviteData && id === "update") {
            setValue('groomName', WebInviteData?.groom_name);
            setValue('groomMother', WebInviteData?.groom_mother_name);
            setValue('groomFather', WebInviteData?.groom_father_name);
            setValue('brideName', WebInviteData?.bride_name);
            setValue('groomGrandmother', WebInviteData?.groom_grand_mother_name);
            setValue('groomGrandfather', WebInviteData?.groom_grand_father_name);
            setValue('tilakSangeetDate', formatDate(WebInviteData?.tilak_sangeet_date));
            setValue('brideMother', WebInviteData?.bride_mother_name);
            setValue('brideFather', WebInviteData?.bride_father_name);
            setValue('brideGrandmother', WebInviteData?.bride_grand_mother_name);
            setValue('brideGrandfather', WebInviteData?.bride_grand_father_name);
            setValue('weddingDate', formatDate(WebInviteData?.lagan_date));
        }


    }, [WebInviteData, setValue]);


    return (
        <Card className={classes.root}>
            <Grid container spacing={2} className={classes.widthCust}>
                <Grid item xs={12} md={5}>
                    <Typography className={classes.title}>
                        {t("WebInvite.GROOMD")}
                    </Typography>
                    {renderTextField(t("WebInvite.GROOMNAME"), 'groomName', errors, register)}
                    {renderTextField(t("WebInvite.GROOMMOTHER"), 'groomMother', errors, register)}
                    {renderTextField(t("WebInvite.GROOMFATHER"), 'groomFather', errors, register)}
                    {renderTextField(t("WebInvite.GROOMGRANDMOTHER"), 'groomGrandmother', errors, register)}
                    {renderTextField(t("WebInvite.GROOMGRANDFATHER"), 'groomGrandfather', errors, register)}
                    <TextField
                        label={t("WebInvite.TILALDATE")}
                        {...register("tilakSangeetDate")}
                        type="date"
                        size='small'
                        margin="dense"
                        className="width-100-p"
                        format="dd/MM/yyyy"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onClick={(event) => {
                            event?.target?.showPicker();
                        }}
                        InputProps={{
                            inputProps: { min: minDate },
                        }}
                        error={!!errors?.tilakSangeetDate}
                        helperText={errors?.tilakSangeetDate?.message}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={2} md={2} className='flex center flex-align-center'>
                        <img src="/images/bg.png" className={classes.imgSize} />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={5}>
                    <Typography className={classes.title}>
                        {t("WebInvite.BRIDED")}
                    </Typography>
                    {renderTextField(t("WebInvite.BRIDENAME"), 'brideName', errors, register)}
                    {renderTextField(t("WebInvite.BRIDEMOTHER"), 'brideMother', errors, register)}
                    {renderTextField(t("WebInvite.BRIDEFATHER"), 'brideFather', errors, register)}
                    {renderTextField(t("WebInvite.BRIDEGRANDMOTHER"), 'brideGrandmother', errors, register)}
                    {renderTextField(t("WebInvite.BRIDEGANDFATHER"), 'brideGrandfather', errors, register)}
                    <TextField
                        label={t("WebInvite.WEDDINGDATE")}
                        {...register("weddingDate")}
                        type="date"
                        className="width-100-p"
                        format="dd/MM/yyyy"
                        size='small'
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onClick={(event) => {
                            event?.target?.showPicker();
                        }}
                        InputProps={{
                            inputProps: { min: minDate },
                        }}
                        error={!!errors?.weddingDate}
                        helperText={errors?.weddingDate?.message}
                    />
                </Grid>

            </Grid>
        </Card>
    );
}