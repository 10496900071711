import React from 'react';

function VerifyIcon(props) {
    return (
        <>
            <img
                src="/images/icons/verify.svg"
                width="218px"
                height="75px"
                alt="lagan-booking-verify.svg"
                style={{
                    height: '22px',
                    width: '22px',
                    margin: '0px 4px'
                }}
            />
        </>
    );
}

export default VerifyIcon;