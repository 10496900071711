import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { getLocalStorage } from "core/utils/utilities";
import { log_activity } from "shared/services/common-service";

export const update_vendor_details = async (
  values,
  enqueueSnackbar,
  history,
  dispatch,
  appActions,
  msg,
  msgVendor,
  msgUpdate
) => {
  postRequest("LbVendor/UpdateVendorDetails", values)
    .then(resp => {
      if (resp.status === 200) {
        enqueueSnackbar(msg.updatemsg, {
          variant: "success",
        });
        if (values?.setting_value_id?.indexOf(",") > 0) {
          dispatch(appActions.setVenSettingValueId(values?.setting_value_id));
          history.push("/vendor/business-select");
        } else {
          let user_data = getLocalStorage("USER_DATA");
          // Update user business type
          user_data.setting_value_id = parseInt(values?.setting_value_id);
          localStorage.setItem("USER_DATA", JSON.stringify(user_data));
          dispatch(appActions.updateUser(user_data));
          history.push("/vendor/dashboard");
        }
        log_activity("update_vendor_details", "owner_reg_helper.js", "Success");
      } else {
        enqueueSnackbar(msgVendor.vendorApi, {
          variant: "error",
        });
        //   console.log(JSON.Stringify(resp));
        log_activity("update_vendor_details", "owner_reg_helper.js", "Error: "+JSON.stringify(resp));
      }
    })
    .catch(error => {
      enqueueSnackbar(msgUpdate.failApi, {
        variant: "error",
      });
      //   console.log(error);
      log_activity("update_vendor_details", "owner_reg_helper.js", "Exception: "+JSON.stringify(error));
    });
};

export const get_business_type = async (enqueueSnackbar, setBusinessType, msgFail, msgError) => {
  try {
    getRequest("LbAuth/GetBusinessTypes")
      .then(resp => {
        if (resp.status === 200) {
          if (setBusinessType) setBusinessType(resp?.data);
        } else {
          enqueueSnackbar(msgFail.failedmsg, {
            variant: "error",
          });
          //   console.log(JSON.Stringify(resp));
        }
      })
      .catch(error => {
        enqueueSnackbar(msgFail.failedmsg, {
          variant: "error",
        });
        //  console.log(error);
      });
  } catch (error) {
    // console.log(JSON.Stringify(error));
    enqueueSnackbar(msgError.failedmsg, {
      variant: "GetBusinessType exception error",
    });
  }
};
