import React from 'react';
import { Checkbox, Grid, Icon, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0px 8px 8px 8px",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "55px",
      width: "55px",
    },
  },
  check:{
    position: 'absolute',
    right: 0,
    top: 0
  },
  paperSize:{
    width: '160px',
    height:'134px !important',
    [theme.breakpoints.down("md")]: {
      height: "124px !important",
      width: "118px",
    },
  }
}));
function BussTypeCard(props) {
    const classes = useStyles();
    return (
        <div className="category mx-4 my-8">
            <Grid container spacing={1}>
                  <Grid item xs={4} md={2} lg={2} xl={2}>
                      <Paper className={classes.paperSize+" paper"}>
                        <Checkbox checked={props?.check?true:false} name="checkedA" className={classes.check} />
                        <div className={"mt-16 material-icons category-icon" }>
                          <img
                            className={classes.imageIcon}
                            src={`/images/icons/1/${props.icon}.png`} alt="icons"
                          />
                        </div>
                        <div className="category-btn">{props.name}</div>
                      </Paper>
                  </Grid>
            </Grid>
          </div>
    );
}

export default BussTypeCard;