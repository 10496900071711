import React, { useState } from "react";
import PropTypes from "prop-types";
import "./lb-profile-body.scss";
import { useHistory } from "react-router-dom";
import { getLocalStorage, logOut } from "core/utils/utilities";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as appActions from "redux/actions/appActions";
import Components from "../../components.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Divider, Icon } from "@material-ui/core";
import LbLinearProgressWithLabel from "../lb-progress-with-label/lb-line-progress-with-lable";
import {
  delete_my_account,
  get_profile_percent,
} from "core/common/profile/profile_helper";
import { useEffect } from "react";

LbProfileBody.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      menuName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  iconCss: {
    backgroundColor: theme.palette.primaryLight.main,
    borderRadius: "4px",
    padding: "4px",
    height: "31px",
    width: "31px",
    color: theme.palette.primary.main,
  },
}));

function LbProfileBody(props) {
  const classes = useStyles();
  const [menuList] = useState(props.menuList ? props.menuList : []);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const msg = {
    LOGOUT: t("HelperMsg.LOGOUT"),
  };
  const msg_del = {
    FAILED: t("HelperMsg.FAILED"),
    DELETED: t("HelperMsg.DELETED"),
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const userData = getLocalStorage("USER_DATA");
  const [profilePercent, setProfilePercent] = React.useState();

  const getProfilePercent = () => {
    if (userData.vendor_id > 0)
      get_profile_percent(
        userData.vendor_id,
        "Vendor",
        enqueueSnackbar,
        setProfilePercent,
        msg
      );
    else if (userData.customer_id > 0)
      get_profile_percent(
        userData.customer_id,
        "Customer",
        enqueueSnackbar,
        setProfilePercent,
        msg
      );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const logoutClick = () => {
    dispatch(appActions?.updateUser({}));
    dispatch(appActions.setVenSettingValueId(""));
    dispatch(appActions.resetState());
    history.push("/");
    logOut(enqueueSnackbar, msg.LOGOUT);
  };
  const deleteClick = () => {
    if (window.confirm(t("Shared.DEL_CONF_MSG"))) {
      delete_my_account(userData.account_id, enqueueSnackbar, msg_del);
      logoutClick();
      // console.log("True");
    }
  };

  useEffect(() => {
    getProfilePercent();
  }, []);
  return (
    <div className="css-1k9q6fl">
      <LbLinearProgressWithLabel
        value={
          profilePercent?.profile_percent ? profilePercent?.profile_percent : 0
        }
      ></LbLinearProgressWithLabel>
      {menuList.map((data, index) => (
        <Accordion
          key={index}
          disabled={data.isDisabled}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <div className="menu-body">
              <div className="align-item">
                <span>
                  <Icon className={classes.iconCss}>{data.icon}</Icon>
                </span>
                <span className="text-align">{t(data.menuName)}</span>
              </div>
            </div>
            <Divider variant="fullWidth" />
          </AccordionSummary>
          <AccordionDetails>
            <div className="menu-body">{Components(data.components)}</div>
          </AccordionDetails>
        </Accordion>
      ))}
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content">
          <div className="menu-body">
            <div className="align-item" onClick={deleteClick}>
              <span>
                <Icon className={classes.iconCss}>delete</Icon>
              </span>
              <span className="text-align">
                {t("Shared.Side_menu.DEL_ACC")}
              </span>
            </div>
            <div className="align-item" onClick={logoutClick}>
              <span>
                <Icon className={classes.iconCss}>logout</Icon>
              </span>
              <span className="text-align">{t("Shared.Side_menu.LOGOUT")}</span>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}

export default LbProfileBody;
