import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay } from 'swiper';
import PropTypes from "prop-types";

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/effect-flip';
import 'swiper/scss/navigation';
import './slider.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CmsContentCard from 'shared/cms/component/cms-content-card';

// Intefaces Defined
LbContentSlider.propTypes = {
  arrayData: PropTypes.array,
  navigation: PropTypes.bool,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  effect: PropTypes.bool,
  autoplay: PropTypes.bool,
  delay: PropTypes.number
};

export default function LbContentSlider({ arrayData, navigation, slidesPerView, spaceBetween, effect, autoplay, delay }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Swiper
        effect={effect ? isMobile ? 'flip' : '' : ''}
        navigation={navigation ? true : false}
        modules={[Autoplay, EffectFlip, Navigation]}
        autoplay={autoplay ? {
          delay: delay ? delay : 3000,
          disableOnInteraction: false,
        } : false}
        loop={true}
        slidesPerView={slidesPerView ? slidesPerView : 3}
        spaceBetween={spaceBetween ? spaceBetween : 10}
        className="blod-slide"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
      >
        {arrayData?.map((item, index) => {
          return (
            <SwiperSlide>
              <Link
                color="inherit"
                className="link"
                to={"/content-view/" + item?.id}
              >
                <CmsContentCard key={index} option={item}></CmsContentCard>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  );
}
