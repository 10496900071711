import React from "react";
import "./App.css";
import "styles/styles.scss";
import { Switch } from "react-router-dom";
import Layout from "core/layout/layout";
import VendorDashboard from "vendor/vendor-dashboard";
import AppRoute from "AppRoute";
import OwnerRegistration from "vendor/owner-reg/owner-reg";
import SignIn from "core/login/sign-in";
import Register from "core/login/register";
import axiosClient from "core/utils/axios-client/axios-client";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "redux/actions/appActions";
import ResetPassword from "core/login/reset-password.jsx";
import CustomerBookingList from "customer/booking-list/customer-booking-list";
import VendorBookingList from "vendor/booking/vendor-booking-list";
import ScrollToTop from "core/common/scroll-to-top";
import CustomerProfile from "customer/profile/customer-profile";
import VendorProfile from "vendor/profile/vendor-profile";
import AddressForm from "core/common/profile/address-form";
import ManageAccounts from "vendor/manage-accounts/manage-accounts";
import VendorList from "home/vendor-list";
import VendorDetails from "home/vendor-details/vendor-details";
import VendorInformationForm from "core/common/profile/vendor-information-form";
import InformationForm from "core/common/profile/information-form";
import VendorBiddingDealList from "vendor/bidding/vendor-bidding-deal-list";
import TermsAndConditions from "home/terms-conditions";
import PrivacyPolicy from "home/privacy-policy";
import BusinessSelect from "vendor/business-select/business-select";
import Category from "home/new-landing/category/category";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalStorage, getUrlWithToken, unAuthorizeAccess } from "core/utils/utilities";
import ErrorPage from "core/common/error/error-page";
import { ThemeProvider } from "@material-ui/styles";
import { useState } from "react";
import { createTheme } from "@material-ui/core";
import { useEffect } from "react";
import { ThemeColor } from "core/utils/constants";
import VendorSearch from "vendor/vendor-search";
import ContactUs from "home/contact-us";
import LbNetCheck from "shared/components/lb-net-check/lb-net-check";
import DealList from "shared/deals/deal-list";
import Notification from "shared/notification/notification";
import NewLanding from "home/new-landing/new-landing";
import Help from "core/common/help/help";
import { useTranslation } from "react-i18next";
import VivahMuhurat from "home/vivah-muhurat";
import ContactList from "vendor/contact-list/contact-list";
import inviteList from "customer/invite/invite_list";
import webInvite from "customer/invite/web-invite";
import ReactGA from "react-ga";
import CmsContentView from "shared/cms/component/cms-content-view";
import GuestListTitle from "customer/guest-list/guest-list-title";
import BookingContract from "shared/booking-contract/booking-contract";
import GuestListDetails from "customer/guest-list/guest_list_details";

export const ThemeContext = React.createContext({
  value: null,
  setValue: () => { },
});
// GA initialization
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [value, setValue] = useState(null);
  var userPerData = useSelector((state) => state?.appData?.userPerData);
  const msg = {
    AUTHENTICATIONLOGINSIGNUP: t("Booking_login.AUTHENTICATIONLOGINSIGN"),
    APIERROR: t("HelperMsg.APIFALIED"),
  };
  const excludeApiLoader = [
    "play.google.com",
    "googleads.g.doubleclick.net",
    "www.youtube.com",
    "jnn-pa.googleapis.com",
    "laganbooking.com",
    "AddUpdatePersonalization",
    "LogActivity",
    "GetNotification",
    "UpdateNotificationFlag",
    "LbAuth/IsEmailExists",
    "LbAuth/IsPhoneExists",
  ];

  axiosClient.interceptors.request.use(
    function (request) {
      // request.headers["Content-Type"] = "multipart/form-data";
      const flag = excludeApiLoader.some((value) =>
        request?.url?.includes(value)
      );
      if (!flag) {
        dispatch(appActions?.increaseLoaderCount());
        // console.log("Intercepter request:", request?.url);
      }
      return request;
    },
    null,
    { synchronous: true }
  );

  axiosClient.interceptors.response.use(
    function (response) {
      //Dispatch any action on success
      const flag = excludeApiLoader.every((url) =>
        response.config?.url?.includes(url)
      );
      if (!flag) dispatch(appActions?.decreaseLoaderCount());
      //     console.log("Intercepter response");
      return response;
    },
    function (error) {
      if (error?.toString().indexOf("401") > -1) {
        unAuthorizeAccess(enqueueSnackbar, history, dispatch, appActions, msg);
      } else if (
        error?.toString().indexOf("403") > -1 ||
        error?.toString().indexOf("500") > -1 ||
        error?.toString().indexOf("404") > -1
      ) {
        enqueueSnackbar(msg.APIERROR, {
          variant: "error",
        });
        console.log(error);
      }
      //  dispatch(appActions.decreaseLoaderCount());
      dispatch(appActions.stopLoader());
      return Promise.reject(error);
    }
  );

  const blueTheme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Raleway", "Open Sans"].join(","),
    },
    palette: {
      background: {
        default: "#7058e7",
      },
      primary: {
        main: "#7058e7",
      },
      primaryLight: {
        main: "#dadfff",
      },
      secondary: {
        main: "#ea4c62",
      },
    },
  });

  const pinkTheme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Raleway", "Open Sans"].join(","),
    },
    palette: {
      background: {
        default: "#f50057",
      },
      primary: {
        main: "#f50057",
      },
      primaryLight: {
        main: "#ffcfe0",
      },
      secondary: {
        main: "#ffcfe0",
      },
    },
  });

  const yellowTheme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Raleway", "Open Sans"].join(","),
    },
    palette: {
      background: {
        default: "#e7ad00",
      },
      primary: {
        main: "#e7ad00",
      },
      primaryLight: {
        main: "#fbf2d9",
      },
      secondary: {
        main: "#3b3a39",
      },
    },
  });
  const [currentTheme, setTheme] = useState(blueTheme);
  // ********These function is used to check internet connection************
  const [netStatus, setNetStatuse] = useState(true);
  const checkInternet = () => {
    window.addEventListener("online", () => {
      setNetStatuse(true);
    });
    window.addEventListener("offline", () => {
      setNetStatuse(false);
    });
  };
  useEffect(() => {
    checkInternet();
  }, []);
  // ***************************End of code***************************

  // ***************************Redirect to vendor website page***************************
  const redirectUrlVendorWeb = () => {
    const searchParams = new URLSearchParams(location.search);
    const isRedirect = searchParams.has('redirect');
    const isWebsiteId = searchParams.has('websiteId');
    if ((isRedirect && searchParams.get('redirect')) && (isWebsiteId && searchParams.get('websiteId'))) {
      let redirectedToken = searchParams.get('redirect');
      let websiteId = searchParams.get('websiteId');
      let validWebToken = btoa(process.env.REACT_APP_PREFIX_TOKEN);
      if (getLocalStorage('LB_TOKEN') && (redirectedToken === validWebToken)) {
        const redirectUrl = getUrlWithToken(websiteId);
        window.location.href = redirectUrl;
      }
    }
  }
  // ***************************Redirect to vendor website page***************************
  useEffect(() => {
    redirectUrlVendorWeb()
    let currentSelectedTheme = getLocalStorage("CURRENT_THEME");
    if ((currentSelectedTheme || value) === ThemeColor.PINK) {
      setTheme(pinkTheme);
    } else if ((currentSelectedTheme || value) === ThemeColor.YELLOW) {
      setTheme(yellowTheme);
    } else {
      setTheme(blueTheme);
    }
    // If user has old cache of loca storage then initializing the personalization data
    if (!userPerData?.data_value)
      dispatch(
        appActions.setUserPerData({
          account_id: 0,
          data_value: {
            userFavorite: [],
            theme: "BLUE",
            lang: "en",
            location: "",
          },
        })
      );

    // Block right click on production environment
    if (process.env.REACT_APP_ENV === "Production") {
      document.addEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    }
  }, [value]);

  return (
    <>
      {netStatus ? (
        <ThemeContext.Provider value={{ value, setValue }}>
          <ThemeProvider theme={currentTheme}>
            <Switch>
              <ScrollToTop>
                <AppRoute
                  exact
                  path="/"
                  component={NewLanding}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/search"
                  component={VendorSearch}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/error"
                  component={ErrorPage}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/register"
                  component={Register}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/reset"
                  component={ResetPassword}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/signin"
                  component={SignIn}
                  layout={Layout}
                />
                <AppRoute
                  path="/vendor-list"
                  component={VendorList}
                  layout={Layout}
                />
                <AppRoute
                  path="/VendorDetails"
                  component={VendorDetails}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/dashboard"
                  component={VendorDashboard}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/manage"
                  component={ManageAccounts}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/ownerRegist"
                  component={OwnerRegistration}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/booking-list"
                  component={VendorBookingList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/booking-list"
                  component={CustomerBookingList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/bidding-deal-list"
                  component={VendorBiddingDealList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/deal-list"
                  component={DealList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/deal-list"
                  component={DealList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/profile"
                  component={CustomerProfile}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/profile/address"
                  component={AddressForm}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/profile"
                  component={VendorProfile}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/profile/address"
                  component={AddressForm}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/profile/information"
                  component={VendorInformationForm}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/profile/information"
                  component={InformationForm}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/guestlist"
                  component={GuestListTitle}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/terms-condition"
                  component={TermsAndConditions}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/contact-us"
                  component={ContactUs}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/business-select"
                  component={BusinessSelect}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/category"
                  component={Category}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/notification"
                  component={Notification}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vendor/contact-list/contact-list"
                  component={ContactList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/notification"
                  component={Notification}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/invite/invite-list"
                  component={inviteList}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/customer/invite/web-invite"
                  component={webInvite}
                  layout={Layout}
                />
                 <AppRoute
                  exact
                  path="/customer/invite/webInvite/:id"
                  component={webInvite}
                  layout={Layout}
                />
                <AppRoute exact path="/help" component={Help} layout={Layout} />
                <AppRoute
                  exact
                  path="/content-view/:id"
                  component={CmsContentView}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/vivah-muhurat"
                  component={VivahMuhurat}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/booking-contract"
                  component={BookingContract}
                  layout={Layout}
                />
                <AppRoute
                  exact
                  path="/guest-list-view/:id"
                  component={GuestListDetails}
                  layout={Layout}
                />
              </ScrollToTop>
            </Switch>
          </ThemeProvider>
        </ThemeContext.Provider>
      ) : (
        <LbNetCheck />
      )}
    </>
  );
}

export default App;
