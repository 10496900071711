import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { get_information, update_customer_profile } from "./profile_helper";
import { useDispatch } from "react-redux";
import * as appActions from "redux/actions/appActions";
import { useTranslation } from "react-i18next";
import InformationView from "./information-view";
import { DeleteForever } from "@material-ui/icons";
import {orderList} from '../../utils/constants'
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "12px",
  },
  marginBottom15: {
    marginBottom: 15,
  },
  width100: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function InformationForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const [isEdit, setIsEdit] = useState(false);
  const [information, setInformation] = useState({});
  const initialValues = {
    first_name: information?.first_name,
    last_name: information?.last_name,
    mobile_no: information?.mobile_no,
    email_id: information?.email_id,
    alt_mobile_no: information?.alt_mobile_no,
    customerId: userData.customer_id ? userData.customer_id : "",
    profile_img: information?.profile_img,
  };
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const InformationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    mobile_no: Yup.string()
      .required("Required")
      .matches(phoneRegExp, t('Validation.PHONENOTVALID')),
    alt_mobile_no: Yup.string()
      //   .required("Required")
      .matches(phoneRegExp, t('Validation.PHONENOTVALID')),
    email_id: Yup.string().required("Required").email(t("Validation.InvalidEmailId")),
  });
  const msg = {
    successMsg: t("HelperMsg.UPDATEDSUCCESSFULLY"),
    apiFailed: t("HelperMsg.APIFALIED"),
    addressApiFailed: t("HelperMsg.ADDRESS_API_FAILED"),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: InformationSchema,
    onSubmit: (values) => {
      update_customer_profile(
          userData.customer_id,
          values,
          enqueueSnackbar,
          history,
          setIsEdit,
          msg
        );
    },
  });

  const getInformation = () => {
    if (userData.vendor_id > 0)
      get_information(
        userData.vendor_id,
        "Vendor",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
    else if (userData.customer_id > 0)
      get_information(
        userData.customer_id,
        "Customer",
        enqueueSnackbar,
        dispatch,
        history,
        appActions,
        setInformation,
        msg
      );
  };

  useEffect(() => {
    if (!isEdit) getInformation();
  }, [isEdit]);
  const editClick = () => {
    setIsEdit(true);
    formik.setFieldValue("first_name", information?.first_name);
    formik.setFieldValue("last_name", information?.last_name);
    formik.setFieldValue("mobile_no", information?.mobile_no);
    formik.setFieldValue("alt_mobile_no", information?.alt_mobile_no);
    formik.setFieldValue("email_id", information?.email_id);
    formik.setFieldValue("profile_img", information?.profile_img);
  };
  const cancelClick = () => {
    setIsEdit(false);
  };
  return (
    <>
      <Container component="main" maxWidth="xl" className="pt-10">
        {!isEdit && (
          <InformationView information={information} orderList={orderList}></InformationView>
        )}
        {!isEdit && (
          <Box display="flex" justifyContent="end">
            <Button
              type="button"
              variant="contained"
              size="small"
              color="secondary"
              onClick={editClick}
            >
              {t("Profile_Details.EDIT")}
            </Button>
          </Box>
        )}
        {isEdit && (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="first_name"
                      type="text"
                      label={t("Booking_login.F_NAME")}
                      fullWidth
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.first_name && formik.touched.first_name
                      }
                      helpertext={
                        formik.touched.first_name && formik.errors.first_name
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="last_name"
                      variant="outlined"
                      fullWidth
                      id="last_name"
                      label={t("Booking_login.L_NAME")}
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helpertext={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="mobile_no"
                      type="tel"
                      label={t("Vendor.VendorDetails.MOBILE")}
                      fullWidth
                      name="mobile_no"
                      value={formik.values.mobile_no}
                      error={
                        formik.touched.mobile_no &&
                        Boolean(formik.errors.mobile_no)
                      }
                      helpertext={
                        formik.touched.mobile_no && formik.errors.mobile_no
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="alt_mobile_no"
                      type="tel"
                      label={t("Vendor.VendorDetails.ALT_MOB_NO")}
                      fullWidth
                      name="alt_mobile_no"
                      value={formik.values.alt_mobile_no}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.alt_mobile_no &&
                        Boolean(formik.errors.alt_mobile_no)
                      }
                      helpertext={
                        formik.touched.alt_mobile_no &&
                        formik.errors.alt_mobile_no
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="email_id"
                      type="email"
                      label={t("Booking_login.EMAIL")}
                      fullWidth
                      className={classes.width100}
                      name="email_id"
                      value={formik.values.email_id}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.email_id &&
                        Boolean(formik.errors.email_id)
                      }
                      helpertext={
                        formik.touched.email_id && formik.errors.email_id
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {formik.values.profile_img === "" ? (
                      ""
                    ) : (
                      <ImageList className={classes.imageList}>
                        <ImageListItem>
                          <img
                            src={formik.values.profile_img}
                            alt="profile pic"
                          />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton
                                //  aria-label={`info about ${item.name}`}
                                className={classes.icon}
                                onClick={() => {
                                  formik.setFieldValue("profile_img", "");
                                }}
                              >
                                <DeleteForever color="secondary" />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      </ImageList>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input
                      type="file"
                      hidden
                      id="profile_img"
                      name="profile_img"
                      onChange={(event) => {
                        let files = event.target.files;
                        let reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        reader.onload = (e) => {
                          formik.setFieldValue("profile_img", e.target?.result);
                        };
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {formik.values.profile_img === "" ? (
                      <Button
                        onClick={() => {
                          document.getElementById("profile_img").click();
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        {t("Vendor.VendorDetails.UPLOAD_IMAGE")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      type="button"
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.submit}
                      onClick={cancelClick}
                    >
                      {t("Shared.CNCL")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.submit}
                    >
                      {t("Shared.SUBMIT")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </>
  );
}
