import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { log_activity } from "shared/services/common-service";

export const save_vendor_service = async (
  values,
  handleClose,
  returnManageService,
  enqueueSnackbar,
  msgSuccess,
  msgFail
) => {
  postRequest("LbVendorService/SaveVendorService", values)
    .then((resp) => {
      if (resp.status === 200) {
        handleClose();
        returnManageService(true);
        enqueueSnackbar(msgSuccess.failedmsg, {
          variant: "success",
        });
        log_activity("save_vendor_service", "service_manage_helper.js", "Success");
      } else {
        returnManageService(false);
        enqueueSnackbar(msgFail.failedmsg, {
          variant: "error",
        });
        log_activity("save_vendor_service", "service_manage_helper.js", "Non 200 : "+JSON.stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
      // console.log(error);
      log_activity("save_vendor_service", "service_manage_helper.js", "Exception : "+JSON.stringify(error));
    });
};

export const update_vendor_service = async (
  values,
  handleClose,
  returnManageService,
  enqueueSnackbar,
  msgSuccessU,
  msgFail
) => {
  postRequest("LbVendorService/UpdateVendorService", values)
    .then((resp) => {
      if (resp.status === 200) {
        handleClose();
        returnManageService(true);
        enqueueSnackbar(msgSuccessU.failedmsg, {
          variant: "success",
        });
        log_activity("update_vendor_service", "service_manage_helper.js", "Success");
      } else {
        returnManageService(false);
        enqueueSnackbar(msgFail.failedmsg, {
          variant: "error",
        });
        log_activity("update_vendor_service", "service_manage_helper.js", "Non 200 : "+JSON.stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
      log_activity("update_vendor_service", "service_manage_helper.js", "Exception : "+JSON.stringify(error));
    });
};

export const get_vendor_service = async (
  setUpdatedList,
  setApiResponse,
  vendorId,
  businessType,
  enqueueSnackbar,
  msgFail
) => {
  // console.log("Service");
  getRequest(`LbVendorService/GetVendorService?vendorId=${vendorId}&businessType=${businessType}`)
    .then((resp) => {
      if (resp.status === 200) {
        setApiResponse({ success: true, data: resp.data });
        setUpdatedList(JSON.parse(resp.data.service_data));
        log_activity("get_vendor_service", "service_manage_helper.js", "Success");
      } else {
        // enqueueSnackbar('Failed', {
        //   variant: "error",
        // });
        log_activity("get_vendor_service", "service_manage_helper.js", "Non 200 : "+JSON.stringify(resp));
      }
    })
    .catch((error) => {
      setApiResponse({ success: false });
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
      log_activity("get_vendor_service", "service_manage_helper.js", "Exception : "+JSON.stringify(error));
    });
};
export const get_vendor_images = async (
  setApiResponse,
  vendServId,
  enqueueSnackbar,
  msgFail
) => {
  getRequest(`LbVendorService/GetVendorServiceImages?vendServId=${vendServId}`)
    .then((resp) => {
      if (resp.status === 200) {
        // Add Checked Status for Checkbox on Images 
        resp.data.map(res => res.checked = false);
        setApiResponse(resp.data);
        log_activity("get_vendor_images", "service_manage_helper.js", "Success");
      } else {
        enqueueSnackbar(msgFail.failedmsg, {
          variant: "error",
        });
        log_activity("get_vendor_images", "service_manage_helper.js", "Non 200 : "+JSON.stringify(resp));
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
      log_activity("get_vendor_images", "service_manage_helper.js", "Non 200 : "+JSON.stringify(error));
    });
};