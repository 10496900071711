import React from "react";
import Chip from "@material-ui/core/Chip";
import {
  getBusinesIconBySettingValueId,
  getBusinesNameBySettingValueId,
} from "core/utils/utilities";
import { useTranslation } from "react-i18next";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    height: "100%",
    width: "100%",
  },
  chipBg: {
    backgroundColor: "#ffff",
    border: theme.palette.primary.main + " solid 1px",
  },
}));

export default function BusinessChip(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {props?.settingValueId?.split(",")?.map((id, index) => (
        <Chip
          avatar={
            <Avatar
              className={classes.imageIcon}
              alt="business-icons"
              src={`/images/icons/1/${getBusinesIconBySettingValueId(id)}.png`}
            />
          }
          className={classes.chipBg + " m-4"}
          key={index}
          label={t(getBusinesNameBySettingValueId(id))}
        />
      ))}
    </>
  );
}
