import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { Checkbox } from "@material-ui/core";
import LbTitle from "shared/components/lb-title/lb-title";
import {
  getBusinessServicesSettingId,
  getLocalStorage,
} from "core/utils/utilities";
import { makeStyles } from "@material-ui/styles";
import { getRequest } from "core/utils/axios-client/axios-client";
import {
  save_vendor_service,
  update_vendor_service,
} from "vendor/services/service_manage_helper";
import { log_activity } from "shared/services/common-service";

const convertArray = (data) => {
  let descriptionArray = [];
  let serviceIdsArray = [];
  let filteredData = data.filter((f) => f.checked === true);
  for (let key in filteredData) {
    serviceIdsArray.push(filteredData[key].setting_value_id);
    descriptionArray.push(filteredData[key].service_description);
  }
  return {
    service_data: descriptionArray.join(),
    service_ids: serviceIdsArray.join(),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
export default function ManageServiceDialog(props) {
  // const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [serviceList, setServiceList] = React.useState([]);
  const user = getLocalStorage("USER_DATA");

  const msg = {
    failedmsg: t("HelperMsg.GETSETTINGVALUEFAILED"),
  };
  const msgSuccess = {
    failedmsg: t("HelperMsg.SUCCESSFULLYADD"),
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
  };
  const msgSuccessU = {
    failedmsg: t("HelperMsg.SUCCESSFULLYUPDATE"),
  };
  const handleClickOpen = () => {
    setOpen(true);
    remove();
    serviceList.map((r, index) => {
      // Find and make existing services checked in list otherwise uncheck
      let extingValue = props?.vendorServicesList.find(
        (o) => o.setting_value_id === r.setting_value_id
      );
      if (extingValue && extingValue?.setting_value_id > 0) {
        r.checked = true;
        r.service_description = extingValue?.service_description;
      } else {
        r.checked = false;
        r.service_description = "";
      }
      //  console.log(r);
      append(r);
    });
    setValue("comments", props?.apiResponse?.data?.comments);
    log_activity("handleClickOpen", "manage-service-dialog.jsx", "Dialog Open");
  };

  const handleClose = () => {
    setOpen(false);
    log_activity("handleClose", "manage-service-dialog.jsx", "Dialog Close");
  };

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      vendor_id: "",
      business_type: "",
      service_ids: "",
      service_data: "",
      comments: "",
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "arrayFields",
  });
  const onSubmit = (data) => {
    data.service_data = JSON.stringify(
      data.arrayFields.filter((item) => item.checked === true)
    );
    data.service_ids = convertArray(data.arrayFields).service_ids;
    data.vend_serv_id = props.apiResponse?.data?.vend_serv_id;
    data.vendServId = props.apiResponse?.data?.vend_serv_id;
    const { arrayFields, ...finalData } = data; //remove key arrayFields
    if (props.apiResponse.success) {
      update_vendor_service(
        finalData,
        handleClose,
        props.returnManageService,
        enqueueSnackbar,
        msgSuccessU,
        msgFail
      );
    } else {
      save_vendor_service(
        finalData,
        handleClose,
        props.returnManageService,
        enqueueSnackbar,
        msgSuccess,
        msgFail
      );
    }
  };

  function getSettingServices(settingId) {
    getRequest("LbAdmin/GetSettingValues/" + settingId)
      .then((resp) => {
        if (resp?.status === 200) {
          setServiceList(resp.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(msg.failedmsg, {
          variant: "error",
        });
      });
  }

  const UserDetails = (e) => {
    setValue("vendor_id", user?.vendor_id);
    setValue("business_type", parseInt(user?.setting_value_id));
  };

  // Watch Handler to see input value and its state
  const WatchHandler = () => {
    const subscription = watch((value) => {
      value.arrayFields.some((f) => f.checked);
    });
    return () => subscription.unsubscribe();
  };

  React.useEffect(() => {
    UserDetails();
  }, []);

  React.useEffect(() => {
    WatchHandler();
  }, [watch]);

  React.useEffect(() => {
    if (!props.apiResponse.success) {
      getSettingServices(
        getBusinessServicesSettingId(parseInt(user?.setting_value_id))
      );
    }
  }, [props.apiResponse]);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className="mb-4"
      >
        {t("Shared.Service_management.MANAGE_SERVICE")}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          {t("Shared.Service_management.MANAGE_SERVICE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Shared.Service_management.SELECT_LIST_MSG")}
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {fields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div>
                      <Checkbox
                        {...register(`arrayFields.${index}.checked`)}
                        checked={watch(`arrayFields.${index}.checked`)}
                      />
                      <LbTitle name={item.setting_value} size="15"></LbTitle>
                    </div>
                    <div>
                      {/* {watch(`arrayFields.${index}.checked`) && ( */}
                      <TextField
                        size="small"
                        id="outlined-description"
                        placeholder="Service description"
                        {...register(
                          `arrayFields.${index}.service_description`
                        )}
                        value={watch(
                          `arrayFields.${index}.service_description`
                        )}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                      {/* )} */}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-8">
              <TextField
                multiline
                rows={4}
                size="small"
                id="outlined-comments"
                placeholder="About the business"
                {...register(`comments`)}
                variant="outlined"
                style={{ width: "100%" }}
                className="mb-4"
              />
            </div>
            <div className="flex flex-end">
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                size="small"
                className="mr-5"
              >
                {t("Shared.CNCL")}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="small"
              >
                {t("Shared.SUBMIT")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
