import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TemplateView from 'shared/template/template-view';
//import './template.scss';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    imgbg: {
        // marginTop: '6rem',
        height: '100%',
        width: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
        // backgroundPositionY: 'center'
    },
    alignDetail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%',
        width: '100%',
        margin: 'auto',
        textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px #fafafa'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewDetail({ handleClose, open, item }) {


const value = {
    "groomName": item?.groom_name,
    "groomMother": item?.groom_mother_name,
    "groomFather": item?.groom_father_name,
    "groomGrandmother": item?.groom_grand_mother_name,
    "groomGrandfather": item?.groom_grand_father_name,
    "tilakSangeetDate": item?.tilak_sangeet_date,
    "brideName": item?.bride_name,
    "brideMother": item?.bride_mother_name,
    "brideFather": item?.bride_father_name,
    "brideGrandmother": item?.bride_grand_mother_name,
    "brideGrandfather": item?.bride_grand_father_name,
    "weddingDate": item?.lagan_date,
    "devotional": item?.devine_quote,
    "venueAddress": item?.event_venue,
    "guardianName": item?.guardianName,
    "inviteMessage": item?.custom_invite_msg,
    "address": item?.invitee_address,
    "template": item?.template_name
}

const data=item?.template_name;

    return (
        <div>
              <TemplateView handleClose={handleClose} open={open} data={data} value={value}></TemplateView> 
    </div>
    );
}
