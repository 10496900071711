import { Grid, Icon, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BUSINESSCATEGORYLIST } from "core/utils/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LbTitle from "shared/components/lb-title/lb-title";
import "../new-landing.scss";
import { useState } from "react";
import { log_activity } from "shared/services/common-service";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 8px 8px 8px",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
  },
}));
function Category(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  useState(() => {
    log_activity("Category-page load", "category.jsx", "Viewed categories");
  }, []);
  return (
    <>
      <div className={classes.root}>
        <LbTitle size={16} name={t("Shared.SELECTCATEGORY")}></LbTitle>
        <div className="category">
          <Grid container spacing={1}>
            {BUSINESSCATEGORYLIST?.map((item, index) => {
              return (
                <Grid item xs={4} md={3} sm={3} lg={2} key={index}>
                  <Link to={item.link} className="no-text-style">
                    <Paper className="paper">
                      {item.live ? "" : <div className="soon">Soon</div>}
                      <Icon className="material-icons category-icon">
                        <img
                          alt="laganimg"
                          className={classes.imageIcon}
                          src={`/images/icons/1/${item.icon}.png`}
                        />
                      </Icon>
                      <div className="category-btn">{t(item.title)}</div>
                    </Paper>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Category;
