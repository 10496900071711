import React, { useEffect, useState } from "react";
import { Card, Divider, Grid, makeStyles } from "@material-ui/core";
import CardTopPart from "./card-top-part";
import RatingCard from "shared/review-rating/rating-card";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import * as appActions from "redux/actions/appActions";
import { get_vendor_reviews } from "vendor/services/vendor_helper";
import "./vendor-details.scss";
import { useTranslation } from "react-i18next";
import VendorServicesList from "../vendor-services-list/vendor-services-list";
import { get_vendor_service } from "vendor/services/service_manage_helper";
import LbTitle from "shared/components/lb-title/lb-title";
import LbBestDealAd from "shared/components/lb-best-deal-ad";
import BusinessChip from "../../shared/components/business-chip";
import { get_business_type } from "vendor/services/owner_reg_helper";
import { GAPageViewTrigger, getBusinesNameBySettingValueId } from "core/utils/utilities";
import { SEO } from "core/common/seo-title";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: "0px 4px"
  },
  selectLayout: {
    backgroundColor: "#fff",
    marginLeft: "4px",
    marginRight: "4px",
  },
}));

export default function VendorDetails() {
  const classes = useStyles();
  const appData = useSelector((state) => state.appData);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [vendorReviews, setVendorReviews] = useState([]);
  const { t } = useTranslation();
  const search = useLocation().search;
  const settingValueId = new URLSearchParams(search).get("settingValueId");
  const favIndex = new URLSearchParams(search).get("favInx");
  const [serviceList, setServiceList] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const [businessType, setBusinessType] = useState([]);

  const msg = {
    failedmsg: t("HelperMsg.GETVENDORREVIEWS"),
  };
  const msgFail = {
    failedmsg: t("HelperMsg.FAILLED"),
  };
  function GetVendorReviews() {
    get_vendor_reviews(
      appData?.selectedVendor?.vendor_id,
      enqueueSnackbar,
      dispatch,
      history,
      appActions,
      setVendorReviews,
      msg
    );
  }

  // Handle use effect
  useEffect(() => {
    //  console.log("Main");
    if (appData?.user?.account_id > 0) {
      GetVendorReviews();
      // Get vendor services
      get_vendor_service(
        setServiceList,
        setApiResponse,
        appData?.selectedVendor?.vendor_id,
        settingValueId,
        enqueueSnackbar,
        msgFail
      );
    } else {
      let location = {
        pathname: "/signin",
        state: { prevRoute: history.location.pathname },
      };
      history.push(location);
      enqueueSnackbar(t("HelperMsg.LOGIN_BEFORE"), {
        variant: "info",
      });
    }
  }, [settingValueId, favIndex]);

  const pageSEOTitle = {
    name: "Lagan Booking",
    sub_title: t(getBusinesNameBySettingValueId(settingValueId)) + " - Vendor Details",
    descriptio: "Search wedding vendors near your location",
  };


  useEffect(() => {
    GAPageViewTrigger(window.location.pathname + window.location.search,"vendor-details");
    get_business_type(enqueueSnackbar, setBusinessType, msgFail);
  }, [settingValueId, favIndex]);
  const refreshList = () => {};

  return (
    <div>
       <SEO {...pageSEOTitle} />
      <CardTopPart
        vendorDetails={appData.selectedVendor}
        settingValueId={settingValueId}
        refreshList={refreshList}
        favIndex={favIndex}
      ></CardTopPart>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {apiResponse?.data?.comments?.length > 0 ? (
            <>
              <LbTitle size="18" name={t("Shared.ABOUT")} />
              <LbTitle size="16" name={": " + apiResponse?.data?.comments} />
            </>
          ) : (
            ""
          )}
          <VendorServicesList
            serviceList={serviceList}
            apiResponse={apiResponse}
            viewOnly={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className="mb-8" />
          <LbTitle
            className="ml-4"
            size="16"
            name={t("Vendor.VendorDetails.ALSO_LISTED")}
          />
          <Card className="">
            <BusinessChip
              settingValueId={appData?.selectedVendor?.setting_value_id}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Divider className="mb-8" />
          <LbBestDealAd
            settingValueId={settingValueId}
            vendorId={appData?.selectedVendor?.vendor_id}
            text={t("Vendor.VendorDetails.BEST_DEAL_AD_TXT")}
            iconName={"card_giftcard_icon"}
            fontSize={100}
          ></LbBestDealAd>
        </Grid>
        <Grid item xs={12}>
          {vendorReviews.length > 0 && (
            <RatingCard data={vendorReviews}></RatingCard>
          )}

          {vendorReviews.length === 0 && (
            <RatingCard data={vendorReviews}></RatingCard>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
