import { Button, Chip } from "@material-ui/core";
import { Call, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RatingStar from "shared/rating-star";
import { getRequest } from "core/utils/axios-client/axios-client";
import { useSnackbar } from "notistack";
import * as appActions from "redux/actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import LbFavorite from "shared/lb-favorite";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { log_activity } from "shared/services/common-service";
const useStyles = makeStyles(() => ({
  detailsCard: {
    color: "#fff",
    padding: "10px 10px",
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "10px",
    backgroundColor: "#403c3c80",
    border: "white solid 1px"

  },
  rating: {
    display: "inline-flex",
  },
  h2: {
    textAlign: "left",
  },
}));

export default function VendorDetailsCard(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectedVendor = useSelector(state => state.appData.selectedVendor);
  const search = useLocation().search;
  const settingValueId = new URLSearchParams(search).get("settingValueId");

  const viewPhone = () => {
    // console.log("View Hpone clicked");
    getRequest("LbVendor/GetVendorPhone/?vendorId=" + selectedVendor.vendor_id)
      .then(resp => {
        if (resp.status === 200) {
          // setMob(resp.data);
          selectedVendor.mobile_no = resp.data;
          dispatch(appActions.setInventoryVendor(selectedVendor));
          log_activity("GetVendorPhone", "vendor-detail-card.jsx", "Success");
        }
      })
      .catch(error => {
        // console.log(error);
        enqueueSnackbar(t("HelpreMsg.VIEWFAILED"), {
          variant: "error",
        });
        log_activity("GetVendorPhone", "vendor-detail-card.jsx", "Exception: "+JSON.stringify(error));
      });
  };

  return (
    <>
      <div className={classes.detailsCard}>
        <h2 className={classes.h2}> {props.vendorDetails?.org_name}  <LbFavorite data={props.vendorDetails} settingValueId = {settingValueId}></LbFavorite></h2>
        <h3>
          {props.vendorDetails?.first_name +
            " " +
            props.vendorDetails?.last_name}
        </h3>
        <div className={classes.rating}>
          <Chip size="medium" label={props.vendorDetails?.star_rating?.toFixed(1)}></Chip>
          <RatingStar value={props.vendorDetails?.star_rating}></RatingStar>
          <h4 className={classes.m4}>
            {props.vendorDetails?.review_count} votes
          </h4>
        </div>
        <h3>
          <Call></Call>
          {selectedVendor?.mobile_no?.length > 3
            ? selectedVendor?.mobile_no
            : props.vendorDetails?.mobile_no}
          <Button className="link" onClick={viewPhone}>
            <Visibility></Visibility>
          </Button>
        </h3>
      </div>
    </>
  );
}
