import { getRequest, postRequest } from "core/utils/axios-client/axios-client";
import { log_activity } from "shared/services/common-service";

export const save_guest = (
  payload,
  enqueueSnackbar,
  msgFail
) => { 
  postRequest(`LbGuest/SaveGuest`,payload)
    .then((resp) => {
      //  console.log(resp);
      if (resp.status === 200) {
        enqueueSnackbar('Success', {
          variant: "Success",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
    });
}


export const save_guest_list = (
  payload,
  enqueueSnackbar,
  msgFail
) => { 
  postRequest(`LbGuest/AudGuestList`,payload)
    .then((resp) => {
      //  console.log(resp);
      if (resp.status === 200) {
        enqueueSnackbar('Success', {
          variant: "Success",
        });
      }
    })
    .catch((error) => {
      enqueueSnackbar(msgFail.failedmsg, {
        variant: "error",
      });
    });
}

