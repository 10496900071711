import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import LbTitle from "shared/components/lb-title/lb-title";
import { Avatar, Grid, IconButton, TextField } from "@material-ui/core";
import BuisnessType from "shared/lb-forms/buisness-type/buisness-type";
import { useFieldArray, useForm } from "react-hook-form";
import UserDetails from "../user-details/user-details";
import BestDealView from "./best-deal-view";
import {
  getLocalStorage,
  getSelectedService,
  getUserType,
} from "core/utils/utilities";
import {
  getZipcode,
  get_vendor_service_list,
} from "shared/services/booking-service";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LbLocationNew from "shared/components/lb-location/lb-location-new";
import { log_activity } from "shared/services/common-service";
import { yupResolver } from "@hookform/resolvers/yup";
import { DealSchema } from "shared/validation-schema/validation-schema";
import LbAvtarName from "shared/components/lb-avtar-name/lb-avtar-name";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  mb20: {
    marginBottom: "20px",
  },
  mb8: {
    marginBottom: "8px",
  },
  width100: {
    width: "100%",
    marginTop: "12px",
  },
  imageIcon: {
    height: "3rem",
    width: "3rem",
    border: "1px solid #7058e7",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function BestDeal({ title, settingValueId, favIndex, vendorId, color }) {
  let responseKeys = {};
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [bestDealData, setBookingData] = useState({});
  const [listValue, setListValue] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const msgFail = {
    msg: t("HelperMsg.FAILLED"),
  };


  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(DealSchema(t)) });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "service_data",
  });

  const onSubmit = (data) => {
    // console.log(data);
    data.cust_name = data?.first_name + " " + data?.last_name;
    data.business_type = +settingValueId;
    data.cust_vend = getUserType();
    data.vendor_id = vendorId;
    data.service_data = getSelectedService(data.service_data);
    let payload = {
      ...data,
      ...responseKeys,
    };
    // console.log(payload);
    setBookingData(payload);
    setShowDetail(true);
    log_activity("Best Deal", "best-deal.jsx", "Moved to summary");
  };

  const handleClickOpen = () => {
    setOpen(true);
    log_activity("Best Deal", "best-deal.jsx", "Form opened");
  };

  const handleClose = () => {
    setOpen(false);
    log_activity("Best Deal", "best-deal.jsx", "Form closed");
  };

  const handleShowDetails = () => {
    setShowDetail(false);
  };

  const getSettingServices = () => {
    let businessType = settingValueId;
    get_vendor_service_list({
      vendorId,
      businessType,
      append,
      remove,
      enqueueSnackbar,
      msgFail,
    });
  };

  // set input name Disabled with value If Customer is loggedin else enable the field only
  const setUserDeatils = () => {
    const user = getLocalStorage("USER_DATA");
    if (user?.customer_id) {
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("cust_mob", user?.mobile_no);
      setDisableField(true);
    }
  };

  const zipCodeCallD = (zipCode) => {
    //********Set Venodr Id********/
    if (zipCode.length === 6) {
      getZipcode(zipCode, enqueueSnackbar, setListValue, setOpen);
    }
  };

  React.useEffect(() => {
    setUserDeatils();
    getSettingServices();
  }, [settingValueId, favIndex]);

  return (
    <div>
      <Button
        size="small"
        type="button"
        variant="contained"
        color={color}
        className="mr-2"
        onClick={handleClickOpen}
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            margin: "20px",
          },
        }}
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <LbAvtarName
            firstName={t("Shared.BEST_DEAL")}
            src={"/images/icons/1/best-price.png"}
          ></LbAvtarName>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          > <CloseIcon />
          </IconButton>
        </DialogTitle>
        <hr />
        <DialogContent style={{ padding: "15px" }}>
          {showDetail ? (
            ""
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <UserDetails
                  register={register}
                  errors={errors}
                  disable={disableField}
                />
                <LbTitle
                  name={t("Shared.LOCATION")}
                  size="16"
                  weight="600"
                ></LbTitle>
                <LbLocationNew
                  zipCodeCallD={zipCodeCallD}
                  listValue={listValue}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  watch={watch}
                  clearErrors={clearErrors}
                ></LbLocationNew>
                <BuisnessType
                  register={register}
                  fields={fields}
                  watch={watch}
                  errors={errors}
                  control={control}
                  clearErrors={clearErrors}
                />
                <TextField
                  id="notes"
                  size="small"
                  label={t("Shared.FORM.NOTES")}
                  {...register(`notes`)}
                  multiline
                  rows={3}
                  className={classes.width100}
                  variant="outlined"
                />
              </Grid>
              <div align="end" className="mt-8">
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleClose}
                  className="mr-5"
                >
                  {t("Shared.CANCEL")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  {t("Shared.CONFIRM")}
                </Button>
              </div>
            </form>
          )}

          {showDetail ? (
            <BestDealView
              bestDealData={bestDealData}
              handleClose={handleClose}
              handleShowDetails={handleShowDetails}
            ></BestDealView>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default BestDeal;
