import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  img: {
    position: "absolute",
    // left: -8,
    // top: -8,
  },
}));
function CardArt1({ rotate, leftS, rightS, topS, bottomS , heightS, widthS}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
       alt="designimg"
        src="/images/design/art31.png"
        className={classes.img}
        style={{
          transform: "rotate(" + rotate + "deg)",
          top: topS,
          left: leftS,
          right: rightS,
          bottom: bottomS,
          height: heightS?heightS:'75px',
          width: widthS?widthS:'75px'
        }}
      />
    </div>
  );
}

export default CardArt1;
